import { Directive, ElementRef, Input, Renderer2, OnChanges } from '@angular/core';
import { LibGeral } from '../../core/libraries/libGeral';
import { LibString } from '../../core/libraries/libString';

/**
 * Diretiva para destacar termos em um texto.
 *
 * @export
 * @class HighlightTextDirective
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[highlightText]' })
export class HighlightTextDirective implements OnChanges {

  /**
   * Termo a ser destacado.
   * @type {string}
   */
  @Input() highlightText: string;

  /**
   * Classe CSS que será utilizada para destacar os termos passados.
   *
   * @type {string}
   */
  @Input() highlightClass: string;

  private elementRef: ElementRef;
  private renderer: Renderer2;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }

  ngOnChanges(): void {
    if (LibGeral.estaPreenchido(this.highlightText) && !LibString.isNullOrEmpty(this.highlightText)) {
      const tokenized = this.tokenize(this.highlightText);
      const regex = new RegExp(tokenized.join('|'), 'gmi');
      const html = this.elementRef.nativeElement.textContent.replace(regex, (match, item) => {
        return this.replacer(match, item);
      });

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', html);
    }
  }

  private replacer(match: string, item: string): string {
    return LibString.isNullOrEmpty(this.highlightClass) ?
      `<b>${match}</b>` :
      `<span class="${this.highlightClass}">${match}</span>`;
  }

  private tokenize(keyword: string): string[] {
    const keywords = keyword.replace(new RegExp(',$', 'g'), '').split(' ');
    return keywords.map((char: string) => {
      return char.replace(new RegExp('| $', 'g'), '');
    });
  }
}
