import { Component, Input } from '@angular/core';

// import { BooleanFieldValue } from '@angular2-material/core/annotations/field-value';
// import { MdInput, MD_INPUT_DIRECTIVES } from '@angular2-material/input';

let lastInputSearchId = 0;

@Component({
  selector: 'ip-input-search',
  template: `
    <div lass="mdl-textfield mdl-js-textfield mdl-textfield--expandable">
      <label class="mdl-button mdl-js-button mdl-button--icon" for="expando1">
        <span>?</span>
      </label>
      <div class="mdl-textfield__expandable-holder">
        <input class="mdl-textfield__input" type="text" id="expando1">
        <label class="mdl-textfield__label" for="expando1">Expandable text field</label>
      </div>
    </div>`
})
export class IPInputSearchComponent {

  // <div class="mdl-textfield mdl-js-textfield mdl-textfield--expandable mdl-textfield--floating-label mdl-textfield--align-left">
  //   <label class="mdl-button mdl-js-button mdl-button--icon" attr.for="ip-input-search{{ lastInputSearchId }}">
  //     <i class="material-icons">search</i>
  //   </label>
  //   <div class="mdl-textfield__expandable-holder">
  //     <input class="mdl-textfield__input" type="text" id="ip-input-search{{ lastInputSearchId }}">
  //      <label class="mdl-textfield__label" for="sample-expandable">Expandable Input</label>
  //   </div>
  // </div>

  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() type = 'text';

  get value(): any { return this._value; };
  @Input() set value(v: any) {this._value = v; }

  private _value: any = '';

  constructor() {
    lastInputSearchId++;
  }
}
