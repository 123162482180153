export class TipoEncaminhamento {
  public Encaminhamento = "E";
  public Retorno = "R";
  public Reavaliacao = "V";
}
export abstract class SituacaoEncaminhamento {
  public Encaminhado = "E";
  public Aberto = "A";
  public Cancelado = "C";
}

export enum MenuExameArvore {
  BuscaImagem = 0,
  Documentos = "99_Documento_99",
}
