import { SimNao } from "@gemed-core/enums/gemed.enum";
import { InsumoSobraDTO } from "./insumoSobraDTO";

export class PrescricaoSeparacaoEstoqueDTO {
  PrescricaoId: number;
  PrescricaoPedidoItemId: number;
  PrescricaoNome: string;
  DataProducao: any;
  PacienteNome: string;
  PacienteId: number;
  ProtocoloNome: string;
  ResponsavelLiberacao: string;
  EstaLiberadoPeloCliente: boolean;
  EstaConciliada = false;
  EstaProduzida = false;
  ListaInsumosDesejados: Array<InsumosAtendidosDesejados>;
  ListaInsumosAtendidos: Array<InsumosAtendidosDesejados>;
}

export class InsumosAtendidosDesejados {
  Atendido: SimNao;
  IdPrescricaoItem: number;
  IdMedicamento: number;
  IdSeqDetalhe: number;
  IdUEstoque: number;
  IdPrincipioAtivo: number;
  IdInsumo: number;
  IdLote: number;
  IdSobra: number;
  IdMovimento: number;
  QuantidadeReservada: number;
  InsumoDescricao: string;
  Dose: number;
  DoseReferencia: number;
  MedidaDose: string;
  DoseComMedida: string;
  LoteDescricao: string;
  MedicamentoDescricao: string;
  MedicamentoDataValidade: Date;
  Origem: string;
  PrincipioNome: string;
  UnidadeEstoqueDescricao: string;
  Conciliado: boolean;
  UtilizaViaOral: boolean;
  QuantidadePorVolume: number;

  public static MapInsumoSobraDTOParaInsumoAtendidosDesejados(
    insumo: InsumoSobraDTO
  ) {
    const insumoAtendidoDesejado = new InsumosAtendidosDesejados();
    insumoAtendidoDesejado.IdPrincipioAtivo = insumo.IdPrincipioAtivo;
    insumoAtendidoDesejado.IdSobra = insumo.IdSobra;
    insumoAtendidoDesejado.IdInsumo = insumo.IdInsumo;
    insumoAtendidoDesejado.IdMovimento = insumo.IdMovimento;
    insumoAtendidoDesejado.IdLote = insumo.IdLote;
    insumoAtendidoDesejado.LoteDescricao = insumo.Lote;
    insumoAtendidoDesejado.MedicamentoDescricao = insumo.Descricao;
    insumoAtendidoDesejado.MedicamentoDataValidade = insumo.DataValidade;
    insumoAtendidoDesejado.Dose = insumo.Dose;
    insumoAtendidoDesejado.DoseReferencia =
      insumo.Dose * insumo.QuantidadeUtilizada;
    insumoAtendidoDesejado.MedidaDose = insumo.MedidaDose;
    insumoAtendidoDesejado.IdUEstoque = insumo.IdUEstoque;
    insumoAtendidoDesejado.UnidadeEstoqueDescricao = insumo.UnidadeEstoque;
    insumoAtendidoDesejado.IdSeqDetalhe = insumo.IdSeqDetalhe;
    insumoAtendidoDesejado.IdPrescricaoItem = insumo.IdPrescricaoItem;
    insumoAtendidoDesejado.UtilizaViaOral = insumo.UtilizaViaOral;

    if (insumo.UtilizaViaOral) {
      insumoAtendidoDesejado.QuantidadeReservada = insumo.QuantidadeDeCaixasNecessarias;
      insumoAtendidoDesejado.QuantidadePorVolume = insumo.QuantidadePorVolume;
    } else {
      insumoAtendidoDesejado.QuantidadeReservada = insumo.QuantidadeUtilizada;
    }

    return insumoAtendidoDesejado;
  }
}

export class PrescricaoInsumos {
  insumo: InsumosAtendidosDesejados;
  prescricao: PrescricaoSeparacaoEstoqueDTO;
  constructor(
    insumo?: InsumosAtendidosDesejados,
    prescricao?: PrescricaoSeparacaoEstoqueDTO
  ) {
    this.insumo = insumo;
    this.prescricao = prescricao;
  }
}
