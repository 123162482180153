import { Protocolo } from "src/app/assistencial/shared/models/protocolo.model";
import { Prescricao } from "./prescricao.model";
import { ProgramacaoConfiguracao } from "./programacaoConfiguracao.model";

export class PrescricaoSugerida extends Prescricao {
  Id: number;
  Descricao: string;
  DataBase: Date;
  IdProtocoloGrupo: number;
  NomeProtocolo: string;

  public ObterSiglasProtocolo() {
    let Siglas = "";

    this.Protocolos.forEach((protocoloGrupo) => {
      if (Siglas) {
        Siglas += ", ";
      }
      Siglas += protocoloGrupo.ProtocoloGrupo.Sigla ? protocoloGrupo.ProtocoloGrupo.Sigla : Siglas += protocoloGrupo.Protocolo.Sigla;
    });

    return Siglas;
  }

  // set NomeProtocolo(value) {
  //   setTimeout(() => (this.NomeProtocolo = value));
  // }

  // get Identificacao(): string {
  //   return this.IdentificacaoComProtocoloGrupo;
  // }

  // set Identificacao(value) {}

  Sessoes: string;
  Protocolo: Protocolo = new Protocolo();
  Medidas: any;
  Config: ProgramacaoConfiguracao = new ProgramacaoConfiguracao();
}
