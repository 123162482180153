import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellEditButtonsComponent } from './ip-cell-edit-buttons.component';
import { IPCellEditDirective } from './ip-cell-edit.directive';
import { IPCellInlineEditAutoCompleteDirective } from './ip-cell-inline-edit-autocomplete.directive';
import { IPCellInlineEditCheckBoxDirective } from './ip-cell-inline-edit-checkbox.directive';
import { IPCellInlineEditNumberDirective } from './ip-cell-inline-edit-number.directive';
import { IPCellInlineEditSelectDirective, IPCellInlineSelectComponent } from './ip-cell-inline-edit-select.directive';
import { IPCellInlineEditTextDirective } from './ip-cell-inline-edit-text.directive';
// import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';
import { IPCellDirective } from './ip-cell.directive';
import { IPColumnDirective } from './ip-column.directive';
import { IPFloatHeaderDirective } from './ip-float-header.directive';
import { IPRowDirective } from './ip-row.directive';
import { IPStickTableGroupComponent } from './ip-stick-table-group.component';
import { IPTableContainerComponent } from './ip-table-container.component';
import { IPTableHeadProgressDirective } from './ip-table-head-progress.directive';
import { IPTableDirective } from './ip-table.directive';
import { MaterialModule } from '../../../material/material.module';
import { IPCellInlineEditDataDirective } from './ip-cell-inline-edit-data.directive';

const componentes = [
  CellEditButtonsComponent,
  IPCellEditDirective,
  IPCellInlineEditAutoCompleteDirective,
  IPCellInlineEditCheckBoxDirective,
  IPCellInlineEditNumberDirective,
  IPCellInlineEditSelectDirective,
  IPCellInlineSelectComponent,
  IPCellInlineEditTextDirective,
  IPCellDirective,
  IPColumnDirective,
  IPFloatHeaderDirective,
  IPRowDirective,
  IPStickTableGroupComponent,
  IPTableContainerComponent,
  IPTableHeadProgressDirective,
  IPTableDirective,
  IPCellInlineEditDataDirective
];

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: componentes,
  exports: componentes,
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPTableModule {
}
