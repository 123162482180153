export enum EnumAlignText {
  start = <any>'start',
  end = <any>'end'
}

export interface  IPInputComponent {
  ariaLabel: string;
  align: EnumAlignText;
  disabled: boolean;
  floatingPlaceholder: boolean;
  maxLength: number;
  placeholder: string;
  required: boolean;
  type: string;
}
