import { DataPEP } from './dataPep.model';
import { PEPBase } from './pepBase.model';
import { SituacaoEncaminhamento } from './enums.model';

export class PEPEncaminhamento extends PEPBase {
  public Categoria: any;
  public DataEcaminhar: DataPEP;
  public DataRegistro: DataPEP;
  public IdAgenda: number;
  public Paciente: any;
  public Profissional: any;
  // todo: esperar que seja feito login para poder obter este dado
  public Usuario: any;
  public Tipo: any;
  public Situacao: SituacaoEncaminhamento;
  public Comentario: string;
  public InstrucaoAgendamento: string;

  constructor() {
    super();
    this.Categoria = null;
    this.DataEcaminhar = new DataPEP(null);
    this.DataRegistro = new DataPEP(new Date());
    this.IdAgenda = 0;
    this.Paciente = null;
    this.Profissional = null;
    this.Usuario = null;
    this.Tipo = null;
  }
}
