/**
 * Classe para criação de strings
 *
 * @export
 * @class StringBuilder
 */
export class StringBuilder {
  private texto: string[];

  constructor(texto?: string) {
    this.texto = [];

    if (texto) {
      this.texto.push(texto);
    }
  }
  append(texto: string): void {
    this.texto.push(texto);
  }
  appendLine(texto: string): void {
    this.texto.push(`\n${texto}`);
  }
  clear(): void {
    this.texto.length = 0;
  }
  toString(separador: string = ''): string {
    return this.texto.join(separador);
  }
}
