import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AvisoSimplesComponent } from "./core-ui/components/aviso-simples/aviso-simples.component";
import { GemedWebGuard } from "./core/guards";
import { DashBoardComponent } from "./dashboard/dashboard.component";
import { LoginPageComponent } from "./users/login-page/login-page.component";

export const URL_USER_BASE = `users`;
export const URL_USER_ACCOUNT = "user-account";
export const URL_USER_LOG_IN = "log-in";

const routes: Routes = [
  {
    path: "users/log-in",
    component: LoginPageComponent,
  },
  {
    canActivate: [GemedWebGuard],
    path: "farma",
    loadChildren:  () => import('./farma/farma.module').then(f => f.FarmaModule),
  },
  {
    canActivate: [GemedWebGuard],
    path: "assistencial",
    loadChildren:  () => import('./assistencial/assistencial.module').then(a => a.AssistencialModule),

  },
  {
    canActivate: [GemedWebGuard],
    path: "centraloperadora",
    loadChildren:  () => import('./central-operadora/central-operadora.module').then(co => co.CentralOperadoraModule),

  },
  {
    canActivate: [GemedWebGuard],
    path: "paciente",
    loadChildren:  () => import('./paciente/paciente.module').then(f => f.PacienteModule),
  },
  {
    canActivate: [GemedWebGuard],
    path: "fluxo",
    loadChildren:  () => import('./fluxo/fluxo.module').then(f => f.FluxoModule),

  },
  {
    path: "404",
    component: AvisoSimplesComponent,
  },
  {
    path: "aviso",
    component: AvisoSimplesComponent,
  },
  {
    canActivate: [GemedWebGuard],
    path: "",
    component: DashBoardComponent,
  },
  {
    path: "**",
    redirectTo: "/404",
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule {}
