/**
 * Status da pergunta.
 * 
 * @export
 * @enum {number}
 */
export enum QuestionarioPerguntaStatus {
  /**
   * Pergunta não respondida, nada informado.
   */
  NaoRespondida,
  /**
   * Pergunta marcada como não informada.
   */
  NaoInformada,
  /**
   * Pergunta que já está respondida, mas não foi editada.
   */
  NaoEditada,
  /**
   * Pergunta com resposta válida.
   */
  Valida,
  /**
   * Pergunta com resposta inválida.
   */
  Invalida,
  /**
   * Pergunta sendo editada.
   */
  EmEdicao,
  /**
   * Pergunta bloqueada, não é possível editá-la.
   */
  Bloqueada
}
