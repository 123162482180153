import { ActionReducerMap } from '@ngrx/store';
import { menuReducer } from '@pep-assistencial-store/reducers/menu.reducer';
import { GemedState } from '../state';
import { AppReducer } from './app.reducer';
import { AuthReducer } from './auth.reducer';
import { RequestReducer } from './request.reducer';
import { RouteReducer } from './route.reducer';

export const RootReducer: ActionReducerMap<GemedState> = {
  app: AppReducer,
  auth: AuthReducer,
  requests: RequestReducer,
  router: RouteReducer,
  menu: menuReducer,
};
