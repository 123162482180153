import { Action } from '@ngrx/store';
import { type } from '../../libraries';

export const CHANGE_URL = 'CHANGE_URL';
export const NAVAGATE_REQUEST = 'NAVAGATE_REQUEST';
export const NAVAGATE_ACCEPT = 'NAVAGATE_ACCEPT';
export const NAVAGATE_REJECT = 'NAVAGATE_REJECT';

export const ActionTypes = {
  CHANGE_URL: type('[navagation] Change URL.'),
  NAVAGATE_REQUEST: type('[navagation] Navagate request.'),
  NAVAGATE_ACCEPT: type('[navagation] Navagate accept.'),
  NAVAGATE_REJECT: type('[navagation] Navagate reject.')
};

export class ChangeURLAction implements Action {
  public type: string = ActionTypes.CHANGE_URL;
  constructor(public payload: string, public mensagem = "") {}
}

export class RequestNavagationAction implements Action {
  public type: string = ActionTypes.NAVAGATE_REQUEST;
  constructor(public payload: string) {}
}

export class AcceptUrlAction implements Action {
  public type: string = ActionTypes.NAVAGATE_ACCEPT;
  constructor(public payload: string) {}
}

export class RejectUrlAction implements Action {
  public type: string = ActionTypes.NAVAGATE_REJECT;
  constructor(public payload: string) {}
}

