// Referência: https://getmdl.io/components/index.html#textfields-section
import { Attribute, Component, Input, Output, EventEmitter } from '@angular/core';
import './ip-textarea.component.less';

@Component({
  selector: 'ip-textarea',
  template: `
  <mat-form-field>
    <textarea matInput
      type="text"
      [disabled]="disabled"
      [value]="model"
      [(ngModel)]="model"
      (change)="onChange($event)"
      [placeholder]="placeholder"
      [attr.maxLength]="maxLength"
      [attr.rows]="rows"
    ></textarea>
  </mat-form-field>`,
  styles: [
    `
    :host mat-input-container {
      display: flex;
      flex: 1
    }
    `
  ]
})
export class IPTextAreaComponent {
  @Input() disabled: string;
  @Input() model: string;
  @Input() placeholder: string;
  @Input() rows: number;
  // Atributos
  maxLength: number;
  @Output() private modelChange: EventEmitter<string>;

  constructor(
      @Attribute('maxLength') maxLength: number,
      @Attribute('rows') rows: number) {
    this.maxLength = maxLength || -1;
    this.rows = rows || 1;
    this.modelChange = new EventEmitter<string>();
  }
  onChange($event: any): void {
    this.modelChange.next($event.target.value);
  }
}
