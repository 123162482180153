import { Protocolo, CID } from "src/app/assistencial/shared/models";
import { ProtocoloGrupo } from "./protocoloGrupo.model";
import { Prescricao } from "./prescricao.model";
import { ProtocoloDetalhe } from "./protocoloDetalhe.model";
import { TipoCiclo } from "@gemed-core/enums/gemed.enum";

export class PrescricaoProtocolo {
  Prescricao: Prescricao;
  Protocolo: Protocolo;
  ProtocoloGrupo: ProtocoloGrupo;
  ProtocoloDetalhes: ProtocoloDetalhe[];
  Cid: CID;
  TipoCiclo: TipoCiclo;
  Ciclo: number;
  Sessao: number;


}
