import { Component, Input } from '@angular/core';

// import { LibString } from '../../libraries/';

import './ip-placeholder.component.less';

/**
 * Componente de placeholder, que permite floatable placeholder.
 *
 * @export
 * @class IPPlaceholderComponent
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'label[ip-placeholder]',
  template: `<ng-content></ng-content>`,
  // eslint-disable-next-line
  host: {
    '[class.ip-input-placeholder]': 'true',
    '[class.ip-float]': 'shouldFloat',
    '[class.ip-floating]': 'floating',
    '[attr.for]': 'for'
  }
})
export class IPPlaceholderComponent {
  /**
   * Define se o placeholder deve estar posicionado acima do input.
   * @type {boolean}
   */
  @Input() set floating(value: boolean) { this._floating = value; };
  get floating(): boolean { return this._floating; }
  _floating: boolean;
  /**
   * ID do input ao qual o placeholder está vinculado.
   * @type {string}
   */
  @Input() for: string;
  /**
   * Define se o placeholder deve flutuar quando o um valor é preenchido no input.
   * Quando esta propriedade é FALSE o placeholder é escondido quando um valor é preenchido.
   * @type {boolean}
   */
  @Input() shouldFloat = true;

  // get hasPlaceholder(): boolean { return !LibString.isNullOrEmpty(this._elementRef.nativeElement.innerHTML); }
  // private _elementRef: ElementRef;

  constructor() {
    // this._elementRef = elementRef;
  }
}
