import { Component } from '@angular/core';

import { IPButtonComponent } from './ip-button.component';

@Component({
  selector: 'ip-button-mini-fab',
  template: `
    <button mat-mini-fab [color]="color" [disabled]="disabled">
      <ng-content></ng-content>
    </button>
  `,
  styles: [`
    button {
      margin: 20px;  
      box-shadow: -6px -6px 16px 0px rgba(255,255,255,0.5), 6px 6px 16px 0px #d1cdd780 !important;
      border-radius: 50%;
    }
  `]
})
export class IPButtonMiniFabComponent extends IPButtonComponent { }
