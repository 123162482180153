<div class="page-content">
  <!--Conteudo que será paginado-->
  <ng-content></ng-content>
</div>

<div class="paging-footer">
  <ng-content select="[footer]"></ng-content>

  <div class="paging-info">
    <div [hidden]="hasRecords">{{'componentes.naoHaRegistros' | translate}}</div>

    <div class="page" [hidden]="!hasRecords">
      <span>{{'componentes.pagina' | translate}} </span>
      <span>{{ currentPage }} {{'componentes.de' | translate}} {{totalDePaginas()}}</span>
    </div>

    <div class="buttons" [hidden]="!hasRecords">
      <ip-button-icon id="btnPagPrimeira" [disabled]="!hasRecords || isFirstPage" (click)="changePage(1)">
        <i [ngClass]="{ 'highlight-icon': hasRecords && !isFirstPage }" class="material-icons">first_page</i>
      </ip-button-icon>

      <ip-button-icon id="btnPagAnterior" [disabled]="!hasRecords || isFirstPage" (click)="changePage(currentPage - 1)">
        <i [ngClass]="{ 'highlight-icon': hasRecords && !isFirstPage }" class="material-icons">keyboard_arrow_left</i>
      </ip-button-icon>

      <ip-button-icon id="btnPagProxima" [disabled]="!hasRecords || isLastPage" (click)="changePage(currentPage + 1)">
        <i [ngClass]="{ 'highlight-icon': hasRecords && !isLastPage }" class="material-icons">keyboard_arrow_right</i>
      </ip-button-icon>

      <ip-button-icon id="btnPagUltima"  [disabled]="!hasRecords || isLastPage" (click)="changePage(pagingInfo.PageCount)">
        <i  [ngClass]="{ 'highlight-icon': hasRecords && !isLastPage }" class="material-icons">last_page</i>
      </ip-button-icon>
    </div>

    <div class="records" [hidden]="!hasRecords">
      {{ initialRecordOnCurrentPage }} {{'componentes.ate' | translate}} {{ lastRecordOnCurrentPage }}, {{'componentes.totalDe' | translate}} {{ totalRecordCount }}
    </div>
  </div>
</div>
