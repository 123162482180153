import { Component, Input, ElementRef, EventEmitter, Output, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { LibGeral } from '../../../core/libraries/libGeral';
import { LibString } from '../../../core/libraries/libString';
import { TranslateService } from '@ngx-translate/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as libMoment from 'moment';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM/YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM/YYYY',
  },
};

@Component({
  selector: 'ip-datepicker',
  styleUrls: ['./ip-datepicker.component.scss'],
  template: `
    <mat-form-field
      color="{{hasError ? 'warn': ''}}"
      [floatPlaceholder]="floatingPlaceholder"
      [disabled]="!editable">
      <input
        matInput
        [formControl]="model"
        [value]="model"
        [matDatepicker]="picker"
        [readonly]="readOnly"
        [disabled]="!editable"
        (dateChange)="onDateChange($event)"
        [placeholder]="placeholder">
      <mat-datepicker-toggle matSuffix
        [disabled]="!editable"
       
        [for]="picker">
      </mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker
      startView="multi-year"
      (yearSelected)="(formato === 'YYYY' ? chosenYearHandler($event, picker) : null)" 
      (monthSelected)="(formato === 'MM/YYYY' ? chosenMonthHandler($event, picker) : null)"
      #picker
    >
    </mat-datepicker>
   `,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
  // eslint-disable-next-line
  host: {
    'class': 'flatpickr input-group',
    'data-wrap': 'true',
    'data-clickOpens': 'false'
  }
})
export class IPDatePickerComponent implements OnDestroy, OnInit {
  static nextUniqueId = 0;
  @Input() disabled = false;
  @Input() floatingPlaceholder = true;
  @Input() model: Date;
  @Input() placeholder: string = null;
  @Input() readOnly = false;
  @Input() openPickerOnCreate: boolean;
  @Input() formato: string = "DD/MM/YYYY";
  @ViewChild('picker') picker: MatDatepicker<any>;
  @Output() modelChange: EventEmitter<Date>;
  @Output() closed = new EventEmitter<boolean>();

  get editable(): boolean { return !this.readOnly && !this.disabled; }
  get empty(): boolean { return LibGeral.estaEmBranco(this._value); }
  get focused(): boolean { return this._focused; }

  hasError: boolean = false;
  private _focused = false;
  private _opened = false;
  private _value: Date;

  /* Proprieade com a qual é realizado o bind.
   * Isto é necessário pois o input espera uma STRING, e não um objeto Date. */
  get valueAsString(): string { return this.model ? this.model.toISOString().substring(0, 10) : null; }

  // Visual elements
  private _wrapperElement: ElementRef;
  // private _datepickerElement: any;
  private eventSelect: any;

  inputId = `ip-datepicker-${IPDatePickerComponent.nextUniqueId++}-placeholder`;
  initialValue: any;
  constructor(element: ElementRef, private adapter: DateAdapter<any>, private translate: TranslateService) {
    this.eventSelect = null;
    this._wrapperElement = element;
    this.modelChange = new EventEmitter<Date>();
    this.mudarLocale();
    this.translate.onLangChange.subscribe(() => {
      this.mudarLocale();
    });
  }

  ngOnInit(): void {
    if (this.picker) {
      this.picker.closedStream.subscribe(x => this.closed.next(true));
    }
  }

  // eslint-disable-next-line
  ngAfterViewInit(): void {
    if (this.openPickerOnCreate) {
      setTimeout(() => {
        this.picker.open();
      });
    }
  }

  mudarLocale(): void {
    const lang = this.translate.currentLang;
    this.adapter.setLocale(lang);
  }

  hasPlaceholder(): boolean {
    return LibGeral.estaPreenchido(this.placeholder) && !LibString.isNullOrEmpty(this.placeholder);
  }
  toggleCalendar(): void {
    this._opened = !this._opened;
  }

  onFocus($event: any): void {
    this._focused = true;
  }
  onBlur($vent: any): void {
    this._focused = false;
  }

  onCalendarOpen(): void {
    this._opened = true;
  }
  onCalendarClose(): void {
    this._opened = false;
  }


  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.model = normalizedYear.toDate();
    this.modelChange.emit(this.model);
    datepicker.close();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.model = normalizedMonth.toDate();
    this.modelChange.emit(this.model);

    datepicker.close();
  }

  onDateChange(event: MatDatepickerInputEvent<Moment>): void {
    if (event instanceof MatDatepickerInputEvent) {
      const valor = (<any>event.targetElement).value;
      const data = libMoment(valor, this.formato);
      if (data.isValid()) {
        this.hasError = false;
        this.model = data.toDate();
        this.modelChange.emit(data.toDate());
      } else {
        this.hasError = true;
        this.model = valor;
        this.modelChange.emit(valor);
      }
    }
  }
  setEventHandlerSelectValue(element: HTMLElement): void {
    // workaround para evitar que evento se propague e feche dialog
    if (this.eventSelect === null) {
      this.eventSelect = (event) => {
        event.stopPropagation();
      };
      this.eventSelect.target = element;
      (<any>element).addEventListener('click', this.eventSelect, false, { passive: true });
    }
  }
  ngOnDestroy(): void {
    if (this.eventSelect) {
      const element = <HTMLElement>this.eventSelect.target;
      element.removeEventListener('click', this.eventSelect, false);
    }
  }
}
