import {
  ViewContainerRef,
  ResolvedReflectiveProvider,
  ReflectiveInjector,
  Type,
  ComponentRef,
  ComponentFactoryResolver,
  Injector
} from '@angular/core';

import { LibCollections } from './libCollections';
import * as moment from 'moment';
export class LibComponent {

  static MergeInjectors(injector: Injector, bindings: ResolvedReflectiveProvider[]): Injector {
    const contextInjector = injector;
    let childInjector = null;

    if (LibCollections.isArray(bindings) && LibCollections.hasItems(bindings)) {
      childInjector = ReflectiveInjector.fromResolvedProviders(bindings, contextInjector);
    } else {
      childInjector = contextInjector;
    }
    return childInjector;
  }
  /**
   * Cria componente e o inserer no ViewHost passado.
   *
   * @static
   * @param {Type<any>} component Componente a ser criado.
   * @param {ComponentFactoryResolver} componentFactoryResolver Objeto contendo as dependências do componente a ser criado.
   * @param {ViewContainerRef} viewContainer View onde o componente criado será inserido.
   * @param {ResolvedReflectiveProvider[]} bindings Valores a serem injetados no componente criado.
   * @returns {ComponentRef<any>} Retorna instância do componente.
   *
   * @memberOf LibComponent
   */
  static CreateComponent(component: Type<any>,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainer: ViewContainerRef,
    bindings: ResolvedReflectiveProvider[]
  ): ComponentRef<any> {
    /* O "ComponentResolver" carrega e retorna o "ComponentFactory", que será responsável por
     * instanciar o componente a ser aberto. */
    const componentFactory = componentFactoryResolver.resolveComponentFactory(component);

    /* A passagem de informações para o componente dialog a ser aberto é realizada através
     * do INJECTOR. Por isto os bindinsgs passados são passados no construtor do Injector, que
     * então é passado para o método "createComponent". */
    const contextInjector = viewContainer.parentInjector;
    const childInjector = this.MergeInjectors(viewContainer.parentInjector, bindings);
    const componentRef = viewContainer.createComponent(componentFactory, viewContainer.length, childInjector);
    return componentRef;
  }
  /**
   * Cria componente e o inserer no ViewHost passado.
   *
   * @static
   * @param {Type<any>} component Componente a ser criado.
   * @param {ComponentFactoryResolver} componentFactoryResolver Objeto contendo as dependências do componente a ser criado.
   * @param {ViewContainerRef} viewContainer View onde o componente criado será inserido.
   * @param {ResolvedReflectiveProvider[]} bindings Valores a serem injetados no componente criado.
   * @returns {Promise<ComponentRef<any>>} Promise que retornará o componente criado.
   *
   * @memberOf LibComponent
   */
  static CreateComponentWithPromise(component: Type<any>,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainer: ViewContainerRef,
    bindings: ResolvedReflectiveProvider[]): Promise<ComponentRef<any>> {
    return Promise.resolve(this.CreateComponent(component, componentFactoryResolver, viewContainer, bindings));
  }

  static AddClass(target, cssClass): HTMLElement | any {
    if (target) {
      target.classList.add(cssClass);
    }
    return target;
  }
  static RemoveClass(target, cssClass): HTMLElement | any {
    if (target) {
      target.classList.remove(cssClass);
    }
    return target;
  }

  static persistirQuestionarioPendente(state, questionario) {
    const { IdPaciente } = state.paciente;
    const auth = JSON.parse(sessionStorage.getItem("auth"));
    const nomeClinica = localStorage.getItem("nomeClinica");
    const key = String(auth.Usuario.IdUsuario) + "|" + nomeClinica;
    const questionariosPendentes = new Map(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null);

    const evolucaoPendente = {
      usuario: auth.Usuario.IdUsuario,
      horario: moment().format("HH:mm:ss DD-MM-YYYY"),
      questionario: questionario,
      resposta: state.respostas.get(questionario.IdPergunta),
      paciente: IdPaciente,
    };

    questionariosPendentes.set(questionario.uid, evolucaoPendente);

    localStorage.setItem(key, JSON.stringify(Array.from(questionariosPendentes)));
  }

  static obterChaveLocalStorage() {
    const auth = JSON.parse(sessionStorage.getItem("auth"));
    const nomeClinica = localStorage.getItem("nomeClinica");
    return String(auth.Usuario.IdUsuario) + "|" + nomeClinica;
  }

  static persistirRespostaQuestionarioPendente(resposta) {
    const key = this.obterChaveLocalStorage();
    const questionariosPendentes = new Map(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null);

    questionariosPendentes.set(resposta.uid, { ...questionariosPendentes.get(resposta.uid) as any, resposta});

    localStorage.setItem(key, JSON.stringify(Array.from(questionariosPendentes)));
  }

  static removeQuestionarioPendenteFromLocalStorage(QuestionarioUID) {
    const key = this.obterChaveLocalStorage();
    const questionariosPendentes = new Map(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null );

    questionariosPendentes.delete(QuestionarioUID);

    localStorage.setItem(key, JSON.stringify(Array.from(questionariosPendentes)));
  }

  static getQuestionariosPendentesFromLocalStorage(state: any) {
    const key = this.obterChaveLocalStorage();
      const questionariosPendentes = new Map(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null );
    return state;
  }


  static mapToArray(value: any): any {
    return Array.from(value);
  }

  static arrayToMap(value: Array<any>) {
    return new Map(value);
  }

  /**
  * Ajusta o tamanho da textArea e adiciona também um limite de altura caso o texto seja muito grande.
  * @param event Passar o event do EventBinding
  *
  * @obs por padrão, o text area terá um limite de crescimento de até 150px.
  */
  static resizeTextArea(event: any): void {
    const  textArea =  event.target;
    const heightLimit = 150;
    textArea.style.height = 'auto';
    textArea.style.height = Math.min(textArea.scrollHeight, heightLimit) + "px";
  }

}
