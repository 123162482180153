import {
  ComponentFactoryResolver, Directive, ElementRef, Host, Input, Optional, Renderer2, Type, ViewContainerRef
} from '@angular/core';

import { IPCellDirective } from './ip-cell.directive';
import { IPInputTextComponent } from '../input';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';

/**
 * Diretiva para edição de texto inline no componente ip-table.
 * Quando a celula é passada para o modo de edição, a diretiva altera
 * seu conteúdo para apresentar um campo onde o usuário poderá inserir texto.
 *
 * @export
 * @class IPCellInlineEditTextDirective
 * @extends {IPCellInlineEditDirective<string>}
 */
@Directive({ selector: '[ip-cell-inline-edit-text]' })
export class IPCellInlineEditTextDirective extends IPCellInlineEditDirective<string> {

  @Input() maxLength = -1;

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);
  }

  public getComponent(): Type<IPInputTextComponent> {
    return IPInputTextComponent;
  }

  public setComponentProps(editorComponent: IPInputTextComponent): void {

    if (this.maxLength > -1) {
      editorComponent.maxLength = this.maxLength;
    } else {
      editorComponent.maxLength = 356;
    }
    editorComponent.model = this.cell.getCurrentValue();
  }
  getValue(): string {
    return this.component.getRawValue();
  }
}
