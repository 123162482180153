import { Component, Input } from "@angular/core";
import {
  IPConfirmDialogConfig,
  IPDialogConfig,
  IPDialogResolver,
} from "../../../core/dialog/ipDialog.service";

@Component({
  selector: "ip-confirm-dialog",
  templateUrl: "./ip-confirm-dialog.component.html",
})
export class IPConfirmDialogComponent {
  config: IPConfirmDialogConfig;
  resolver: IPDialogResolver;
  @Input() ConfirmAction: Function = null;
  @Input() CancelAction: Function = null;

  constructor(config: IPDialogConfig) {
    this.config = <IPConfirmDialogConfig>config;
    if (!this.config) {
      throw new Error(
        'O objeto de configuração deve ser passado para o componente "IPDialogConfirm".'
      );
    }
  }

  handleConfirmar(): void {
    if (this.ConfirmAction !== null) {
      this.ConfirmAction();
    } else {
      this.resolver.resolve(true);
    }
  }
  handleRecusar(): void {
    if (this.CancelAction !== null) {
      this.CancelAction();
    } else {
      this.resolver.dismiss();
    }
  }
}
