import { PacienteSituacao, SimNao, Status } from "@gemed-core/enums/gemed.enum";
import { IAlergia, IAutorizacao, ICidade, IClinica, IConvenioPlano, IEstadoCivil, IFluxo, IPacienteComplento, IPacienteConta, IPacienteFoto, IPacienteObito, IUsuario } from "@gemed-core/interfaces/gemed.interface";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { Prescricao } from "@gemed-core/models/prescricao.model";
import { Profissional } from "@gemed-core/models/profissional.model";
import { Sexo } from "@gemed-core/models/sexo.enum";
import { Documento } from "src/app/gestao-documentos/shared/models";
import { MedicoEncaminhador } from "../../central-medico/shared/models/medicoEncaminhador.model";
import { Convenio } from "./paciente-convenio.model";

declare const Proxy: any;

class Endereco {
  public Descricao: string = null;
  public Complemento: string = null;
  public Bairro: string = null;
  public Cep = '';
  public Numero: number = null;
}

export class Paciente implements IPaciente {

  public get Id(): number { return this.IdPaciente; }
  public set Id(value: number) { this.IdPaciente = value; }
  public IdPaciente: number = null;

  public Bloqueado: string = "Não";
  public Nome: string = null;
  public Pesquisa: string = null;
  public CPF: string = null;
  public Sexo: string = null;
  public Fone: string = null;
  public FoneAux: string = null;
  public Responsavel: string = null;
  public Responsavel2: string = null;
  public FoneR: string = null;
  public NomeMae: string = null;
  public DocumentoValor: string = null;
  public Profissao: string = null;
  public EMail: string = null;
  public DataRegistro: Date = null;
  public DataAtualizado: Date = null;
  public RecebeMalaDireta: string = null;
  public Observacao: string = null;
  public Situacao: string = null;
  EnderecoCompleto?: any;

  public Status: string = null;
  public FoneCelular: string = null;
  public NomeSocial: string;
  public NomeSocialPesquisa: string;

  public CodIntegracao: string = null;
  public Peso: number = null;
  public Altura: number = null;
  public TMPCodIntegracao?: number;
  public Matricula: string = null;
  public Plano: string = null;
  public ValidadeCarteira: Date = null;
  public IdadeUnidade: string = null;
  public PacientePesquisa: SimNao;
  public CNS: string;
  public PossuiEmail: string;
  public OrgaoEmissorRG: string;
  public UFOrgaoRG: string;
  public PaisRG: string;
  public PacienteObito: IPacienteObito;
  public Profissional: Profissional = null;
  public Endereco: Endereco;

  public Complemento: IPacienteComplento; // todo PacienteComplemento
  public Documento: Documento = null;
  public EstadoCivil: IEstadoCivil;
  public Cidade: ICidade;
  public Convenio: Convenio; // Convenio
  public Foto: IPacienteFoto; // PacienteFoto
  public Encaminhador: MedicoEncaminhador;
  public Clinica: IClinica;
  public Usuario: IUsuario;
  public ConvenioPlano: IConvenioPlano;
  public Fluxo: IFluxo[];
  public Autorizacao: IAutorizacao[];
  public Alergias: IAlergia[];
  public Contas: IPacienteConta[];
  public Prescricoes: Prescricao[];

  public HasChange = false;
  public Idade: number = null;
  public IdDocumento: number = null;
  public NumProntuario: string;

  get IdProfissional(): number {
    return this.Profissional ? this.Profissional.Id : null;
  }

  set IdProfissional(value) {
    if (!LibGeral.estaPreenchido(this.Profissional)) {
      this.Profissional = Object.assign(new Profissional, { Id: value });
    } else {
      this.Profissional.Id = value;
    }

  }

  SituacaoPaciente: PacienteSituacao;
  StatusPaciente: Status;
  SexoPaciente: Sexo;


  public Nascimento: Date = null;
  public NaoPossuiEmail: boolean = null;

  constructor() {
    this.Situacao = 'A';
    this.Status = 'A';
    this.Sexo = Sexo.Feminino;
  }

}
/**
 * Cria um proxy para paciente possibilitando manipular comportamento da classe em tempo de execução
 * @param  {Paciente} paciente - objeto a ser manipulado
 * @param  {any} validators - Objeto
 * @param  {any={}} traps
 * @returns Paciente
 */
// export function PacienteProxy(paciente: Paciente, validators: Map<string, Function> = new Map<string, any>(), traps: any = {}): Paciente {
//   return new Proxy(paciente, Object.assign({
//     get: function getter(target: Paciente, property: string): any {
//       if (property === 'Endereco' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = new Endereco();
//       }
//       if (property === 'EstadoCivil' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = {
//           IdECivil: 1,
//           Descricao: null
//         };
//       }
//       if (property === 'Cidade' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = {
//           IdCidade: null,
//           Descricao: null,
//           UF: {
//             Uf: null,
//             Descricao: null
//           }
//         };
//       }
//       if (property === 'Documento' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = {
//           IdDocumento: null,
//           Descricao: null
//         };
//       }
//       if (property === 'Profissional' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = {
//           IdProfissional: null
//         };
//       }
//       if (property === 'Status' && LibGeral.estaEmBranco(target[property])) {
//         target[property] = 'A';
//       }
//       return target[property];
//     },
//     set: function setter(target: Paciente, property: string, value: any): boolean {
//       target.HasChange = true;
//       target[property] = value;
//       if (property === 'IdProfissional') {
//         target.Profissional.IdProfissional = value;
//       }
//       if (validators.has(property)) {
//         validators.get(property)(target, property, value);
//       }
//       return true;
//     }
//   }, traps));
// }
