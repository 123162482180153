/* Componente utiliza classes da bliblioteca Material Design Light
 * Referência: https://getmdl.io/components/index.html#tables-section */

import { Component } from '@angular/core';
import { CellEditButtonsComponent } from './ip-cell-edit-buttons.component';


@Component({
    selector: 'ip-table-container',
    styleUrls: ['./ip-table.scss'],
    template: `<ng-content></ng-content>`
})
export class IPTableContainerComponent { }
