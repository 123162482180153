import {
  AfterViewInit, ComponentFactoryResolver, Directive, ElementRef, Input, Renderer2,
  ResolvedReflectiveProvider,
  Type,
  ViewContainerRef
} from '@angular/core';
import { LibComponent } from '../../../core/libraries/libComponent';
import { IPCellDirective } from './ip-cell.directive';

@Directive()
export abstract class IPCellInlineEditDirective<T> implements AfterViewInit {
  @Input() allowEdit: boolean;
  public obterValoresComplexos: Function;
  /**
   * Indica se a cell está em modo de edição (editor sendo apresentado).
   * @protected
   * @type {boolean}
   */
  public get active(): boolean { return this.isEditActive; }
  public set active(value: boolean) {
    this.isEditActive = value;
  }

  public cell: IPCellDirective;
  public component: any;
  private childNodes: Array<any>;
  private componentRef: any;
  private componentResolver: ComponentFactoryResolver;
  private element: ElementRef;
  private isEditActive: boolean;
  private renderer: Renderer2;
  private viewContainerRef: ViewContainerRef;

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    cell: IPCellDirective) {
    this.allowEdit = true;
    this.cell = cell;
    this.componentResolver = componentResolver;
    this.viewContainerRef = viewContainerRef;
    this.element = element;
    this.renderer = renderer;
    this.isEditActive = false;
    this.obterValoresComplexos = null;
  }

  ngAfterViewInit(): void {
    this.cell.setarEditor(this);
  }

  /**
   * Retorna valor do editor.
   *
   * @protected
   * @returns {T} (description)
   */
  getValue(): T {
    return this.component.model;
  }
  /**
   * Apresenta o editor da celula, permitindo edição do conteúdo.
   */
  show(): void {
    if (this.active === false) {
      this.active = true;
      const element = this.getElement();
      // Armazena conteúdo da celula para restaurar quando a edição for concluída
      // _.toArray(
      this.childNodes = <any>element.childNodes;
      element.innerHTML = '';
      // mata instancia antiga de um componente
      if (this.componentRef) {
        this.componentRef.destroy();
        this.componentRef = null;
      }
      // Cria instancia do componente de edição e o armazena
      LibComponent.CreateComponentWithPromise(
        this.getComponent(),
        this.componentResolver,
        this.viewContainerRef,
        this.getComponentBinding())
        .then((componentRef: any) => {
          // Armazena instancia do componente criado
          this.componentRef = componentRef;
          const componentElement = this.componentRef.hostView.rootNodes[0];
          this.component = this.componentRef.instance;
          this.setComponentProps(this.component);
          // Equivalente: element.appendChild(componentElement);

          this.renderer.appendChild(element, componentElement);
        });
    }
  }


  /**
   * Esconde o editor, para apresentar somente o conteúdo em modo `read-only`.
   */
  hide(): void {
    // Quando a edição for concluida o componente será destruído e a celula será restaurada
    this.active = false;
    const element = this.getElement();
    const fragmento = document.createDocumentFragment();
    element.innerHTML = '';
    for (let i, len = this.childNodes.length; i < len; i++) {
      fragmento.appendChild(this.childNodes[i]);
    }
    element.appendChild(fragmento);
  }

  /**
   * Retorna os binding que serão passados para a instanciação do componente de edição.
   *
   * @protected
   * @returns {ResolvedReflectiveProvider[]} (description)
   */
  public getComponentBinding(): ResolvedReflectiveProvider[] {
    return null;
  }

  /**
   * Retorna o elemento da celula.
   *
   * @protected
   * @returns {HTMLElement} (description)
   */
  public getElement(): HTMLElement {
    return <HTMLElement>this.element.nativeElement;
  }

  public setComponentProps(editorComponent: any): void {
    // Seta o valor atual do componente com o valor da celula
    this.component.model = this.cell.getCurrentValue();
  }

  /**
   * Retorna o componente do editor, que será adicionado na celula.
   * @protected
   * @abstract
   * @returns {Type} (description)
   */
  public abstract getComponent(): Type<any>;
}
