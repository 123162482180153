import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPToogleComponent } from './ip-toogle.component';
import { MaterialModule } from '../../../material/material.module';
@NgModule({
  imports: [MaterialModule],
  declarations: [IPToogleComponent],
  exports: [IPToogleComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPToogleModule {
}
