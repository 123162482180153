export type Status = 'running' | 'success' | 'error';

export const STATUS_RUNNING = 'running';
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';

export class IPRequest {
  status: Status = STATUS_RUNNING;
  data: any = null;
  error: any = null;
  get isRunning(): boolean { return this.status === STATUS_RUNNING; }
  get isSuccess(): boolean { return this.status === STATUS_SUCCESS; }
  get isError(): boolean { return this.status === STATUS_ERROR; }
}

export const RequestStateFactory = () => new Map<string, IPRequest>();
