import { Component } from '@angular/core';

import './ip-content.component.less';

@Component({
  selector: 'ip-content',
  template: `
    <ng-content></ng-content>
  `
})
export class IPContentComponent {

}
