<div *ngIf="!hide">
  <div *ngIf="habilitarIcone" class="inputicon">
    <label for="selecao-arquivo">
      <ip-button-default (onClick)="openFileExplorer()" [materialIconName]="'cloud_upload'" [tipoDeBotao]="'fab'"
        [formatoRedondo]="true" [textoAuxiliar]="
          hasErrorType.length <= 0 ? 'max: 2MB' : 'messagemErroUploadType'
        "></ip-button-default>
    </label>
  </div>

  <label *ngIf="!habilitarIcone" for="selecao-arquivo" class="inputbutton ">{{
    textoLabel
    }}</label>
  <form #formArquivos>
    <input #arquivoInput id="selecao-arquivo" type="file" multiple="multiple" accept=".jpg, .jpeg, .png, .pdf"
      (change)="uploadFile($event)" (click)="(arquivoInput.value)" />
  </form>
</div>
