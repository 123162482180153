import { Component, Input, Output, ElementRef, EventEmitter, ChangeDetectionStrategy, OnInit, OnChanges } from '@angular/core';
// import { IPSliderComponent } from './ip-slider.component';
import { LibGeral } from '../../../core/libraries/libGeral';

@Component({
  selector: 'ip-slider-descritivo',
  templateUrl: './ip-slider-descritivo.component.html',
  styleUrls: ['./ip-slider.component.scss']
})
export class IPSliderDescritivoComponent implements OnChanges {
  @Input() hintMax: string;
  @Input() hintMin: string;
  @Input() max: number;
  @Input() min: number;
  @Input() model: number;
  @Input() step: number;
  @Input() options: any[];
  @Input() funcaoHerdada: Function;
  @Input() naoInformado: boolean;


  @Output() changed: EventEmitter<number>;
  @Output() modelChange: EventEmitter<number>;

  private elementRef: ElementRef;
  public option: any;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.changed = new EventEmitter<number>();
    this.min = 0;
    this.max = 100;
    this.modelChange = new EventEmitter<number>();
    this.step = 1;
  }

  ngOnChanges() {
    this.alterarDescricao();
  }

  alterarDescricao(): void {
    if (LibGeral.estaPreenchido(this.model)) {
      this.option = this.getOption(this.model);
    }
  }

  getOption(value: any): any {
    const options = this.options ? this.options[value] : null;
    return options
  }
  getElement(): HTMLElement {
    return <HTMLElement>this.elementRef.nativeElement;
  }

  onChange = (valor: number): void => {
    this.model = valor;
    this.alterarDescricao();
    this.changed.emit(this.model);
    // this.modelChange.emit(this.model);
  }
}
