import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[ip-raised]'
})
export class IPButtonRaisedDirective implements OnInit {
    private _element: ElementRef;

    constructor(el: ElementRef) {
        this._element = el;
    }

    ngOnInit(): void {
        const attr = document.createAttribute('mat-raised-button');
        this._element.nativeElement.attributes.setNamedItem(attr);
    }
}
