import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPProgressComponent } from './ip-progress.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [IPProgressComponent],
  exports: [IPProgressComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPProgressModule {
}
