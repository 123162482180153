import { Directive, EventEmitter, Input, Host, Optional, Output, HostListener, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPCellDirective } from './ip-cell.directive';
import { IPTableDirective } from './ip-table.directive';
import { IPDialogService } from '../../../core/dialog/ipDialog.service';
import { IPToastService } from '../../../core/toast/ipToast.service';
import { LibGeral } from '../../../core/libraries/libGeral';


//https://angular.io/api/core/Directive
@Directive({
  selector: '[ip-row]'
})
export class IPRowDirective implements AfterViewInit {
  @Input() allowEdit: boolean;
  @Input() allowRemove: boolean;
  @Input() editOpen: boolean;
  @Input() tooltip: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ip-row') model: any;
  @Input() rowIndex: number;
  @Input() denyEditWithId: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('beginEdit') beginEditOutput: EventEmitter<any>;
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('endEdit') endEditOutput: EventEmitter<IEdicaoEventArgs>;
  @Output() selected: EventEmitter<any>;
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('ip-row-change') modelChange: EventEmitter<any> = new EventEmitter();


  public get editing(): boolean { return this.isEditing; }

  private isEditing: boolean;
  private isFirstEdit = true;
  private modelOrigin: any;

  private toastService: IPToastService;
  private dialogService: IPDialogService;

  public cells: IPCellDirective[] = [];
  private table: IPTableDirective;


  constructor(dialogService: IPDialogService, toastService: IPToastService, @Optional() @Host() table: IPTableDirective, private changeDetectorRef: ChangeDetectorRef) {
    this.dialogService = dialogService;
    this.table = table;
    this.toastService = toastService;

    this.allowEdit = false;
    this.allowRemove = false;
    this.modelOrigin = null;

    this.beginEditOutput = new EventEmitter<any>();
    this.endEditOutput = new EventEmitter<IEdicaoEventArgs>();
    this.selected = new EventEmitter();
  }

  ngAfterViewInit(): void {
    if (this.editOpen) {
      this.beginEdit();
    }
  }

  addCell(cell: IPCellDirective): void {
    this.cells.push(cell);
  }

  /**
   * Método que coloca as celulas da linha em modo de edição.
   */
  beginEdit(): void {
    this.modelOrigin = Object.assign({}, this.model);
    const permiteEditarComId = !(this.denyEditWithId && this.modelOrigin.Id !== 0)
    if (permiteEditarComId) {
      this.changeCellsState(true);
    }
    this.beginEditOutput.emit(this.model);
  }
  /**
   * Método que finaliza a edição da linha, passando as celulas para o modo readonly.
   *
   * @param {boolean} confirmed Informa se a edição foi confimada ou cancelada.
   */
  endEdit(confirmed: boolean): void {
    if (confirmed) {
      if (this.table.hasValidateFunction()) {
        // Faz copia do objeto para a validação.
        // Só irá atualizar o conteúdo da row caso este esteja válido.
        let modelToValidate = this.copiarModel();
        modelToValidate = this.atualizarModel(modelToValidate);
        this.table.validateRow(modelToValidate).subscribe((validationResult: IValidation) => {
          if (validationResult.IsValido) {
            const model = this.atualizarModel(this.model);
            // todo fazer com que valores sejam tratados com value type e nao referencia
            const hasChange = true; // this.modelOrigin !== model;
            if (hasChange) {
              this.table.updateRowInSource(this.modelOrigin, model);
            }

            this.changeCellsState(false);
            this.endEditEmit(confirmed, model, hasChange);
          } else {
            this.toastService.erro(`Não foi possível confirmar a edição:\n${validationResult.Mensagem}`);
          }
        });
      } else {
        const model = this.atualizarModel(this.model);
        // todo fazer com que valores sejam tratados com value type e nao referencia
        // this.modelOrigin !== model;
        const hasChange = true;
        if (hasChange) {
          this.table.updateRowInSource(this.modelOrigin, model);
        }

        this.changeCellsState(false);
        this.endEditEmit(confirmed, model, hasChange);
      }
    } else {
      this.endEditEmit(confirmed, this.model, this.modelOrigin !== this.model);
      // TODO (Alexandre)
      // Validar se algo foi preenchido
      // Caso foi, pedir confirmação de cancelamento
      this.model = this.modelOrigin;
      // Se for um registro sendo inserido e o usuário cancelou, o registro é removido.
      // O registro só não será removido caso já tenha sido confirmado antes.
      if (this.editOpen && this.isFirstEdit) {
        this.callTableDeleteRow();
      } else {
        this.changeCellsState(false);
      }
    }

  }

  endEditEmit(confirmed: boolean, data: any, hasChanged: boolean): void {
    this.endEditOutput.emit({ confirmou: confirmed, data: this.model, houveMudanca: hasChanged });
  }
  getCurrentValue(cell: any): any {

    const modelCopy = this.copiarModel();
    return LibGeral.getValue<any>(modelCopy, cell.getModelName());
  }

  delete(): void {
    // TODO - Adicionar mensagem de confirmação
    this.callTableDeleteRow();
  }

  @HostListener('click')
  onClick(): void {
    // Não dispara o evento caso a linha esteja em modo de edição
    if (!this.editing) {
      this.selected.emit(this.model);
    }
  }
  getModelEditor(): any {
    const tempModel = this.copiarModel();
    return this.atualizarModel(tempModel);
  }

  /**
   * Percorre as celulas para apresentar o editor das mesmas
   */
  private changeCellsState(showEditor: boolean, confirmouEdicao?: boolean): void {
    this.cells.forEach((cell: IPCellDirective) => {
      if (cell.hasEditor()) {
        if (showEditor) {
          cell.showEditor();
        } else {
          cell.hideEditor();
          this.isFirstEdit = false;
        }
      }
    });
    this.isEditing = showEditor;
    this.changeDetectorRef.detectChanges();
  }
  /**
   * Cria uma shallow copy da propriedade model.
   * @returns any
   */
  private copiarModel(): any {

    let retorno = this.model;

    if (typeof retorno === "object") {
      const MapPrototype = new Map<string, any>();

      //Mapea o objeto com o prototype original
      JSON.stringify(this.model, function (key, value) {
        if (this.__proto__.constructor.name !== "Object") {

          if (!this["ctor"]) {
            this["ctor"] = this.__proto__.constructor.name;
          }
          MapPrototype.set(this.__proto__.constructor.name, Object.getPrototypeOf(this));

        }
        return value;
      });

      //Deep Copy
      const _json = JSON.stringify(this.model);

      //Parse retornando o prototype original baseado no construtor(ctor).
      retorno = JSON.parse(_json, function (key, value) {
        if (!!this["ctor"]) {
          if (MapPrototype.has(this["ctor"])) {
            Object.setPrototypeOf(this, MapPrototype.get(this["ctor"]));
            delete this["ctor"]
          }
        }
        return value;
      });
    }
    return retorno;
  }
  /**
   * Atualiza o datasource usado no componente ip-table caso a uma de suas linhas tenha sido alterada.
   * @param  {any} model
   * @returns any
   */
  private atualizarModel(model: any): any {
    this.cells.forEach((cell: IPCellDirective) => {
      if (cell.hasEditor()) {
        if (cell.editor.allowEdit) {
          model = LibGeral.setValue<any>(model, cell.getModelName(), cell.getEditorValue());
        }
      }
    });
    return model;
  }

  private callTableDeleteRow() {
    if (LibGeral.estaPreenchido(this.rowIndex)) {
      this.table.deleteRow(this.model, this.rowIndex);
    } else {
      this.table.deleteRow(this.model);
    }
  }


}
