import { SimNao } from "@gemed-core/enums/gemed.enum";
import { Prescricao } from "@gemed-core/models/prescricao.model";
import { Profissional } from "@gemed-core/models/profissional.model";
import { Usuario } from "@gemed-core/models/usuario.model";

export class Pedido {
  public Id: number;
  public Cliente: any;
  public Paciente: any;
  public Prescricao: any;
  public Prioridade: string;
  public Situacao: string;
  public Observacao: string;
  public DataHoraTratamento: Date;
  public ProtocoloDescricao: string;
  public HoraTratamento: Date;
  public Documentos: any[];
  public Gateways: any[];
  public PrescricaoPedidoItens: PrescricaoPedidoItem[];
  public ObservacaoRecusa: string;
}

export class PrescricaoPedidoItem {
  public Id: number;
  public IdFarmaciaOrdemServico: number;
  public PrescricaoPedido: Pedido;
  public Prescricao: Prescricao;
  public DataHoraTratamento: any;
  public DataHoraProducao: any;
  public DataHoraLiberacao: any;
  public UsuarioLiberacao: Usuario;
  public ResponsavelLiberacao: string;
  public Situacao: string;
  public Prioridade: string;
  public CarregarPedido: boolean;
  public ProfissionalManipulacao: Profissional;
  public PrescricaoPedidoItemDetalhes: PrescricaoPedidoItemDetalhe[] = [];
  public IdCabineManipulacao: number;
  public DataHoraManipulacao?: Date;
  public Documentos: Array<any> = [];
  public PularProducao: SimNao;

  public static MapPrescricaoPedidoItemBasico(
    prescricaoPedidoItemRef: PrescricaoPedidoItem
  ): PrescricaoPedidoItem {
    const prescricaoPedidoItem = new PrescricaoPedidoItem();
    prescricaoPedidoItem.Id = prescricaoPedidoItemRef.Id;
    prescricaoPedidoItem.IdFarmaciaOrdemServico =
      prescricaoPedidoItemRef.IdFarmaciaOrdemServico;
    prescricaoPedidoItem.DataHoraTratamento =
      prescricaoPedidoItemRef.DataHoraTratamento;
    prescricaoPedidoItem.DataHoraProducao =
      prescricaoPedidoItemRef.DataHoraProducao;
    prescricaoPedidoItem.DataHoraLiberacao =
      prescricaoPedidoItemRef.DataHoraLiberacao;
    prescricaoPedidoItem.ResponsavelLiberacao =
      prescricaoPedidoItemRef.ResponsavelLiberacao;
    prescricaoPedidoItem.Situacao = prescricaoPedidoItemRef.Situacao;
    prescricaoPedidoItem.PrescricaoPedido = Object.assign(new Pedido(), {
      Id: prescricaoPedidoItemRef.PrescricaoPedido.Id,
    });
    prescricaoPedidoItem.Prescricao = Object.assign(new Prescricao(), {
      Id: prescricaoPedidoItemRef.Prescricao.Id,
    });
    return prescricaoPedidoItem;
  }
}
export class PrescricaoPedidoItemDetalhe {
  public IdSeqDetalhe: number;
  public IdPrescricaoItem?: number;
  public PrescricaoPedidoItem: PrescricaoPedidoItem;
  public Prescricao: Prescricao;
  public HoraInicioManipulacao?: Date;
  public HoraFimManipulacao?: Date;
  public NumeroGrupo: Number;
  public UtilizarSobraFuturo: string;
}

// Exporta o valor equivalente ao banco de dados
export enum PrescricaoPedidoItemSituacao {
  NaoInformado = "",
  Digitada = "A",
  Conferencia = "V",
  PrescricaoConferida = "S",
  EmOrdemServico = "O",
  AguardandoSeparacao = "J",
  EmEstoqueDiário = "D",
  BandejaMontada = "B",
  EnviadaParaProducao = "E",
  RecebidoParaProducao = "R",
  EmManipulacao = "I",
  EmRotulagem = "P",
  RotulagemAtendida = "G",
  RotulagemRecusada = "H",
  Cancelada = "C",
  QualidadeAtendida = "Q",
  EmExpedicao = "X",
  EmTransito = "T",
  Entregue = "F",
}

// Exporta o valor equivalente ao banco de dados
export enum PedidoPrioridade {
  NaoInformado = "",
  Normal = "N",
  Urgente = "U",
}

// Exporta o valor conforme recebo da API
export const PrescricaoPedidoItemSituacaoValueApi = new Map<string, string>([
  [PrescricaoPedidoItemSituacao.NaoInformado, ""],
  [PrescricaoPedidoItemSituacao.Digitada, "Digitada"],
  [PrescricaoPedidoItemSituacao.Conferencia, "Conferencia"],
  [PrescricaoPedidoItemSituacao.PrescricaoConferida, "PrescricaoConferida"],

  [PrescricaoPedidoItemSituacao.EmOrdemServico, "EmOrdemServico"],
  [PrescricaoPedidoItemSituacao.AguardandoSeparacao, "AguardandoSeparacao"],
  [PrescricaoPedidoItemSituacao.EmEstoqueDiário, "EmEstoqueDiário"],
  [PrescricaoPedidoItemSituacao.BandejaMontada, "BandejaMontada"],

  [PrescricaoPedidoItemSituacao.EnviadaParaProducao, "EnviadaParaProducao"],
  [PrescricaoPedidoItemSituacao.RecebidoParaProducao, "RecebidoParaProducao"],
  [PrescricaoPedidoItemSituacao.EmManipulacao, "EmManipulacao"],
  [PrescricaoPedidoItemSituacao.EmRotulagem, "EmRotulagem"],
  [PrescricaoPedidoItemSituacao.RotulagemAtendida, "RotulagemAtendida"],
  [PrescricaoPedidoItemSituacao.RotulagemRecusada, "RotulagemRecusada"],
  [PrescricaoPedidoItemSituacao.Cancelada, "Cancelada"],
  [PrescricaoPedidoItemSituacao.QualidadeAtendida, "QualidadeAtendida"],
  [PrescricaoPedidoItemSituacao.EmExpedicao, "EmExpedicao"],
  [PrescricaoPedidoItemSituacao.EmTransito, "EmTransito"],
  [PrescricaoPedidoItemSituacao.Entregue, "Entregue"],
]);

// Exporta o valor conforme recebo da API
export const PedidoPrioridadeValueApi = new Map<string, string>([
  [PedidoPrioridade.NaoInformado, "NaoInformado"],
  [PedidoPrioridade.Normal, "Normal"],
  [PedidoPrioridade.Urgente, "Urgente"],
]);

// Exportar o texto do Enum Situacao
export const PedidoPrioridadeDescricao = new Map<string, string>([
  [PedidoPrioridade.NaoInformado, "Não Informado"],
  [PedidoPrioridade.Normal, "Normal"],
  [PedidoPrioridade.Urgente, "Urgente"],
]);

// Exporta o valor equivalente ao banco de dados
export enum PedidoSituacao {
  NaoInformado = "",
  Agendada = "A",
  Digitada = "T",
  Recusada = "X",
  Cancelada = "C",
}

export const PrescricaoPedidoItemSituacaoDescricao = new Map<string, string>([
  [PrescricaoPedidoItemSituacao.NaoInformado, "Não Informado"],
  [PrescricaoPedidoItemSituacao.Digitada, "Digitada"],
  [PrescricaoPedidoItemSituacao.Conferencia, "Conferência"],
  [PrescricaoPedidoItemSituacao.PrescricaoConferida, "Prescrição Conferida"],

  [PrescricaoPedidoItemSituacao.EmOrdemServico, "Em Ordem de Serviço"],
  [PrescricaoPedidoItemSituacao.AguardandoSeparacao, "Aguardando Separação"],
  [PrescricaoPedidoItemSituacao.EmEstoqueDiário, "Em Estoque Diário"],
  [PrescricaoPedidoItemSituacao.BandejaMontada, "Bandeja Montada"],

  [PrescricaoPedidoItemSituacao.EnviadaParaProducao, "Enviada para Produção"],
  [PrescricaoPedidoItemSituacao.RecebidoParaProducao, "Recebido para Produção"],
  [PrescricaoPedidoItemSituacao.EmManipulacao, "Em Manipulação"],
  [PrescricaoPedidoItemSituacao.EmRotulagem, "Em Rotulagem"],
  [PrescricaoPedidoItemSituacao.RotulagemAtendida, "Rotulagem Atendida"],
  [PrescricaoPedidoItemSituacao.RotulagemRecusada, "Rotulagem Recusada"],
  [PrescricaoPedidoItemSituacao.Cancelada, "Cancelada"],
  [PrescricaoPedidoItemSituacao.QualidadeAtendida, "Qualidade Atendida"],
  [PrescricaoPedidoItemSituacao.EmExpedicao, "Em Expedição"],
  [PrescricaoPedidoItemSituacao.EmTransito, "Em Trânsito"],
  [PrescricaoPedidoItemSituacao.Entregue, "Entregue"],
]);

export enum Prioridade {
  Normal = "N",
  Urgente = "U",
}

export const PrioridadeDescricao: Map<string, string> = new Map<string, string>(
  [
    [Prioridade.Normal, "Normal"],
    [Prioridade.Urgente, "Urgente"],
  ]
);

export enum PrescricaoPedidoItemSituacaoLiberacao {
  AguardandoLiberacao = "A",
  Sim = "S",
  Nao = "N",
}

export const PrescricaoPedidoItemSituacaoLiberacaoDescricao = new Map<
  string,
  string
>([
  [
    PrescricaoPedidoItemSituacaoLiberacao.AguardandoLiberacao,
    "Aguardando Liberação",
  ],
  [PrescricaoPedidoItemSituacaoLiberacao.Sim, "Sim"],
  [PrescricaoPedidoItemSituacaoLiberacao.Nao, "Não"],
]);

//exportar enum com descrições
export enum DescricoesDasSituacoes {
  "Não Informado",
  "Agendada",
  "Digitada",
  "Recusada",
  "Cancelada",
}

//exportar enum com valor da api
export enum ApiValuesPedidoSituacao {
  "NaoInformado",
  "Agendada",
  "Digitada",
  "Recusada",
  "Cancelada",
}

// Exporta o valor conforme recebo da API
export const PedidoSituacaoValueApi = new Map<string, string>([
  [PedidoSituacao.NaoInformado, ApiValuesPedidoSituacao[0]],
  [PedidoSituacao.Agendada, ApiValuesPedidoSituacao[1]],
  [PedidoSituacao.Digitada, ApiValuesPedidoSituacao[2]],
  [PedidoSituacao.Recusada, ApiValuesPedidoSituacao[3]],
  [PedidoSituacao.Cancelada, ApiValuesPedidoSituacao[4]],
]);

// Exportar o texto do Enum Situacao
export const PedidoSituacaoeDescricao = new Map<string, string>([
  [PedidoSituacao.NaoInformado, DescricoesDasSituacoes[0]],
  [PedidoSituacao.Agendada, DescricoesDasSituacoes[1]],
  [PedidoSituacao.Digitada, DescricoesDasSituacoes[2]],
  [PedidoSituacao.Recusada, DescricoesDasSituacoes[3]],
  [PedidoSituacao.Cancelada, DescricoesDasSituacoes[4]],
]);

export enum PrescricaoPedidoRemessaSituacao {
  EmTransito = "T",
  Entregue = "E",
  Devolvido = "D",
}

export const PrescricaoPedidoRemessaSituacaoDescricao = new Map<string, string>(
  [
    [PrescricaoPedidoRemessaSituacao.EmTransito, "Em Trânsito"],
    [PrescricaoPedidoRemessaSituacao.Entregue, "Entregue"],
    [PrescricaoPedidoRemessaSituacao.Devolvido, "Devolvido"],
  ]
);

export const PrescricaoPedidoRemessaSituacaoValueApi = new Map<string, string>([
  [PrescricaoPedidoRemessaSituacao.EmTransito, "EmTransito"],
  [PrescricaoPedidoRemessaSituacao.Entregue, "Entregue"],
  [PrescricaoPedidoRemessaSituacao.Devolvido, "Devolvido"],
]);
