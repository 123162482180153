import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
@Component({
  selector: 'ip-toogle',
  template: `
    <mat-slide-toggle
        (change)="tratarMudancaEstado($event)"
        [color]="cor"
        [checked]="selecionado"
        [disabled]="desativar">
      <ng-content></ng-content>
    </mat-slide-toggle>
  `,
  styles: [
    `
    :host .mat-slide-toggle {
      margin: 0!important;
    }
    `
  ]
})
export class IPToogleComponent {
  @Input() cor = 'primary';
  @Input() desativar = false;
  @Input() selecionado = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
  tratarMudancaEstado(evento: MatSlideToggleChange): void {
    this.change.emit(evento.checked);
  }
}
