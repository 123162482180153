import { Injectable, OnDestroy } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  Router,
  CanDeactivate,
  CanActivate,
  Route,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { GemedState } from "../store/state";
import { Store } from "@ngrx/store";
import { isAuthenticated } from "../store/reducers/auth.reducer";
import { ChangeURLAction } from "../store/actions/route.actions";
import { AuthService } from "@gemed-core/auth/auth.service";
import { take } from "rxjs/operators";
import { MonitoringService } from "@gemed-core/logging.service";
import { LibGeral } from "@gemed-core/libraries/libGeral";

@Injectable({
  providedIn: "root",
})
export class GemedWebGuard implements CanDeactivate<any>, CanActivate {
  private _state: RouterStateSnapshot;

  constructor(private auth: AuthService, private store: Store<GemedState>, private monitor: MonitoringService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    // Armazena a rota que irá acessar para verificar a permissão
    // OBS - No futuro esta validação não ocorrerá aqui

    this._state = state;
    return new Promise(async (resolve, reject) => {
      const idUsuario = await LibGeral.getIdUsuario(this.store);

      this.monitor.logTrace("ROTA", { "idusuario": idUsuario, "rota": state.url });

      const autenticated = await this.auth.checkAutenticated();
      // Verificar se usuário está autenticado
      if (!autenticated) {
        this.store.dispatch(new ChangeURLAction("/users/log-in"));
      }

      const [isRepresentante, hasConvenio] = await Promise.all([
        this.auth.hasUsuariosRepresentanteConvenio(),
        this.auth.hasUsuariosConvenio(),
      ]);

      if (isRepresentante) {
        if (!hasConvenio) {
          this.store.dispatch(new ChangeURLAction("/users/log-in"));
        }

        if (this._state.url.indexOf("central-medico") > -1) {
          this.store.dispatch(
            new ChangeURLAction("/centraloperadora/pacienteconvenio")
          );
        }
        if (this._state.url.indexOf("farma") > -1) {
          this.store.dispatch(
            new ChangeURLAction("/centraloperadora/pacienteconvenio")
          );
        }
      }

      resolve(autenticated);
    });
  }

  canDeactivate(target: any): boolean {
    return true;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const observable = this.store.select(isAuthenticated);
    observable.pipe(take(1)).subscribe((authenticated) => {
      if (!authenticated) {
        this.store.dispatch(new ChangeURLAction("/users/log-in"));
      }
    });

    return observable;
  }
}
