import { Directive, ElementRef, Input, Renderer2, OnChanges, OnInit } from '@angular/core';
import { LibGeral } from 'src/app/core/libraries/libGeral';

/**
 * Diretiva para destacar termos em um texto.
 *
 * @export
 * @class HighlightPubDirective
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[highlightPub]' })
export class HighlightPubDirective implements OnInit {

  @Input() onEvent: Function;
  @Input() eventName: string;

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit(): void {
    if (LibGeral.estaPreenchido(this.eventName)) {
      this.elementRef.nativeElement.addEventListener(this.eventName, this.onEventDispatcher.bind(this));
    }
  }

  onEventDispatcher(event: MouseEvent) {
    if (LibGeral.estaPreenchido(this.onEvent)) {
      this.onEvent(event);
    }
  }
}
