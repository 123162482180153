import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@gemed-core/abstract.service';
import { RestApiService } from '@gemed-core/http/restApi.service';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { RestAPIType } from '@gemed-core/models/restAPIType.enum';
import { take } from 'rxjs/operators';



const CONTROLLER_MENU = 'Menu';
const CONTROLLER_USUARIO = 'Usuario';
@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends AbstractService {

    private servicesRoutes: any;
    constructor(restApiService: RestApiService) {
        super(restApiService);
        this.servicesRoutes = {
            CarregarMenuWeb: 'CarregarMenuWeb',
            ObterPermissaoUsuarioPorProcesso: 'RecuperarPermissaoUsuarioPorProcesso'
        };
    }

    // TODO - Ajustar a pai para retornar os dados de acordo que temos hoje
    getMenu(idUsuario: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.restApiService.GetById(idUsuario, {
                api: RestAPIType.Seguranca,
                controller: CONTROLLER_MENU,
                action: this.servicesRoutes.CarregarMenuWeb
            })
                .pipe(take(1))
                .subscribe(
                    async result => resolve(await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))),
                    error => reject(error)
                );
        });
    }

    obterPermissaoUsuarioPorProcesso(idUsuario: number, idProcesso: string): Promise<any> {
        let params = new HttpParams();
        params = params.append("idUsuario", String(idUsuario));
        params = params.append("idProcesso", idProcesso);

        return new Promise((resolve, reject) => {
            this.restApiService.Get({
                api: RestAPIType.Auth,
                controller: CONTROLLER_USUARIO,
                action: this.servicesRoutes.ObterPermissaoUsuarioPorProcesso
            }, params)
                .pipe(take(1))
                .subscribe(
                    async result => resolve(await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))),
                    error => reject(error)
                );
        });
    }

}
