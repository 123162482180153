import { Component, Input } from '@angular/core';

@Component({
  selector: 'ip-menu',
  template: `
    <span mat-button [matMenuTriggerFor]="appMenu">
      <ng-content></ng-content>
    </span>
    <mat-menu #appMenu="matMenu">
     <ng-content select="ip-menu-item"></ng-content>
    </mat-menu>
  `
})
export class IPMenuComponent {
  @Input() display = '';
}
