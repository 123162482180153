import { Injectable } from "@angular/core";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { IpParametroChave } from "@gemed-core/models/ipParametroChave.model";
import { IPToastService } from "@gemed-core/toast/ipToast.service";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { PEPService } from "@pep-service/pep.service";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { buscarParametroAction, buscarParametroEmpresaSucessoAction, buscarParametroErroAction, buscarParametroSucessoAction } from "src/app/parametrosManager/Store/Actions/parametrosManager.actions";

@Injectable({
  providedIn: 'root'
})
export class ParametrosManagerEffects {
  constructor(
    private actions$: Actions,
    private pepService: PEPService,
    private store: Store<any>,
    private toast: IPToastService
  ) { }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Effect()
  public buscaParametro =
    createEffect(
      () =>

        this.actions$.pipe(
          ofType(buscarParametroAction),
          switchMap((action) =>
            combineLatest([
              of(action.payload),
              this.store
                .select(
                  (store) =>
                    <Map<string, IpParametroChave>>store.ParametrosManager.Parametros
                )
                .pipe(take(1))
            ]
            )
          ),
          map(([parametro, parametrosManager]) => {
            if (LibGeral.estaPreenchido(parametrosManager[parametro])) {
              return of(parametrosManager[parametro]);
            }
            const buscaParametroRest = this.pepService.getParametro(parametro).pipe(
              map((response) =>{
               return response.Data;
              } ),
              catchError((error) => {
                throw error;
              })
            );

            return buscaParametroRest;
          }),
          switchMap((ipParametroChave: Observable<any>) => ipParametroChave),
          map((ipParametroChave: IpParametroChave) => {
            return buscarParametroSucessoAction({ payload: ipParametroChave });
          }),
          catchError((error) => {
            this.toast.erro("Não foi possível recuperar o Parâmetro");

            return of(buscarParametroErroAction());
          })
        ));

  @Effect()
  public buscaParametroEmpresa =
    createEffect(() =>

      this.actions$.pipe(
        ofType(buscarParametroAction),
        switchMap((action) =>
          combineLatest([
            of(action.payload),
            this.store
              .select(
                (s) => <Map<string, IpParametroChave>>s.ParametrosManager.Parametros
              )
              .pipe(take(1))
          ])
        ),
        map(([parametro, parametrosManager]) => {
          if (LibGeral.estaPreenchido(parametrosManager[parametro])) {
            return of(parametrosManager[parametro]);
          }

          const buscaParametroRest = this.pepService
            .getParametroEmpresa(parametro)
            .pipe(
              map((response) => response.Data),
              catchError((error) => {
                throw error;
              })
            );

          return buscaParametroRest;
        }),
        switchMap((ipParametroChave: Observable<any>) => ipParametroChave),
        map(
          (ipParametroChave: IpParametroChave) =>
            buscarParametroEmpresaSucessoAction({ payload: ipParametroChave })
        ),
        catchError((error) => {
          this.toast.erro("Não foi possível recuperar o Parâmetro");

          return of(buscarParametroErroAction());
        })
      )
    );
}
