import {
  ComponentFactoryResolver, EventEmitter, Inject, Injectable, InjectionToken,
  Renderer2, ResolvedReflectiveProvider, Type,
  ViewContainerRef
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IpDialogColor } from "src/app/core-ui/components/dialog/ip-dialog-colors.enum";
import { LibCollections } from "../libraries/libCollections";
import { LibComponent } from "../libraries/libComponent";
import { LibGeral } from "../libraries/libGeral";

export const TITULO_DIALOG = new InjectionToken<string>("titulo");
export const MENSAGEM_DIALOG = new InjectionToken<string>("mensagem");
export const OK_BUTTON_DIALOG = new InjectionToken<string>("okButton");
export const CANCEL_BUTTON_DIALOG = new InjectionToken<string>("cancelButton");

export interface IDialogComponent {
  onResolve: EventEmitter<any>;
}
export interface ICloseableDialog {
  beforeClose(): boolean;
}
export interface IDismissibleDialog {
  beforeDismiss(): boolean;
}

/**
 * Class que contém as configurações para abertura da modal.
 * @class IPDialogConfig
 */
@Injectable({
  providedIn: "root",
})
export class IPDialogConfig {
  /**
   * Define se a dialog será fechada quando usuário clicar fora da mesma.
   * @type {boolean}
   */
  Dismissible = true;

  /**
   * Define se a dialog será fechada quando usuário pressiona a tecla <ESC>.
   * @type {boolean}
   */
  CloseOnEsc = true;

  /**
   * Define se a modal será aberta ocupando todo o tamanho da tela.
   * @type {boolean}
   */
  FullScreen = false;

  /**
   * Define Largura que modal sera aberta
   * @type {number}
   */
  Width: number = null;

  /**
   * Define Altura que modal sera aberta
   * @type {number}
   */
  Height: number = null;

  /**
   * Define a Cor da modal
   * @type {IpDialogColor}
   */
  Cor: IpDialogColor = IpDialogColor.gemedAzul;
}
/**
 * Class que contém as configurações para dialog de alerta.
 * @export
 * @class IPAlertDialogConfig
 * @extends {IPDialogConfig}
 */
@Injectable()
export class IPAlertDialogConfig extends IPDialogConfig {
  /**
   * Título da dialog.
   * @type {string}
   */
  Titulo: string;
  /**
   * Mensagem apresentada na dialog.
   * @type {string}
   */
  Mensagem: string;
  /**
   * Texto  do botão de confirmar.
   * @type {string}
   */
  OkButton: string;
  Width: number = null;

  constructor(
    @Inject(TITULO_DIALOG) titulo: string,
    @Inject(MENSAGEM_DIALOG) mensagem: string,
    @Inject(OK_BUTTON_DIALOG) okButton?: string,
    width: number = null,
  ) {
    super();
    this.Titulo = titulo;
    this.Mensagem = mensagem;
    this.OkButton = okButton;
    this.Width = width;
  }
}
/**
 * Class que contém as configurações para dialog de confirmações
 * @export
 * @class IPConfirmDialogConfig
 * @extends {IPAlertDialogConfig}
 */
@Injectable()
export class IPConfirmDialogConfig extends IPAlertDialogConfig {
  /**
   * Texto do botão cancelar.
   * @type {string}
   */
  CancelButton: string;

  constructor(
    @Inject(TITULO_DIALOG) titulo: string,
    @Inject(MENSAGEM_DIALOG) mensagem: string,
    @Inject(OK_BUTTON_DIALOG) okButton?: string,
    @Inject(CANCEL_BUTTON_DIALOG) cancelButton?: string
  ) {
    super(titulo, mensagem, okButton);
    this.CancelButton = cancelButton;
  }
}

@Injectable()
export class IPDialogResolver {
  public get dialog(): MatDialogRef<any> {
    return this._dialog;
  }

  private _dialog: MatDialogRef<any>;
  public id: number;
  constructor(dialogInternal: MatDialogRef<any>) {
    this.id = Date.now();
    this._dialog = dialogInternal;
  }

  public resolve(result: any): void {
    this._dialog.close(result);
  }
  public dismiss(): void {
    this._dialog.close();
  }
}
/**
 * Classe para gerenciar as dialogs abertas
 * @class IPDialogStackManager
 */
class IPDialogStackManager {
  // Singleton
  private static _instance: IPDialogStackManager = null;
  public static get instance(): IPDialogStackManager {
    if (this._instance === null) {
      this._instance = new IPDialogStackManager();
    }

    return this._instance;
  }

  private stack: Array<any>;

  get length(): number {
    return this.stack.length;
  }
  get current(): any {
    return LibCollections.last(this.stack);
  }

  constructor() {
    this.stack = new Array<any>();
  }

  indexOf(dialogInstance: any): number {
    return this.stack.indexOf(dialogInstance);
  }

  push(dialogInstance: any): void {
  }

  remove(dialogInstance: any): void {
    const dialogIndex = this.stack.indexOf(dialogInstance);
    if (dialogIndex > -1) {
      this.stack.splice(dialogIndex, 1);
    }

    if (dialogInstance.element.parentNode) {
      dialogInstance.element.parentNode.removeChild(dialogInstance.element);
    }
  }

  pop(): any {
    return this.stack.pop();
  }
}

/**
 * Serviço que permite a abertura de dialogs.
 * @export
 * @class IPDialogService
 */
@Injectable({
  providedIn: "root",
})
export class IPDialogService {
  private static alertComponent: Type<any>;
  private static confirmComponent: Type<any>;

  private renderer: Renderer2;

  private get stackManager(): IPDialogStackManager {
    return IPDialogStackManager.instance;
  }

  constructor(public dialog: MatDialog) { }

  /**
   * Inicializa o serviço para definir o componente root.
   * @param {ViewContainerRef} defaultViewContainer ViewContainer onde a dialog será adicionada.
   */
  init(alertComponent: Type<any>, confirmComponent: Type<any>): void {
    IPDialogService.alertComponent = alertComponent;
    IPDialogService.confirmComponent = confirmComponent;
  }

  /**
   *  Apresenta dialog de alerta, com o título e mensagem passados no objeto de configuração.
   * Além da mensagem, a dialog possui um botão para confirmação.
   * @param {IPAlertDialogConfig} config Objeto de configurações do alerta.
   * @returns {Promise<IPDialogInstance>} Retorna promise que será resolvida quando usuário interagir com a dialog.
   * Casos especiais:
   * - Quando a promise é resolvida, o objeto passado pela dialog é passado por parâmetro;
   * - Se a dialog for dismissed (fechada com click fora da dialog) a promise será REJEITADA;
   * - Se a dialog for fechada através do ESC a promise será REJEITADA.
   */
  // eslint-disable-next-line max-len
  alert(
    config: IPAlertDialogConfig,
    viewContainerRef: ViewContainerRef,
    componentFactoryResolver?: ComponentFactoryResolver
  ): Promise<any> {
    return this.open(
      IPDialogService.alertComponent,
      viewContainerRef,
      componentFactoryResolver,
      config
    );
  }

  /**
   * Abre dialog de confirmação, com o título e mensagem passados no objeto de configuração.
   * Além da mensagem, a dialog possui dois botões para o usuário confirmar ou cancelar.
   * @param {IPConfirmDialogConfig} config Objeto de configurações da configuração.
   * @returns {Promise<IPDialogInstance>} Retorna promise que será resolvida quando usuário interagir com a dialog.
   * Casos especiais:
   * - Quando a promise é resolvida, o objeto passado pela dialog é passado por parâmetro;
   * - Se a dialog for dismissed (fechada com click fora da dialog) a promise será REJEITADA;
   * - Se a dialog for fechada através do ESC a promise será REJEITADA.
   */
  // eslint-disable-next-line max-len
  confirm(
    config: IPConfirmDialogConfig,
    viewContainerRef: ViewContainerRef,
    componentFactoryResolver?: ComponentFactoryResolver
  ): Promise<any> {
    return this.open(
      IPDialogService.confirmComponent,
      viewContainerRef,
      componentFactoryResolver,
      config
    );
  }

  /**
   * Abre componente como dialog.
   * @param {Type} component Componente a ser aberto.
   * @param {IPDialogConfig} config Configurações da dialog.
   * @param {ResolvedReflectiveProvider[]} [bindings] Providers a serem passados para o componente.
   * @returns {Promise<IPDialogInstance>} Retorna promise que será resolvida quando usuário interagir com a dialog.
   * Casos especiais:
   * - Quando a promise é resolvida, o objeto passado pela dialog é passado por parâmetro;
   * - Se a dialog for dismissed (fechada com click fora da dialog) a promise será REJEITADA;
   * - Se a dialog for fechada através do ESC a promise será REJEITADA.
   */
  open(
    component: Type<any>,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    config: IPDialogConfig,
    dataDialog?: any
  ): Promise<any> {


    return new Promise((next, reject) => {
      const dialogRef = this.dialog.open(component, {
        width: `${config.Width}px`,
        height: `${config.Height}px`,
        viewContainerRef: viewContainerRef,
        data: dataDialog,
      });
      const componentInstance = dialogRef.componentInstance;
      componentInstance.config = config;
      componentInstance.resolver = new IPDialogResolver(dialogRef);
      this.stackManager.push(dialogRef);
      dialogRef.afterClosed().subscribe(
        (result) => {
          if (LibGeral.estaPreenchido(result)) {
            next(result);
          } else {
            reject();
          }
        },
        (erro) => {
          console.error(
            "Ocorreu um erro ao tentar resolver a promise da dialog.",
            erro
          );
          reject(erro);
        }
      );
    });
  }

  stackPosition(mInstance: any): number {
    return this.stackManager.indexOf(mInstance);
  }

  stackLength(): number {
    return this.stackManager.length;
  }
}
