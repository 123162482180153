import { Injectable, EventEmitter, ViewChild } from '@angular/core';
import { CustomProperty } from './core/models/customProperty';

@Injectable({
  providedIn: 'root'
})
export class GemedService {
  public titleChange: EventEmitter<any>;
  public DeveAbrirDetalhePrescricaoLateral: EventEmitter<any> = new EventEmitter();
  public DetalhePrescricaoLateralFoiFechado: EventEmitter<any> = new EventEmitter();
  private title: string;


  constructor() {
    this.titleChange = new EventEmitter<any>();
  }

  changeTitle(title: any): void {
    this.title = title;
    this.titleChange.emit(this.title);
  }


  abrirDetalhePrescricaoLateral(componentType, props: CustomProperty[] = []) {
    this.DeveAbrirDetalhePrescricaoLateral.emit({componentType, props});
  }

  avisoDetalhePrescaoLateralFechado() {
    this.DetalhePrescricaoLateralFoiFechado.emit(true);
  }
}
