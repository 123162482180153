import { ILoginTemporario } from '@gemed-core/interfaces/gemed.interface';
import { Action } from '@ngrx/store';
import { type } from '../../libraries';
import { AuthState } from '../state/auth.state';

interface UsuarioPayload {
  IsAuthenticated?: boolean;
  user: any;
  manterLogado?: boolean;
}

export const ActionTypes = {
  AUTHENTICATED: type('[auth] Autenticado'),
  AUTHENTICATED_ERROR: type('[auth] Autenticado erro'),
  AUTHENTICATED_SUCCESS: type('[auth] Autenticado sucesso'),
  LOG_OUT: type('[auth] Log off'),
  LOG_OUT_ERROR: type('[auth] Log off erro'),
  LOG_OUT_SUCCESS: type('[auth] Log off success'),
  LOG_IN: type('[auth] Log in'),
  LOG_IN_ERROR: type('[auth] Log in error'),
  LOG_IN_SUCCESS: type('[auth] Log in success'),
  LIMPAR_ERRO_LOG_IN: type('[auth] Limpa erros Log.'),
  ATUALIZAR_TOKEN_REFRESH: type('[auth] Atualizar refresh e token'),
  FORCAR_REFRESHTOKEN: type('[auth] Informar que o token está expirado'),
  ABRIR_MODAL_CLINICAS: type('[auth] Abrir modal de clinicas para o usuário selecionar qual será utilizada'),
  FECHAR_MODAL_CLINICAS: type('[auth] Fechar modal de clinicas'),
};

export class AutenticadoAction implements Action {
  public type: string = ActionTypes.AUTHENTICATED;

  constructor(public payload?: { token?: string }) { }
}

export class AutenticadoSucessoAction implements Action {
  public type: string = ActionTypes.AUTHENTICATED_SUCCESS;
  constructor(public payload: AuthState) { }
}

export class AutenticadoErroAction implements Action {
  public type: string = ActionTypes.AUTHENTICATED_ERROR;
  constructor(public payload?: any) { }
}

export class LogOutAction implements Action {
  public type: string = ActionTypes.LOG_OUT;
  constructor() { }
}

export class LogOutErroAction implements Action {
  public type: string = ActionTypes.LOG_OUT_ERROR;
  constructor() { }
}

export class LogOutSuccessAction implements Action {
  public type: string = ActionTypes.LOG_OUT_SUCCESS;
  constructor() { }
}

export class LogInAction implements Action {
  public type: string = ActionTypes.LOG_IN;
  constructor(public payload: UsuarioPayload) { }
}

export class LogInErroAction implements Action {
  public type: string = ActionTypes.LOG_IN_ERROR;
  constructor(public payload: string) { }
}

export class LogInSucessoAction implements Action {
  public type: string = ActionTypes.LOG_IN_SUCCESS;
  constructor(public payload: any) { }
}

export class AtualizarTokenRefresh implements Action {
  public type: string = ActionTypes.ATUALIZAR_TOKEN_REFRESH;
  constructor(public payload: any) { }
}

export class ForcarRefreshToken implements Action {
  public type: string = ActionTypes.FORCAR_REFRESHTOKEN;
  constructor(public payload: any) { }
}

export class LimparErroLogInAction implements Action {
  public type: string = ActionTypes.LIMPAR_ERRO_LOG_IN;
  constructor() { }
}

export class AbrirModalClinicasAction implements Action {
  public type: string = ActionTypes.ABRIR_MODAL_CLINICAS;
  constructor(public payload: ILoginTemporario) { }
}

export class FecharModalClinicasAction implements Action {
  public type: string = ActionTypes.FECHAR_MODAL_CLINICAS;
  constructor() { }
}


export type Actions
  =
  AutenticadoAction
  | AutenticadoErroAction
  | AutenticadoSucessoAction
  | LogInAction
  | LogInErroAction
  | LogInSucessoAction
  | LimparErroLogInAction;
