import { MaterialModule } from './../material/material.module';
import { IPButtonModule } from './../core-ui/components/button/ip-button.module';
import { NgModule } from "@angular/core";
import { DashBoardComponent } from "./dashboard.component";

@NgModule({
    imports: [
        IPButtonModule,
        MaterialModule
    ],
    declarations: [
        DashBoardComponent
    ],
    exports: [
        DashBoardComponent
    ]
})
export class DashBoardModule {

}
