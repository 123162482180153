import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { IPRadioGroupComponent } from './ip-radio-group.component';
import { IPRadioButtonComponent } from './ip-radio-button.component';

@NgModule({
  imports: [CommonModule, MatRadioModule],
  declarations: [IPRadioGroupComponent, IPRadioButtonComponent],
  exports: [IPRadioGroupComponent, IPRadioButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IPRadioModule {
}
