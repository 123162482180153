
export class PEPExamesImagem {
    IdImagem : string;
    Cliente : string;
    Sistema : string;
    Tamanho : string;
    Tipo : string;
    UrlImage : any;
    Bytes : [];
    Base64 : any  = () => {
        var uints = new Uint8Array(this.Bytes);
        var blob = new Blob([uints], { type: 'application/octet-stream' });
        return blob;
    }
}
