import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPDatePickerComponent } from './ip-datepicker.component';
import { IPInputNumberComponent } from './ip-input-number.component';
import { IPInputTextComponent } from './ip-input-text.component';
import { IPPlaceholderComponent } from './ip-placeholder.component';
import { IPTimePickerComponent } from './ip-timepicker.component';
import { IPTextAreaComponent } from './ip-textarea.component';
import { MaterialModule } from '../../../material/material.module';
import { IPInputSearchComponent } from './ip-input-search.component';


const inputs = [
  IPDatePickerComponent,
  IPInputNumberComponent,
  IPInputTextComponent,
  IPPlaceholderComponent,
  IPTimePickerComponent,
  IPTextAreaComponent,
  IPInputSearchComponent,
];



@NgModule({
  imports: [CommonModule, MaterialModule, MatMomentDateModule],
  declarations: inputs,
  exports: inputs,
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPInputModule {
}
