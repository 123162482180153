export enum PrescricaoSituacao {
  Gerada = "G",
  Orcada = "O",
  ConfirmaPrescricao = "P",
  Impressa = "I",
  Admitida = "A",
  ConfirmadaQuimio = "Q",
  Faturada = "F",
  Cancelada = "C",
  AtendimentoExterno = "E",
  NaoInformado = ""
}

// enum de valores para API
export enum valoresParaApi {
  "Gerada",
  "Orcada",
  "ConfirmaPrescricao",
  "Impressa",
  "Admitida",
  "ConfirmadaQuimio",
  "Faturada",
  "Cancelada",
  "AtendimentoExterno",
  "NaoInformado"
}
// enum de descricoes
export enum descricoesSituacoes {
  "Gerada",
  "Aguardando Orçamento",
  "Aguardando Confirmação",
  "Impressa",
  "Admitida",
  "Atendimento Finalizado",
  "Faturada",
  "Cancelada",
  "Atendimento Externo",
  "Situação Não Informada"
}


export const PrescricaoSituacaoValueApi = new Map<string, string>(
  [
    [PrescricaoSituacao.Gerada, valoresParaApi[0]],
    [PrescricaoSituacao.Orcada, valoresParaApi[1]],
    [PrescricaoSituacao.ConfirmaPrescricao, valoresParaApi[2]],
    [PrescricaoSituacao.Impressa, valoresParaApi[3]],
    [PrescricaoSituacao.Admitida, valoresParaApi[4]],
    [PrescricaoSituacao.ConfirmadaQuimio, valoresParaApi[5]],
    [PrescricaoSituacao.Faturada, valoresParaApi[6]],
    [PrescricaoSituacao.Cancelada, valoresParaApi[7]],
    [PrescricaoSituacao.AtendimentoExterno, valoresParaApi[8]],
    [PrescricaoSituacao.NaoInformado, valoresParaApi[9]]
  ]
);

// Exportar o texto do Enum Situacao
export const PrescricaoSituacaoDescricao = new Map<string, string>(
  [
    [PrescricaoSituacao.Gerada, descricoesSituacoes[0]],
    [PrescricaoSituacao.Orcada, descricoesSituacoes[1]],
    [PrescricaoSituacao.ConfirmaPrescricao, descricoesSituacoes[2]],
    [PrescricaoSituacao.Impressa, descricoesSituacoes[3]],
    [PrescricaoSituacao.Admitida, descricoesSituacoes[4]],
    [PrescricaoSituacao.ConfirmadaQuimio, descricoesSituacoes[5]],
    [PrescricaoSituacao.Faturada, descricoesSituacoes[6]],
    [PrescricaoSituacao.Cancelada, descricoesSituacoes[7]],
    [PrescricaoSituacao.AtendimentoExterno, descricoesSituacoes[8]],
    [PrescricaoSituacao.NaoInformado, descricoesSituacoes[9]]
  ]
);
// Exportar o texto do Enum Situacao
export const PrescricaoSituacaoValorApiParaDescricao = new Map<string, string>(
  [
    [valoresParaApi[0], descricoesSituacoes[0]],
    [valoresParaApi[1], descricoesSituacoes[1]],
    [valoresParaApi[2], descricoesSituacoes[2]],
    [valoresParaApi[3], descricoesSituacoes[3]],
    [valoresParaApi[4], descricoesSituacoes[4]],
    [valoresParaApi[5], descricoesSituacoes[5]],
    [valoresParaApi[6], descricoesSituacoes[6]],
    [valoresParaApi[7], descricoesSituacoes[7]],
    [valoresParaApi[8], descricoesSituacoes[8]],
    [valoresParaApi[9], descricoesSituacoes[9]]
  ]
);


export const SituacaoFiltro = [
  {
    grupoPai: "Recepcionadas",
    subgrupos: [
      {
        value: valoresParaApi[4],
        viewValue: descricoesSituacoes[4]
      },
      {
        value: valoresParaApi[5],
        viewValue: descricoesSituacoes[5]
      },
    ]
  },
  {
    grupoPai: "Finalizadas",
    subgrupos: [
      {
        value: valoresParaApi[6],
        viewValue: descricoesSituacoes[6]
      }
    ]
  }
];
