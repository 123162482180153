import { Component } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "feed-back-button",
  template: `
    <button *ngIf="!mostraTextArea" mat-button (click)="mostrarTextArea()">
      <mat-icon>sentiment_satisfied_alt</mat-icon>
      Feedback
    </button>

    <section class="textFeed" *ngIf="mostraTextArea">
      <form [formGroup]="feedForm" class="form" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <input matInput placeholder="Nome" formControlName="nome" />
        </mat-form-field>
        <mat-form-field>
          <textarea
            matInput
            placeholder="Opinião"
            formControlName="opiniao"
            style="height: 140px;"
          ></textarea>
        </mat-form-field>
        <div style="text-align: end;">
          <button mat-button (click)="cancelar($event)">Cancelar</button>
          <button mat-button type="submit">Salvar</button>
        </div>
      </form>
    </section>
  `,
  styles: [
    `
      :host {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .form {
        z-index: 2;
        flex: 1;
        flex-direction: column;
        display: flex;
        width: inherit;
        justify-content: space-between;
      }
      .textFeed {
        display: flex;
        width: 300px;
        height: 300px;
        flex: 1;
        flex-direction: column;
        box-shadow: 1px 1px 1px 2px #6b6969;
        align-items: center;
        justify-content: baseline;
        z-index: 2;
      }
    `,
  ],
})
export class FeedBackComponent {
  mostraTextArea = false;
  feedForm = new UntypedFormGroup({
    nome: new UntypedFormControl(null, [Validators.required]),
    opiniao: new UntypedFormControl(null, [Validators.required]),
  });
  mostrarTextArea(): void {
    this.mostraTextArea = true;
  }

  cancelar(event: Event): void {
    event.stopPropagation();
    this.mostraTextArea = false;
  }

  onSubmit(): void {}
}
