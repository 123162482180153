import { type } from "@gemed-core/libraries";
import { IpParametroChave } from "@gemed-core/models/ipParametroChave.model";
import { createAction, props } from "@ngrx/store";
export const ActionTypes = {
  IP_PARAMETRO: type('[ParametrosManager] Buscando IPParametro.'),
  IP_PARAMETRO_SUCESSO: type('[ParametrosManager] Buscando IPParametro Sucesso.'),
  IP_PARAMETRO_ERRO: type('[ParametrosManager] Erro ao buscar IPParametro.'),
  IP_PARAMETRO_EMPRESA: type('[ParametrosManager] Buscando IPParametroEmpresa.'),
  IP_PARAMETRO_EMPRESA_SUCESSO: type('[ParametrosManager] Buscando IPParametroEmpresa.'),
};


export const buscarParametroAction = createAction(
  ActionTypes.IP_PARAMETRO,
  props<{ payload: string }>()
);

export const buscarParametroSucessoAction = createAction(
  ActionTypes.IP_PARAMETRO_SUCESSO,
  props<{payload: IpParametroChave}>()
);

export const buscarParametroErroAction = createAction(
  ActionTypes.IP_PARAMETRO_ERRO,
);


export const buscarParametroEmpresaSucessoAction = createAction(
  ActionTypes.IP_PARAMETRO_EMPRESA,
  props<{payload: IpParametroChave}>()
);
