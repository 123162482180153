import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { State } from '@ngrx/store';
import { UsuarioService } from '@pep-assistencial-service/usuario.service';
import { carregaFiltroErroAction } from '@pep-assistencial-store/actions/farma.action';
import { carregarMenuAction, carregarMenuSucessoAction, criarStateMenuAction, criarStateMenuSucessoAction } from '@pep-assistencial-store/actions/menu.action';
import { MenuBase } from '@pep-assistencial-store/state/menu.state';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IPToastService } from '../../../../core/toast/ipToast.service';


@Injectable({
  providedIn: 'root'
})
export class MenuEffects {

  criarMenuUsuario$ = this.actions$
    .pipe(
      ofType(criarStateMenuAction),
      map(action => action.payload),
      switchMap((state) => {
        return of(criarStateMenuSucessoAction({ payload: state }));
      })

    )


  @Effect()
  carregarMenuUsuario$ = this.actions$.
    pipe(
      ofType(carregarMenuAction),
      map(action => action.payload),
      switchMap((idUsuario) => {
        return this.usuarioService.getMenu(idUsuario).then(
          data => carregarMenuSucessoAction({ payload: data }),
          error => carregaFiltroErroAction({ payload: error })
        );
      })
    )

  constructor(
    private actions$: Actions,
    private state: State<MenuBase>,
    private usuarioService: UsuarioService,
    private toastService: IPToastService) {
    this.toastService = toastService;
  }

}
