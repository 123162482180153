import { Injectable } from '@angular/core';
import environment from '@environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LibGeral } from './libraries/libGeral';

@Injectable()
export class MonitoringService {
  appInsights: ApplicationInsights;
  constructor() {

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment?.applicationInsightsKey ?? "",
        enableAutoRouteTracking: true, // para considerar projetos com SPA
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true
      }
    });

    if (!this.enableApplicationInsights()) {
      return null;
    }

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  enableApplicationInsights(): boolean {
    return LibGeral.estaPreenchido(environment?.applicationInsightsKey ?? "");
  }

  logPageView(name?: string, url?: string) { // option to call manually
    if (this.enableApplicationInsights()) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.enableApplicationInsights()) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.enableApplicationInsights()) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.enableApplicationInsights()) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.enableApplicationInsights()) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }
}
