export enum RestAPIType {
  Auth,
  Resources,
  Seguranca,
  GemedWebImage,
  ProntuarioExame,
  ChecagemMobile,
  VideoChamada,
  VideoChamadaToken,
}
