import { Injectable } from '@angular/core';
import { ILogAdaptatividade } from '@gemed-core/interfaces/gemed.interface';
import { DataFormato, LibDate } from '@gemed-core/libraries/libDate';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { Empresa } from '@gemed-core/models/empresa.model';
import { Usuario } from '@gemed-core/models/usuario.model';
import { GemedState } from '@gemed-core/store/state';
import { Store } from '@ngrx/store';
import { LogService } from '@pep-assistencial-service/log.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private readonly ACAO_ERRO = 8;
  private readonly CLASSE = 'WEB';

  constructor(
    private logService: LogService,
    private store: Store<GemedState>
  ) { }


  private async EmpresaFactory(): Promise<Empresa> {
    const idClinica = await LibGeral.getIdClinica(this.store);
    const empresa = new Empresa();
    empresa.IdEmpresa = idClinica;
    return empresa;
  }

  private async UsuarioFactory(): Promise<Usuario> {
    const idUsuario = await LibGeral.getIdUsuario(this.store);
    const usuario = new Usuario();
    usuario.Id = idUsuario;

    return usuario;
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Ie';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }

  async logError(message: string, stack: string) {

    const empresa = await this.EmpresaFactory();
    const usuario = await this.UsuarioFactory();
    const browserName = this.getBrowserName();

    const adpLog: ILogAdaptatividade = {
      Acao: this.ACAO_ERRO,
      Complemento: `Message:${message}, Stack:${stack}`,
      DataHora: LibDate.FormatarData(new Date(), DataFormato.Rest),
      Usuario: usuario,
      Empresa: empresa,
      Classe: this.CLASSE,
      ComputadorNome: `Plataforma: ${window.navigator.platform}, Browser:${browserName}`
    }
    this.logService.RegistrarLogAdp(adpLog);
  }
}
