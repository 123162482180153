import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPListComponent } from './ip-list.component';
import { IPListItemComponent } from './ip-list-item.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [MaterialModule],
  declarations: [IPListComponent, IPListItemComponent],
  exports: [IPListComponent, IPListItemComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPListModule {
}
