import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { IPToastService } from '@gemed-core/toast/ipToast.service';
import { Observable } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

// import { BooleanFieldValue } from '@angular2-material/core/annotations/field-value';

/**
 * Componente de auto complete.
 * Quando o usuário começa a digitar, o componente dispara consulta de dados para sugerir como opção.
 * A consulta que será realizada deve ser passada no @input 'search'.
 * @export
 * @class IPAutoCompleteComponent
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ip-autocomplete',
  template: `
  <form fxLayout="row">
    <mat-form-field class="example-full-width">
      <input type="text"  [placeholder]="placeholder" matInput [formControl]="myControl" [matAutocomplete]="auto">
      <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="limpaValor()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete"
        [displayWith]="getValueModel"
        panelWidth="300"
        (optionSelected)="alterarObjetoSelecionado($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{obterPropriedadeDisplay(option)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-content></ng-content>
    </form>`
  ,
  styles: [
    ':host { display: inline-block; position: relative; width: 100%; }'
  ]
})
export class IPAutoCompleteComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  /**
  * Define se o componete deve ter o autofocus.
  * @type {boolean}
  */
  @Input() habilitarAutofocus = true;

  public filteredOptions: Observable<string[]>;
  // Referência: https://github.com/Pixabay/JavaScript-autoComplete
  /**
   * Define se o usuário poderá editar o valor do componente.
   * @type {boolean}
   */
  @Input() disabled = false;
  /**
   * Define se o placeholder ficará flutuando quando o usuário infomar um valor.
   * @type {boolean}
   */
  @Input() floatingPlaceholder = true;
  /**
   * Nome da propriedade do objeto que o identifica.
   * @type {string}
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('key') idProp: any = 'Id';
  /**
   * Nome da propriedade do objeto que contém a descrição que será apresentada.
   * @type {string}
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('display') displayProp = 'Descricao';
  /**
  * Define o número mínimo de caracteres para que a consulta seja disparada.
  * Valor padrão é 2.
  * @type {number}
  */
  @Input() minChars = 2;
  /**
   * Valor selecionado.
   *
   * @type {*}
   */
  @Input() model: any;
  /**
   * Texto que será apresentado no campo quando este não tiver valor, para indicar ao usuário
   * o que deve ser preenchido.
   * @type {string}
   */
  @Input() placeholder: string;
  /**
   * Função para obter as sugestões do auto-complete.
   * Este método recebe o termo de pesquisa informado e deve retornar um Observable.
   * @type {Function}
   */
  @Input() search: (termo: any) => Observable<any>;

  @Input() plainText: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any>;
  @Output() modelChange: EventEmitter<any>;
  @Output() selectChange: EventEmitter<any>;
  @Output() clear: EventEmitter<any> = new EventEmitter();
  myControl = new UntypedFormControl();
  public search$: any;
  public isLoading = false;
  private elementRef: ElementRef;
  public resultList: any[];
  private text: string;
  private numeroDeChecagensDados: number;
  private numeroDeAjustes: number;
  private temporizadorAjustes: any;
  searchedTerm: any;

  constructor(
    elementRef: ElementRef,
    private toastService: IPToastService,
  ) {
    this.elementRef = elementRef;
    this.change = new EventEmitter<any>();
    this.modelChange = new EventEmitter<any>();
    this.selectChange = new EventEmitter<any>();
    this.numeroDeAjustes = 0;
    this.numeroDeChecagensDados = 0;
    this.getValueModel = this.getValueModel.bind(this);
  }

  ngOnInit(): void {

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        debounceTime(350),
        tap((valor) => {
          if (LibGeral.estaPreenchido(valor) && valor instanceof String) {
            this.searchedTerm = valor.toLowerCase()
          }
        }),
        map(valor => {
          if (this.text === valor) {
            return this.resultList;
          }
          this.isLoading = true;
          return this.search(valor);
        }),
        switchMap(resultados => resultados),
        map(resultados => {
          this.isLoading = false;
          if (LibGeral.estaPreenchido(resultados)) {
            const retorno = !!resultados.Data ? resultados.Data : resultados;

            this.resultList = [...retorno];

            // se tem termo pesquisado, realiza o filtro, se não retorna a lista
            if (LibGeral.estaPreenchido(this.searchedTerm)) {
              return retorno.filter(option => {
                return option[this.displayProp].toLowerCase().indexOf(this.searchedTerm.toLowerCase()) > -1;
              });
            } else {
              return retorno;
            }
          }
          return [];
        })
        ,
        catchError(err => {
          this.isLoading = false;
          this.limpaValor();
          this.toastService.aviso("Ocorreu um erro ao obter lista.");
          return [];
        }));
  }

  private aplicarAutoFocus() {
    if (LibGeral.estaPreenchido(this.elementRef)) {
      const input = this.obterInput();
      if (LibGeral.estaPreenchido(input) && this.habilitarAutofocus) {
        input.focus();
      }
    }
  }

  ngAfterViewInit(): void {
    this.aplicarAutoFocus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.aplicarAutoFocus();
    if (!!changes.disabled) {
      if (changes.disabled.currentValue === true) {
        this.myControl.disable();

      } else {
        this.myControl.enable({ emitEvent: false });

      }
    }

    if (!!changes.model && !!changes.model.currentValue && !(changes.model.currentValue instanceof Event)) {
      const { previousValue, currentValue } = changes.model;
      this.myControl.patchValue(changes.model.currentValue, { emitEvent: false });
    }
    else if (changes.hasOwnProperty('model') && changes.model.currentValue === null && changes.model.previousValue !== null) {
      this.limparTodosValores();
    }
  }

  limparTodosValores() {
    this.model = null;
    this.searchedTerm = null;
    this.myControl.setValue(null, { emitEvent: true });
    this.modelChange.emit(this.myControl.value);
  }

  limpaValor() {
    if (!this.disabled) {
      this.limparTodosValores();
    }
  }

  obterInput(): HTMLInputElement {
    return this.elementRef.nativeElement.querySelector('input');
  }

  obterPropriedadeDisplay(model: any): string {
    return model
      ? model[this.displayProp]
      : "";
  }

  alterarObjetoSelecionado(itemSelecionado: MatAutocompleteSelectedEvent): void {
    const item = itemSelecionado.option.value;
    const valorDescritivo = this.getValueModel(item);
    const model = this.plainText ? valorDescritivo : item;
    this.text = valorDescritivo;
    this.modelChange.emit(model);
    this.change.emit(model);
    this.model = model;
  }

  getValueModel(model: any): any {
    let value = null;
    if (LibGeral.estaPreenchido(model)) {
      value = (typeof model === 'object') ? model[this.displayProp] : model;
    }

    return value;
  }
  getId(item: any): any {
    if (typeof this.idProp === 'string') {
      return item[this.idProp];
    } else {
      return this.idProp(item);
    }
  }

  ngOnDestroy(): void {
    if (this.search$) {
      this.search$.unsubscribe();
    }
  }
}
