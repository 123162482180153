import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPTreelistComponent } from './ip-treelist.component';
import { MaterialModule } from '../../../material/material.module';


@NgModule({
  imports: [MaterialModule],
  declarations: [IPTreelistComponent],
  exports: [IPTreelistComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPTreeelistModule {
}
