import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "@gemed-core/auth/auth.service";
import { SimNao } from "@gemed-core/enums/gemed.enum";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { HttpStatus } from "@gemed-core/models/httpStatus.enum";
import { LogInErroAction } from "@gemed-core/store/actions/auth.actions";
import { GemedState } from "@gemed-core/store/state";
import { IPToastService } from "@gemed-core/toast/ipToast.service";
import { State, Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { catchError, distinctUntilChanged, map, switchMap, take } from "rxjs/operators";
import { LogInComponent } from "src/app/users/log-in/log-in.component";
import { GenericInterceptor } from "./genericInterceptor";

@Injectable({ providedIn: 'root' })
export class RefreshTokenInterceptor implements HttpInterceptor {

  private dialogLoginRef: MatDialogRef<LogInComponent>;
  private requisicaoOriginal: HttpRequest<any>;

  constructor(
    private authService: AuthService,
    private toastService: IPToastService,
    private genericInterceptor: GenericInterceptor,
    private httpService: HttpClient,
    private dialog: MatDialog,
    private state: State<GemedState>,
    private store: Store<GemedState>
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(error => {
          const responseError = error as HttpErrorResponse;
          const sessaoExpirada = responseError.headers.get("SessaoExpirada");
          const usuarioAutenticado = this.state.value.auth.IsAuthenticated;

          if (LibGeral.estaPreenchido(sessaoExpirada) && SimNao.Sim === sessaoExpirada) {
            return this.handleEfetuarLoginNovamente(next, responseError.error.Message).pipe(
              switchMap(requisicao => requisicao as any)
            ) as Observable<HttpEvent<any>>;
          }

          if (responseError.status === 401 && usuarioAutenticado &&
            (!sessaoExpirada || SimNao.Nao === sessaoExpirada)) {
            if (!LibGeral.estaPreenchido(this.requisicaoOriginal)) {
              this.requisicaoOriginal = req.clone();
            }
            return this.authService.refresh()
              .pipe(
                take(1),
                switchMap(response => {
                  if (response.Status === HttpStatus.OK) {
                    return this.genericInterceptor.intercept(req, next);
                  }
                  return throwError(response.StatusText);
                }),
                catchError((refreshError) => {
                  this.apresentarErro(refreshError)
                  let mensagem = refreshError.Menssagem;
                  if (!LibGeral.estaPreenchido(mensagem)) {
                    mensagem = refreshError.StackTrace;
                  }
                  return throwError(mensagem);
                })
              );
          }

          return throwError(error);
        })
      );
  }


  private apresentarErro(error: any) {
    let mensagem = error.Menssagem;
    if (!LibGeral.estaPreenchido(mensagem)) {
      mensagem = error.StackTrace;
    }

    return throwError(mensagem);
  }

  handleEfetuarLoginNovamente(next: HttpHandler, mensagem: string) {

    if (!LibGeral.estaPreenchido(this.dialogLoginRef)) {
      this.dialogLoginRef = this.dialog.open(LogInComponent);

      this.store.select(store => store.auth)
        .pipe(
          switchMap(auth => of(auth.Usuario)),
          map(usuario => usuario.TokenExpirado),
          distinctUntilChanged(),
        )
        .subscribe(tokenExpirado => {
          const tokenRenovadoComSucesso = !LibGeral.estaPreenchido(tokenExpirado);
          if (tokenRenovadoComSucesso &&
            LibGeral.estaPreenchido(this.dialogLoginRef)) {
            this.dialogLoginRef.close(true);
          }

          return tokenExpirado;
        });

      this.dialogLoginRef.afterClosed().subscribe(() => {

        this.dialogLoginRef = null;
      });

      return this.dialogLoginRef.beforeClosed().pipe(
        map((tokenRenovado) => {

          const usuarioFechouManualmente = !LibGeral.estaPreenchido(tokenRenovado);

          if (usuarioFechouManualmente) {
            setTimeout(() => {
              this.dialog.closeAll();
              this.authService.LogOut();
            }, 1000);
            return new Observable<never>();
          } else {
            return this.genericInterceptor.intercept(this.requisicaoOriginal, next);
          }
        })
      )

    } else {
      this.store.dispatch(new LogInErroAction({ Menssagem: mensagem } as any));
    }
  }
}
