
import {
  HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { IPError } from '@gemed-core/models';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retry, retryWhen, scan, tap } from 'rxjs/operators';
import { LoggingService } from './loggingService';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const logger = this.injector.get(LoggingService);

    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        mergeMap((error: IPError, index) => {
          // Verifica o status de retorno
          if (error.StackTrace.status !== 500) {
            // Se o status não for 500, tenta novamente mais uma vez
            if (index < 1) {
              const retryDelay = 500; // Define um atraso de 0.5 segundo antes de tentar novamente
              return timer(retryDelay);
            }
          }
          // Se o status for 500 e já tentou uma vez retornar o erro
          throw error;
        })
      )),
      catchError((error: any) => {
        if (error instanceof IPError) {
          const message = error.Menssagem;
          const stackTrace = JSON.stringify(error.StackTrace);

          if (LibGeral.estaEmBranco(message)) {
            return;
          }

          logger.logError(message, stackTrace);
        }

        return throwError(error);
      })
    );
  }
}
