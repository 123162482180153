export class LibCollections {
  public static first<T>(array: T[]): T {
    return !array
      ? null
      : array[0];
  }

  public static last<T>(array: T[]): T {
    return (!array || array.length === 0)
    ? null
    : array[array.length - 1];
  }

  public static indexOf<T>(array: T[], value: T, startIndex: number = 0): number {
    return array.indexOf(value, startIndex);
  }

  public static isEmpty(list: any[]): boolean {
    return list.length === 0;
  }

  public static hasItems(list: any[]): boolean {
    return !this.isEmpty(list);
  }

  public static isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public static clear(list: any[]): void {
    list.length = 0;
  }
}

export const filterMap = (map: {}, predicado: any) => {
  const mapFilter = new Map();
  Object.entries(map).map((valor, key) => {
    if (predicado(key)) {
      mapFilter.set(key, valor);
    }
  });
  return mapFilter;
};

export const mapToArray = (map: Map<any, any>) => {
  const arr = [];
  Object.keys(map).forEach((k, i) => {
    arr.push(map[k]);
  });
  return arr;
};

