import { Component, OnInit, Input, Output } from '@angular/core';


@Component({
  selector: 'ip-treelist',
  templateUrl: './ip-treelist.component.html',
  styleUrls: ['./ip-treelist.component.css']
})



export class IPTreelistComponent {
  @Output() linhasSelecionadas: any[] = [];
  @Input() listaDados: any[];
  @Input() tipoSelecao: string = "multiple";
  @Input() habilitarSelecaoRecursiva: boolean = true;

  constructor() {
  }
}
