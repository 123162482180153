export const p = {
  "pt": {
    "Yes": "Sim",
    "No": "Não",
    "Cancel": "Cancelar",
    "Clear": "Limpar",
    "Done": "Feito",
    "Loading": "Carregando...",
    "Select": "Selecionar...",
    "Search": "Buscar",
    "Back": "Voltar",
    "OK": "OK",
    "dxCollectionWidget-noDataText": "Não há dados para apresentar",
    "validation-required": "Requerido",
    "validation-required-formatted": "{0} é requerido",
    "validation-numeric": "O valor deve ser um número",
    "validation-numeric-formatted": "{0} deve ser um número",
    "validation-range": "Valor fora dos limites",
    "validation-range-formatted": "{0} está fora dos limites",
    "validation-stringLength": "O comprimento do valor não é válido",
    "validation-stringLength-formatted": "O comprimento de {0} não é válido",
    "validation-custom": "Valor inválido",
    "validation-custom-formatted": "{0} é inválido",
    "validation-compare": "Valores não são iguais",
    "validation-compare-formatted": "{0} não é igual",
    "validation-pattern": "Valor em formato incorreto",
    "validation-pattern-formatted": "O formato de {0} não é válido",
    "validation-email": "Email inválido",
    "validation-email-formatted": "{0} é inválido",
    "validation-mask": "Valor é inválido",
    "dxLookup-searchPlaceholder": "Número mínimo de caracteres: {0}",
    "dxList-pullingDownText": "Puxe para baixo para atualizar...",
    "dxList-pulledDownText": "Release to refresh...",
    "dxList-refreshingText": "Atualizando...",
    "dxList-pageLoadingText": "Carregando...",
    "dxList-nextButtonText": "Mais",
    "dxList-selectAll": "Selecionar todos",
    "dxListEditDecorator-delete": "Deletar",
    "dxListEditDecorator-more": "Mais",
    "dxScrollView-pullingDownText": "Puxe para baixo para atualizar...",
    "dxScrollView-pulledDownText": "Solte para atualizar...",
    "dxScrollView-refreshingText": "Atualizando...",
    "dxScrollView-reachBottomText": "Carregando...",
    "dxDateBox-simulatedDataPickerTitleTime": "Selecione a hora",
    "dxDateBox-simulatedDataPickerTitleDate": "Selecione a data",
    "dxDateBox-simulatedDataPickerTitleDateTime": "Selecione a data e a hora",
    "dxDateBox-validation-datetime": "Valor deve ser uma data ou uma hora",
    "dxFileUploader-selectFile": "Selecione o arquivo",
    "dxFileUploader-dropFile": "ou solte o arquivo aqui",
    "dxFileUploader-bytes": "bytes",
    "dxFileUploader-kb": "kb",
    "dxFileUploader-Mb": "Mb",
    "dxFileUploader-Gb": "Gb",
    "dxFileUploader-upload": "Enviar",
    "dxFileUploader-uploaded": "Enviado",
    "dxFileUploader-readyToUpload": "Pronto para enviar",
    "dxFileUploader-uploadFailedMessage": "Envio falhou",
    "dxRangeSlider-ariaFrom": "De",
    "dxRangeSlider-ariaTill": "Até",
    "dxSwitch-onText": "LIGADO",
    "dxSwitch-offText": "DESLIGADO",
    "dxForm-optionalMark": "opcional",
    "dxForm-requiredMessage": "{0} é requerido",
    "dxNumberBox-invalidValueMessage": "Valor deve ser um número",
    "dxDataGrid-columnChooserTitle": "Seletor de coluna",
    "dxDataGrid-columnChooserEmptyText": "Arraste a coluna qui para escondê-la",
    "dxDataGrid-groupContinuesMessage": "Continua na próxima página",
    "dxDataGrid-groupContinuedMessage": "Continuação da página anterior",
    "dxDataGrid-groupHeaderText": "Agrupar por esta coluna",
    "dxDataGrid-ungroupHeaderText": "Desagrupar",
    "dxDataGrid-ungroupAllText": "Desagrupar todas",
    "dxDataGrid-editingEditRow": "Editar",
    "dxDataGrid-editingSaveRowChanges": "Salvar",
    "dxDataGrid-editingCancelRowChanges": "Cancelar",
    "dxDataGrid-editingDeleteRow": "Deletar",
    "dxDataGrid-editingUndeleteRow": "Restaurar",
    "dxDataGrid-editingConfirmDeleteMessage": "Você tem certeza que deseja deletar este registro?",
    "dxDataGrid-validationCancelChanges": "Cancelar alterações",
    "dxDataGrid-groupPanelEmptyText": "Arraste o cabeçalho da coluna aqui para agrupar pela coluna",
    "dxDataGrid-noDataText": "Sem dados",
    "dxDataGrid-searchPanelPlaceholder": "Pesquisa...",
    "dxDataGrid-filterRowShowAllText": "(Todos)",
    "dxDataGrid-filterRowResetOperationText": "Resetar",
    "dxDataGrid-filterRowOperationEquals": "Igual",
    "dxDataGrid-filterRowOperationNotEquals": "Diferente",
    "dxDataGrid-filterRowOperationLess": "Menor que",
    "dxDataGrid-filterRowOperationLessOrEquals": "Menor or igual que",
    "dxDataGrid-filterRowOperationGreater": "Maior que",
    "dxDataGrid-filterRowOperationGreaterOrEquals": "Maior ou igual que",
    "dxDataGrid-filterRowOperationStartsWith": "Começa com",
    "dxDataGrid-filterRowOperationContains": "Contém",
    "dxDataGrid-filterRowOperationNotContains": "Não contém",
    "dxDataGrid-filterRowOperationEndsWith": "Termina com",
    "dxDataGrid-filterRowOperationBetween": "Entre",
    "dxDataGrid-filterRowOperationBetweenStartText": "Inicia",
    "dxDataGrid-filterRowOperationBetweenEndText": "Termina",
    "dxDataGrid-applyFilterText": "Aplicar filtro",
    "dxDataGrid-trueText": "verdadeiro",
    "dxDataGrid-falseText": "falso",
    "dxDataGrid-sortingAscendingText": "Ordenar ascendentemente",
    "dxDataGrid-sortingDescendingText": "Ordenar descendentemente",
    "dxDataGrid-sortingClearText": "Limpar ordenação",
    "dxDataGrid-editingSaveAllChanges": "Salvar alterações",
    "dxDataGrid-editingCancelAllChanges": "Descartar alterações",
    "dxDataGrid-editingAddRow": "Adicionar linha",
    "dxDataGrid-summaryMin": "Mínimo: {0}",
    "dxDataGrid-summaryMinOtherColumn": "Mínimo de {1} é {0}",
    "dxDataGrid-summaryMax": "Máximo: {0}",
    "dxDataGrid-summaryMaxOtherColumn": "Máximo de {1} é {0}",
    "dxDataGrid-summaryAvg": "Média: {0}",
    "dxDataGrid-summaryAvgOtherColumn": "Média de {1} é {0}",
    "dxDataGrid-summarySum": "Soma: {0}",
    "dxDataGrid-summarySumOtherColumn": "Soma de {1} é {0}",
    "dxDataGrid-summaryCount": "Contador: {0}",
    "dxDataGrid-columnFixingFix": "Fixar",
    "dxDataGrid-columnFixingUnfix": "Desfixar",
    "dxDataGrid-columnFixingLeftPosition": "Para a esquerda",
    "dxDataGrid-columnFixingRightPosition": "Para a direita",
    "dxDataGrid-exportTo": "Exportar",
    "dxDataGrid-exportToExcel": "Exportar para Excel",
    "dxDataGrid-excelFormat": "Arquivo Excel",
    "dxDataGrid-selectedRows": "Selecionar linhas",
    "dxDataGrid-exportSelectedRows": "Exportar linhas selecionadas",
    "dxDataGrid-exportAll": "Exportar tudo",
    "dxDataGrid-headerFilterEmptyValue": "(Vazio)",
    "dxDataGrid-headerFilterOK": "OK",
    "dxDataGrid-headerFilterCancel": "Cancelar",
    "dxDataGrid-ariaColumn": "Coluna",
    "dxDataGrid-ariaValue": "Valor",
    "dxDataGrid-ariaFilterCell": "Filtar célula",
    "dxDataGrid-ariaCollapse": "Contrair",
    "dxDataGrid-ariaExpand": "Expandir",
    "dxDataGrid-ariaDataGrid": "Data grid",
    "dxDataGrid-ariaSearchInGrid": "Pesquisa no grid",
    "dxDataGrid-ariaSelectAll": "Selecionar tudo",
    "dxDataGrid-ariaSelectRow": "Selecionar linha",
    "dxPager-infoText": "Página {0} de {1} ({2} itens)",
    "dxPager-pagesCountText": "de",
    "dxPivotGrid-grandTotal": "Total geral",
    "dxPivotGrid-total": "{0} Total",
    "dxPivotGrid-fieldChooserTitle": "Seletor de campo",
    "dxPivotGrid-showFieldChooser": "Apresentar seletor de campo",
    "dxPivotGrid-expandAll": "Expandir tudo",
    "dxPivotGrid-collapseAll": "Contrair tudo",
    "dxPivotGrid-sortColumnBySummary": "Ordenar '{0}' por esta coluna",
    "dxPivotGrid-sortRowBySummary": "Ordenar '{0}' por esta linha",
    "dxPivotGrid-removeAllSorting": "Remover todas ordenações",
    "dxPivotGrid-rowFields": "Campos de linha",
    "dxPivotGrid-columnFields": "Campos de coluna",
    "dxPivotGrid-dataFields": "Campos de dados",
    "dxPivotGrid-filterFields": "Campos de filtro",
    "dxPivotGrid-allFields": "Todos campos",
    "dxPivotGrid-columnFieldArea": "Largue campos de coluna aqui",
    "dxPivotGrid-dataFieldArea": "Largue campos de dados aqui",
    "dxPivotGrid-rowFieldArea": "Largue campos de liha aqui",
    "dxPivotGrid-filterFieldArea": "Largue campos de filtro aqui",
    "dxScheduler-editorLabelTitle": "Assunto",
    "dxScheduler-editorLabelStartDate": "Data de início",
    "dxScheduler-editorLabelEndDate": "Data de término",
    "dxScheduler-editorLabelDescription": "Descrição",
    "dxScheduler-editorLabelRecurrence": "Repetir",
    "dxScheduler-openAppointment": "Abrir agenda",
    "dxScheduler-recurrenceNever": "Nunca",
    "dxScheduler-recurrenceDaily": "Diário",
    "dxScheduler-recurrenceWeekly": "Semanal",
    "dxScheduler-recurrenceMonthly": "Mensal",
    "dxScheduler-recurrenceYearly": "Anual",
    "dxScheduler-recurrenceEvery": "Sempre",
    "dxScheduler-recurrenceEnd": "Terminar recorrência",
    "dxScheduler-recurrenceAfter": "Depois",
    "dxScheduler-recurrenceOn": "De",
    "dxScheduler-recurrenceRepeatDaily": "dias(s)",
    "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
    "dxScheduler-recurrenceRepeatMonthly": "mes(es)",
    "dxScheduler-recurrenceRepeatYearly": "ano(s)",
    "dxScheduler-switcherDay": "Dia",
    "dxScheduler-switcherWeek": "Semana",
    "dxScheduler-switcherWorkWeek": "Semana de trabalho",
    "dxScheduler-switcherMonth": "Mês",
    "dxScheduler-switcherAgenda": "Agenda",
    "dxScheduler-switcherTimelineDay": "Linha do tempo (dia)",
    "dxScheduler-switcherTimelineWeek": "Linha do tempo (semana)",
    "dxScheduler-switcherTimelineWorkWeek": "Linha do tempo (semana de trabalho)",
    "dxScheduler-switcherTimelineMonth": "Linha do tempo (mes)",
    "dxScheduler-recurrenceRepeatOnDate": "no dia",
    "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
    "dxScheduler-allDay": "Dia todo",
    "dxScheduler-confirmRecurrenceEditMessage": "Você deseja editar somente esta agenda ou todas as recorrências?",
    "dxScheduler-confirmRecurrenceDeleteMessage": "Você deseja remover somente esta agenda ou todas as recorrências?",
    "dxScheduler-confirmRecurrenceEditSeries": "Editar recorrência",
    "dxScheduler-confirmRecurrenceDeleteSeries": "Deletar recorrências",
    "dxScheduler-confirmRecurrenceEditOccurrence": "Editar agenda",
    "dxScheduler-confirmRecurrenceDeleteOccurrence": "Deletar agenda",
    "dxScheduler-noTimezoneTitle": "Sem fuso horário",
    "dxCalendar-todayButtonText": "Hoje",
    "dxCalendar-ariaWidgetName": "Calendário",
    "dxColorView-ariaRed": "Vermelho",
    "dxColorView-ariaGreen": "Verde",
    "dxColorView-ariaBlue": "Azul",
    "dxColorView-ariaAlpha": "Transparência",
    "dxColorView-ariaHex": "Código da cor",
    "vizExport-printingButtonText": "Imprimir",
    "vizExport-titleMenuText": "Exportar/Imprimir",
    "vizExport-exportButtonText": "{0} Arquivo"
  }
};
