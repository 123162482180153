import { ILoginTemporario } from "@gemed-core/interfaces/gemed.interface";
import { Clinica } from "@gemed-core/models/clinica.model";
import { Usuario } from "@gemed-core/models/usuario.model";
import { IUsuarioContexto, ICliente, IFilial } from "ip_types_ts/common-typings";

export class AuthState {

  public ehPrimeiroAcesso = true;

  public Usuario: Usuario = new Usuario();

  public idClinica: number;
  public razaoClinica: string;

  public sistema: string;

  public contextos?: Map<string, IUsuarioContexto>;
  public loginTemporario: ILoginTemporario;

  /**
   * Cliente selecionado.
   * @type {ICliente}
   * @memberOf AuthState
   */
  public cliente?: ICliente;
  /**
   * Filial selecionada.
   * @type {IFilial}
   * @memberOf AuthState
   */
  public filial?: IFilial;

  public contexto?: IUsuarioContexto;
  public IsAuthenticated = false;
  public error: any = null;
  public isLoading: boolean = false;
  public manterLogado?: boolean;
}
