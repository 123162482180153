import { Component, EventEmitter, Input, Output } from '@angular/core';
import './ip-radio.component.less';

//  moduleId: String(module.id),
@Component({
  selector: 'ip-radio-group',
  template: `
    <mat-radio-group [(value)]="model" [disabled]="disabled" [name]="nameGroup">
      <ng-content></ng-content>
    </mat-radio-group>
  `
})
export class IPRadioGroupComponent {
  @Input() nameGroup: string;
  @Input() disabled: boolean;
  @Input() model: any;
  @Output() change: EventEmitter<any>;

  constructor() {
    this.change = new EventEmitter<any>();
  }
}
