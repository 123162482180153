// Referência: https://getmdl.io/components/index.html#tables-section
// import { BooleanFieldValue } from '@angular2-material/core/annotations/field-value';
import {  Directive,
          Input,
          DoCheck,
          Inject,
          ElementRef,
          Renderer2,
          OnInit,
          ViewContainerRef,
          ComponentFactoryResolver } from '@angular/core';

import { IPProgressComponent } from '../progress';
import { LibComponent } from '../../../core/libraries/libComponent';

@Directive({ selector: '[ip-table-head-progress]' })
export class IPTableHeadProgressDirective implements OnInit, DoCheck {

    private element: ElementRef;
    private renderer: Renderer2;
    private viewContainerRef: ViewContainerRef;
    private componentResolver: ComponentFactoryResolver;
    private progressComponent: any;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('ip-table-head-progress') isBusy = false;

    constructor(elementRef: ElementRef,
                renderer: Renderer2,
                viewContainerRef: ViewContainerRef,
                @Inject(ComponentFactoryResolver) componentResolver: ComponentFactoryResolver) {
      this.element = elementRef;
      this.renderer = renderer;
      this.viewContainerRef = viewContainerRef;
      this.componentResolver = componentResolver;
    }

    ngOnInit(): void {
      LibComponent
        .CreateComponentWithPromise(IPProgressComponent, this.componentResolver, this.viewContainerRef, null)
        .then((componentRef: any) => {
          this.progressComponent = componentRef;
          this.progressComponent.hostView.rootNodes[0].style.position = 'absolute';
          this.progressComponent.hostView.rootNodes[0].style.display = this.isBusy ? 'flex' : 'none';

          this.updateWidthProgress();

          const componentElement = this.progressComponent.hostView.rootNodes[0];
          
          // this.renderer.invokeElementMethod(
          //   this.viewContainerRef.element.nativeElement,
          //   'appendChild',
          //   [componentElement]);
          this.renderer.appendChild(this.viewContainerRef.element.nativeElement, componentElement);
        });
    }

    ngDoCheck(): void {
      if (this.progressComponent) {
        this.updateWidthProgress();
        this.progressComponent.hostView.rootNodes[0].style.display = this.isBusy ? 'flex' : 'none';
      }
    }

    updateWidthProgress(): void {
      const box = this.viewContainerRef.element.nativeElement.getBoundingClientRect();
      const componentElement = this.progressComponent.hostView.rootNodes[0];
      componentElement.style.width = `${box.width}px`;
    }
}
