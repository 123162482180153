import { Component, OnInit, Input, OnChanges, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { IPProgressComponent } from '../progress';

@Component({
    selector: 'ip-loading-with-overlay',
    templateUrl: './loading-with-overlay.component.html',
    styleUrls: ['./loading-with-overlay.component.scss']
})
export class LoadingWithOverlayComponent implements OnInit{

  @Input() isLoading: boolean;
  @Input() tipo: string = "circular";
  @Input() CustomStyles: any[] = [];
  @ViewChild('ipProgress', {read: ElementRef}) ipProgress: ElementRef;
  @ViewChild('overlay', {read: ElementRef}) overlay: ElementRef;
  
  constructor(private _renderer: Renderer2) { }


  deveAlterarEstilo(){
    if(this.CustomStyles.length > 0){
      this.CustomStyles.map((newStyle:string) => {
        const { [0]:style, [1]:value} = newStyle.match(/([A-z]+|\-)+[^\{\:\}]/g);
        this._renderer.setStyle(this.overlay.nativeElement, style, value);
        
      })
    }
  }

  ngOnInit() {
    this.deveAlterarEstilo();
  }

}
