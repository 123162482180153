import { Injectable } from '@angular/core';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { GemedState } from '@gemed-core/store/state';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { carregarMenuAction, resetStateMenuAction } from '@pep-assistencial-store/actions/menu.action';
import { iif } from 'rxjs';
import { last, map, skip } from 'rxjs/operators';
import * as state from '../state/menu.state';
import { StoreService } from './StoreService.service';



const getFiltro = state => state.MenuBase.menu;
const getEntities = state => state.entities;


@Injectable()
export class MenuUsuarioStoreService extends StoreService {

    private MenuBaseState = createFeatureSelector<state.MenuBaseState>('menu');

    private selectors = state.MenuBaseAdapter.getSelectors(this.MenuBaseState);

    private selectCurrentTaskId = createSelector(
        this.MenuBaseState,
        state.selectedMenuBaseId
    );

    private isLoading = createSelector(this.MenuBaseState, state.selectIsLoading);
    private error = createSelector(this.MenuBaseState, state.selectError);

    constructor(protected store: Store<GemedState>) {
        super();
    }

    dispatchCreateAction(record: any) {
        throw new Error("Method not implemented.");
    }

    dispatchCreateActionCarregarMenuAction(dados: number): Promise<any> {
        this.dispatchAction(carregarMenuAction({ payload: dados }));
        return this.getAllMenuAsync();
    }

    dispatchCreateActionResetMenu() {
      this.dispatchAction(resetStateMenuAction());
  }


    dispatchCreateActionBuscarNomeMenu(dados: any): Promise<string> {
        return this.getNameMenuAsync(dados);
    }

    dispatchLoadAction() {
        throw new Error("Method not implemented.");
    }

    dispatchUpdateAction(record: any) {
        throw new Error("Method not implemented.");
    }
    dispatchRemoveAction({ id: any }: { id: any; }) {
        throw new Error("Method not implemented.");
    }



    /**
     * Busca todos os menus que o usuário tem direito a acessar de forma assincrona
     * @param componente nome do componente
     */
    async getAllMenuAsync(): Promise<any> {
        return new Promise(resolve => {
            this.findAllMenu()
                .subscribe(({ menus }) => {
                    if (LibGeral.estaPreenchido(menus)) {
                        resolve(menus);
                    }
                });
        });
    }

    /**
      * Busca objeto menu específico que usuário tem direito a acessar
     * @param componente nome do componente
     */
    public getMenuAsync(componente: string): Promise<any> {
        return new Promise(resolve => {
            this.findAllMenu()
                .subscribe(filtro => {
                    if (LibGeral.estaPreenchido(filtro) && LibGeral.estaPreenchido(filtro.menus)) {
                        filtro.menus.forEach(menu => {
                            const existeMenu = menu.Submenu.some(submenu => submenu.Rota === componente);
                            if (existeMenu) {
                                return resolve(menu.Submenu.find(submenu => submenu.Rota === componente));
                            }
                        });
                    }
                });
        });
    }

    /**
      * Busca nome do menu específico que usuário tem direito a acessar
     * @param componente nome do componente
     */
    async getNameMenuAsync(componente: string): Promise<string> {
        const menu = await this.getMenuAsync(componente);
        if (LibGeral.estaPreenchido(menu)) {
            return menu.Descricao;
        }
        return "";
    }


    /**
     * Filtrar a lista de menus
     */
    private findAllMenu() {
        return this.store.select(({ menu }) => menu);
    }

    /**
     * Recuperar os menus do store
     */
    private getMenus() {
        return this.store.select(this.selectors.selectAll);
    }

}
