import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPMenuComponent } from './ip-menu.component';
import { IPMenuItemComponent } from './ip-menu-item.component';
import { MaterialModule } from '../../../material/material.module';
import { IPMenuLateralComponent } from './ip-menu-lateral.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IPMenuLateralListComponent } from './ip-menu-lateral-list.component';
import { IPCollapseModule } from '../collapse';

const components = [
  IPMenuComponent, IPMenuItemComponent, IPMenuLateralComponent, IPMenuLateralListComponent
];

@NgModule({
  imports: [MaterialModule, CommonModule, IPCollapseModule, TranslateModule.forChild()],
  declarations: components,
  exports: components,
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPMenuModule {
}
