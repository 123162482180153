/**
 * Classe que representa um intervalo de tempo.
 *
 * @export
 * @class DateRange
 */
export class DateRange {

  /**
   * Início do intervalo.
   *
   * @type {Date}
   */
  public get startDate(): Date { return this._startDate; }
  public set startDate(date: Date) { this._startDate = date; }

  /**
   * Fim do intervalo.
   *
   * @type {Date}
   */
  public get endDate(): Date { return this._endDate;  }
  public set endDate(date: Date) { this._endDate = date; }

  /**
   * Retorna TRUE se o intervalo refere-se a um dia.
   *
   * @readonly
   * @type {boolean}
   */
  public get sameDay(): boolean { return this.startDate.toDateString() === this.endDate.toDateString(); }

  private _startDate: Date;
  private _endDate: Date;

  constructor(startDate: Date, endDate?: Date) {
    this.startDate = startDate;
    this.endDate = endDate || this.startDate;
  }
}
