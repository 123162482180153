import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LibGeral } from '@gemed-core/libraries/libGeral';

@Pipe({ name: 'formatDateHours' })
export class FormatDateHours implements PipeTransform {
  transform(cellInfo: any, format: string): string {
    let newStr: string = cellInfo;
    var datePipe = new DatePipe("en-US");

    if (LibGeral.estaPreenchido(cellInfo)) {
      if (cellInfo instanceof Date) {
        switch (format) {
          case 'DATE':
            newStr = datePipe.transform(cellInfo, 'dd/MM/yyyy');
            break;
          case 'HOURS':
            newStr = datePipe.transform(cellInfo, 'HH:mm');
            break;
          case 'DATE_HOURS':
            newStr = datePipe.transform(cellInfo, 'dd/MM/yyyy HH:mm');
            break;
          default:
            break;
        }
      }
    }


    return newStr;
  }
}