import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "ip-menu-lateral-list",
  template: ` <ip-list flex style="display: flex; flex-direction:column ">
    <ip-list-item
      *ngFor="let item of source"
      layout="column"
      layout-align="start start"
      [ngClass]="{ 'menu-pai': childrens(item).length > 0 }"
    >
      <span
        flex
        style="width: 100%"
        [ngClass]="{
          'span-pai': childrens(item).length > 0,
          'span-filho': childrens(item).length <= 0,
          'rota-ativa': RotaEstaAtiva(item)
        }"
      >
        <ip-button-flat
          [efeitoRipple]="false"
          (click)="onClick(item)"
          data-toggle="dropdown"
          class="sidebar-list-item"
          [ngClass]="classeIndentacao"
          flex
          layout="row"
          layout-align="start center"
          layout-fill
        >
          <img  [attr.src]="item.IconPath" style="height:25px; margin:5px;" />
          <span
            flex
            layout="row"
            layout-align="start center"
            style="padding-left:2%;"
          >
            {{ display(item) }}
          </span>
          <i
            class="material-icons"
            style="padding-right: 2%;"
            *ngIf="item.isCollapse && childrens(item).length > 0"
          >
            keyboard_arrow_right
          </i>
          <i
            class="material-icons"
            style="padding-right: 2%;"
            *ngIf="!item.isCollapse && childrens(item).length > 0"
          >
            keyboard_arrow_down
          </i>
        </ip-button-flat>
      </span>
      <ip-menu-lateral-list
      class="color-submenu"
        style="padding-left: 10%; width:100%; "
        flex
        layout="column"
        *ngIf="childrens(item).length > 0"
        [levelIndentacao]="levelIndentacao + 1"
        [display]="display"
        (itemClick)="onClick($event)"
        [childrens]="childrens"
        [source]="childrens(item)"
        [(ip-collapse)]="item.isCollapse"
      >
      </ip-menu-lateral-list>
    </ip-list-item>
  </ip-list>`,
  styleUrls: ["./ip-menu-lateral-list.component.scss"],
})
export class IPMenuLateralListComponent implements OnInit {
  @Input() display: (x: any) => any;
  // @Input() image: (x: any) => any;
  @Input() source: (x: any) => any;
  @Input() childrens: (x: any) => any;
  @Input() levelIndentacao = 0;
  @Output() itemClick: EventEmitter<any>;

  public isCollapse: boolean;
  public classeIndentacao: string;
  public router: Router;

  constructor(router: Router) {
    this.router = router;
    this.isCollapse = true;
    this.itemClick = new EventEmitter();
  }

  RotaEstaAtiva(item: any) {
    return !!this.router && this.router.url == item.Rota && item.IdPai > 0
      ? true
      : false;
  }

  ngOnInit(): void {
    this.classeIndentacao = `iden-${this.levelIndentacao}`;
  }

  onClick(event: any): void {
    if (this.childrens(event).length > 0) {
      event.isCollapse = !event.isCollapse;
    } else {
      this.itemClick.emit(event);
    }
  }
}
