import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IPPaginationComponent } from './ip-pagination.component';
import { MaterialModule } from '../../../material/material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [MaterialModule, TranslateModule.forChild(), CommonModule],
  declarations: [IPPaginationComponent],
  exports: [IPPaginationComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPPaginationModule {
}
