// import 'expose-loader?BezierEasing!bezier-easing/src/index.js';
import * as BezierEasing from 'bezier-easing';

import { Directive, ElementRef, Input, SimpleChange, ChangeDetectorRef, OnInit, OnChanges, AfterContentInit } from '@angular/core';
@Directive({
  selector: '[ip-collapse]',
  // eslint-disable-next-line
  host: {
    'style': 'overflow: hidden'
  }
})
export class IPCollapseDirective implements OnInit, OnChanges, AfterContentInit {
  @Input() duration: number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ip-collapse') collapse: boolean;
  @Input() easeInSample: any[];
  @Input() easeOutSample: any[];

  private changeDetectorRef: ChangeDetectorRef;
  private easeIn: Function;
  private easeOut: Function;
  private elementRef: ElementRef;
  private element: HTMLElement;
  private get elementExtraBoundings(): number {
    const style: CSSStyleDeclaration = getComputedStyle(this.element);
    const marginBT = parseInt(style.marginBottom, 10);
    const paddingTop = parseInt(style.paddingTop, 10);
    const paddingBT = parseInt(style.paddingBottom, 10);
    const marginTop = parseInt(style.marginTop, 10);

    return marginTop + marginBT + paddingTop + paddingBT;
  }
  private get elementHeight(): number {
    const el = this.element;
    const style: CSSStyleDeclaration = getComputedStyle(el);
    let height: number = el.offsetHeight;
    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
  }
  private hideTimeout: any;
  private showTimeout: any;
  private startStep: number;
  private requestId: number;
  private originalHeight: string;
  private isComponentInit: boolean;

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
    this.duration = 200;
    this.easeInSample = [0.4, 0.0, 1, 1];
    this.easeOutSample = [0.0, 0.0, 0.2, 1];
    this.elementRef = elementRef;
    this.element = this.elementRef.nativeElement;
    this.isComponentInit = false;
    this.startStep = null;
  }
  ngOnInit(): void {
    this.easeOut = BezierEasing.apply(BezierEasing, this.easeOutSample);
    this.easeIn = BezierEasing.apply(BezierEasing, this.easeInSample);
  }
  ngOnChanges(changes: any): void {
    if (this.isComponentInit === false) {
      return void (0);
    }
    const collapse: SimpleChange = changes.collapse;
    if (collapse.currentValue !== collapse.previousValue) {
      if (this.collapse) {
        this.cancelShow();
        clearTimeout(this.hideTimeout);
        this.hideTimeout = setTimeout(this.hide.bind(this), 1);
      } else {
        this.cancelHide();
        clearTimeout(this.showTimeout);
        this.showTimeout = setTimeout(this.show.bind(this), 1);
      }
    }
  }
  ngAfterContentInit(): void {
    this.originalHeight = this.element.style.height;
    this.isComponentInit = true;
    this.initState();
  }

  initState(): void {
    if (this.collapse) {
      this.cancelHide();
    } else {
      this.cancelShow();
    }
  }
  hide(): void {
    if (this.startStep === null) {
      const initialHeigth = this.elementHeight;
      this.animate((step: number) => {
        const height = initialHeigth * this.easeOut(step);
        if (height >= 0) {
          const finalHeigth = initialHeigth - height;
          this.element.style.height = `${finalHeigth}px`;
        } else {
          this.element.style.height = '0';
        }
      });
    }
  }

  show(): void {
    if (this.startStep === null) {
      const endHeight = this.element.scrollHeight + this.elementExtraBoundings;
      this.animate((step: number) => {
        let height = endHeight * this.easeIn(step);

        if (height > endHeight) {
          height = endHeight;
        }

        this.element.style.height = `${height}px`;
      });
    }
  }
  animate(transform: Function): void {
    this.cancelAnimation();
    this.requestId = window.requestAnimationFrame(this.step.bind(this, transform));
  }
  step(transform: Function, timestamp: number): void {
    if (!this.startStep) {
      this.startStep = timestamp;
    }
    const progress = timestamp - this.startStep;
    const step = progress / this.duration;
    transform(step);
    if (progress < this.duration) {
      this.cancelAnimation();
      this.requestId = window.requestAnimationFrame(this.step.bind(this, transform));
    }
    if (progress > this.duration) {
      this.startStep = null;

      if (!this.collapse) {
        this.element.style.height = this.originalHeight || 'auto';
      } else {
        // garante que no final da animação sempre sera 0
        this.element.style.height = '0';
      }
    }
  }
  private cancelShow(): void {
    // this.element.style.height = `${this.element.scrollHeight + this.elementExtraBoundings}px`;
    this.clear();
  }
  private cancelHide(): void {
    this.element.style.height = `0`;
    this.clear();
  }
  private clear(): void {
    this.startStep = null;
    this.cancelAnimation();
  }
  private cancelAnimation(): void {
    if (this.requestId) {
      // workaround angular 2 altera requestFrame
      window.cancelAnimationFrame(this.requestId);
    }
  }
}
