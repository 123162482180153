// Referência: https://getmdl.io/components/index.html#tables-section
import { Directive, ElementRef, EventEmitter, Input, Renderer2, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { LibGeral } from '../../../core/libraries/libGeral';
 

@Directive({
  selector: '[ip-table]'
})
export class IPTableDirective implements OnInit {
  /**
   * Lista dos objetos sendo apresentados.
   * @type {any[]}
   */
  @Input() source: any[];
  /**
   * Função chamada para validar a linha após edição.
   * @type {Function}
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateRow') validateRowFunction: Function;


  /**
   * Evento emitido quando uma linha foi removida da tabela.
   * @type {EventEmitter<any>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('deleteRow') deleteRowOutput: EventEmitter<any>;
  /**
   * Evento emitido quando a lista de objetos sendo apresentada foi alterada.
   * @type {EventEmitter<any>}
   */
  @Output() sourceChange: EventEmitter<any>;

  private element: ElementRef;
  private renderer: Renderer2;

  constructor(ngEl: ElementRef, renderer: Renderer2) { // loader: DynamicComponentLoader, private _containerRef: ViewContainerRef
    this.element = ngEl;
    this.renderer = renderer;

    this.deleteRowOutput = new EventEmitter<any>();
    this.sourceChange = new EventEmitter<any>();
  }

  ngOnInit(): void {
    /// this.renderer.addClass(this.element.nativeElement, 'mat-table');
    this.renderer.addClass(this.element.nativeElement, 'mdl-data-table');
    this.renderer.addClass(this.element.nativeElement, 'mdl-js-data-table');
  }

  hasValidateFunction(): boolean {
    return LibGeral.estaPreenchido(this.validateRowFunction);
  }
  validateRow(rowData: any): Observable<any> {
    return this.validateRowFunction(rowData);
  }
  deleteRow(rowData: any, index?: number): void {
    if(LibGeral.estaPreenchido(index)) {
      this.deleteRowOutput.emit({...rowData, rowIndex: index});
    } else {
      this.deleteRowOutput.emit(rowData);
    }
  }

  updateRowInSource(row: any, newRow: any): void {
    if (this.source) {
      this.source = this.source.map(item => {
        if (item === row) {
          return newRow;
        }
        return item;
      });
      this.sourceChange.emit(this.source);
    }
  }
  // removeRowFromSource(row: any): void {
  //   if (this.source) {
  //     const rowIndex = this.source.findIndex(item => item === row);
  //     this.source.splice(rowIndex, 1);
  //     this.sourceChange.emit(this.source);
  //   }
  // }
}
