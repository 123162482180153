import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { PrescricaoPedidoItemSituacao } from "src/app/assistencial/shared/models/farmaPedido.model";

@Injectable({ providedIn: "root" })
export class IPRoteamentoService {

    private ultimaRotaAcessada: string;
    private rotaAtual: string;

    constructor(
        private router: Router,
        private location: Location,
        private rotaAtiva: ActivatedRoute
    ) {
        this.rotaAtual = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.ultimaRotaAcessada = this.rotaAtual;
                this.rotaAtual = event.url;
            };
        });

    }

    voltarNaHistoria() {
        this.location.back();
    }

    obterParametrosDaRota() {
        return {
            ...this.rotaAtiva.snapshot.queryParams,
            ...this.rotaAtiva.snapshot.params
        };
    }

    navegarParaUltimaRotaConhecida() {
        if (LibGeral.estaPreenchido(this.ultimaRotaAcessada) && this.router.url != this.ultimaRotaAcessada) {
            this.router.navigateByUrl(this.ultimaRotaAcessada);
            return;
        }

        this.voltarNaHistoria();
    }

    navegarParaRotaDeConferenciaFarma(IdPrescricaoPedidoItem: number) {
        this.router.navigateByUrl(`/farma/conferir/${IdPrescricaoPedidoItem}`);
    }

    navegarParaRotaDeSepararItensDoEstoqueDiarioFarma(IdFarmaciaOrdemServico: number, IdPrescricao: number) {
        this.router.navigateByUrl(`/farma/estoque_diario/separaritems?idFarmaciaOrdemServico=${IdFarmaciaOrdemServico}&idPrescricao=${IdPrescricao}`);
    }

    navegarParaRotaDePedidosFarma() {
      this.router.navigateByUrl(`/farma/pedidos/novopedido?acao=${PrescricaoPedidoItemSituacao.Digitada}`);
  }
}
