import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from "@angular/router";
import environment from "@environment";
import { SimNao } from "@gemed-core/enums/gemed.enum";
import { ISubMenu } from "@gemed-core/interfaces/gemed.interface";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { HabilitarModalConfirmaAcao } from "@gemed-core/store/actions/app.actions";
import { ChangeURLAction } from "@gemed-core/store/actions/route.actions";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { MenuUsuarioStoreService } from "@pep-assistencial-store/store-service/menuUsuarioStoreService.service";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import {
  IPAlertDialogComponent,
  IPConfirmDialogComponent
} from "./core-ui/components/dialog";
import { AuthService } from "./core/auth/auth.service";
import { IPDialogService } from "./core/dialog/ipDialog.service";
import { LibDate } from "./core/libraries/libDate";
import { LogOutAction } from "./core/store/actions/auth.actions";
import { isAuthenticated } from "./core/store/reducers/auth.reducer";
import { GemedState } from "./core/store/state";
import { IPToastService } from "./core/toast/ipToast.service";
import { GemedService } from "./gemed-app.service";

import { loadMessages, locale } from "devextreme/localization";
import * as menssagensPTBR from '../locais/devextreme.ptBR';
import { SwUpdate } from "@angular/service-worker";
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  public title: any;
  public loadingRouteConfig: boolean;
  public opcoesMenu: any[];
  public sideMenuOpen: boolean;
  public ehPrimeiroAcesso = false;
  public usuarioTipo: string;
  public isHomolog: boolean;
  public usuarioContexto$: Observable<any>;
  public subscriptions: Subscription[] = [];
  public isAuthenticated$: Observable<boolean>;
  private activateRoute: ActivatedRoute;
  private gemedService: GemedService;
  private route: Router;
  private tradutorServico: TranslateService;
  private toastService: IPToastService;
  private authService: AuthService;

  constructor(
    private store: Store<GemedState>,
    private menuUsuario: MenuUsuarioStoreService,
    dialogService: IPDialogService,
    route: Router,
    activateRoute: ActivatedRoute,
    gemedService: GemedService,
    authService: AuthService,
    translate: TranslateService,
    toastService: IPToastService
  ) {
    // Passa o viewContainer da raiz do app, para que este seja o container padrão das dialogs abertas.
    dialogService.init(IPAlertDialogComponent, IPConfirmDialogComponent);
    loadMessages(menssagensPTBR);
    //navigator.language
    locale("pt");
    this.gemedService = gemedService;
    this.sideMenuOpen = false;
    this.activateRoute = activateRoute;
    this.route = route;
    this.opcoesMenu = [];
    this.gemedService.titleChange.subscribe((x) => this.handleTitleChange(x));
    this.authService = authService;
    this.tradutorServico = translate;
    this.toastService = toastService;
    this.logout = this.logout.bind(this);
    this.isHomolog = !environment.production;
  }

  async ngOnInit(): Promise<void> {
    this.route.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });

    // Configura Moment.js para pt-Br
    LibDate.configurarMomentJs();

    this.isAuthenticated$ = this.store.select(isAuthenticated);

    this.subscriptions.push(
      this.store
        .select((store) => store.auth.ehPrimeiroAcesso)
        .pipe(take(1))
        .subscribe((primeiroAcesso) => {
          this.ehPrimeiroAcesso = primeiroAcesso;
        })
    );

    // Carregar menu após o usuário estar autenticado
    this.subscriptions.push(
      this.isAuthenticated$.subscribe(async (authenticated) => {
        if (authenticated) {
          // Buscar o tipo de usuário logado
          this.usuarioTipo = await this.authService.getUsuarioTipo();

          // Buscar o menu na api
          await this.carregarOpcoesMenu();

          if (this.ehPrimeiroAcesso) {
            // Redirecionar a primeira tela que o usuário terá acesso
            this.redirecionarPrimeiraTelaAcesso();
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (LibGeral.estaPreenchido(subscription)) {
        subscription.unsubscribe();
      }
    });
  }

  openMenu(): void {
    this.sideMenuOpen ? this.fecharMenuLateral() : this.abrirMenuLateral();
  }

  public logout(event: any): void {
    event.stopPropagation();
    this.store.dispatch(new HabilitarModalConfirmaAcao(new LogOutAction()));
  }

  // Bucar os menus no banco de dados
  async carregarOpcoesMenu() {
    const idUsuario = await LibGeral.getIdUsuario(this.store);

    if (LibGeral.estaPreenchido(idUsuario)) {
      const menusPorModulo = await this.menuUsuario.dispatchCreateActionCarregarMenuAction(
        idUsuario
      );
      this.opcoesMenu = this.formataMenu([...menusPorModulo]);
    }

  }

  private formataMenu(menusPorModulo: any) {
    let novoMenu = [];

    if (LibGeral.estaPreenchido(menusPorModulo)) {
      novoMenu = menusPorModulo.map((menu) => {
        let novoSubMenu = [];

        if (LibGeral.estaPreenchido(menu.Submenu)) {
          novoSubMenu = menu.Submenu.map((submenu) => {
            return {
              ...submenu,
              IconPath: null
            }
          });
        }

        return {
          ...menu,
          Submenu: LibGeral.estaPreenchido(novoSubMenu) ? novoSubMenu : menu.Submenu,
          IconPath: `${environment.domain}assets/images/menu/${menu.IconPath}`,
        };
      });
    }
    return novoMenu;
  }

  handleTitleChange(title: any): void {
    if (LibGeral.estaPreenchido(title) && title !== this.title) {
      this.title = title;
    }
  }

  alterarLinguagemAplicacao(linguagem: string): void {
    this.tradutorServico.use(linguagem);
  }

  fecharMenuLateral(): void {
    this.sideMenuOpen = false;
  }
  abrirMenuLateral(): void {
    this.sideMenuOpen = true;
  }


  /*
  * Redicionar o usuário para a primeira tela visível no menu
  */
  private redirecionarPrimeiraTelaAcesso() {
    const rota = this.obterPrimeiraTelaAcesso();

    if (LibGeral.estaEmBranco(rota)) {
      this.store.dispatch(new ChangeURLAction("aviso", "avisoPermissaoMenu"));
      return;
    }

    this.store.dispatch(new ChangeURLAction(rota));
  }

  private verificarExistenciaMenu(): boolean {
    const existeMenu = LibGeral.estaPreenchido(this.opcoesMenu);
    const existeSubMenu = existeMenu && LibGeral.estaPreenchido(this.opcoesMenu[0].Submenu);
    const existeRota = existeSubMenu && LibGeral.estaPreenchido(this.opcoesMenu[0].Submenu[0].Rota);

    return existeRota;
  }

  private obterPrimeiraTelaAcesso() {
    const existeMenu = this.verificarExistenciaMenu();
    const existeMenuAssistencial = existeMenu && this.opcoesMenu.some((menu) => menu.Descricao === "Assistencial");
    const menuAssistencial = existeMenuAssistencial ? this.opcoesMenu.find((menu) => menu.Descricao === "Assistencial") : null;

    const opcoesDeSubmenusPorModulo = this.opcoesMenu.map(modulo => {
      return modulo.Submenu;
    });
    const subMenusDiponiveis = opcoesDeSubmenusPorModulo.reduce((subMenusDisponiveis, submenus) => subMenusDisponiveis.concat(submenus), []) as ISubMenu[];
    const indexSubMenuEscolhidoComoPaginaInicial = subMenusDiponiveis.findIndex(subMenu => SimNao[subMenu.PaginaInicial] === SimNao.Sim);

    if (indexSubMenuEscolhidoComoPaginaInicial >= 0) {
      return subMenusDiponiveis[indexSubMenuEscolhidoComoPaginaInicial].Rota;
    }

    if (!LibGeral.estaEmBranco(menuAssistencial)) {
      const existeSubmenu = existeMenuAssistencial && !LibGeral.estaEmBranco(menuAssistencial.Submenu);

      const existeRota = existeSubmenu && !LibGeral.estaEmBranco(menuAssistencial.Submenu[0].Rota);

      if (existeRota) {
        return menuAssistencial.Submenu[0].Rota;
      }
    }

    if (existeMenu) {
      return subMenusDiponiveis[0].Rota;
    }
  }
}
