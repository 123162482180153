<dx-chart  palette="Soft"   [dataSource]="valores">

    <dxi-series  *ngFor="let energy of legendas" [valueField]="energy.value"  [name]="energy.name"></dxi-series>

    <dxo-common-series-settings #seriesSettings  argumentField="Data"  [type]="'line'"></dxo-common-series-settings>

    <dxo-margin [bottom]="20"></dxo-margin>

    <dxo-argument-axis  [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
        <dxo-grid [visible]="true"></dxo-grid>
    </dxo-argument-axis>

    <dxo-crosshair [enabled]="true"  color="#949494" [width]="3" dashStyle="dot">
        <dxo-label [visible]="true" backgroundColor="#949494">
            <dxo-font color="#fff" [size]="12"> </dxo-font>
        </dxo-label>
    </dxo-crosshair>

    <dxo-legend  verticalAlignment="bottom" horizontalAlignment="center"  itemTextPosition="bottom"></dxo-legend>

    <dxo-title text="{{titulo}}"></dxo-title>

    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>

</dx-chart>