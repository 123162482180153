import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, OnDestroy } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { Subscription } from 'rxjs';
import { CANCEL_BUTTON_DIALOG, MENSAGEM_DIALOG, OK_BUTTON_DIALOG, TITULO_DIALOG } from '../core/dialog/ipDialog.service';
import { MaterialModule } from '../material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { IPButtonModule } from './components/button/ip-button.module';
import { IPCardModule } from './components/card';
import { IPCEPInputTextComponent } from './components/cep-input';
import { IPChartModule } from './components/chart';
import { IPCheckBoxModule } from './components/checkbox';
import { IPCollapseModule } from './components/collapse';
import { ConfirmSimpleDialogComponent, IPAlertDialogComponent, IPConfirmDialogComponent, IPDialogContainerComponent, IPDialogModule } from './components/dialog';
import { FeedBackComponent } from './components/feedback/feedback-button.component';
import { GemedSelectComponent } from './components/gemed-select/gemed-select.component';
import { IPInputModule } from './components/input';
import { IPAutoCompleteModule } from './components/ip-autocomplete/ip.autocomplete.module';
import { IPCarouselComponent } from './components/ip-carousel/ip-carousel.component';
import { IpPhotoPreviewComponent } from './components/ip-photo-preview/ip-photo-preview.component';
import { IpPhotoComponent } from './components/ip-photo/ip-photo.component';
import { IpPreviewFileComponent } from './components/ip-preview-file/ip-preview-file.component';
import { IpQrcodeComponent } from './components/ip-qrcode/ip-qrcode.component';
import { IpTeleconsultaComponent } from './components/ip-teleconsulta/ip-teleconsulta.component';
import { IpUploadFileComponent } from './components/ip-upload-file/ip-upload-file.component';
import { IPLayoutModule } from './components/layout';
import { IPListModule } from './components/list';
import { IPMenuModule } from './components/menu';
import { IPNotificationModule } from './components/notification';
import { IPPaginationModule } from './components/pagination';
import { IPProgressModule } from './components/progress';
import { IPRadioModule } from './components/radio';
import { IPSelectModule } from './components/select';
import { IPSideNavLayoutModule } from './components/side-nav';
import { IPSliderModule } from './components/slider';
import { IPTabsModule } from './components/tab';
import { IPTableModule } from './components/table/ip-table.module';
import { IpToastComponent } from './components/toast/ip-toast-default.component';
import { IPToogleModule } from './components/toogle';
import { IPTooltipModule } from './components/tooltip';
import { IPTreeelistModule } from './components/treelist';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { HighlightPubSubModule } from './directives/highlightPubSub/highlight.module';
import { SanatizeHtmlPipeModule } from './directives/sanatize-html.module';
import { SwiperModule } from 'swiper/angular';
import { AvisoSimplesModule } from './components/aviso-simples/aviso-simples.modulets';

const modulosCore = [
  IPAutoCompleteModule,
  IPCardModule,
  IPButtonModule,
  IPCheckBoxModule,
  IPCollapseModule,
  IPDialogModule,
  IPInputModule,
  IPListModule,
  IPTabsModule,
  IPPaginationModule,
  IPProgressModule,
  IPSelectModule,
  IPSideNavLayoutModule,
  IPLayoutModule,
  IPTooltipModule,
  IPSliderModule,
  IPMenuModule,
  IPToogleModule,
  IPTableModule,
  IPRadioModule,
  IPNotificationModule,
  IPChartModule,
  IPTreeelistModule,
  DragDropModule,
  AvisoSimplesModule,
];


@NgModule({
  providers: [
    { provide: OK_BUTTON_DIALOG, useValue: 'ok' },
    { provide: TITULO_DIALOG, useValue: null },
    { provide: CANCEL_BUTTON_DIALOG, useValue: 'Cancelar' },
    { provide: MENSAGEM_DIALOG, useValue: null }
  ],
  declarations: [
    IpToastComponent,
    HighlightTextDirective,
    FeedBackComponent,
    IPCEPInputTextComponent,
    IPCarouselComponent,
    IpUploadFileComponent,
    IpPreviewFileComponent,
    IpPhotoComponent,
    GemedSelectComponent,
    IpPhotoPreviewComponent,
    IpQrcodeComponent,
    IpTeleconsultaComponent
  ],
  imports: [
    PipesModule,
    SanatizeHtmlPipeModule,
    HighlightPubSubModule,
    MaterialModule,
    TranslateModule.forChild(),
    CommonModule,
    FlexModule,
    SwiperModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    ZXingScannerModule,
    ...modulosCore
  ],
  exports: [
    ...modulosCore,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IPCEPInputTextComponent,
    SanatizeHtmlPipeModule,
    HighlightPubSubModule,
    IpToastComponent,
    IPCarouselComponent,
    IpUploadFileComponent,
    IpPreviewFileComponent,
    IpTeleconsultaComponent,
    IpPhotoComponent,
    GemedSelectComponent,
    IpQrcodeComponent
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class IPCoreModule implements OnDestroy {
  private tradutorSub: Subscription;
  constructor(tradutor: TranslateService) {
    // escolherLinguagem('pt');
    // this.cancelarTratudorSub();
    // this.tradutorSub = tradutor.onLangChange.subscribe(({ lang }) => {
    //   escolherLinguagem(lang);
    // });
  }
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnDestroy(): void {
    this.cancelarTratudorSub();
  }
  cancelarTratudorSub(): void {
    if (this.tradutorSub) {
      this.tradutorSub.unsubscribe();
    }
  }
}
