import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPSelectComponent } from './ip-select.component';
import { IPSelectItemComponent } from './ip-select-item.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [IPSelectComponent, IPSelectItemComponent],
  exports: [IPSelectComponent, IPSelectItemComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPSelectModule {
}
