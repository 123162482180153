export class ValidacaoModel implements IValidation {

  /**
   * Retorna objeto indicando validação OK.
   *
   * @static
   * @type {ValidacaoModel}
   */
  static Valido: ValidacaoModel = new ValidacaoModel(true);

  public IsValido: boolean;
  public Mensagem: string;
  public Error: Error;

  constructor(isValido: boolean, error?: string | Error) {
    this.IsValido = isValido;

    if (typeof error === 'string') {
      this.Mensagem = error;
    } else {
      this.Error = error;
    }
  }

  appendMessage(mensagem: string): void {
    if (this.Mensagem && this.Mensagem.length > 0) {
      this.Mensagem += ' ';
    } else {
      this.Mensagem = '';
    }

    this.Mensagem += mensagem;
  }
  setMessage(mensagem: string): void {
    this.Mensagem = mensagem;
  }
}
