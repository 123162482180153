import {
  IProfissional,
  IPortador,
  IClinica,
  IUnidadeEstoque,
  IUsuario,
} from "@gemed-core/interfaces/gemed.interface";

export class Profissional implements IProfissional {
  get Id(): number {
    return this.IdProfissional;
  }

  set Id(value) {
    this.IdProfissional = value;
  }

  IdProfissional: number;
  Nome: string;
  Conselho: string;
  RegistroConselho: string;
  UfConselho: string;
  IdEspecialidade?: number;
  Especialidade: string;
  CBOS: string;
  CBOS_V2_02_03: string;
  CBOS_V3: string;
  Categoria: IProfissionalCategoria;
  Tratamento: string;
  Registro: string;
  CPF: string;
  PercentualRepasse: number;
  Portador: IPortador;
  Usuario: IUsuario;
  Clinica: IClinica;
  UnidadeEstoque: IUnidadeEstoque;
  Abreviado: string;
  CodIntegracao: string;
  InformacaoAdicionalAssinatura: string;
  NomeTratamento: string;
  PrimeiroNome: string;
  PrimeiroNomeTratamento: string;
  NomeEConselho: string;
  RegistroCompleto: string;
  IdTextBoxPesquisa: number;
  DescricaoTextBoxPesquisa = "";
  ToolTipTextBoxPesquisa = "";
}
