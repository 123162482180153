// eslint-disable-next-line max-len
import {
  Directive, ElementRef, ViewContainerRef, ComponentFactoryResolver, Renderer2, AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { CellEditButtonsComponent } from './ip-cell-edit-buttons.component';
import { LibComponent } from '../../../core/libraries/libComponent';

@Directive({
  selector: '[ip-cell-edit]',
  // eslint-disable-next-line
  host: {
    '[class.mdl-data-table__cell--non-numeric]': '!_number',
    '[class.wrap-content]': '_wrap'
  }
})
export class IPCellEditDirective implements AfterViewInit {
  private componentResolver: ComponentFactoryResolver;
  private element: ElementRef;
  private renderer: Renderer2;
  private viewContainerRef: ViewContainerRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver) {
    this.element = element;
    this.renderer = renderer;
    this.viewContainerRef = viewContainerRef;
    this.componentResolver = componentResolver;
  }
  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
    LibComponent.CreateComponentWithPromise(CellEditButtonsComponent, this.componentResolver, this.viewContainerRef, null)
      .then((componentRef: any) => {
        const nativeElement = this.element.nativeElement;
        const componentElement = componentRef.location.nativeElement;
        // Equivalente: element.appendChild(componentElement);
        this.renderer.appendChild(nativeElement, componentElement);
      })
      .then(() => {
        this.changeDetectorRef.reattach();
      })
      .catch(err => {
        console.error('IPCellEditDirective', err);
        this.changeDetectorRef.reattach();
      });
  }
}
