import { Component } from '@angular/core';

import { IPButtonComponent } from './ip-button.component';

@Component({
  selector: 'ip-button-fab',
  template: `
    <button mat-fab [color]="color" [disabled]="disabled"><ng-content></ng-content></button>
  `
})
export class IPButtonFabComponent extends IPButtonComponent { }
