import { ActionReducer, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { IAssistencialState } from "@pep-assistencial-store/state/assistencial.state";
// LocalStorage Redux App
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      "app",
      "router",
      {
        "assistencial": [{ "prontuario": ["questionarios", "perguntas", "respostas"] }]
      }
    ],
    rehydrate: true
  })(
    reducer
  );
}

export function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{
      "auth": [
        'ehPrimeiroAcesso',
        'Usuario',
        'IsAuthenticated',
        'idClinica',
        'sistema',
        'contextos',
        'cliente',
        'filial',
        'contexto',
        'error',
        'manterLogado',
        'razaoClinica'
      ]
    },
      "assistencial",
      "centralmedico",
      "farmaFiltro"
    ],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  sessionStorageSyncReducer,
];

export const ex = 1;
