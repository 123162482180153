import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPTabs } from './ip-tabs.component';
import { IPTab } from './ip-tab.component';
import { MaterialModule } from '../../../material/material.module';


@NgModule({
  imports: [MaterialModule],
  declarations: [IPTabs, IPTab],
  exports: [IPTabs, IPTab],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPTabsModule {
}
