import { CicloMesConfigDTO } from "./cicloMesConfigDTO.mode";
import { CicloSemanalConfigDTO } from "./cicloSemanalConfigDTO.model";
import { CicloConfigDTO } from "./cicloConfigDTO.model";
import { TipoCiclo } from "@gemed-core/enums/gemed.enum";

export class ProgramacaoConfiguracao implements CicloConfigDTO, CicloMesConfigDTO, CicloSemanalConfigDTO{
    InicioCiclo: number = 1;
    FimCiclo: number = 1;
    Dias: string = null;
    Quantidade: number = null;
    Intervalo: number = null;
    Semanas: string = null;
}