<section fxLayout="column" #IDCUSTOMJ fxFlex="1 1 100%">
  <ip-slider
    style="flex: 0 0 48px;"
    [model]="model"
    [discrete]="true"
    [max]="max"
    [naoInformado]="naoInformado"
    [min]="min"
    [step]="step"
    (modelChange)="onChange($event)"
  ></ip-slider>

  <div flex layout="row" layout-fill layout-align="space-between">
    <span *ngIf="hintMin" class="ip-slider-hint">{{ hintMin }}</span>
    <div flex class="slider-roll-box">
      <div flex layout="column" class="slider-roll-box-options">
        <div flex layout="row" layout-fill-width layout-align="center center">
          {{ option }}
        </div>
      </div>
    </div>
    <span *ngIf="hintMax" class="ip-slider-hint">{{ hintMax }}</span>
  </div>
</section>
