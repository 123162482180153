<mat-card
  *ngIf="isVisible"
  cdkDrag
  cdkDragBoundary="body"
  class="ip-modal-card"
  (cdkDragEnded)="onDragDropped()"
>
  <mat-card-title>
    <div cdkDragHandle style="cursor: move; text-align: right;">
      <i
        class="material-icons"
        mat-button
        style="color: red; cursor: pointer; align-self: center;"
        (click)="Fechar()"
        >clear</i
      >
    </div>
  </mat-card-title>
  <mat-card-content #conteudo> </mat-card-content>
</mat-card>
