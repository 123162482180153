import { Action } from '@ngrx/store';
import { type } from '../../libraries';


interface IRequest {
  identificador: string;
  response?: Response;
}


export const ActionTypes = {
  INICIAR_REQUEST: type('[request] Iniciar request'),
  CONCLUIR_REQUEST: type('[request] Concluir request')
};

export class IniciarRequestAction implements Action {
  public type: string = ActionTypes.INICIAR_REQUEST;
  constructor(public payload: string) {}
}

export class FinalizarRequestAction implements Action {
  public type: string = ActionTypes.CONCLUIR_REQUEST;
  constructor(public payload: IRequest) {}
}

export type Actions
  =
  IniciarRequestAction
  | FinalizarRequestAction;
