import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPCollapseDirective } from './ip-collapse.directive';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [MaterialModule],
  declarations: [IPCollapseDirective],
  exports: [IPCollapseDirective],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPCollapseModule {
}
