<div
  flex
  layout="column"
  *ngIf="showTeleconsulta"
  [style.height.px]="sizeTeleconsulta"
  [style.width.px]="sizeTeleconsulta"
>
  <ip-toolbar
    [background-color]="'#099809'"
    header
    class="tc-white bg-blue-1 no-padding"
    flex
    layout-align="center center"
    layout="row"
    layout-xs="column"
  >
    <div
      flex
      flex-gt-xs="20"
      style="align-items: center; justify-content: center;"
    >
      <span class="fs-title">
        Teleconsulta
      </span>
    </div>

    <div flex></div>

    <div
      style="
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
      "
    >
      <i class="material-icons" (click)="fechar()">close</i>
    </div>
  </ip-toolbar>
  <iframe  allowusermedia  allow="camera *;microphone *" class="iframe-cdkDrag" #iframe [src]="roomUrl | safe"></iframe>

  <div class="div-buttom">
    <ip-button-default
      (onClick)="alterarTamanhoTela(100)"
      [disabled]="false"
      [formatoRedondo]="true"
      [tipoDeBotao]="'fab'"
      [materialIconName]="'zoom_in'"
    ></ip-button-default>

    <ip-button-default
      (onClick)="alterarTamanhoTela(-100)"
      [disabled]="false"
      [formatoRedondo]="true"
      [tipoDeBotao]="'fab'"
      [materialIconName]="'zoom_out'"
    ></ip-button-default>

    <ip-button-default
      (onClick)="fechar()"
      [disabled]="false"
      [formatoRedondo]="true"
      [tipoDeBotao]="'fab'"
      [materialIconName]="'close'"
    ></ip-button-default>
  </div>
</div>
