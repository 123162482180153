import { Injectable } from "@angular/core";
import { ILogAdaptatividade, ILogDetalheDto, IResult } from "@gemed-core/interfaces/gemed.interface";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { UnidadeEstoque } from "@gemed-core/models/unidadeEstoque.model";
import { take } from "rxjs/operators";
import { RestApiService } from "../../../core/http/restApi.service";
import { RestAPIType } from "../../../core/models";

@Injectable()
export class LogService {
  private controles = {
    Log: "Log",
    LogsPrescricao: "LogsPrescricao"
  };
  private servicesRoutes = {
    InserirLogAdp: "InserirLogAdp",
    ObterLogPrescricao: "ObterLogPrescricao",
  };

  constructor(private restApiService: RestApiService) { }
  /**
  * @method RegistrarLogAdp Registrar log adp.
  * @param { idCliente } idCliente id cliente do pedido da farma
  * @return retorna uma IResult com a lsta de unidade estoque.
  * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
  */
  RegistrarLogAdp(logAdaptatividade: ILogAdaptatividade): Promise<void> {
    return this.restApiService
      .Post(
        logAdaptatividade,
        {
          api: RestAPIType.Seguranca,
          controller: this.controles.Log,
          action: this.servicesRoutes.InserirLogAdp,
        },
      )
      .pipe(take(1))
      .toPromise()
      .then(
        async (response) => Promise.resolve()
      );
  }

  async ObterLogPrescricao(idPrescricao: number): Promise<ILogDetalheDto[]> {
    return this.restApiService
      .GetById(idPrescricao, {
        api: RestAPIType.Resources,
        controller: this.controles.LogsPrescricao,
        action: this.servicesRoutes.ObterLogPrescricao,
      })
      .pipe(take(1))
      .toPromise()
      .then(
        async (retornoApi) =>
          await LibGeral.ParseAndResolve(JSON.stringify(retornoApi.Data))
      );
  }

}
