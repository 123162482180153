import { Component, Input} from '@angular/core';

import './ip-button.component.less';

@Component({
  selector: 'ip-button',
  template: `
    <ip-button-flat mat-button [disabled]="disabled">
      <ng-content></ng-content>
    </ip-button-flat>`,
  styles: [`
    :host[mat-icon-button] {
      padding: 8px;
    }
  `]
})
export class IPButtonComponent {
  @Input() sizeBorder = 5;
  @Input() color: string;
  @Input() disabled = false;
}
