import { Directive, ElementRef, OnDestroy, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { IpParametroChave } from "@gemed-core/models/ipParametroChave.model";
import { LibGeral } from "@gemed-core/libraries/libGeral";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[parametrosManagerAutoriza]'
})
export class ParametrosManagerDirective implements OnDestroy, OnInit {

    @Input() Parametro: string;
    @Input() ValorParaComparacao: any;

    private storeSubscription: Subscription;

    constructor(private store: Store<any>, private elementRef: ElementRef) {
    }
    ngOnInit(): void {
        if (LibGeral.estaPreenchido(this.Parametro) && LibGeral.estaPreenchido(this.ValorParaComparacao)) {
            this.storeSubscription = this.store
                .select(state => state["ParametrosManager"].Parametros)
                .subscribe((parametrosManager: {}) => {
                    if (parametrosManager[this.Parametro]) {
                        this.elementRef.nativeElement.disabled = !(parametrosManager[this.Parametro].Valor === this.ValorParaComparacao);
                    }
                });
        }

    }

    ngOnDestroy(): void {
        if (LibGeral.estaPreenchido(this.storeSubscription)) {
            this.storeSubscription.unsubscribe();
        }
    }
}