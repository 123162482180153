import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { of, Subscription } from 'rxjs';

@Component({
  selector: 'ip-skeleton-loading',
  templateUrl: './skeleton-loading.component.html',
  styleUrls: ['./skeleton-loading.component.scss']

})
export class SkeletonLoadingComponent implements AfterViewInit {

  @ViewChild('container') containerBody: ElementRef;
  @ViewChild('item') itemBody: ElementRef;
  public _counter: number = 1;
  public onContainerBodyChildrenChange: Subscription;

  @Input() LoadingType: string = "SkeletonList";

  constructor(
    private _renderer: Renderer2,
  ) { }

  setListSize = async () => {
    const counter = Math.floor((this.containerBody.nativeElement.clientHeight /
      this.itemBody.nativeElement.clientHeight));
    this._counter = (isNaN(counter) || counter < 1 ? 1 : counter);
  }

  addClassToChildrenElements = () => {
    const children = this.containerBody.nativeElement.children;
    return of(children).subscribe(childElements => {
      setTimeout(() => {
        Array.from(childElements).map(itemElement => this._renderer.addClass(itemElement, "loading"))
      });
    });
  }

  ngAfterViewInit() {

    setTimeout(async () => {
      await this.setListSize();
      this.onContainerBodyChildrenChange = this.addClassToChildrenElements();
    });
  }

  ngDestroy() {
    this.onContainerBodyChildrenChange.unsubscribe();
  }

}
