export class IPError {
  public Cliente: any;
  public DataHora: Date;
  public Menssagem: string;
  public StackTrace: any;
  constructor (cliente: any, dataHora: Date, menssagem: string, stackTrace?: any) {
    this.Cliente = cliente;
    this.DataHora = dataHora;
    this.Menssagem = menssagem;
    this.StackTrace = stackTrace;
  }
}
