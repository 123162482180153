import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ip-notification',
  templateUrl: './ip-notification.component.html',
  styleUrls : ['./ip-notification.component.scss']
})

export class IPNotificationComponent {
  @Input() aviso = '';
  @Input() color = 'default';
  @Input() display = ''
}
