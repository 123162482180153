import {
   Directive, ComponentFactoryResolver, ElementRef, Host, Input, Optional, Renderer2, Type, ViewContainerRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { IPCellDirective } from './ip-cell.directive';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';
import { IPAutoCompleteComponent } from '../ip-autocomplete/ip-autocomplete.component';

/**
 * Diretiva para edição inline do ip-table com auto-complete.
 * @export
 * @abstract
 * @class IPCellInlineEditAutoCompleteDirective
 * @extends {IPCellInlineEditDirective<number>}
 */
@Directive({
  selector: '[ip-cell-inline-edit-autocomplete]'
})
export class IPCellInlineEditAutoCompleteDirective extends IPCellInlineEditDirective<any> {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('key') idProp: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('display') displayProp: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('search') searchFunction: Function;
  @Input() placeholder: string;

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);
    this.obterValoresComplexos = () => this.obterValorDescritivo();
  }

  /**
   * Método executado para obter as sugestões do auto-complete.
   * Este método recebe o termo de pesquisa informado e deve retornar um Observable.
   *
   * @abstract
   * @param {string} termo (description)
   * @returns {Observable<any>} (description)
   */
  public searchValues(termo: string, model?: any): Observable<any> {
    return this.searchFunction(termo, model);
  }

  public getComponent(): Type<any> {
    return IPAutoCompleteComponent;
  }

  public setComponentProps(editorComponent: IPAutoCompleteComponent): void {
    // Seta o valor atual do componente com o valor da celula
    editorComponent.model = this.cell.getCurrentValue();
    editorComponent.placeholder = this.placeholder;
    editorComponent.search = (term: string) => {
       const rowModel = this.cell.row ? this.cell.row.model : null;
       return this.searchValues(term, rowModel);
    };

    if (this.idProp) {
      editorComponent.idProp = this.idProp;
    }

    if (this.displayProp) {
      editorComponent.displayProp = this.displayProp;
    }
  }
  obterValorDescritivo(): any {
    const propriedade = (<any>this.cell.row.model)[this.cell.getModelName()];
    if (propriedade) {
      return propriedade[this.displayProp];
    } else {
      return null;
    }
  }
}
