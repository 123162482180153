import { IPRequest, RequestStateFactory } from '../state/request.state';
import { HttpService } from '../../http/http.service';
import { ActionTypes } from '../actions/request.actions';
import { HttpResponse } from 'src/app/core/models';

export function RequestReducer(state = RequestStateFactory(), action: any) {
  const updateOrCreate = (x: Map<string, IPRequest>, key, value: IPRequest) => {
    const novoState = new Map(x);
    novoState.set(key, value);
    return novoState;
  };
  switch (action.type) {
    case ActionTypes.INICIAR_REQUEST:
      return updateOrCreate(state, action.payload, new IPRequest());
    case ActionTypes.CONCLUIR_REQUEST:
      const payload = <{ identificador: string, response: HttpResponse<any> }> action.payload;
      const httpResponse = payload.response;
      const status = HttpService.isStatusSucesso(httpResponse.Status) ? 'success' : 'error';
      const request = state.get(action.payload.identificador);
      return updateOrCreate(state, action.payload.identificador, <any>{
        ...request,
        status: status
      });
    default: return state;
  }
}
