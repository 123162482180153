import { Action } from '@ngrx/store';
import { type } from '../../libraries';
import { IAppMensagem, IAppErro } from 'ip_types_ts/common-typings';

export const ActionTypes = {
  APRESENTAR_MENSAGEM: type('[app] Apresentar Mensagem'),
  APRESENTAR_NOTIFICACAO: type('[app] Apresentar notificao'),
  OFF_LINE: type('[app] Offline'),
  ON_LINE: type('[app] Online'),
  REGISTRAR_ERRO: type('[app] Registrar erro'),
  INCONFORMIDADE_APP: type('[app] Inconformidade encontrada'),
  MODAL_CONFIRMA_ACAO: type('[app] Habilitar modal de ação'),
};

export class ApresentarAvisoAction implements Action {
  public type: string = ActionTypes.APRESENTAR_MENSAGEM;
  constructor(public payload: IAppMensagem) {}
}

export class ApresentarNotificacaoAction implements Action {
  public type: string = ActionTypes.APRESENTAR_NOTIFICACAO;
  constructor(public payload: IAppMensagem) {}
}

export class RegistrarErroAction implements Action {
  public type: string = ActionTypes.REGISTRAR_ERRO;
  constructor(public payload: IAppErro) {}
}

export class InconformidadeAction implements Action {
  public type: string = ActionTypes.INCONFORMIDADE_APP;
  constructor(public payload: { mensagem: string, identificador: string }) {}
}

export class OnlineAction implements Action {
  public type: string = ActionTypes.ON_LINE;
  constructor() {}
}

export class OfflineAction implements Action {
  public type: string = ActionTypes.OFF_LINE;
  constructor() {}
}

export class HabilitarModalConfirmaAcao implements Action{
  public type: string = ActionTypes.MODAL_CONFIRMA_ACAO;
  constructor(public payload: Action) {};
}

export type Actions
  =
  ApresentarAvisoAction
  | OfflineAction
  | OnlineAction
  | ApresentarNotificacaoAction
  | RegistrarErroAction
  | InconformidadeAction 
  | HabilitarModalConfirmaAcao;

/*
@Injectable()
export class AppActionCreator extends Action implements IAppActionCreator {

  public ngRedux: NgRedux<GemedState>;

  constructor(ngRedux: NgRedux<GemedState>) {
    super();
    this.ngRedux = ngRedux;
  }

  apresentarAviso(mensagem: string, titulo?: string): void {
    this.ngRedux.dispatch({
      type: APRESENTAR_MENSAGEM,
      payload: <IAppMensagem>{
        texto: mensagem,
        titulo,
        tipo: ToastTipo.Aviso
      }
    });
  }

  apresentarNotificacao(mensagem: string, titulo?: string): void {
    this.ngRedux.dispatch({
      type: APRESENTAR_MENSAGEM,
      payload: <IAppMensagem>{
        texto: mensagem,
        titulo,
        tipo: ToastTipo.Info
      }
    });
  }

  registrarErro(error: any, mensagem?: string): void {
    this.ngRedux.dispatch({
      type: REGISTRAR_ERRO,
      payload: <IAppErro>{
        data: new Date(),
        error,
        mensagem
      }
    });
  }
}
*/

