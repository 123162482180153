import { LibString } from '../libraries/libString';
import { PagingInfo } from './pagingInfo.model';
import { RestApiRequestConfig } from './restApiRequestConfig.model';
import { HttpParams, HttpHeaders } from '@angular/common/http';

type RequestMethod = "POST" | "GET";

/**
 * Classe para configuração de chamadas HTTP.
 *
 * @export
 * @class HttpRequestConfig
 */
export class HttpRequestConfig {

  identificador?: string;
  params: HttpParams;
  body: string;
  url: string;
  method: RequestMethod;
  headers: HttpHeaders;
  /**
   * Cria objeto de configuração para requisição GET.
   *
   * @static
   * @param {string} url URL da requisição.
   * @param {{ [name: string]: string }} [params] Parâmetros para serem adicionados na URL.
   * @returns {HttpRequestConfig} Retorna o próprio objeto para permitir encadeamento de métodos.
   */
  static createGetRequest(url: string, params?: HttpParams, requestConfig?: RestApiRequestConfig): HttpRequestConfig {
    const config = new HttpRequestConfig("GET", url);
    config.params = params;

    if (requestConfig) {
      config.definirIdentificadorRequest(requestConfig);
    }

    return config;
  }

  /**
   * Cria objeto de configuração para requisição POST.
   *
   * @static
   * @param {string} url URL da requisição.
   * @param {*} data Dado a ser enviado no corpo da requisição.
   * @param {HttpParams} [params] Parâmetros para serem adicionados na URL.
   * @returns {HttpRequestConfig} Retorna o próprio objeto para permitir encadeamento de métodos.
   */
  static createPostRequest(url: string, data: any, params?: HttpParams, requestConfig?: RestApiRequestConfig): HttpRequestConfig {
    const config = new HttpRequestConfig("POST", url);
    config.body = data;
    config.params = params;
    config.headers = requestConfig.headers;

    if (requestConfig) {
      config.configurarContentType(requestConfig);
      config.definirIdentificadorRequest(requestConfig);
    }

    return config;
  }

  constructor(method: RequestMethod, url: string, identificador?: string) {
    this.method = method;
    this.url = url;
    this.identificador = identificador;
  }

  obeterMethod(): string {
    return this.method
  }

  private configurarContentType(requestConfig: RestApiRequestConfig): void {
    switch (requestConfig.ContentType) {
      case 'json':
        this.addHeader('Content-Type', 'application/json;charset=UTF-8');
        break;
    }
  }
  private definirIdentificadorRequest(requestConfig: RestApiRequestConfig): void {
    this.identificador = requestConfig.identificador;
  }

  /**
   * Adiciona parâmetro na lista de parâmetros da requisição.
   *
   * @param {string} name (Nome do parâmetro a ser adicionado.)
   * @param {*} value (Valor do parâmetro a ser adicionado.)
   * @returns {HttpRequestConfig} Retorna o próprio objeto para permitir encadeamento de métodos.
   */
  addParameter(name: string, value: any): HttpRequestConfig {
    if (!this.params) {
      this.params = new HttpParams();
    }
    this.params = this.params.append(name, value);
    return this;
  }

  /**
   * Adiciona os headers necessários para fazer requisição com paginação.
   *
   * @param {interprocess.services.body.PagingInfo} pagingInfo (Informações sobre a paginação desejada.)
   * @returns {HttpRequestConfig} (Retorna o próprio objeto para permitir encadeamento de métodos.)
   */
  configurePaging(pagingInfo: PagingInfo): HttpRequestConfig {
    this.addHeader(PagingInfo.PageSizeHeaderName, pagingInfo.PageSize.toString());
    this.addHeader(PagingInfo.PageNumberHeaderName, pagingInfo.PageNumber.toString());
    return this;
  }

  /**
   * Adiciona chave e valor no cabeçalho da requisição.
   *
   * @param {string} name (Nome da chave a ser adicionada.)
   * @param {string} value (Valor a ser adicionado para a chave.)
   * @returns {HttpRequestConfig} (Retorna o próprio objeto para permitir encadeamento de métodos.)
   */
  addHeader(name: string, value: string): HttpRequestConfig {
    if (!this.headers) {
      this.headers = new HttpHeaders();
    }
    this.headers = this.headers.append(name, value);
    return this;
  }
  /**
   * Remove chave do cabeçalho da requisição.
   *
   * @param {string} name (Nome da chamge a ser removido)
   * @returns {HttpRequestConfig} (Retorna o próprio objeto para permitir encadeamento de métodos.)
   */
  removeHeader(name: string): HttpRequestConfig {
    return this.addHeader(name, undefined);
  }
  possuiIdentificador(): boolean {
    return !LibString.isNullOrEmpty(this.identificador);
  }
  getIdentificador(): string {
    return this.identificador;
  }
}
