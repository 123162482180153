import { LibGeral } from '../libraries/libGeral';
import { LibCollections } from '../libraries/libCollections';
import { PagingInfo } from './pagingInfo.model';

/**
 * Classe que representa os cabeçalhos de uma chamada HTTP.
 *
 * @export
 * @class HttpHeaders
 */
export class HttpHeaders {
  private _headers: Map<string, string[]>;
  private _paggingInfo: PagingInfo = null;

  constructor(headers?: Headers) {
     this._headers = new Map<string, string[]>();
    if (LibGeral.estaPreenchido(headers)) {
      headers.forEach((value, key, parent): void => {
        this.append(key, value);
      });
    }
    return this;
  }

  append(name: string, value: string): void {
    const mapName = this._headers.get(name);
    const list = LibGeral.estaPreenchido(mapName) ? mapName : [];
    list.push(value);
    this._headers.set(name.toLowerCase(), list);
  }

  delete(name: string): void {
    this._headers.delete(name.toLowerCase());
  }

  get(header: string): string {
    return LibCollections.first(this._headers.get(header.toLowerCase()));
  }

  has(header: string): boolean {
    return this._headers.has(header.toLowerCase());
  }

  forEach(fn: (values: string[], name: string, headers: Map<string, string[]>) => void): void {
    this._headers.forEach(fn);
  }
  public get hasPagingInfo(): boolean { return this.has(PagingInfo.PageSizeHeaderName); }
  public get PagingInfo(): PagingInfo {
    if (this._paggingInfo !== null) {
      return this._paggingInfo;
    }
    if (this.hasPagingInfo) {
      this._paggingInfo = new PagingInfo(
        +this.get(PagingInfo.PageSizeHeaderName),
        +this.get(PagingInfo.PageNumberHeaderName),
        +this.get(PagingInfo.TotalRecordCountHeaderName)
      );
    }

    return this._paggingInfo;
  }
}
