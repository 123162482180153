<section flex layout="column">

  <ip-toolbar [background-color]="'#345ca5'" header class="tc-white bg-blue-1 no-padding" flex layout-align="center center" layout="row" layout-xs='column' style="height:40px">
    <div flex flex-gt-xs="20" style="align-items: center; justify-content: center;">
      <span class="fs-title">
        Preview File 
      </span>
    </div>  
    
    <div flex></div>
    
    <div style="align-items: center; justify-content: center; display: flex; cursor:pointer">
      <mat-icon style="color: white;cursor: pointer;" (click)="abrirEmUmaNovaJanela()">open_in_new</mat-icon>
      <i class="material-icons" (click)="fechar()">close</i>
    </div>

  </ip-toolbar>

  <div class="container" id="modalPreview" #container>
  </div>
  
</section>