import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ip-photo-preview',
  templateUrl: './ip-photo-preview.component.html',
  styleUrls: ['./ip-photo-preview.component.scss']
})
export class IpPhotoPreviewComponent implements OnInit {

  public width: number;
  public heigth: number;

  @ViewChild("video")
  public video: ElementRef;

  @ViewChild("canvas")
  public canvas: ElementRef;

  public constraints: any;

  constructor(public dialogRef: MatDialogRef<IpPhotoPreviewComponent>) {
    this.width = 640;
    this.heigth = 480;
  }

  async ngOnInit() {
    await this.solicitaPermissao();
    await this.configurarCamera();
    this.mostrarCamera();
  }

  public mostrarCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(this.constraints).then(stream => {
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();
      })
    }
  }

  public capturar() {
    var context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, this.width, this.heigth);
    this.fechar(this.canvas.nativeElement.toDataURL("image/png"));
  }

  public fechar(photo?) {
    this.desligarCamera();
    this.dialogRef.close(photo);
  }

  private desligarCamera() {
    this.video.nativeElement?.srcObject?.getTracks()?.forEach(track => track?.stop());
  }

  private async solicitaPermissao() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(t => t.stop());
    } catch (e) {

    }
  }

  public async configurarCamera(): Promise<void> {
    this.constraints = {
      video: {
        facingMode: {
          exact: "environment"
        }
      }
    };
  }
}
