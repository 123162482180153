import { LibDate, DataFormato } from "../../../../core/libraries/libDate";
import { LibGeral } from "../../../../core/libraries/libGeral";
import { LibString } from "../../../../core/libraries/libString";
import { LibNumeric } from "../../../../core/libraries/libNumeric";

export class DataPEP implements IDataPEP {
  /**
   * Data sendo apresentada no componente.
   *
   * @type {Date}
   */
  public Data: Date = null;
  /**
   * Formato que a data será apresentada: D (Data), M (Mês/Ano), A (Ano).
   *
   * @type {string}
   */
  public Formato: string;

  // ira tentar fazer cast de argument to DataPEP
  // caso nao consiga ira gerar erro
  static from(object: any): DataPEP {
    let castObject = null;
    if (object instanceof DataPEP) {
      // castObject = LibPEP.Clone(DataPEP, object);
    } else {
      castObject = new DataPEP(new Date());
      const data = object.Data;
      if (data instanceof Date) {
        castObject.Data = data;
      } else {
        castObject.Data = LibDate.ToDate(data, 'YYYY-MM-DD');
      }
      castObject.TemDataInformada = object.TemDataInformada;
      // todo mudar serviço para trazer formato data PEP
      // castObject.Formato = object.Formato;
    }
    return castObject;
  }

  /**
   * Retorna string referente a data no formato estipulado.
   *
   * @readonly
   * @type {string}
   */
  public Texto
  // get Texto(): string { return this.formatarData(); }

  // set Texto(texto) { setTimeout(() => this.Texto = texto)}
  /**
   * Retorna TRUE caso o formato estipulado não seja data (D).
   *
   * @readonly
   * @type {boolean}
   */
  get Desconhecida(): boolean { return this.Formato !== 'D'; }
  /**
   * Retorna TRUE caso a data esteja em branco.
   *
   * @readonly
   * @type {boolean}
   */
  get IsEmpty(): boolean { 
    return LibGeral.estaEmBranco(this.Data) || !LibDate.IsDate(this.Data); 
  }

  constructor(data: Date, formato?: string, texto?: string) {
    this.Data = LibGeral.estaPreenchido(data) ? data
              : LibGeral.estaPreenchido(texto) ? this.obterDataPeloTexto(texto) : null;

    this.Formato = !!formato ? formato
                 : !!texto ? this.obterFormatoPeloTexto(texto) : 'D';

    this.Texto = !!texto ? texto : LibDate.ObterTextoPorFormato(this.Data, this.Formato);
    // Se não houver formato setado, define default.
    if (LibGeral.estaEmBranco(this.Formato)) {
      this.Formato = 'D';
    }
  }
  clone(): DataPEP {
    return new DataPEP(this.Data, this.Formato, this.Texto);
  }
  toISOString(): string {
    return this.Data
      ? this.Data.toISOString()
      : null;
  }
  private obterFormatoPeloTexto(texto: string): string {
    if (LibString.isNullOrEmpty(texto)) {
      return null;
    }

    // Se tiver 3 parte (dd/mm/yy) é considerado data completa
    if (texto.split('/').length === 3) {
      return 'D';
    }

    // Se for somente um número é considerado ano
    if (LibNumeric.IsNumeric(texto)) {
      return 'A';
    }

    return 'M';
  }

  private obterDataPeloTexto(texto: string): Date {
    if (LibString.isNullOrEmpty(texto)) {
      return null;
    }

    try {
      if (texto.indexOf('/') >= 0) {
        const parts = texto.split('/');

        if (parts.length !== 2) {
          throw new Error('DataPEP: Texto com formato incorreto para conversão para data (' + texto + ').');
        }

        const mes = +parts[0] - 1;
        const ano = +parts[1];
        return new Date(ano, mes, 1);
      } else {
        return new Date((+texto), 0, 1);
      }
    } catch (err) {
      throw new Error('DataPEP: Não foi possível converter o texto para Date (' + texto + ').');
    }
  }
}
