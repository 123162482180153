import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { Subscription } from 'rxjs';

import { IpPhotoPreviewComponent } from '../ip-photo-preview/ip-photo-preview.component';


@Component({
  selector: 'ip-photo',
  templateUrl: './ip-photo.component.html',
  styleUrls: ['./ip-photo.component.scss']
})
export class IpPhotoComponent implements OnDestroy {
  @Input() habilitarIcone = false;
  @Input() habilitarLeitorQrcode = false;
  @Input() legenda: string;
  @Input() hide = false;
  @Output() onCaptureFile: EventEmitter<any>;
  DialogRef: MatDialogRef<IpPhotoPreviewComponent>;

  private listaSubcription$: Subscription[] = [];

  private barcode = '';
  private configQuagga: any;

  constructor(
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {
    this.onCaptureFile = new EventEmitter();
  }


  ngOnDestroy() {
    this.listaSubcription$.forEach(subscribe => subscribe.unsubscribe);
  }

  public showCam() {
    const dialog = this.dialog.open(IpPhotoPreviewComponent, { panelClass: 'dialog-examepreview' });
    this.capture(dialog);
  }

  private capture(dialog) {
    const captureSubscription$ = dialog.afterClosed().subscribe(photo => {
      if (LibGeral.estaPreenchido(photo)) {
        const id: string = btoa(Date.now().toString());
        const objPhoto = { id: id, nome: Date.now(), type: "image/jpeg", bytearray: photo.toString().split(",")[1], result: photo };
        this.onCaptureFile.emit({ files: [objPhoto] });
      }
    });

    this.listaSubcription$.push(captureSubscription$);
  }





}
