import { Component, Input, OnInit } from '@angular/core';
import './ip-progress.component.less';
const IP_PROGRESS_VALID_TYPE = [
  'linear',
  'circular'
];

const IP_PROGRESS_VALID_COLOR = [
  'primary',
  'accent',
  'warn'
];

@Component({
  selector: 'ip-progress',
  templateUrl: './ip-progress.component.html'
})
export class IPProgressComponent implements OnInit {

  get isLinear(): boolean { return this.tipo === 'linear'; }
  get isCircular(): boolean { return this.tipo === 'circular'; }
  get mode(): string { return this.modo === 'indeterminado' ? 'indeterminate' : 'determinate'; }

  /**
   * Tipo do progress a ser apresentado (LINEAR, CIRCULAR).
   *
   * @type {string}
   */
  @Input() tipo = 'linear';

  /**
   * Indeterminado, Determinado ou Query (este último somente para modo barra).
   *
   * @type {string}
   */
  @Input() modo = 'indeterminado';

  /**
   * Define a core que o progress será apresentado.
   *
   * @type {string}
   */
  @Input() cor = 'primary';

  /**
   * Determina o 'percentual' completo para o modo determinado.
   *
   * @type {number}
   */
  @Input() valor: number;

  @Input() raio = 10;

  ngOnInit(): void {
    this._Validate();
  }

  private _Validate(): void {
     if (IP_PROGRESS_VALID_TYPE.indexOf(this.tipo) === -1) {
      throw new Error(`Tipo '${ this.tipo }' inválido para o componente <ip-progress>`);
    }

    if (IP_PROGRESS_VALID_COLOR.indexOf(this.cor) === -1) {
      throw new Error(`Cor '${ this.cor }' inválida para o componente <ip-progress>`);
    }
  }
}
