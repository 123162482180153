import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef, Directive,
  HostListener, Injector, Input,
  OnChanges, OnInit,
  Renderer2,
  SimpleChanges, ViewContainerRef
} from "@angular/core";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { LoadingWithOverlayComponent } from "../../components/loading-with-overlay/loading-with-overlay.component";
import { SkeletonLoadingComponent } from "../../components/skeleton-loading/skeleton-loading.component";


@Directive({
  selector: "[ipLoadingWithOverlay]",
})
export class LoadingWithOverlayDirective implements OnChanges, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("ipLoadingWithOverlay") Loading: boolean;
  @Input() LoadingType:
    | "Circular"
    | "Linear"
    | "SkeletonList"
    | "SkeletonBox"
    | "";
  @Input() CustomStyles: any[] = [];

  private _component: ComponentRef<
    LoadingWithOverlayComponent | SkeletonLoadingComponent
  >;



  constructor(
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _renderer: Renderer2
  ) { }


  @HostListener("window:resize")
  onResize(event) {
    if (
      !!this._component &&
      this._component.instance instanceof SkeletonLoadingComponent
    ) {
      setTimeout(() =>
        this.calculaTamanho(<SkeletonLoadingComponent>this._component.instance)
      );
    }
  }

  _getComponentType = () => {
    switch (this.LoadingType) {
      case "Circular":
        return LoadingWithOverlayComponent;
      case "Linear":
        return LoadingWithOverlayComponent;
      case "SkeletonList":
      case "SkeletonBox":
        return SkeletonLoadingComponent;
      default:
        return LoadingWithOverlayComponent;
    }
  };

  _createComponent = () =>
  (this._component = this.componentFactoryResolver
    .resolveComponentFactory<
      LoadingWithOverlayComponent | SkeletonLoadingComponent
    >(this._getComponentType())
    .create(this.injector));

  calculaTamanho = (SkeletonComponent: SkeletonLoadingComponent) => {
    if (!!SkeletonComponent.containerBody) {
      const containerNativeElement =
        SkeletonComponent.containerBody.nativeElement;
      const parentElementCSS = getComputedStyle(
        this.viewContainerRef.element.nativeElement.parentElement
      );
      const siblingElementCSS = getComputedStyle(
        this.viewContainerRef.element.nativeElement
      );

      if (!!parentElementCSS) {
        const alturaMaxima: number =
          parseFloat(parentElementCSS.getPropertyValue("height")) -
          parseFloat(siblingElementCSS.getPropertyValue("height")) -
          15;
        this._renderer.setStyle(
          containerNativeElement,
          "max-height",
          alturaMaxima + "px"
        );
      }
      this._renderer.setStyle(
        containerNativeElement,
        "max-width",
        siblingElementCSS.getPropertyValue("width")
      );
    }
  };

  insertOrDestroyComponent = () => {
    if (!this._component || this._component.hostView.destroyed) {
      this._createComponent();
    }

    if (
      !!this._component &&
      this._component.instance instanceof SkeletonLoadingComponent
    ) {
      (<SkeletonLoadingComponent>(
        this._component.instance
      )).LoadingType = this.LoadingType;
      setTimeout(() =>
        this.calculaTamanho(<SkeletonLoadingComponent>this._component.instance)
      );
    }

    if (
      !!this._component &&
      this._component.instance instanceof LoadingWithOverlayComponent &&
      !!this.LoadingType
    ) {
      (<LoadingWithOverlayComponent>(
        this._component.instance
      )).tipo = this.LoadingType.toLowerCase();
      if (this.CustomStyles.length > 0) {
        (<LoadingWithOverlayComponent>(
          this._component.instance
        )).CustomStyles = this.CustomStyles;
      }
    }

    if (this.Loading) {
      this.viewContainerRef.insert(this._component.hostView);
    } else {
      this._component.destroy();
    }
  };

  ngAfterViewInit() {
    this.insertOrDestroyComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("Loading") &&
      LibGeral.estaPreenchido(changes.Loading.currentValue)
    ) {
      this.insertOrDestroyComponent();
    }
  }
}
