import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
// import { LibGeral } from '../../libraries/libGeral';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[float-header]'
})
export class IPFloatHeaderDirective {
  private _element: ElementRef;
  private _renderer: Renderer2;

  constructor(ngEl: ElementRef, renderer: Renderer2) { // loader: DynamicComponentLoader, private _containerRef: ViewContainerRef
    this._element = ngEl;
    this._renderer = renderer;
  }


}
