import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { catchError, tap, debounceTime, switchMap } from 'rxjs/operators';

import { HttpRequestConfig, HttpResponse } from '../models';
import { ActionTypes as AuthActions } from '../store/actions/auth.actions';
import { FinalizarRequestAction, IniciarRequestAction } from '../store/actions/request.actions';
import { Store } from '@ngrx/store';
import { GemedState } from '../store/state';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  static isStatusSucesso(httpStatus: number): boolean {
    return httpStatus >= 200 && httpStatus < 400;
  }

  constructor(private http: HttpClient, private store: Store<GemedState>) {
  }

  request(config: HttpRequestConfig): Observable<HttpResponse<any>> {
    const request = <Observable<any>>this.http.request(config.obeterMethod(), config.url, <any>{
      body: config.body,
      headers: config.headers,
      params: config.params,
      config: config
    });
    return request;
  }
}
