import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList
} from '@angular/core';

import { IPSelectItemComponent } from './ip-select-item.component';

import './ip-select.component.less';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'ip-select',
  template: `
    <mat-form-field>
      <mat-select
          [disabled]="disabled"
          class="select-input"
          [placeholder]="placeholder"
          [value]="model"
          [multiple]="habilitaMultiple"
          (selectionChange)="itemSelecionado($event)">
        <mat-option *ngFor="let item of opcoes" [value]="item.value">
          {{item.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    `,
  styles: [
    `
      :host mat-select {
        flex: 1
      }
      :host mat-select span.mat-select-value {
        flex: 1 1 auto;
        display: flex;
        position: absolute;
        width: calc(100% - 10px);
        overflow-y: hidden;
        height: 20px;
      }

      ::ng-deep .mat-select-placeholder {
        color: #666;
      }

      .select-input:hover{
        background-color: rgba(249, 251, 255, 0.8);
      }
      `
  ]
})
export class IPSelectComponent {
  @ContentChildren(IPSelectItemComponent) opcoes: QueryList<IPSelectItemComponent>;
  @Input() disabled: boolean;
  @Input() floatingPlaceholder = true;
  @Input() placeholder: string;
  @Input() widthContainer: number;
  @Input() model: any;
  @Input() habilitaMultiple = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() open = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<any>();


  constructor() {
  }
  itemSelecionado(sender: MatSelectChange): void {
    this.change.emit(sender);
  }
}
