<section class="container-login">
  <ip-toolbar>
    <div>
      <span class="fs-title">{{'multiClinicas.titulo' | translate}}</span>
    </div>

    <ip-button-default
      [tipoDeBotao]="'fab'"
      [materialIconName]="'close'"
      (onClick)="this.handleFecharModalSemClinicaSelecionada()"
    ></ip-button-default>
  </ip-toolbar>

  <mat-radio-group
    class="container-login-clinicas"
    [(ngModel)]="clinicaSelecionada"
  >
    <mat-radio-button
      class="container-login-clinica"
      [color]="'primary'"
      [value]="clinica"
      *ngFor="let clinica of clinicas;"
    >
      {{clinica.Empresa.Fantasia}}
    </mat-radio-button>
  </mat-radio-group>

  <div class="container-login-footer">
    <ip-button-default
      [disabled]="!clinicaSelecionada"
      (onClick)="dialogRef.close(clinicaSelecionada)"
      [textoAuxiliar]="'multiClinicas.botaoConfirmar'"
    >
    </ip-button-default>
  </div>
</section>
