import { type } from "../../../../core/libraries";
import { NgRxAction } from "./ngrx-action";
import {
  FiltroAplicado,
  FarmaBase,
} from "@pep-assistencial-store/state/farma.state";
import { Pedido, PrescricaoPedidoItem } from "../../models/farmaPedido.model";
import { createAction, props } from "@ngrx/store";
import { PrescricaoPedidoItemDTO } from "@gemed-core/models/PrescricaoPedidoItemDTO";

export const ActionTypes = {
  CARREGA_PEDIDOS: type(
    "[Filtro_Pedido] Carregando dados de pedido com filtro."
  ),
  CARREGA_PEDIDOS_SUCESSO: type(
    "[Filtro_Pedido] Dados de pedido carregados com sucesso."
  ),
  CARREGA_ERRO: type("[Filtro_Pedido] Dados não foram carregados."),

  CRIAR_FARMA_FILTRO: type("[Farma_Filtro_Transcrição] Criando filtro farma."),
  CRIAR_FARMA_FILTRO_SUCESSO: type(
    "[Farma_Filtro_Transcrição] Criado filtro farma com sucesso."
  ),

  ATUALIZAR_FARMA_FILTRO: type(
    "[Farma_Filtro_Transcrição] Atualizando  filtro farma."
  ),
  ATUALIZAR_FARMA_FILTRO_SUCESSO: type(
    "[Farma_Filtro_Transcrição] Atualizado filtro farma com sucesso."
  ),

  CRIAR_NOVO_PEDIDO_FARMA: type("[Farma_Novo_Pedido] Criar novo pedido farma."),
  RECUSAR_PEDIDO_FARMA: type("[Farma_RECUSAR_Pedido] recusar pedido farma."),
  CRIAR_NOVO_PEDIDO_FARMA_SUCESSO: type(
    "[Farma_Novo_Pedido] Criado novo pedido farma sucesso."
  ),

  CONSULTAR_CLIENTES_FARMA: type(
    "[CONSULTAR_CLIENTES_FARMA] BUSCAR CLIENTES PARA NOVO PEDIDO FARMA."
  ),
  CONSULTAR_CLIENTES_FARMA_SUCCESS: type(
    "[CONSULTAR_CLIENTES_FARMA_SUCCESS] RETORNADO ITERAVEL DE CLIENTES COM SUCESSO."
  ),

  CARREGAR_PEDIDO: type("[CARREGAR_PEDIDO] BUSCAR PEDIDO EXISTENTE."),
  CARREGAR_PEDIDO_SUCCESS: type(
    "[CARREGAR_PEDIDO_SUCCESS] RETORNADO DA BUSCA DE PEDIDO COM SUCESSO."
  ),

  SALVAR_PRESCRICAO_PEDIDO_ITEM: type(
    "[SALVAR_PRESCRICAO_PEDIDO_ITEM] Salvar a PrescricaoPedidoItem de um pedido e uma prescrição."
  ),
  SALVAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO: type(
    "[SALVAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO] Registrado a PrescricaoPedidoItem com sucesso."
  ),

  CARREGAR_PRESCRICAO_PEDIDO_ITEM: type(
    "[CARREGAR_PRESCRICAO_PEDIDO_ITEM] Carregar as PrescricaoPedidoItens."
  ),
  CARREGAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO: type(
    "[CARREGAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO] Carregado as PrescricaoPedidoItens."
  ),

  LIMPAR_HISTORICO_CARREGAR_PEDIDO_SELECIONADO: type(
    "[LIMPAR_HISTORICO_CARREGAR_PEDIDO_SELECIONADO] Chamar a action para limpar histórico e chamar action para carregar dados do pedido"
  ),
  LIMPAR_ULTIMO_PEDIDO_SELECIONADO: type(
    "[LIMPAR_ULTIMO_PEDIDO_SELECIONADO] Limpar o último pedido do store selecionado"
  ),

  VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM: type(
    "[VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM] Validar ou Produzir a PrescricaoPedidoItem"
  ),
  VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM_SUCESSO: type(
    "[VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM_SUCESSO] Validar ou Produzir a PrescricaoPedidoItem"
  ),

  RECUPERAR_UNIDADE_ESTOQUE: type(
    "[RECUPERAR_UNIDADE_ESTOQUE] Recuperar unidade estoque por clinicas"
  ),
  RECUPERAR_UNIDADE_ESTOQUE_SUCESSO: type(
    "[RECUPERAR_UNIDADE_ESTOQUE_SUCESSO] Unidade por estoque recuperada por clinicas"
  ),
};

export const carregaFiltroPedidoAction = createAction(
  ActionTypes.CARREGA_PEDIDOS,
  props<{ payload: FiltroAplicado }>()
);

export const carregaFiltroPedidoSucessoAction = createAction(
  ActionTypes.CARREGA_PEDIDOS_SUCESSO,
  props<{ payload: any }>()
);

export const carregaFiltroErroAction = createAction(
  ActionTypes.CARREGA_ERRO,
  props<{ payload: any }>()
);

export const createFarmaFiltroAction = createAction(
  ActionTypes.CRIAR_FARMA_FILTRO,
  props<{ payload: any }>()
);

export const createFarmaFiltroSuccessAction = createAction(
  ActionTypes.CRIAR_FARMA_FILTRO_SUCESSO,
  props<{ payload: FarmaBase }>()
);

export const updateFarmaFiltroAction = createAction(
  ActionTypes.ATUALIZAR_FARMA_FILTRO,
  props<{ payload: FiltroAplicado }>()
);

export const updateFarmaFiltroSuccessAction = createAction(
  ActionTypes.ATUALIZAR_FARMA_FILTRO_SUCESSO,
  props<{ payload: FiltroAplicado }>()
);

export const createFarmaNovoPedidoAction = createAction(
  ActionTypes.CRIAR_NOVO_PEDIDO_FARMA,
  props<{ payload: Pedido }>()
);
export const createFarmaRecusarPedidoAction = createAction(
  ActionTypes.RECUSAR_PEDIDO_FARMA,
  props<{ payload: Pedido }>()
);

export const createFarmaNovoPedidoSuccessAction = createAction(
  ActionTypes.CRIAR_NOVO_PEDIDO_FARMA_SUCESSO,
  props<{ payload: Pedido }>()
);

export const carregaIteravelDeClientesAction = createAction(
  ActionTypes.CONSULTAR_CLIENTES_FARMA,
  props<{ payload: any[] }>()
);

export const carregaIteravelDeClientesSucessoAction = createAction(
  ActionTypes.CONSULTAR_CLIENTES_FARMA_SUCCESS,
  props<{ payload: any[] }>()
);

export const carregarFarmaPedidoAction = createAction(
  ActionTypes.CARREGAR_PEDIDO,
  props<{ payload: FarmaBase }>()
)

export const carregarFarmaPedidoSucessoAction = createAction(
  ActionTypes.CARREGAR_PEDIDO_SUCCESS,
  props<{ payload: any }>()
);

export const salvarPrescricaoPedidoItemAction = createAction(
  ActionTypes.SALVAR_PRESCRICAO_PEDIDO_ITEM,
  props<{ payload: PrescricaoPedidoItem }>()
);

export const salvarPrescricaoPedidoItemSucessoAction = createAction(
  ActionTypes.SALVAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO,
  props<{ payload: boolean }>()
);

export const carregarPrescricaoPedidoItemAction = createAction(
  ActionTypes.CARREGAR_PRESCRICAO_PEDIDO_ITEM,
  props<{ payload: PrescricaoPedidoItem }>()
);

export const carregarPrescricaoPedidoItemSucessoAction = createAction(
  ActionTypes.CARREGAR_PRESCRICAO_PEDIDO_ITEM_SUCESSO,
  props<{ payload: any }>()
);

export const limparHistorioUltimoPedidoSelecioandoAction = createAction(
  ActionTypes.LIMPAR_ULTIMO_PEDIDO_SELECIONADO,
  props<{ payload: any }>()
);

export const validarProduzirPrescricaoPedidoItemAction = createAction(
  ActionTypes.VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM,
  props<{ payload: PrescricaoPedidoItemDTO }>()
);

export const validarProduzirPrescricaoPedidoItemSucessoAction = createAction(
  ActionTypes.VALIDAR_PRODUZIR_PRESCRICAO_PEDIDO_ITEM_SUCESSO,
  props<{ payload: boolean }>()
);

export const recuperarUnidadeEstoqueAction = createAction(
  ActionTypes.RECUPERAR_UNIDADE_ESTOQUE,
  props<{ payload: number }>()
);

export const recuperarUnidadeEstoqueSucessoAction = createAction(
  ActionTypes.RECUPERAR_UNIDADE_ESTOQUE_SUCESSO,
  props<{ payload: any }>()
);
