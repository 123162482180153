import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';


export enum tiposDeBotao {
  default = 'mat-button',
  flat = 'mat-flat-button',
  stroke = 'mat-stroked-button',
  fab = 'mat-fab',
  miniFab = 'mat-mini-fab',
  icon = 'mat-icon-button'
}

export enum tamanhoDeBotao {
  fullSize = 'fullSize',
  textSize = 'textSize'
}


@Component({
  selector: 'ip-button-default',
  templateUrl: './ip-button-default.component.html',
  styleUrls: ["./ip-button-default.component.scss"]
})



//https://dev.azure.com/interprocessti/Gemed/_wiki/wikis/Gemed.wiki/375/UI-UX-Gemed-Web
export class IPButtonDefaultComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() throttleTime = 700;
  @Input() efeitoRipple = true;
  @Input() disabled = false;
  @Input() formatoRedondo = false;
  @Input() htmlTypeButton = 'button';
  @Input() public tipoDeBotao: tiposDeBotao = tiposDeBotao["default"];
  @Input() color = "";
  @Input() materialIconName: string = null;
  @Input() textoAuxiliar: string = null;
  @Input() textoParaPessoasCegas: string = null;
  @Input() fullSize = false;
  @Input() textSize = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<any>();
  @Output() throttledClick = new EventEmitter();
  @ViewChild('buttonDefault') botaoRef: ElementRef;
  public textoParaPessoasCegas$: Observable<string>;

  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private tradutor: TranslateService) {
  }

  onClickButton(event: any) {
    this.onClick.emit(event);
  }

  ngOnInit(): void {
    this.subscription = this.clicks.pipe(
      throttleTime(this.throttleTime)
    ).subscribe(e => this.onClickButton(e));
  }

  ngAfterViewInit(): void {
    this.botaoRef.nativeElement.setAttribute(tiposDeBotao[this.tipoDeBotao], '');
    if (this.fullSize) {
      this.botaoRef.nativeElement.setAttribute('fullSize', '');
    }
    if (this.textSize) {
      this.botaoRef.nativeElement.setAttribute('textSize', '');
    }
    this.adicionaBordarArredondadas();
  }

  adicionaBordarArredondadas() {
    if (this.formatoRedondo) {
      this.botaoRef.nativeElement.classList.add("redondo");
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clickEvent(event) {
    if (!this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks.next(event);
    }
  }

  // criar switch para verificar o tipo passado e setar o m-at button correpondente. ex.: mat-flat-button

}
