import { NexodataAmbienteEnum } from "@gemed-core/enums/gemed.enum";
import { IEnvironmentProps } from "@gemed-core/interfaces/gemed.interface";

const environment: IEnvironmentProps = {
  production: false,
  idiomasSuportados: ["pt-BR", "en"],
  readOnlyMode: false,
  domain: "",
  applicationInsightsKey: '##{applicationInsightsKey}##',
  version: '##{Build.BuildNumber}##',
  url: {
    Auth: "../seguranca/api",
    Seguranca: "../seguranca/api",
    Resources: "../gemed/api",
    GemedWebImage: "../gemedweb/exameimagem.aspx",
    Graphql: "../gemed/api/GraphQL/GraphQL",
    ProntuarioExameWeb:
      "../wsprontuario/ExameImagem.asmx",
    Mobile: "../ChecagemMobile/api",
    VideoAtendimento: "https://api.daily.co/v1/rooms",
    VideoAtendimentoToken: "https://api.daily.co/v1/meeting-tokens",
  },
  TipoAmbienteNexoData: NexodataAmbienteEnum.HOMOLOG,
  hmr: false,
};

export default environment;
