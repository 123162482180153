import { ProtocoloOficialEnum } from "./protocoloOficial.enum";
import {
  IClinica,
  IPatologia,
  IProtocoloRadioterapia,
  IProtocoloTipo,
} from "@gemed-core/interfaces/gemed.interface";
import { SimNao, TipoMedicamento } from "@gemed-core/enums/gemed.enum";
import { ProtocoloGrupo } from "@gemed-core/models/protocoloGrupo.model";
import { ProtocoloDetalhe } from "@gemed-core/models/protocoloDetalhe.model";
import { LibGeral } from "@gemed-core/libraries/libGeral";

export class Protocolo {
  IdProtocolo: number;
  Id: number;
  Clinica: IClinica;
  Patologia: IPatologia;
  Sigla = null;
  Descricao = null;
  Tempo: number;
  IntervaloCiclosEmDias?: number;
  TempoLock: SimNao;
  Periodicidade: string;
  Referencia: string;
  Indicacao: string;
  Observacao: string;
  ExigePrescricao: SimNao;
  Oficial: ProtocoloOficialEnum;
  Status: string;
  IdOld?: number;
  Log: any;
  ConfProtocolo: SimNao;
  TipoMedicamento: TipoMedicamento;
  IdPergunta?: number;
  ProtocoloGrupos: ProtocoloGrupo[];
  ProtocoloDetalhes: ProtocoloDetalhe[];
  AlterouCabecalho: boolean;
  NomePatologias: string;
  Impressao: boolean;
  ProtocoloRadioterapias: IProtocoloRadioterapia[];
  ProtocoloTipo: IProtocoloTipo;

  get IdTextBoxPesquisa(): number {
    return this.IdProtocolo;
  }

  set IdTextBoxPesquisa(value) { }

  get DescricaoTextBoxPesquisa(): string {
    let descricaoPesquisa = "";

    if (LibGeral.estaPreenchido(this.Sigla)) {
      descricaoPesquisa = this.Sigla;
    }

    if (LibGeral.estaPreenchido(this.Descricao)) {
      descricaoPesquisa += LibGeral.estaPreenchido(descricaoPesquisa) ? " - " : "";
      descricaoPesquisa += this.Descricao;
    }

    return descricaoPesquisa;
  }

  set DescricaoTextBoxPesquisa(value) { }

  get ToolTipTextBoxPesquisa(): string {
    return this.Descricao;
  }

  set ToolTipTextBoxPesquisa(value) { }

  constructor(
    id?: number,
    idProtocolo?: number,
    sigla?: string,
    descricao?: string,
    oficial?: ProtocoloOficialEnum,
    status?: string
  ) {
    this.Id = id;
    this.IdProtocolo = idProtocolo;
    this.Sigla = sigla ? sigla : null;
    this.Descricao = descricao ? descricao : null;
    this.Oficial = oficial;
    this.Status = status;
  }
}
