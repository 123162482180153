import { Component, Input } from '@angular/core';
@Component({
  selector: 'ip-menu-item',
  template: `
  <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span><ng-content></ng-content></span>
  </button>
  `
})
export class IPMenuItemComponent {
  @Input() display: string;
}
