import { Usuario } from '@gemed-core/models/usuario.model';
import { PEPBase } from './pepBase.model';

export class PEPAntropometria extends PEPBase {
  ProfissionalNome: string;
  QuestionarioNome: string;
  Altura: number;
  Idade: number;
  IMC: number;
  IMCClassificacao: string;
  Peso: number;
  PerformanceStatus: number;
  PerformanceStatusDescricao: string;
  Superficie: any;
  SuperficieCorporalValorFixo: any;
  DCE: number;
  Creatinina: number;
  Curva: number;
  IntensidadeDaDor: number;
  PressaoArterialSistolica: number;
  PressaoArterialDiastolica: number;
  FrequenciaCardiaca: number;
  FrequenciaRespiratoria: number;
  TemperaturaAxilar: number;
  OximetriaPulso: number;
  NovaMedida: boolean;
  constructor() {
    super();
  }
}


export enum TipoCalculoSuperficieCorporal {
  CalculaSuperficieCorporalDubois = 1,
  calculaSuperficieCorporalMosteller = 2
}
