import { Component } from '@angular/core';
import { IPAlertDialogConfig, IPDialogConfig, IPDialogResolver } from '../../../core/dialog/ipDialog.service';

@Component({
  selector: 'ip-alert-dialog',
  template: `
    <ip-dialog-container>
      <h3 header>{{ config.Titulo }}</h3>
      <p content>{{ config.Mensagem }}</p>
      <div actions style="display: flex; flex: 1; justify-content:flex-end">
        <ip-button-flat actions [color]="'warn'" (click)="onOkClick()">{{ config.OkButton }}</ip-button-flat>
      </div>
    </ip-dialog-container>
  `
})
export class IPAlertDialogComponent {
  config: IPAlertDialogConfig;
  resolver: IPDialogResolver;

  constructor(config: IPDialogConfig) {
    // this.config = new IPAlertDialogConfig('tit', 'text', 'teste');
    this.config = <IPAlertDialogConfig>config;

    if (!this.config) {
      throw new Error(`O objeto de configuração deve ser passado para o componente "IPDialogAlert".`);
    }
  }

  onOkClick(): void {
    this.resolver.resolve(true);
  }
}
