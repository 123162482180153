import { RestApiService } from './http/restApi.service';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class AbstractService {
  public _restApiService: RestApiService;
  public get restApiService(): RestApiService { return this._restApiService; }
  constructor(restApiService: RestApiService) {
    this._restApiService = restApiService;
  }
}
