import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class IPHighlightService {

  private element$ = new BehaviorSubject(HTMLElement);
  public currentElement$ = this.element$.asObservable();

  constructor() { }


  eventDispatcher(element: any) {
    return this.element$.next(element);
  }
}
