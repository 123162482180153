import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPCardComponent } from './ip-card.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [IPCardComponent],
  exports: [IPCardComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPCardModule {
}
