import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { IPToastService } from "@gemed-core/toast/ipToast.service";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import { Subject, Subscription } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { LoggingService } from "src/app/error/loggingService";

@Component({
  selector: "ip-qrcode",
  templateUrl: "./ip-qrcode.component.html",
  styleUrls: ["./ip-qrcode.component.scss"],
})
export class IpQrcodeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() habilitarIcone = false;
  @Input() legenda: string;
  @Input() corBotao = "primary";
  @Input() exibirLeitor2d = false;
  @Output() CaptureQrcodeChange: EventEmitter<any> = new EventEmitter(false);
  @Output() showCamChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;
  @ViewChild('campoleitor2d') campoleitor2d: ElementRef;

  public mostrarLiveBarcode = false;
  public rerender = true;
  public formularioCodigoDeBarras: UntypedFormGroup;


  public cameraAvailability = false;
  public formatosPermitidos: BarcodeFormat[];
  private changeDetectorRef: ChangeDetectorRef;
  private leituraRealizada = new Subject<string>();
  private subscriptionArray: Subscription[] = [];
  private showToast = true;

  constructor(
    @Optional() changeDetectorRef: ChangeDetectorRef,
    private toast: IPToastService,
    private formBuilder: UntypedFormBuilder,
    private logService: LoggingService,
  ) {
    this.changeDetectorRef = changeDetectorRef;
    this.subscriptionArray.push(
      this.leituraRealizada
        .pipe(
          tap(() => {
            if (this.showToast) {
              window.navigator.vibrate(150);
              this.toast.info("Processando leitura. Aguarde");
              this.showToast = false;
            }
          }),
          debounceTime(1200),
          tap(() => (this.showToast = true))
        )
        .subscribe((value) => {
          this.CaptureQrcodeChange.emit(value);
        }
        )
    );
  }

  ngOnDestroy() {
    this.subscriptionArray.forEach((subscription) => {
      if (LibGeral.estaPreenchido(subscription)) {
        subscription.unsubscribe();
      }
    });
  }


  async ngOnInit() {
    this.criarFormularioCodigoDeBarras()
    this.formatosPermitidos = this.obterFormatosPermitidos();
    this.cameraAvailability = true;
  }

  async ngAfterViewInit() {
    if (this.exibirLeitor2d) {
      this.handleFocarCampoDeLeitura();
    }

    if (LibGeral.estaPreenchido(this.scanner)) {
      const enableCam = await this.scanner.askForPermission();

      if (typeof enableCam == "boolean") {
        if (!enableCam) {
          this.toast.info("Câmera não hablitada.")
        }
      } else {
        this.logService.logError("Câmera não hablitada", String(enableCam));
      }
    }
  }

  private handleFocarCampoDeLeitura() {
    if (LibGeral.estaPreenchido(this.campoleitor2d)) {
      this.campoleitor2d.nativeElement.focus();
    }
  }

  private criarFormularioCodigoDeBarras(): void {
    this.formularioCodigoDeBarras = this.formBuilder.group({
      codigoDeBarras: ["", Validators.minLength(8)],
    });
  }

  handleLeituraComSucesso(codigoLido: string) {
    this.leituraRealizada.next(codigoLido);
  }

  public handleEnviarLeitura2D() {
    const codigoDeBarras: string = this.formularioCodigoDeBarras.get('codigoDeBarras').value;
    if (!codigoDeBarras) {
      return;
    }

    this.handleLeituraComSucesso(codigoDeBarras)

    this.formularioCodigoDeBarras.reset();
  }

  showCam() {
    this.mostrarLiveBarcode = true;
    this.doRerender();
    this.scanner.enable = true;
    this.showCamChange.emit(true);
  }

  camerasNotFound(e: Event) {
  }

  handlePermissionResponse(e: boolean) {
    this.logService.logError("handlePermissionResponse", String(e));
  }

  scanErrorHandler(e: Error) {
    this.logService.logError(e.message, e.stack);


    this.toast.info(
      "Leitura incorreta, tente novamente.",
      "Erro de leitura",
      {
        tempoApresentacao: 4,
        botaoFechar: true,
      }
    );
  }

  doRerender() {
    this.rerender = false;
    this.changeDetectorRef.detectChanges();
    this.rerender = true;
  }

  desativarLeitura() {
    if (LibGeral.estaPreenchido(this.scanner)) {
      this.scanner.enable = false;
      this.scanner.reset();
    }

    this.mostrarLiveBarcode = false;
    this.doRerender();
    this.showCamChange.emit(false);
  }

  private obterFormatosPermitidos() {
    return [
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.QR_CODE,
      BarcodeFormat.EAN_13,
      BarcodeFormat.EAN_8,
    ];
  }
}
