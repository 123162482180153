import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HighlightPubDirective } from "./highlight-pub.directive";
import { HighlightSubDirective } from "./highlight-sub.directive";
import { IPHighlightService } from "./highlight.service";

const diretivas = [
  HighlightPubDirective,
  HighlightSubDirective
];

@NgModule({
  imports: [CommonModule],
  declarations: diretivas,
  exports: diretivas,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [IPHighlightService]
})
export class HighlightPubSubModule {

}
