import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { Clinica } from '@gemed-core/models/clinica.model';
import { FecharModalClinicasAction } from '@gemed-core/store/actions/auth.actions';
import { GemedState } from '@gemed-core/store/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ip-lista-multi-clinicas',
  templateUrl: './lista-multi-clinicas.component.html',
  styleUrls: ['./lista-multi-clinicas.component.scss']
})
export class ListaMultiClinicasComponent implements OnInit {

  @Input() clinicas: Clinica[] = [];
  public clinicaSelecionada: Clinica;

  constructor(
    public dialogRef: MatDialogRef<ListaMultiClinicasComponent>,
    private store: Store<GemedState>,
    @Inject(MAT_DIALOG_DATA) public data: { clinicas: Clinica[] }
  ) { }

  ngOnInit() {
    if (LibGeral.estaPreenchido(this.data.clinicas)) {
      this.clinicas = this.data.clinicas;
    }
  }

  handleFecharModalSemClinicaSelecionada(){
    this.store.dispatch(new FecharModalClinicasAction());
    this.dialogRef.close();
  }

}
