import {
 ComponentFactoryResolver, Directive, ElementRef, Host, Optional, Renderer2, Type, ViewContainerRef
} from '@angular/core';

import { IPCellDirective } from './ip-cell.directive';
import { IPInputNumberComponent } from '../input';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';

/**
 * Diretiva para edição de números inline no componente ip-table.
 * Quando a celula é passada para o modo de edição, a diretiva altera
 * seu conteúdo para apresentar um campo onde o usuário poderá inserir o valor.
 * @export
 * @class IPCellInlineEditNumberDirective
 * @extends {IPCellInlineEditDirective<number>}
 */
@Directive({selector: '[ip-cell-inline-edit-number]'})
export class IPCellInlineEditNumberDirective extends IPCellInlineEditDirective<number> {

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);
  }

  public getComponent(): Type<any> {
    return IPInputNumberComponent;
  }

  public setComponentProps(editorComponent: IPInputNumberComponent): void {
    // Seta o valor atual do componente com o valor da celula
    editorComponent.input.nativeElement.value = this.cell.getCurrentValue();
  }
}
