import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PagingInfo } from '@gemed-core/models';
import { LibGeral } from '../../../core/libraries/libGeral';
@Component({
  selector: 'ip-pagination',
  templateUrl: './ip-pagination.component.html',
  styleUrls: ['./ip-pagination.component.scss']
})
export class IPPaginationComponent {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('paging-info') pagingInfo: IPaginacao = new PagingInfo(12);

  /**
   * Evento disparado quando ocorre alguma alteração na paginação.
   *
   * @type {EventEmitter<IPaginacao>}
   */
  @Output() paginate: EventEmitter<IPaginacao>;

  /**
   * Retorna a página sendo apresentada.
   *
   * @readonly
   * @type {number}
   */
  get currentPage(): number { return LibGeral.estaPreenchido(this.pagingInfo) ? this.pagingInfo.PageNumber : 0; }

  /**
   * Retorna o número do primeiro registro apresentado na página atual.
   *
   * @readonly
   * @type {number}
   */
  get initialRecordOnCurrentPage(): number {
    return LibGeral.estaPreenchido(this.pagingInfo) ?
      (this.pagingInfo.PageNumber - 1) * this.pagingInfo.PageSize + 1 :
      0;
  }

  /**
   * Retorna o número do último registro apresentado na página atual.
   *
   * @readonly
   * @type {number}
   */
  get lastRecordOnCurrentPage(): number {
    if (LibGeral.estaEmBranco(this.pagingInfo)) {
      return 0;
    }

    const lastRecord = this.pagingInfo.PageNumber * this.pagingInfo.PageSize;
    return lastRecord > this.totalRecordCount ? this.totalRecordCount : lastRecord;
  }

  public totalDePaginas(): number {
    if (LibGeral.estaPreenchido(this.pagingInfo)) {
      const { PageSize } = this.pagingInfo;
      return Math.ceil(this.totalRecordCount / PageSize);

    }
    return 1;
  }

  /**
   * Retorna o número total de registros.
   *
   * @readonly
   * @type {number}
   */
  get totalRecordCount(): number { return this.pagingInfo ? this.pagingInfo.TotalRecordCount : 0; }

  get isFirstPage(): boolean { return this.pagingInfo && this.currentPage === 1; }
  get isLastPage(): boolean { return this.pagingInfo && this.currentPage >= this.pagingInfo.PageCount; }
  get hasRecords(): boolean { return this.pagingInfo && this.pagingInfo.TotalRecordCount > 0; }

  constructor() {
    this.paginate = new EventEmitter<IPaginacao>();
  }

  changePage(page: number): void {
    if (LibGeral.estaEmBranco(this.pagingInfo) || isNaN(page)) {
      return;
    }

    if (page === 0 || page > this.pagingInfo.PageCount) {
      return;
    }
    const newPage: IPaginacao = { ...this.pagingInfo, PageNumber: page }
    this.paginate.emit(newPage);
  }

  reset(): void {
    if (LibGeral.estaEmBranco(this.pagingInfo)) {
      return;
    }

    this.pagingInfo.PageNumber = 1;
    this.pagingInfo.PageCount = 1;
    this.pagingInfo.TotalRecordCount = 0;
  }
}
