import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ip-chart',
  templateUrl: './ip-chart.component.html',
  styleUrls: ['./ip-chart.component.scss']
})

export class IPChartComponent {
  @Input() legendas: any;
  @Input() valores: any;
  @Input() titulo: string;
  @Input() subtitulo: string;
  @Input() tipoChart: number;


  constructor() {
  }

  customizeTooltip(arg: any) {
    return arg.valueText
  }

}
