import { Component, Input, Attribute, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange, MAT_CHECKBOX_CONTROL_VALUE_ACCESSOR } from '@angular/material/checkbox';
import { LibGeral } from '../../../core/libraries/libGeral';



@Component({
  selector: 'ip-checkbox',
  template: `
    <mat-checkbox
      [(ngModel)]="model"
      [checked]="model"
      [indeterminate]="isIndeterminate"
      [(disabled)]="disabled"
      (change)="_onChanged($event)"
      [labelPosition]='align'
      [color]="'primary'">
    <ng-content></ng-content>
  </mat-checkbox>`,
  styles: [
    `
      :host {
        mat-checkbox: {
          margin-bottom: 0px;
        }
      }
    `
  ],
  providers: [MAT_CHECKBOX_CONTROL_VALUE_ACCESSOR]
})
export class IPCheckBoxComponent {
  // private _readOnly: boolean = false;
  public _model: boolean;
  @Input() checked: boolean;
  @Input() align = 'before';
  @Output() change: EventEmitter<boolean>;
  @Input() disabled: boolean;
  @Input() set model(v: boolean) { this._model = v; }
  // @Input('read-only') set readOnly(value: boolean) { this._readOnly = !!value; }
  @Output() modelChange: EventEmitter<boolean>;

  get model(): boolean { return this._model; }
  get isIndeterminate(): boolean { return LibGeral.estaEmBranco(this._model); }

  constructor() {
    this.disabled = false;
    this.checked = false;
    this.modelChange = new EventEmitter<boolean>(false);
    this.change = new EventEmitter<boolean>(false);
  }

  _onChanged($event: MatCheckboxChange): void {
    if (this.isIndeterminate) {
      this._model = false;
      return;
    }
    this.modelChange.emit($event.checked);
    this.change.emit($event.checked);
  }
}
