import { ComponentFactoryResolver, Directive, ElementRef, Host, HostListener, Input, Optional, Renderer2, Type, ViewContainerRef } from '@angular/core';
import { LibDate } from 'src/app/core/libraries/libDate';
import { IPDatePickerComponent } from '../input';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';
import { IPCellDirective } from './ip-cell.directive';

@Directive({
  selector: '[ip-cell-inline-edit-data]'
})
export class IPCellInlineEditDataDirective extends IPCellInlineEditDirective<any>  {

  @Input() dateChangedFunction: Function;

  public getComponent(): Type<IPDatePickerComponent> {
    return IPDatePickerComponent;
  }

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);
  }

  @HostListener('click', ['$event']) onClickEvent(event) {
    if (event.target.id === 'fx') {
      event.stopPropagation();
      this.cell.row.beginEdit();
    }
    return false;
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.active) {
      // eslint-disable-next-line max-len
      if (this.cell.row.allowEdit) {
        const tdElement = this.getElement();
        tdElement.style.position = 'relative';
        tdElement.innerHTML += `<i class="material-icons"
                                         style="color: #345ca5;
                                         cursor:pointer;
                                         position: absolute;
                                         top: 13px;
                                         font-size:20px;"
                                         id='fx'> edit </i>`;
      }

    }
  }
  @HostListener('mouseleave') onMouseLeave() {
    if (!this.active) {
      this.setDate(this.cell.getCurrentValue());
    }
  }

  public setComponentProps(editorComponent: IPDatePickerComponent): void {

    editorComponent.model = this.cell.getCurrentValue();
    editorComponent.openPickerOnCreate = true;
    editorComponent.modelChange.subscribe(() => this.dateChangedFunction(this.cell.row.model, editorComponent.model));

    editorComponent.closed.subscribe(x => {
      this.cell.row.endEdit(true);
      this.setDate(this.cell.getCurrentValue());
    });

    editorComponent.modelChange.subscribe(changes => {
      this.cell.row.endEdit(true);
      this.setDate(this.cell.getCurrentValue());
    });
  }

  private setDate(dateValue) {
    this.getElement().innerHTML = `${LibDate.FormatarData(dateValue, 'DD/MM/YYYY')}`;
  }
}
