// Referência: https://github.com/angular/material2/tree/master/src/components/toolbar

import { Component, Input } from "@angular/core";
@Component({
  selector: "ip-toolbar",
  styleUrls: ["./ip-toolbar.component.scss"],
  template: `
    <mat-toolbar
      [color]="color"
      [class]="customClass"
      [style.background]="bgColor"
      [style.height]="this.toolbarHeight"
    >
      <ng-content></ng-content>
    </mat-toolbar>
  `,
})
export class IPToolbarComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("background-color") bgColor = "#345ca5";
  @Input() color = "primary";
  @Input() height: number | string = "'auto'";
  @Input() customClass = "";

  public toolbarHeight = this.height;
}
