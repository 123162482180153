<!-- APRESENTAÇÃO DO BOTÃO -->
<div *ngIf="!mostrarLiveBarcode">
  <div *ngIf="habilitarIcone">
    <ip-button-default [disabled]="!cameraAvailability" (onClick)="showCam()" [color]="corBotao" [tipoDeBotao]="'fab'"
      [materialIconName]="'photo_camera'"></ip-button-default>
  </div>
  <div *ngIf="!habilitarIcone">
    <ip-button-default [disabled]="!cameraAvailability" (onClick)="showCam()" [color]="corBotao"
      [materialIconName]="'photo_camera'" [textoAuxiliar]="legenda"></ip-button-default>
  </div>
</div>

<!-- APRESENTAÇÃO DA FUNCIONALIDADE DE LEITURA -->

<zxing-scanner #scanner class="scanner-box"
 *ngIf="mostrarLiveBarcode"
 [enable]="false"
 [formats]="formatosPermitidos"
 (scanSuccess)="handleLeituraComSucesso($event)"
 (camerasNotFound)="camerasNotFound($event)"
 (permissionResponse)="handlePermissionResponse($event)"
  (scanError)="scanErrorHandler($event)">
</zxing-scanner>


<!-- APRESENTAÇÃO DA FUNCIONALIDADE DE LEITURA COM LEITORES 2D -->

<ng-container *ngIf="exibirLeitor2d">
  <form [formGroup]="this.formularioCodigoDeBarras" (submit)="handleEnviarLeitura2D()">

    <mat-form-field appearance="standard">
      <mat-label>{{legenda| translate}}</mat-label>
      <input matInput formControlName="codigoDeBarras" minlength="8" tabindex="1" type="text" #campoleitor2d
        id="campoCodigoDeBarras" />
    </mat-form-field>

    <button type="submit"></button>
  </form>
</ng-container>
