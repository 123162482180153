import { IPButtonModule } from 'src/app/core-ui/components/button/ip-button.module';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPContentComponent } from './ip-content.component';
import { IPToolbarComponent } from './ip-toolbar.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [MaterialModule, IPButtonModule],
  declarations: [IPContentComponent, IPToolbarComponent],
  exports: [IPContentComponent, IPToolbarComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPLayoutModule {
}
