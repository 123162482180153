declare let _: any;
import { LibGeral } from "./libGeral";
import { LibString } from "./libString";

const padNumeraisDecimais = (numerais: any[], casasDecimais: number): any => {
  for (let i = 0; i < casasDecimais; i++) {
    numerais.push(0);
  }
  return numerais;
};
const formatarPontoMilhar = (valor, separador) => {
  return valor.reduce((state, numero, indice) => {
    if (indice > 0 && indice % 3 === 0) {
      state.push(separador);
    }
    state.push(numero);
    return state;
  }, []);
};
export class LibNumeric {
  static IsNumeric(value: any): boolean {
    return (
      LibGeral.estaPreenchido(value) &&
      !LibString.isNullOrEmpty(value.toString()) &&
      !isNaN(Number(value))
    );
  }

  static isFloat(valor: number): boolean {
    return valor.toString().split(".").length > 1;
  }
  /**
   * Gera um valor para display com base em uma configuração.
   * @param {any} value - Valor a ser formatado.
   * @param {number} numInt - Seta o máximo de dígitos para a parte inteira(padrão 6).
   * @param {number} numFract - Seta o máximo de dígitos fracionários(padrão 2).
   * @param {string} numSep - Seta o carácter para separador decimal(padrão '.').
   * @param {boolean} numThousand - Diz se numero irá usar separador de milhar ou não(padrão false)
   * @param {string} numThousandSep - Seta o carácter para separador de milhar(padrão '').
   */
  static Formatter(
    value: any,
    numInt?: number,
    numFract?: number,
    numSep?: string,
    numThousand?: boolean,
    numThousandSep?: string
  ): string {
    if (
      value === "" ||
      LibGeral.estaEmBranco(value) ||
      /[a-zA-Z]/.test(value)
    ) {
      return "0";
    }
    if (value === 0 || value === "0") {
      value = padNumeraisDecimais([0], numFract).join("");
    }
    numInt = LibGeral.estaPreenchido(numInt) ? Number(numInt) : 6;
    numFract = LibGeral.estaPreenchido(numFract) ? Number(numFract) : 2;
    numSep = LibGeral.estaPreenchido(numSep) ? numSep : ".";
    numThousand = LibGeral.estaPreenchido(numThousand) ? numThousand : false;
    numThousandSep = LibGeral.estaPreenchido(numThousandSep)
      ? numThousandSep
      : "";
    const valorTemp = LibNumeric.getRawString(value);
    const numerais = valorTemp
      .split("")
      .reverse()
      .filter((x, indice) => indice < numInt + numFract);
    const parteDecimal = numerais.filter((x, indice) => indice + 1 <= numFract);
    const partInteira = numerais
      .filter((x, indice) => indice + 1 > numFract)
      .reverse()
      .reduce(
        (state, valor, indice) => {
          if (valor !== "0") {
            state.permiteAdicionar = true;
          }
          if (state.permiteAdicionar) {
            state.numeros.push(valor);
          }
          return state;
        },
        { numeros: [], permiteAdicionar: false }
      )
      .numeros.reverse();

    if (partInteira.length === 0) {
      partInteira.push("0");
    }
    if (numFract > 0 && parteDecimal.length < numFract) {
      padNumeraisDecimais(parteDecimal, numFract);
    }
    return [
      numThousand
        ? formatarPontoMilhar(partInteira, numThousandSep).reverse().join("")
        : partInteira.reverse().join(""),
      parteDecimal.reverse().join(""),
    ]
      .filter((x) => x !== "")
      .join(numSep);
  }
  static getRaw(value: any, numSep?: string, numThousandSep?: string): number {
    let rawValue = value.replace(/\./gi, "").replace(/,/gi, "");
    if (numSep && numSep !== ".") {
      const regExpDecimal = new RegExp(numSep, "gi");
      rawValue = rawValue.replace(regExpDecimal);
    }
    if (numThousandSep && numThousandSep !== ".") {
      const regExpThousand = new RegExp(numThousandSep, "gi");
      rawValue = rawValue.replace(regExpThousand);
    }
    return rawValue * 1;
  }
  /**
   * Retorna todos digitos concatenados como uma string.
   * @param  {any} value
   * @param  {string} numSep?
   * @param  {string} numThousandSep?
   * @returns string
   */
  static getRawString(
    value: any,
    numSep?: string,
    numThousandSep?: string
  ): string {
    let rawValue = value.toString().replace(/\./gi, "").replace(/,/gi, "");
    if (numSep && numSep !== ".") {
      const regExpDecimal = new RegExp(numSep, "gi");
      rawValue = rawValue.replace(regExpDecimal);
    }
    if (numThousandSep && numThousandSep !== ".") {
      const regExpThousand = new RegExp(numThousandSep, "gi");
      rawValue = rawValue.replace(regExpThousand);
    }
    return rawValue;
  }
  /**
   * Retorna o objeto 'Number' valido em javascript.
   * @param  {string} maskValue - valor que foi formatado.
   * @param  {string} numSep - separador de casas decimais.
   * @param  {string} numThousandSep - separador de milhar.
   * @returns number
   */
  static toNumber(
    maskValue: string,
    numSep: string = ",",
    numThousandSep: string = "."
  ): number {
    let newValue = maskValue;
    if (numThousandSep !== "") {
      newValue = newValue.replace(new RegExp(`\\${numThousandSep}`, "gi"), "");
    }
    newValue = newValue.replace(new RegExp(`\\${numSep}`, "gi"), ".");
    return Number(newValue);
  }
}
