import {
  ActionTypes,
  AcceptUrlAction,
  ChangeURLAction,
} from "../actions/route.actions";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { toPayload } from "../libraries";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RouteEffects {
  @Effect()
  public ApresentarAviso: Observable<Action> = this.actions
    .pipe(ofType(ActionTypes.CHANGE_URL))
    .pipe(
      map(({ payload, mensagem }: ChangeURLAction) => {
        this.router.navigateByUrl(payload, {
          state: {
            mensagem,
          },
        });
        return new AcceptUrlAction(payload);
      })
    );
  constructor(private actions: Actions, private router: Router) {}
}
