import { MaterialModule } from "../../../material/material.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { IPAutoCompleteComponent } from './ip-autocomplete.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule, FlexLayoutModule],
  declarations: [IPAutoCompleteComponent],
  exports: [IPAutoCompleteComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPAutoCompleteModule {
}
