<dx-tree-view
id="selection-treeview"
[items]="listaDados"
[width]="320"
showCheckBoxesMode="normal">
    <ng-content></ng-content>
</dx-tree-view>


<!-- <dx-tree-list
    id="treelist"
    [dataSource]="listaDados"
    keyExpr="id"
    parentIdExpr="parent_id"
    [showRowLines]="false"
    [showBorders]="false"
    [columnAutoWidth]="true"
    [expandedRowKeys]="[1, 2, 10]"
    [(selectedRowKeys)]="linhasSelecionadas">
    <dxo-selection
            mode="tipoSelecao"
            [recursive]="habilitarSelecaoRecursiva">
    </dxo-selection>
</dx-tree-list> -->