import { AuthState } from './auth.state';
import { GemedState } from './gemed.state';
import { LibGeral } from '../../libraries/libGeral';

export class LibState {
  // TODO: Rever;
  static isLogado(state: GemedState): boolean {
    return LibGeral.estaPreenchido(state.auth.IsAuthenticated);
  }
  static hasContextoSelecionado(state: AuthState): boolean {
    return LibGeral.estaPreenchido(state.cliente) && LibGeral.estaPreenchido(state.filial);
  }
}
