<div class="card-container">
  <button (click)="handleVoltarParaHome()" fxFlexAlign="center" class="logo-container">
    <img class="logomarca-login" src="assets/logo-interprocess-gemed-sf.png" alt="Logo da Aplicação GEMED" />
  </button>

  <form autocomplete="off" class="gemed-login-form" *ngIf="(loading | async) !==true" [formGroup]="form">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field>
        <input [autofocus]="true" data-test="gemed-input-usuario" matInput required tabindex="1" id="loginInput"
          placeholder="{{ 'loginEmailPlaceholder' | translate }}" autocorrect="off" autocapitalize="off"
          spellcheck="off" formControlName="usuario" type="text" />
      </mat-form-field>
      <mat-form-field>
        <div class="wrapper-senha">
          <input matInput data-test="gemed-input-senha" #inputSenha id="passwordInput" required type="password"
            tabindex="2" placeholder="{{ 'loginSenhaPlaceHolder' | translate }}" autocomplete="off" autocorrect="off"
            autocapitalize="off" spellcheck="off" formControlName="password" />
          <ip-button-default [tipoDeBotao]="'icon'" (onClick)="handleAlterarVisibilidadeDaSenha()"
            [materialIconName]="nomeLogoPassword" [textoParaPessoasCegas]="'Mostrar/Ocultar senha'"
            [color]="'secondary'">
          </ip-button-default>
        </div>
      </mat-form-field>
      <mat-form-field>
        <input matInput data-test="gemed-input-clinica" required [value]="recuperarUltimaClinicaInformada()" type="text"
          tabindex="3" id="clinicaInput" placeholder="{{ 'loginClinicaPlaceHolder' | translate }}" autocorrect="off"
          autocapitalize="off" spellcheck="off" formControlName="clinica" />
      </mat-form-field>

      <ip-button-default data-test="gemed-button-login" ngClass="btn-full-size" [tipoDeBotao]="'default'"
        [htmlTypeButton]="'submit'" id="submitButton" (onClick)="handleSubmit()" [textoAuxiliar]="'loginEntrar'"
        [textoParaPessoasCegas]="'Logar no sistema'" [color]="'primary'" [disabled]="form.invalid" [fullSize]="true">
      </ip-button-default>
    </div>
  </form>

  <div fxLayout="row" fxLayoutAlign="center">
    <mat-spinner *ngIf="loading | async"></mat-spinner>
  </div>
</div>
