import { Component, Input } from '@angular/core';
import './ip-card.component.less';

@Component({
  selector: 'ip-card',
  template: `
    <mat-card *ngIf="true">
       <mat-card-title *ngIf="subTitulo">{{subTitulo}}</mat-card-title>
       <mat-card-title>{{titulo}}</mat-card-title>
       <mat-card-content>
         <ng-content></ng-content>
       </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      :host {
        position: relative;
        margin: 5px;
      }
      :host mat-card {
        display: flex;
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      :host mat-card-title {
        padding: 0!important;
        display: flex;
        flex: 0 0 40px;
      }
      :host mat-card mat-card-content {
        display: flex;
        flex: 1;
        padding: 0;
      }
    `
  ]
})
export class IPCardComponent {
  @Input() avatar: string;
  @Input() titulo: string;
  @Input() subTitulo: string;
  @Input() imagemCard: string;

  private get preenchidoAvatar(): boolean { return !!this.avatar; }
  private set preenchidoAvatar(value: boolean) { /* */ }

  private get preenchidoSubTitulo(): boolean { return !!this.subTitulo; }
  private set preenchidoSubTitulo(value: boolean) { /* */ }
}
