import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { GemedState } from "@gemed-core/store/state";
import { State, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })

export class GenericInterceptor implements HttpInterceptor {

    constructor(private state: State<GemedState>, private store: Store<GemedState>) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const reqDup = req.clone({
            headers: this.adicionarAuthorizationHeaders(req.headers, req.url)
        });
        return next.handle(reqDup);
    }

    private adicionarAuthorizationHeaders(headers: HttpHeaders, url: string): any {
        let novosHeaders: HttpHeaders = headers;
        const tokenExpirado = this.verificarTokenExpirado();
        const authToken: string = this.obterAuthorizationToken();
        const refreshToken: string = this.obterRefreshToken();
        const sessao = this.obterSessao();

        if (authToken) {
            novosHeaders = novosHeaders.set('Authorization', `${authToken}`);
        }

        if (tokenExpirado) {
            novosHeaders = novosHeaders.set('AuthRefreshToken', `${refreshToken}`);
        }

        if (sessao) {
            novosHeaders = novosHeaders.set('Sessao', sessao);
        }

        return novosHeaders;
    }

    private verificarTokenExpirado(): boolean {
        if (this.state.value.auth.Usuario) {
            return this.state.value.auth.Usuario.TokenExpirado;
        }
    }


    private obterAuthorizationToken(): string {
        const {  Usuario } = this.state.value.auth;
        if (LibGeral.estaPreenchido(Usuario) && LibGeral.estaPreenchido(Usuario.Token)) {
            const token = `Bearer ${this.state.value.auth.Usuario.Token}`;
            return token;
        }
    }

    private obterRefreshToken(): string {
        if (this.state.value.auth.Usuario) {
            return this.state.value.auth.Usuario.RefreshToken;
        }
    }

    private obterSessao(): string {
        if (this.state.value.auth.Usuario) {
            return this.state.value.auth.Usuario.Token;
        }
    }
}