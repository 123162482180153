import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'validaformato'})
export class ValidaFormato implements PipeTransform {
    transform(cellInfo: any, format:string): any {
    
        switch (format) {
            case 'NUMERO':
                return  cellInfo;     
                break;
            case 'LETRA':
                return cellInfo;
                break;
            default:
                return cellInfo;
                break;
        }
    }
    
}