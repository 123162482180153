<section class="container" [fxLayout]="'column'">
  <mat-toolbar style="background: rgb(52, 92, 165)" [color]="'primary'">
    {{ config.Titulo }}
  </mat-toolbar>
  <div class="padding-lateral">
    <div style="margin: 2rem 1rem">
      <p>{{ config.Mensagem }}</p>
    </div>
  </div>
  <div
    [fxLayout]="'row'"
    class="padding-lateral"
    style="margin-top: auto"
    [fxLayoutAlign]="'space-between'"
  >
    <ip-button-default
      [color]="'secondary'"
      (onClick)="handleRecusar()"
      [textoAuxiliar]="config.CancelButton"
    ></ip-button-default>
    <ip-button-default
      (onClick)="handleConfirmar()"
      [textoAuxiliar]="config.OkButton"
    >
    </ip-button-default>
  </div>
</section>
