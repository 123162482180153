<ip-button data-toggle ip-icon class="ip-timepicker-button" [disabled]="!editable">
  <i class="material-icons input-btn">access_time</i>
</ip-button>

<div class="ip-timepicker-container">
  <input data-input class="ip-timepicker-input"
    [disabled]="disabled"
    [id]="inputId"
    readonly="true"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)">

  <label ip-placeholder
    [floating]="_focused || hasValue"
    [for]="inputId"
    [shouldFloat]="floatingPlaceholder">{{ placeholder }}</label>

  <ip-button ip-icon class="ip-timepicker-triangle-button" [disabled]="!editable" (click)="togglePicker()">
    <i class="material-icons input-btn">arrow_drop_down</i>
  </ip-button>
</div>