import { Component, Input, SimpleChange, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import './ip-stick-table-group.component.less';
/**
 * let schema = {
 *  columns: ['Nome', 'Descricao']
 * }
 * <ip-stick-table-group
 * [groupBy]="item => item.Cat.Name"
 * [schema]=""
 * [source]="modelos"
 * >
 * </ip-stick-table-group>
 */
@Component({
  selector: 'ip-stick-table-group',
  template: `
    <ng-content select="ip-header"></ng-content>
    <div flex layout="column" class="ip-ip-stick-table-group--content" (scroll)="onScroll($event)">
      <div *ngFor="let groupItem of innerSource; let i = index">
        <span [hidden]="i === 0" class="ip-stick-table--title">{{groupItem.groupByField}}</span>
        <ip-table-container style="overflow-y: inherit">
          <table ip-table style='table-layout: fixed;'>
            <thead>
              <tr>
                <th *ngFor="let column of schema.columns" ip-column>{{column}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of groupItem.list"
                (selected)="click($event)"
                [ip-row]="item"
              >
                <td ip-cell *ngFor="let column of schema.columns">
                  {{item[column]}}
                </td>
              </tr>
            </tbody>
          </table>
        </ip-table-container>
      </div>
    </div>
  `
})
export class IPStickTableGroupComponent implements OnInit, OnChanges {

  @Input() groupBy: Function | string;
  @Input() source: any[];
  @Input() schema: any;


  /**
   * Evento disparando quando uma linha é selecionada, quando ocorre um click ou tap sobre a linha.
   * @property  {EventEmitter<any>;} onLinhaSelecionada
   * @returns EventEmitter
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLinhaSelecionada: EventEmitter<any>;

  private cacheData: any;
  public innerSource: any[];
  private timer: any;
  private title: HTMLElement;
  constructor() {
    this.onLinhaSelecionada = new EventEmitter();
    this.innerSource = [];
  }

  ngOnInit(): void {
    const target = document.querySelector('ip-stick-table-group > ip-header');
    this.title = <HTMLElement>target.querySelector('ip-title');
    if (!this.title) {
      this.title = document.createElement('ip-title');
      target.appendChild(this.title);
    }
  }
  ngOnChanges(changes: {[propertyName: string]: SimpleChange}): void {
    if ( 'source' in changes) {
      // apply group function
      this.innerSource = _((<any>changes).source.currentValue)
      .groupBy(this.groupBy)
      .toPairs()
      .map(item => _.zipObject(['groupByField', 'list'], item))
      .value();
      if (this.innerSource.length > 0) {
        this.title.innerHTML = this.innerSource[0].groupByField;
      }
    }
  }
  /**
   * Metodo que gera cache dos titulos para optimizar perfomace.
   * @returns void
   */
  cacheDataTitles(): void {
    const target = document.querySelector('ip-stick-table-group > ip-header');
    const elements = document.querySelectorAll('.ip-stick-table--title');
    const allTitle = [];
    for (let i = 0, len = elements.length; i < len; i++) {
      const element = elements[i];
      allTitle.push({
        text: element.textContent.trim(),
        // eslint-disable-next-line no-bitwise
        y: element.getBoundingClientRect().top ^ 0
      });
    }
    this.cacheData = {
      allTitles: allTitle,
      target: target,
      scroll: document.querySelector('.ip-ip-stick-table-group--content')
    };
    // eslint-disable-next-line no-bitwise
    this.cacheData.yTarget = (this.cacheData.target.getBoundingClientRect().top + 30) ^ 0;
  }


  /**
   * Reponsavel pelo controle do scroll mantendo o titulo atualizado com o ultimo que não se encontra mais disponivel.
   * @param  {Event} event
   * @returns void
   */
  onScroll(event: Event): void {
    if (this.cacheData === undefined) {
      this.cacheDataTitles();
    }
    if (this.timer) {
      clearTimeout(this.timer.data.handleId);
      this.timer = null;
    }
    this.timer = setTimeout(_ => {
      // this.titleOutViewPortName = null;
      if (this.cacheData.scroll.scrollTop !== 0) {
        let titlesValid = null;
        const delta = -1;
        for (let i = 0, len = this.cacheData.allTitles.length; i < len; i++) {
          const title = this.cacheData.allTitles[i];
          // |S| => |S| ∈ ℝ | |S| >= 0
          // yt = y[i] + (|S| * - 1)
          const yT = title.y + (this.cacheData.scroll.scrollTop * delta);
          if (yT < this.cacheData.yTarget) {
            titlesValid = title.text;
          }
        }
         this.title.innerHTML = titlesValid;
      }
    }, 50);
  }

  /**
   * Metodo interno invocado quando uma linha é selecionada disparando um evento {onLinhaSelecionada}.
   * @param  {any} linha
   * @returns void
   */
  click(linha: any): void {
    this.onLinhaSelecionada.emit(linha);
  }
}
