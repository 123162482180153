import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AgendaTelemedicinaParticipanteDTO } from 'src/app/assistencial/central-medico/shared/models/AgendaTelemedicina.models';
import { PacienteMinhaAgenda } from 'src/app/assistencial/central-medico/shared/models/pacienteMinhaAgenda.model';
import { TipoParticipante, TipoParticipanteValueApi } from 'src/app/assistencial/central-medico/shared/models/TipoParticipante.enum';
import { take } from "rxjs/operators";
import environment from "src/environments/environment";
import { removerTeleMedicinaSelecionadoAction } from '@pep-assistencial-store/actions/centralmedico.action';


@Component({
  selector: 'ip-teleconsulta',
  templateUrl: './ip-teleconsulta.component.html',
  styleUrls: ['./ip-teleconsulta.component.scss']
})
export class IpTeleconsultaComponent implements OnInit {

  @Input() pacienteTeleconsulta: Observable<any>;
  @ViewChild('iframe') iframe: ElementRef;

  public roomUrl: string;
  public showTeleconsulta: boolean;
  public sizeTeleconsulta: number = 550;
  public sizeAtualTeleconsulta: number = 400;
  private baseDaily: string;

  constructor(
    private _renderer: Renderer2,
    private store: Store<any>,
  ) {
  }

  ngOnInit(): void {
    this.baseDaily = environment.production ? "gemed" : "gemedhomolog";
    this.pacienteTeleconsulta.pipe(take(1)).subscribe(paciente => {
      this.showTeleconsulta = LibGeral.estaPreenchido(paciente);
      if (this.showTeleconsulta) {
        this.mostrarTeleConsulta(paciente);
      }
    });
  }


  // Ação que fecha o popup
  fechar() {
    this.store.dispatch(removerTeleMedicinaSelecionadoAction());
    this.showTeleconsulta = false;
  }

  /**
   * @todo ajustar o grid do componente html e remover tamanhos fixos, para que esse componente seja responsivo.
   *
  */
  mostrarTeleConsulta(agenda: PacienteMinhaAgenda): void {
    const existeSalaAgenda = LibGeral.estaPreenchido(agenda.AgendaTelemedicina);
    const participanteProfissional = agenda.AgendaTelemedicina.Participantes.find((participante: AgendaTelemedicinaParticipanteDTO) => participante.Tipo == TipoParticipanteValueApi.get(TipoParticipante.ProfissionaldaSaude));
    const existeTokenMedico = existeSalaAgenda && LibGeral.estaPreenchido(participanteProfissional)
    if (existeSalaAgenda && existeTokenMedico) {
      this.roomUrl = `https://${this.baseDaily}.daily.co/${agenda.AgendaTelemedicina.CodigoSala}?t=${participanteProfissional.Token}`
    }
  }

  public alterarTamanhoTela(tamanho: number) {
    this.sizeAtualTeleconsulta += tamanho;

    if (this.sizeAtualTeleconsulta > 700) {
      this.sizeAtualTeleconsulta = 700;
    } else if (this.sizeAtualTeleconsulta < 400) {
      this.sizeAtualTeleconsulta = 400;
    }

    this.sizeTeleconsulta = this.sizeAtualTeleconsulta;
  }
}
