import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IPSideNavLayoutComponent } from './ip-side-nav-layout.component';
import { MaterialModule } from '../../../material/material.module';


@NgModule({
  imports: [MaterialModule],
  declarations: [IPSideNavLayoutComponent],
  exports: [IPSideNavLayoutComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class IPSideNavLayoutModule {
}
