export enum Sexo {
    NaoInformado = "",
    Masculino = "M",
    Feminino = "F"
}


export const SexoValueApi = new Map<string, string>([
  [Sexo.NaoInformado, ""],
  [Sexo.Masculino, "Masculino"],
  [Sexo.Feminino, "Feminino"]
]);