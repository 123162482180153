import { Location } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SimNao } from "@gemed-core/enums/gemed.enum";
import { IEvolucoesFiltroDTO, IFiltroIpParametro } from "@gemed-core/interfaces/gemed.interface";
import { IpParametroChave } from "@gemed-core/models/ipParametroChave.model";
import { PrescricaoProgramacaoConfigDTO } from "@gemed-core/models/prescricaoProgramacaoConfigDTO.model";
import { ProtocoloDetalhe } from "@gemed-core/models/protocoloDetalhe.model";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { PrescricaoSituacao } from "src/app/assistencial/central-medico/shared/models/PrescricaoSituacao.enum";
import { ModalComponent } from "src/app/core-ui/components/modal/modal.component";
import { RestApiService } from "../../../../core/http/restApi.service";
import { LibDate } from "../../../../core/libraries/libDate";
import { LibGeral } from "../../../../core/libraries/libGeral";
import { HttpResponse, PagingInfo, RestAPIType } from "../../../../core/models";
import { CID, Paciente } from "../../../shared/models";
import {
  DataPEP,
  PEPAlergia,
  PEPAntropometria,
  PEPDiagnostico,
  PEPDiagnosticoOncologico,
  PEPMedicamentoEmUso,
} from "../models";
@Injectable()
export class PEPService {
  private _servicesRoutes: any;
  private location: Location;
  private termoPesquisaProntuario: string;

  constructor(
    private restApiService: RestApiService,
    location: Location,
    public dialog: MatDialog
  ) {
    this.restApiService = restApiService;
    this.location = location;

    this._servicesRoutes = {
      getAgendas: "RecuperarAgendas",
      getDiagnostico: "RecuperarUltimosDiagnosticosOncologicosDoPaciente",
      getMedicamentosEmUso: "RecuperarUltimosMedicamentosEmUsoDoPaciente",
      getComorbidades: "RecuperarUltimosDiagnosticosDoPaciente",
      getUltimosTratamentos: "UltimosTratamentos",
      getMedidasAntropometricas: "RecuperarAntropometriaDoPaciente",
      getUltimaAntropometriaPaciente: "RecuperarUltimaAntropometriaDoPaciente",
      recuperarUltimaAntropometriaPacienteAteData:
        "RecuperarUltimaAntropometriaAteData",
      getEvolucoes: "RecuperarEvolucoes",
      getEvolucoesRespostas: "RecuperarEvolucoesRespostas",
      obterResumoEvolucoesRespostas: "RecuperarResumoEvolucoes",
      obterTipoQuestionarioPorPacienteEProfissional:
        "RecuperarQuestionariosPorPacienteEProfissional",
      getAlergias: "RecuperarAlergiasDoPaciente",
      getProblemasRiscosSugestao: "Obter",
      getProblemasRiscosPaciente: "Obter",
      getPrescricoes: "RecuperarPrescricoes",
      getVersoesPrescricao: "Versoes",
      SolicitarOrcamento: "SolicitarOrcamento",
      excluirPrescricao: "Delete",
      getHierarquiaExames: "HierarquiaCompletaExames",
      getResultadosExames: "GetListaPorPaciente",
      getResultadosExamesT: "RecuperarPorPacienteMatriz",
      getResultadosExamesD: "RecuperarDescritivos",
      alterarExameResultadoDetalhe: "Alterar",
      getParametro: "RecuperarParametro",
      getParametroEmpresa: "RecuperarParametroEmpresaComValorPadrao",
      obterRelatorioProntuarioPDF: "ObterRelatorioProntuarioPDF",
      obterResumoPaciente: "Obter",
      registrarResumoPaciente: "Registrar",
      gerarProgramacaoPrescricao: "GerarProgramacao",
      recalcularProgramacaoPrescricao: "RecalcularProgramacao",
      recuperarPrescricoesParaEvoluir: "RecuperarPrescricoesParaEvoluir",
      recuperarPrescricoesParaEvoluirPorConfiguracaoQuestionario: "RecuperarPrescricoesParaEvoluirPorConfiguracaoQuestionario",
      recuperarParaEvoluir: "RecuperarParaEvoluir",
      recuperarListaDetalhePorGrupo: "RecuperarListaDetalhePorGrupo",
      recuperaListaEstadiamento: "RecuperarListaEstadiamento",
      recuperarListaPatologiasAtivas: "RecuperarListaAtivas",
      recuperarListaProtocolo: "RecuperaLista",
      recuperarProtocoloCompleto: "Recuperar",
      recuperarTodosMedicamentos: "RecuperarListaComFiltro",
      ObterListaParametro: "ObterLista",
      consultarPEP: "ConsultarPEP",
      ObterImpressaoEvolucao: "ObterImpressaoEvolucao",
      ObterImpressaoEvolucaoFiltroPersonalizado: "ObterImpressaoEvolucaoFiltroPersonalizado"
    };
  }

  obterListaParametros(
    parametros: string[]
  ): Observable<HttpResponse<IpParametroChave[]>> {
    return this.restApiService.Post(
      { IpParamateros: parametros } as IFiltroIpParametro,
      {
        api: RestAPIType.Resources,
        controller: "IpParametro",
        action: this._servicesRoutes.ObterListaParametro,
      }
    );
  }

  getParametro(id: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("Id", id);
    return this.restApiService.Post(
      { Parametro: { IdParametro: id } },
      {
        api: RestAPIType.Resources,
        controller: "IpParametro",
        action: this._servicesRoutes.getParametro,
      }
    );
  }
  getParametroEmpresa(id: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("Id", id);
    return this.restApiService.Post(
      { Parametro: { IdParametro: id } },
      {
        api: RestAPIType.Resources,
        controller: "IpParametro",
        action: this._servicesRoutes.getParametroEmpresa,
      }
    );
  }
  getAgendas(
    idPaciente: number,
    tipoAgenda: string,
    paginacaoObj: IPaginacao,
    requestId: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("id", String(idPaciente));
    params = params.append("tipo", tipoAgenda);

    const pagingInfo = new PagingInfo(
      +paginacaoObj.PageSize,
      +paginacaoObj.PageNumber
    );

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes.getAgendas,
        pagingInfo: pagingInfo,
        identificador: requestId,
      },
      params
    );
  }

  obterPrescricoes(prescricaoFiltro: { paciente: Paciente, Situacao: PrescricaoSituacao }) {
    return this.restApiService.Post(prescricaoFiltro, {
      api: RestAPIType.Resources,
      controller: "Prescricao",
      action: this._servicesRoutes.consultarPEP
    });
  }


  getPrescricoes(
    idPaciente: number,
    status: string,
    paginacaoObj: IPaginacao,
    requestId?: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("id", String(idPaciente));
    params = params.append("status", status);

    const pagingInfo = new PagingInfo(
      +paginacaoObj.PageSize,
      +paginacaoObj.PageNumber
    );

    return this.restApiService.Get(
      {
        identificador: requestId,
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: this._servicesRoutes.getPrescricoes,
        pagingInfo: pagingInfo,
      },
      params
    );
  }

  getListaEstadiamento(): Observable<HttpResponse<any>> {
    return this.restApiService.Get({
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.recuperaListaEstadiamento,
    });
  }

  buscaPrescricao(idPrescricao: number, IdUsuario: number) {
    if (IdUsuario <= 0 || idPrescricao <= 0) return;

    return this.restApiService.Post(
      { Prescricoes: [{ idPrescricao }], Usuario: { IdUsuario } },
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action:
          idPrescricao > 0
            ? "CarregamentoEdicaoPrescricao"
            : "CarregamentoNovaPrescricao",
      }
    );
  }

  solicitarOcamento(prescricao: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(prescricao, {
      api: RestAPIType.Resources,
      controller: "Prescricao",
      action: this._servicesRoutes.SolicitarOrcamento,
    });
  }

  getVersoesPrescricoes(idPrescricao: number): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("id", String(idPrescricao));
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: this._servicesRoutes.getVersoesPrescricao,
      },
      params
    );
  }

  getProtocoloDetalhe(
    idProtocolo: number,
    idGrupo: number
  ): Promise<ProtocoloDetalhe[]> {
    let params = new HttpParams();
    params = params.append("IdProtocolo", String(idProtocolo));
    params = params.append("IdProtocoloGrupo", String(idGrupo));

    return new Promise((resolve, reject) => {
      this.restApiService
        .Get(
          {
            api: RestAPIType.Resources,
            controller: "Protocolo",
            action: this._servicesRoutes.recuperarListaDetalhePorGrupo,
          },
          params
        )
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          (error) => reject(error)
        );
    });
  }

  getProtocoloCompleto(protocolo): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("Id", String(protocolo.IdProtocolo));
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Protocolo",
        action: this._servicesRoutes.recuperarProtocoloCompleto,
      },
      params
    );
  }

  getAlergias(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.getAlergias,
    });
  }

  mapAlergia(alergiaJson: any): PEPAlergia {
    const pepAlergia = <PEPAlergia>Object.assign(new PEPAlergia(), alergiaJson);

    pepAlergia.DataInicio = new DataPEP(
      new Date(alergiaJson.DataInicio.Data),
      null,
      alergiaJson.DataInicio.Texto
    );

    if (LibGeral.estaPreenchido(alergiaJson.DataFim)) {
      pepAlergia.DataFim = new DataPEP(
        new Date(alergiaJson.DataFim.Data),
        null,
        alergiaJson.DataFim.Texto
      );
    }

    pepAlergia.Nega =
      alergiaJson.Nega === "Sim" || alergiaJson.Nega === "S"
        ? SimNao.Sim
        : SimNao.Nao;

    return pepAlergia;
  }

  getProblemasRiscosSugestao(tipo: string): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("tipo", tipo);
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "PEPProblemaSugestao",
        action: this._servicesRoutes.getProblemasRiscosSugestao,
      },
      params
    );
  }
  getProblemasRiscosPaciente(
    idPaciente: number,
    tipo: string,
    status: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("idPaciente", String(idPaciente));
    params = params.append("tipo", tipo);
    params = params.append("status", String(status));

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "PEPProblema",
        action: this._servicesRoutes.getProblemasRiscosPaciente,
      },
      params
    );
  }
  getHierarquiaExames(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "ExameMarcador",
      action: this._servicesRoutes.getHierarquiaExames,
    });
  }


  getDiagnosticoAsync(idPaciente: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.restApiService.GetById(idPaciente, {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes.getDiagnostico,
      })
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          (error) => reject(error)
        )
    });
  }

  getDiagnostico(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.getDiagnostico,
    });
  }
  mapDiagnosticoOncologico(diagnostico: any): PEPDiagnosticoOncologico {

    const diagnosticoOnco = new PEPDiagnosticoOncologico();
    Object.assign(diagnosticoOnco, diagnostico);

    diagnosticoOnco.Tipo = "O";

    diagnosticoOnco.CID = new CID();
    Object.assign(diagnosticoOnco.CID, diagnostico.CID);

    diagnosticoOnco.DataInicio = new DataPEP(
      new Date(diagnostico.DataInicio.Data),
      null,
      diagnostico.DataInicio.Texto
    );

    if (LibGeral.estaPreenchido(diagnostico.DataFim)) {
      diagnosticoOnco.DataFim = new DataPEP(
        new Date(diagnostico.DataFim.Data),
        null,
        diagnostico.DataFim.Texto
      );
    }

    return diagnosticoOnco;
  }

  getComorbidades(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.getComorbidades,
    });
  }
  mapComorbidades(dataComorbidade: any): PEPDiagnostico {
    const comorbidade = new PEPDiagnostico();
    Object.assign(comorbidade, dataComorbidade);

    comorbidade.Tipo = "C";

    comorbidade.CID = new CID();
    if (LibGeral.estaPreenchido(dataComorbidade.CID)) {
      Object.assign(comorbidade.CID, dataComorbidade.CID);
    }

    if (
      LibGeral.estaPreenchido(dataComorbidade.DataInicio) &&
      LibGeral.estaPreenchido(dataComorbidade.DataInicio.Data)
    ) {
      comorbidade.DataInicio = new DataPEP(
        new Date(dataComorbidade.DataInicio.Data),
        null,
        dataComorbidade.DataInicio.Texto
      );
    }

    if (
      LibGeral.estaPreenchido(dataComorbidade.DataFim) &&
      LibGeral.estaPreenchido(dataComorbidade.DataFim.Data)
    ) {
      comorbidade.DataFim = new DataPEP(
        new Date(dataComorbidade.DataFim.Data),
        null,
        dataComorbidade.DataFim.Texto
      );
    }

    return comorbidade;
  }

  getMedicamentosEmUso(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.getMedicamentosEmUso,
    });
  }
  mapMedicamentosEmUso(pepMedicamentoEmUso: any): PEPMedicamentoEmUso {
    const medicamentoEmUso = Object.assign(
      new PEPMedicamentoEmUso(),
      pepMedicamentoEmUso
    );
    if (pepMedicamentoEmUso.DataInicio) {
      medicamentoEmUso.DataInicio = new DataPEP(
        new Date(pepMedicamentoEmUso.DataInicio.Data),
        null,
        pepMedicamentoEmUso.DataInicio.Texto
      );
    }
    if (LibGeral.estaPreenchido(pepMedicamentoEmUso.DataFim)) {
      medicamentoEmUso.DataFim = new DataPEP(
        new Date(pepMedicamentoEmUso.DataFim.Data),
        null,
        pepMedicamentoEmUso.DataFim.Texto
      );
    }
    return medicamentoEmUso;
  }

  getUltimosTratamentos(idPaciente: number): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("IdPaciente", String(idPaciente));
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: this._servicesRoutes.getUltimosTratamentos,
      },
      params
    );
  }
  mapUltimosTratamentos(tratamento: any): any {
    tratamento.Data = new Date(tratamento.Data);
    tratamento.DataInicio = LibDate.FormatarData(tratamento.Data);
    tratamento.DataFim = LibDate.FormatarData(tratamento.DataFim);
    return tratamento;
  }
  mapMedidasAntropometricas(medidaAntropometrica: any): any {
    // eslint-disable-next-line max-len
    medidaAntropometrica.DataInicio = new DataPEP(
      new Date(medidaAntropometrica.DataInicio.Data),
      null,
      medidaAntropometrica.DataInicio.Texto
    );
    return medidaAntropometrica;
  }

  getResultadosExames(paciente: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(paciente, {
      api: RestAPIType.Resources,
      controller: "ExameResultado",
      action: this._servicesRoutes.getResultadosExames,
    });
  }

  getListaPatologiasAtivas(): Observable<HttpResponse<any>> {
    return this.restApiService.Get({
      api: RestAPIType.Resources,
      controller: "Patologia",
      action: this._servicesRoutes.recuperarListaPatologiasAtivas,
    });
  }

  getResultadosExamesTabulares(filtro: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(filtro, {
      api: RestAPIType.Resources,
      controller: "ExameResultado",
      action: this._servicesRoutes.getResultadosExamesT,
    });
  }

  alterarExameResultadoDetalhe(exame: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(exame, {
      api: RestAPIType.Resources,
      controller: "ExameResultadoDetalhe",
      action: this._servicesRoutes.alterarExameResultadoDetalhe,
    });
  }

  getResultadosExamesDescritivos(filtro: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(filtro, {
      api: RestAPIType.Resources,
      controller: "ExameResultado",
      action: this._servicesRoutes.getResultadosExamesD,
    });
  }

  getMedidasAntropometricas(idPaciente: number): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Antropometria",
      action: this._servicesRoutes.getMedidasAntropometricas,
    });
  }

  getUltimaAntropometriaPaciente(
    idPaciente: number
  ): Observable<HttpResponse<any>> {
    return this.restApiService.GetById(idPaciente, {
      api: RestAPIType.Resources,
      controller: "Antropometria",
      action: this._servicesRoutes.getUltimaAntropometriaPaciente,
    });
  }

  recuperarUltimaAntropometriaPacienteAteData(
    idPaciente: number,
    dataLimite: Date
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("idPaciente", `${idPaciente}`);
    params = params.append("dataLimite", `${dataLimite}`);
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Antropometria",
        action: this._servicesRoutes
          .recuperarUltimaAntropometriaPacienteAteData,
      },
      params
    );
  }

  async RecuperarUltimaAntropometriaDoPaciente(idPrescricao: number): Promise<PEPAntropometria> {
    let params = new HttpParams();
    params = params.append("id", String(idPrescricao));

    return new Promise((resolve) => {
      this.restApiService
        .Get(
          {
            api: RestAPIType.Resources,
            controller: "Antropometria",
            action: this._servicesRoutes
              .getUltimaAntropometriaPaciente,
          },
          params
        )
        .pipe(take(1))
        .subscribe(
          async (retornoApi) => {
            const parsedAndResolved = await LibGeral.ParseAndResolve(JSON.stringify(retornoApi.Data));
            resolve(parsedAndResolved);
          });
    });
  }

  // eslint-disable-next-line max-len
  obterResumoEvolucoesResposta(
    idPaciente: Number,
    categorias: Array<string>,
    questionarios: Array<number>,
    paginacaoObj: IPaginacao,
    requestId?: string
  ): Observable<HttpResponse<any>> {
    let filtro = new HttpParams();
    filtro = filtro.append("IdPaciente", `${idPaciente}`);
    Array.from(categorias).forEach((categaoria) => {
      filtro = filtro.append("Categorias", categaoria);
    });
    Array.from(questionarios).forEach((questionario) => {
      filtro = filtro.append("Questionarios", questionario.toString());
    });
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes.obterResumoEvolucoesRespostas,
        pagingInfo: new PagingInfo(
          +paginacaoObj.PageSize,
          +paginacaoObj.PageNumber
        ),
        identificador: requestId,
      },
      filtro
    );
  }

  obterTipoQuestionarioPorPacienteEProfissional(
    idPaciente: number
  ): Observable<HttpResponse<any>> {
    let filtro = new HttpParams();
    filtro = filtro.append("IdPaciente", idPaciente.toString());
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes
          .obterTipoQuestionarioPorPacienteEProfissional,
      },
      filtro
    );
  }

  getEvolucoes(
    idPaciente: Number,
    categorias: Array<string>,
    questionarios: Array<number>,
    paginacaoObj: IPaginacao,
    requestId?: string
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("IdPaciente", String(idPaciente));

    categorias.forEach((categoria) => {
      params = params.append("Categorias", categoria);
    });

    questionarios.forEach((questionario) => {
      params = params.append("Questionarios", questionario.toString());
    });

    const pagingInfo = new PagingInfo(
      +paginacaoObj.PageSize,
      +paginacaoObj.PageNumber
    );
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes.getEvolucoes,
        pagingInfo: pagingInfo,
        identificador: requestId,
      },
      params
    );
  }

  // TODO: Remover
  getEvolucoesRespostas(
    data: any,
    requestId?: string
  ): Observable<HttpResponse<any>> {
    return this.restApiService.Post(JSON.stringify(data), {
      api: RestAPIType.Resources,
      controller: "Prontuario",
      action: this._servicesRoutes.getEvolucoesRespostas,
      ContentType: "json",
      identificador: requestId,
    });
  }
  obterRelatorioProntuarioPDF(filtro: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(JSON.stringify(filtro), {
      api: RestAPIType.Resources,
      controller: "RelatorioWeb",
      action: this._servicesRoutes.obterRelatorioProntuarioPDF,
      ContentType: "json",
    });
  }

  obterListaProtocolos(filtroProtocolos: any): Observable<HttpResponse<any>> {
    return this.restApiService.Post(JSON.stringify(filtroProtocolos), {
      api: RestAPIType.Resources,
      controller: "Protocolo",
      action: this._servicesRoutes.recuperarListaProtocolo,
      ContentType: "json",
    });
  }
  obterListaMedicamentos(
    filtroMedicamentos: any
  ): Observable<HttpResponse<any>> {
    return this.restApiService.Post(JSON.stringify(filtroMedicamentos), {
      api: RestAPIType.Resources,
      controller: "Medicamento",
      action: this._servicesRoutes.recuperarTodosMedicamentos,
      ContentType: "json",
    });
  }

  setValorPesquisa(valor: string): void {
    this.termoPesquisaProntuario = valor;
  }

  /**
   * Obtem termo pesquisado no prontuário e após ler valor o seta para nulo.
   */
  getValorPesquisa(): string {
    const valor = this.termoPesquisaProntuario;
    this.termoPesquisaProntuario = null;
    return valor;
  }

  obterResumoPaciente(idPaciente: Number): Observable<any> {
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "PacienteResumo",
        action: "Obter",
      },
      new HttpParams().set("idPaciente", idPaciente.toString())
    );
  }

  obterUltimaEvolucao(
    idPaciente: Number,
    categoriaProfissional: String
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append("IdPaciente", String(idPaciente));
    params = params.append("Categorias", String(categoriaProfissional));

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: "RecuperarEvolucoes",
      },
      params
    );
  }

  obterInfomacoesResumoPaciente(idPaciente: Number): Observable<any> {
    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "PacienteResumo",
        action: "Obter",
      },
      new HttpParams().set("idPaciente", idPaciente.toString())
    );
  }

  saveResumoPaciente(
    idPaciente: number,
    data: Date,
    resumo: string,
    requestId?: string
  ): Observable<HttpResponse<any>> {
    const resumoPaciente = {
      IdPaciente: idPaciente,
      Data: data,
      Resumo: resumo,
    };

    return this.restApiService.Post(JSON.stringify(resumoPaciente), {
      api: RestAPIType.Resources,
      controller: "PacienteResumo",
      action: this._servicesRoutes.registrarResumoPaciente,
      ContentType: "json",
      identificador: requestId,
    });
  }

  gerarProgramacaoPrescricao(
    filtroProgramacaoCiclosDTO: PrescricaoProgramacaoConfigDTO
  ): Observable<HttpResponse<any>> {
    return this.restApiService.Post(
      JSON.stringify(filtroProgramacaoCiclosDTO),
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: this._servicesRoutes.gerarProgramacaoPrescricao,
        ContentType: "json",
      }
    );
  }

  recalcularProgramacaoPrescricao(
    listProgramacao: any[],
    programacaoAlterada: any,
    isEdicao: boolean
  ): Observable<HttpResponse<any>> {
    const paramsProgramacao = {
      listProgramacao: listProgramacao,
      programacaoAlterada: programacaoAlterada,
      isEdicao: isEdicao,
      index: programacaoAlterada.Index,
    };

    return this.restApiService.Post(JSON.stringify(paramsProgramacao), {
      api: RestAPIType.Resources,
      controller: "Prescricao",
      action: this._servicesRoutes.recalcularProgramacaoPrescricao,
      ContentType: "json",
    });
  }

  obterPrescricoesNaoAssinadasParaEvoluir(
    idPaciente: number,
    idQuestionario: number,
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("IdPaciente", String(idPaciente));

    if (idQuestionario > 0) {
      params = params.append("IdQuestionario", String(idQuestionario));
    }

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: idQuestionario === 0
          ? this._servicesRoutes.recuperarPrescricoesParaEvoluir
          : this._servicesRoutes.recuperarPrescricoesParaEvoluirPorConfiguracaoQuestionario,
      },
      params
    );
  }

  ObterImpressaoEvolucao(idPaciente: number, idQuestionarioResposta: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.restApiService
        .Post({ idPaciente: idPaciente, IdQuestionarioResposta: idQuestionarioResposta }, {
          api: RestAPIType.Resources,
          controller: "Relatorio",
          action: this._servicesRoutes.ObterImpressaoEvolucao,
        })
        .pipe(take(1))
        .subscribe(
          async result =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          error => reject(error)
        );
    });
  }

  ObterImpressaoEvolucaoFiltroPersonalizado(idPaciente: number, Categorias: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.restApiService
        .Post({ idPaciente: idPaciente, Categorias: Categorias }, {
          api: RestAPIType.Resources,
          controller: "Relatorio",
          action: this._servicesRoutes.ObterImpressaoEvolucaoFiltroPersonalizado,
        })
        .pipe(take(1))
        .subscribe(
          async result =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          error => reject(error)
        );
    });
  }

  async obterListaEvolucoesParaImpressao(filtro: IEvolucoesFiltroDTO) {

    const { IdQuestResposta, IdPaciente, Categorias } = filtro;

    let httpParams = new HttpParams();

    httpParams = httpParams.append("ModoImpressao", "true");
    httpParams = httpParams.append("IdPaciente", IdPaciente.toString());

    if (LibGeral.estaPreenchido(Categorias)) {
      httpParams = httpParams.append("Categorias", Categorias.join(",").toString());
    }


    if (LibGeral.estaPreenchido(IdQuestResposta)) {
      httpParams = httpParams.append("IdQuestResposta", IdQuestResposta.toString());
    }

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prontuario",
        action: this._servicesRoutes.obterResumoEvolucoesRespostas,
      },
      httpParams
    )
      .pipe(
        map(response => response.Data as string)
      )
      .toPromise();
  }

  recuperarParaEvoluir(idPrescricao: number): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("id", String(idPrescricao));

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: "Prescricao",
        action: this._servicesRoutes.recuperarParaEvoluir,
      },
      params
    );
  }

  criaOuAtualizaModalDinamico(propridades: any) {
    const componentRef = this.dialog.open(ModalComponent, {
      data: propridades,
      hasBackdrop: false,
    });

    componentRef.componentInstance.onFechar.subscribe((deveFechar) =>
      deveFechar ? componentRef.close() : null
    );
  }

  getParametroEmpresaAsync(id: string): Promise<any> {
    let params = new HttpParams();
    params = params.append("Id", id);

    return new Promise((resolve, reject) => {
      this.restApiService.Post(
        { Parametro: { IdParametro: id } },
        {
          api: RestAPIType.Resources,
          controller: "IpParametro",
          action: this._servicesRoutes.getParametro,
        }
      )
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data)))
          ,
          (error) => reject(error)
        );
    });
  }

}
