import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse as IPHttpRensponse, HttpHeaders, RestAPIType, HttpRequestConfig, RestApiRequestConfig, IPError } from '../models';
import { GemedState, LibState } from '../store/state';
import { State, Store } from '@ngrx/store';
import { LogInErroAction } from '../store/actions/auth.actions';
import { FinalizarRequestAction, IniciarRequestAction } from '../store/actions/request.actions';
import { LibGeral } from '@gemed-core/libraries/libGeral';

// !LibString.isNullOrEmpty(this.identificador);
//  this.store.dispatch(new FinalizarRequestAction({ identificador: config.getIdentificador(), response }));

const possuiIdentificador = ({ headers }) => headers.has('identificador');
const obterIdentificador = ({ headers }) => headers.get('identificador');

@Injectable({ providedIn: 'root' })
export class IPHttpBaseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (possuiIdentificador(request)) {
      this.store.dispatch(new IniciarRequestAction(obterIdentificador(request)));
    }
    const newRequest = request.clone({
      headers: this.adicionarAuthorizationHeaders(request.headers, request.url)
    });
    return next
      .handle(newRequest)
      .pipe(map((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          const urlsToIgnore = /(\/graphql)|(\/assets\/)/gi;
          if (!urlsToIgnore.test(ev.url)) {
            ev = ev.clone({
              body: this.mapToHttpResponse<any>(ev)
            });
          }
        }
        return ev;
      }))
      .pipe(map(response => {
        if (possuiIdentificador(request)) {
          this.finalizarRequest(response, obterIdentificador(request));
        }
        return response;
      }))
      .pipe(catchError(response => {
        const erro = new IPError('GemedWeb', new Date(), response.error, response);

        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            erro.Menssagem = `${erro.Menssagem}`;
          }
        }

        if(LibGeral.estaEmBranco(erro.Menssagem)){
          return;
        }

        return throwError(erro);
      }));
  }
  private mapToHttpResponse<T>(response: HttpResponse<any>): IPHttpRensponse<T> {
    const mappedResponse = {} as any;
    mappedResponse.Status = response.status;
    mappedResponse.StatusText = response.statusText;
    mappedResponse.Data = response.body;
    const cloneHeaders = new HttpHeaders();
    response.headers.keys().forEach(key => {
      cloneHeaders.append(key.toLowerCase(), response.headers.get(key));
    });
    mappedResponse.Headers = cloneHeaders;
    return mappedResponse;
  }
  private adicionarAuthorizationHeaders(headers: any, url: string): any {
    let novosHeaders: any = headers;

    const tokenExpirado = this.verificarTokenExpirado();
    const authToken: string = this.obterAuthorizationToken();
    const refreshToken: string = this.obterRefreshToken();
    const sessao = this.obterSessao();

    if (authToken) {
      novosHeaders = novosHeaders.set('Authorization', `${authToken}`);
    }

    if (tokenExpirado) {
      novosHeaders = novosHeaders.set('AuthRefreshToken', `${refreshToken}`);
    }

    if (sessao) {
      novosHeaders = novosHeaders.set('Sessao', sessao);
    }

    return novosHeaders;
  }

  private verificarTokenExpirado(): boolean {
    if (this.state.value.auth.Usuario) {
      return this.state.value.auth.Usuario.TokenExpirado;
    }
  }


  private obterAuthorizationToken(): string {
    const { Usuario } = this.state.value.auth;
    if (!!Usuario && !!Usuario.Token) {
      const token = `Bearer ${this.state.value.auth.Usuario.Token}`;
      return token;
    }
  }

  private obterRefreshToken(): string {
    if (this.state.value.auth.Usuario) {
      return this.state.value.auth.Usuario.RefreshToken;
    }
  }
  private obterNomeCliente(): string {
    let nomeCliente = "";
    if (this.state.value.auth.Usuario) {
      const token = this.state.value.auth.Usuario.Token;
      nomeCliente = atob(token).split('|')[1];
    }
    return nomeCliente;
  }

  private obterSessao(): string {
    if (this.state.value.auth.Usuario) {
      return this.state.value.auth.Usuario.Token;
    }
  }
  private finalizarRequest(response: any, identificador: any): void {
    this.store.dispatch(new FinalizarRequestAction({ identificador: identificador, response }));
  }
  constructor(private state: State<GemedState>, private store: Store<GemedState>) { }
}
