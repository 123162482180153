
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import environment from "@environment";
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { IpPreviewFileComponent } from '../ip-preview-file/ip-preview-file.component';

@Component({
  selector: 'ip-carousel',
  templateUrl: './ip-carousel.component.html',
  styleUrls: ['./ip-carousel.component.scss']
})
export class IPCarouselComponent implements OnInit {

  @Input() listaItems: Array<any> = [];
  @Input() mostrarBtnClose = true;

  @Output() listaItemChange: EventEmitter<any> = new EventEmitter();
  @Output() arquivoSelecionado: EventEmitter<any> = new EventEmitter();

  DialogRef: MatDialogRef<IpPreviewFileComponent>;

  public urlPdfImage;

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.urlPdfImage = this.sanitizer.bypassSecurityTrustUrl(`${environment.domain}assets/images/farma/pdf.png`);
  }

  remover(refId) {

    const listaAtualizada = [...this.listaItems.filter(item => item.id != refId)];

    this.listaItemChange.emit({ files: listaAtualizada, updateAll: true });

  }

  /**
  * Abre modal contendo o arquivo passado por parâmetro.
  * @param {object} data ojeto contendo dados do arquivo a ser renderizado num modal de exibição.
  */
  abrirPreviewArquivoSelecionado(data): void {
    if (!!data.result) {
      const fileType = LibGeral.formatoFile(data.extension);
      this.DialogRef = this.dialog.open(IpPreviewFileComponent, {
        panelClass: 'dialog-examepreview',
        data: { imageBase64: data.result, id: data.id, type: fileType }
      });
    }
  }

  public onSlideChange(index: any) {
    this.arquivoSelecionado.emit(this.listaItems[index]);
  }

  public onSwiper(swiper: any) {
    this.onSlideChange(0);
  }

}
