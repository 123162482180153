import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { IpToastComponent } from "../../core-ui/components/toast/ip-toast-default.component";
import { TranslateService } from "@ngx-translate/core";
// import 'toastr/build/toastr.min.css';
// import 'expose-loader?toastr!toastr';

const POSICAO_TOPO_DIREITA = "toast-top-right";
const POSICAO_BAIXO_DIREITA = "toast-bottom-right";
const POSICAO_BAIXO_ESQUERDA = "toast-bottom-left";
const POSICAO_TOPO_ESQUERDA = "toast-top-left";
const POSICAO_TOPO_COMPLETO = "toast-top-full";
const POSICAO_BAIXO_COMPLETO = "toast-bottom-full";

export enum ToastPosicao {
  TopoDireita,
  BaixoDireita,
  BaixoEsquerda,
  TopoEsquerda,
  TopoCompleto,
  BaixoCompleto,
}

export enum ToastTipo {
  Sucesso,
  Info,
  Erro,
  Aviso,
}

/**
 * Classe que fornece o serviço de messagens toast
 *
 * @export
 * @class IPToastService
 */
@Injectable({
  providedIn: "root",
})
export class IPToastService {
  constructor(public snackBar: MatSnackBar, private tradutor: TranslateService) {}

  sucesso(
    mensagem: string,
    titulo?: string,
    optionsOverride?: IPToastOptionsLite
  ): void {
    this.show(<IPToastOptions>{
      mensagem: this.tradutor.instant(mensagem),
      tipo: ToastTipo.Sucesso,
      titulo: titulo,
      ...optionsOverride,
    });
  }
  info(
    mensagem: string,
    titulo?: string,
    optionsOverride?: IPToastOptionsLite
  ): void {
    this.show(<IPToastOptions>{
      mensagem: this.tradutor.instant(mensagem),
      tipo: ToastTipo.Info,
      titulo: titulo,
      ...optionsOverride,
    });
  }
  erro(
    mensagem: string,
    titulo?: string,
    optionsOverride?: IPToastOptionsLite
  ): void {
    this.show(<IPToastOptions>{
      mensagem: this.tradutor.instant(mensagem),
      tipo: ToastTipo.Erro,
      titulo: titulo,
      ...optionsOverride,
    });
  }
  aviso(
    mensagem: string,
    titulo?: string,
    optionsOverride?: IPToastOptionsLite
  ): void {
    this.show(<IPToastOptions>{
      mensagem: this.tradutor.instant(mensagem),
      tipo: ToastTipo.Aviso,
      titulo: titulo,
      ...optionsOverride,
    });
  }
  show(options: IPToastOptions): void {
    Promise.resolve().then(() => {
      this.snackBar.openFromComponent(IpToastComponent, {
        duration: 10000,
        data: options,
        panelClass: ["toastCustomizeCss"],
      });
    });
  }

  private obterClassePosicionamento(posicao: ToastPosicao): string {
    switch (posicao) {
      case ToastPosicao.TopoDireita:
        return POSICAO_TOPO_DIREITA;
      case ToastPosicao.BaixoDireita:
        return POSICAO_BAIXO_DIREITA;
      case ToastPosicao.BaixoEsquerda:
        return POSICAO_BAIXO_ESQUERDA;
      case ToastPosicao.TopoEsquerda:
        return POSICAO_TOPO_ESQUERDA;
      case ToastPosicao.TopoCompleto:
        return POSICAO_TOPO_COMPLETO;
      case ToastPosicao.BaixoCompleto:
        return POSICAO_BAIXO_COMPLETO;
      default:
        return undefined;
    }
  }

  private criarObjetoOpcoesToastr(options: IPToastOptions): ToastrOptions {
    const toartrOptions = <ToastrOptions>{
      positionClass: this.obterClassePosicionamento(options.posicao),
      closeButton: options.botaoFechar,
      progressBar: options.barraDeProgresso,
      showDuration: options.tempoApresentacao,
    };

    return toartrOptions;
  }
}



export interface IPToastOptionsLite {
  posicao?: ToastPosicao;
  botaoFechar?: boolean;
  barraDeProgresso?: boolean;
  tempoApresentacao?: number;
}

export interface IPToastOptions extends IPToastOptionsLite {
  mensagem: string;
  tipo: ToastTipo;
  titulo?: string;
}

interface ToastrOptions {
  closeButton?: boolean;
  progressBar?: boolean;
  positionClass: string;
}
