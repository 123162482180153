import { Component, OnDestroy, OnInit } from "@angular/core";
import environment from "@environment";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { isAuthenticated } from "@gemed-core/store/reducers/auth.reducer";
import { GemedState } from "@gemed-core/store/state";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  public estaAutenticado$: Observable<boolean>;
  public version: string = LibGeral.estaPreenchido(environment.version) ? environment.version : "localhost";

  constructor(private store: Store<GemedState>) {

  }

  ngOnInit(): void {

    this.estaAutenticado$ = this.store.select(isAuthenticated);
  }


}
