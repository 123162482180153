import { Record } from 'immutable';
import { IAppErro, IAppMensagem } from 'ip_types_ts/common-typings';

export class AppState {
  erros: IAppErro[];
  mensagens: IAppMensagem[];
  Online = true;
  mostrarModal: boolean = false;

  constructor() {
    this.erros = [];
    this.mensagens = [];
  }
}

export const AppStateFactory = function (): AppState {
  const appStateRecord = new (<any>Record(new AppState()));
  return appStateRecord;
};
