import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { GemedState } from '../../../core/store/state';
import { ChangeURLAction } from '../../../core/store/actions/route.actions';
import { obterNomeUsuario, obterDescricaoProfissional } from '../../../core/store/libraries';
import { LibGeral } from '@gemed-core/libraries/libGeral';
 

@Component({
  selector: 'ip-menu-lateral',
  templateUrl: './ip-menu-lateral.component.html',
  styleUrls: ['./ip-menu-lateral.component.scss']
})
export class IPMenuLateralComponent {

  @Input() systemMenuOptions: any[];
  @Input() sairSistema: Function;
  @Input() userOptions: any[];
  @Output() opcaoSelecionada: EventEmitter<boolean>;

  public isHiddenMenuUser: boolean;
  public route: Router;

  public NomeUsuario$: Observable<string>;
  public categoriaProfissional$: Observable<string>;

  constructor(private store: Store<GemedState> ,  route: Router) {
    this.route = route;
    this.isHiddenMenuUser = true;
    this.opcaoSelecionada = new EventEmitter<boolean>();
    this.NomeUsuario$ = this.store.select(obterNomeUsuario);
    this.categoriaProfissional$ = this.store.select(obterDescricaoProfissional);
  }
  changeStatusMenuUser(): void {
    this.isHiddenMenuUser = !this.isHiddenMenuUser;
  }
  go(option: any): void {
    this.opcaoSelecionada.emit(true);
    this.store.dispatch(new ChangeURLAction(option.Rota));
  }
  getDisplay(item: any): string {
    return item.Descricao;
  }

  getChildrens(item: any): any[] {
    let existeSubmenu =LibGeral.estaPreenchido(item.Submenu);
    existeSubmenu = existeSubmenu &&  item.Submenu.length > 0;
    return (existeSubmenu) ? item.Submenu : [];
  }

  sair(): void {
    if (this.sairSistema) {
      this.sairSistema();
    }
  }
}
