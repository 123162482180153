import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IPConfirmDialogConfig } from "@gemed-core/dialog/ipDialog.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { State, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AssistencialState } from "@pep-assistencial-store/state/assistencial.state";
import { combineLatest, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IPConfirmDialogComponent } from "src/app/core-ui/components/dialog";
import { ActionTypes } from "../actions/app.actions";
import { toPayload, verficaSeDeveAbrirModal } from "../libraries";

@Injectable({
  providedIn: "root",
})
export class AppEffects {
  @Effect({ dispatch: false })
  public ApresentarAviso = this.actions
    .pipe(ofType(ActionTypes.APRESENTAR_MENSAGEM))
    .pipe(map(toPayload))
    .pipe(map((payload: any) => {}));
  @Effect({ dispatch: false })
  public ApresentarNotificacao = this.actions
    .pipe(ofType(ActionTypes.APRESENTAR_NOTIFICACAO))
    .pipe(map(toPayload))
    .pipe(map((payload: any) => {}));
  @Effect({ dispatch: false })
  public RegistrarErro = this.actions
    .pipe(ofType(ActionTypes.REGISTRAR_ERRO))
    .pipe(map(toPayload))
    .pipe(map((payload: any) => {}));

  @Effect({ dispatch: false })
  public ConfirmaAcao = this.actions
    .pipe(ofType(ActionTypes.MODAL_CONFIRMA_ACAO))
    .pipe(
      map((x) => {
        return combineLatest(
          of(x),
          this.tradutor.get(
            "prontuario.avisoTrocaDePacienteComQuestionarioAberto"
          )
        );
      }),
      switchMap((x) => x),
      map((x) => {
        const payload = (<any>x[0]).payload;
        const deveAbrirModal = verficaSeDeveAbrirModal(this.state.value);

        if (deveAbrirModal) {
          const textosParts = x[1].split("@");
          const config = new IPConfirmDialogConfig(
            textosParts[0],
            textosParts[1],
            textosParts[2],
            textosParts[3]
          );
          const dialog = this.dialogService.open(IPConfirmDialogComponent, {
            disableClose: true,
          });
          dialog.componentInstance.config = config;
          dialog.componentInstance.CancelAction = () => {
            dialog.close();
          };
          dialog.componentInstance.ConfirmAction = () => {
            this.store.dispatch(payload);
            dialog.close();
          };

          return;
        }

        this.store.dispatch(payload);
      })
    );

  constructor(
    private actions: Actions,
    private dialogService: MatDialog,
    private state: State<AssistencialState>,
    private store: Store<any>,
    private tradutor: TranslateService
  ) {}
}
