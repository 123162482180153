// Referência: https://getmdl.io/components/index.html#tables-section

import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[ip-column]',
  // eslint-disable-next-line
  host: {
    '[class.mdl-data-table__cell--non-numeric]': '!_number',
    '[class.wrap-content]': '_wrap'
  }
})
export class IPColumnDirective implements OnInit {
  /**
   * Define se a celula pode aumentar sua altura caso o conteúdo não caiba em uma linha.
   * Default: TRUE.
   * @type {boolean}
   */
  @Input() set wrap(value: boolean) { this._wrap = !!value; }

  /**
   * Define se o conteúdo da cell será numérico, para definir o alinhamento do mesmo.
   * Para conteúdo numérico o conteúdo é alinhado a direita.
   * @type {boolean}
   */
  @Input() set number(value: boolean) { this._number = !!value; }

  private _number = false;
  private _wrap = true;
  private element: ElementRef;
  private renderer: Renderer2;

  constructor(ngEl: ElementRef, renderer: Renderer2) { // loader: DynamicComponentLoader, private _containerRef: ViewContainerRef
    this.element = ngEl;
    this.renderer = renderer;
  }

  ngOnInit(): void {
    this.renderer.addClass(this.element.nativeElement, 'mdl-data-table__cell--non-numeric');
  }
}
