import { AfterContentInit, Component, Input, ElementRef, EventEmitter, Output } from '@angular/core';
// import { BooleanFieldValue } from '@angular2-material/core/annotations/field-value';
import './ip-timepicker.component.less';
import { LibGeral } from '../../../core/libraries/libGeral';
import { LibString } from '../../../core/libraries/libString';

/** TODO:
 * - Implementar ripple
 */
@Component({
  selector: 'ip-timepicker',
  templateUrl: './ip-timepicker.component.html',
  // eslint-disable-next-line
  host: {
    'class': 'flatpickr input-group',
    'data-wrap': 'true',
    'data-enabletime': 'true',
    'data-nocalendar': 'true'
  }
})
export class IPTimePickerComponent implements AfterContentInit {
  static nextUniqueId = 0;

  @Input() disabled = false;
  @Input() floatingPlaceholder = true;

  @Input() id = `ip-timepicker-${IPTimePickerComponent.nextUniqueId++}`;

  @Input() set model(value: string) { this.alterarValor(value); }
  get model(): string { return this._time; }

  @Input() placeholder: string = null;
  @Input() readOnly = false;

  @Output() modelChange: EventEmitter<Date>;

  get editable(): boolean { return !this.readOnly && !this.disabled; }
  get empty(): boolean { return LibGeral.estaEmBranco(this._time); }
  get focused(): boolean { return this._focused; }
  get hasValue(): boolean { return LibGeral.estaPreenchido(this._time); }
  get opened(): boolean { return this._opened; }
  get ready(): boolean { return LibGeral.estaPreenchido(this._timepickerElement); }

  // Internal state
  public _focused = false;
  public _opened = false;

  private get _time(): string {
    return LibGeral.estaEmBranco(this._hour) || LibGeral.estaEmBranco(this._minute) ?
      null :
      `${this._hour.toString().length === 1 ? '0' : ''}${this._hour}:${this._minute.toString().length === 1 ? '0' : ''}${this._minute}`;
  }
  private _hour: number;
  private _minute: number;

  // Visual elements
  private _wrapperElement: ElementRef;
  private _timepickerElement: any;

  inputId = `ip-timepicker-${IPTimePickerComponent.nextUniqueId++}-placeholder`;
  initialValue: any;

  constructor(element: ElementRef) {
    this._wrapperElement = element;
    this.modelChange = new EventEmitter<Date>();
  }

  ngAfterContentInit(): void {
    // Inicia componente e armazena instancia
    this._timepickerElement = flatpickr(this._wrapperElement.nativeElement, {
      onChange: this.onDateChange.bind(this),
      onOpen: this.onPickerOpen.bind(this),
      onClose: this.onPickerClose.bind(this)
    });

    if (this.initialValue) {
      this.alterarValor(this.initialValue);
    }
  }

  hasPlaceholder(): boolean {
    return LibGeral.estaPreenchido(this.placeholder) && !LibString.isNullOrEmpty(this.placeholder);
  }

  alterarValor(newValue: any): void {
    // Se o componente não tiver sido inicializado, não executa nada (aguarda)
    if (!this.ready) {
      this.initialValue = newValue;
      return;
    }

    if (this._time !== newValue) {
      if (LibGeral.estaEmBranco(newValue) || LibString.isNullOrEmpty(newValue)) {
        this._hour = null;
        this._minute = null;
        this._timepickerElement.clear();
        this.onDateChange(null, null);
      } else if (typeof newValue === 'string') {
        try {
          const timeParts = newValue.split(':');
          if (timeParts.length !== 2 || isNaN(+timeParts[0]) || isNaN(+timeParts[0])) {
            this.restaurarValor();
          } else {
            this._hour = +timeParts[0];
            this._minute = +timeParts[1];

            this._wrapperElement.nativeElement.parentElement.getElementsByClassName('flatpickr-hour')[0].value = timeParts[0];
            this._wrapperElement.nativeElement.parentElement.getElementsByClassName('flatpickr-minute')[0].value = timeParts[1];
            this._wrapperElement
              .nativeElement
              .parentElement
              .getElementsByClassName('flatpickr-am-pm')[0]
              .innerHTML = this._hour > 12 ? 'PM' : 'AM';

            this._timepickerElement.setDate(this._timepickerElement.selectedDateObj, false);
          }
        } catch (err) {
          this.restaurarValor();
        }
      } else {
        // Se o valor informado não for um tempo válida, o valor anterior é restaurado.
        this.restaurarValor();
      }
    }
  }
  restaurarValor(): void {
    // if (this._time === null) {
    //   this._timepickerElement.clear();
    // }
  }

  togglePicker(): void {
    this._opened = !this._opened;
    this._timepickerElement.toggle();
  }

  onFocus($event: any): void {
    this._focused = true;
  }
  onBlur($vent: any): void {
    this._focused = false;
  }

  onPickerOpen(): void {
    this._opened = true;
  }
  onPickerClose(): void {
    this._opened = false;
  }

  // Evento disparado quando uma data é selecionada no calendário
  onDateChange(dateObj: Date, dateStr: string): void {
    this._hour = dateObj.getHours();
    this._minute = dateObj.getMinutes();
    this.modelChange.next(<any>this._time);
  }
}
