import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import environment from "@environment";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { State, Store } from "@ngrx/store";
import { Observable } from "rxjs";
 
// import { config } from "../config"; remover depois de completada a atividade
import {
  HttpRequestConfig,
  HttpResponse,
  RestApiRequestConfig,
  RestAPIType,
} from "../models";
import { GemedState } from "../store/state";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class RestApiService {
  constructor(
    private httpService: HttpService,
    private store: Store<GemedState>,
    private state: State<GemedState>
  ) {}

  GetById<T>(
    id: number,
    requestConfig: RestApiRequestConfig
  ): Observable<HttpResponse<T>> {
    const url = this.obterUrl(requestConfig);
    const httpConfig = HttpRequestConfig.createGetRequest(
      url,
      null,
      requestConfig
    ).addParameter("id", id);
    return this.httpService.request(httpConfig);
  }

  Get<T>(
    requestConfig: RestApiRequestConfig,
    params?: HttpParams
  ): Observable<HttpResponse<T>> {
    const url = this.obterUrl(requestConfig);
    const httpConfig = HttpRequestConfig.createGetRequest(
      url,
      params,
      requestConfig
    );
    if (requestConfig.pagingInfo) {
      httpConfig.configurePaging(requestConfig.pagingInfo);
    }

    return <Observable<any>>this.httpService.request(httpConfig);
  }

  Post<T, R>(
    data: T,
    requestConfig: RestApiRequestConfig
  ): Observable<HttpResponse<R>> {
    const url = this.obterUrl(requestConfig);
    const httpConfig = HttpRequestConfig.createPostRequest(
      url,
      data,
      requestConfig.params,
      requestConfig
    );
    if (requestConfig.pagingInfo) {
      httpConfig.configurePaging(requestConfig.pagingInfo);
    }

    return <Observable<any>>this.httpService.request(httpConfig);
  }

  public obterUrl(requestConfig: RestApiRequestConfig): string {
    const host = this.obterHost(requestConfig.api);
    if (LibGeral.estaPreenchido(requestConfig.controller)) {
      return `${host}/${requestConfig.controller}/${requestConfig.action}`;
    }
    return `${host}`;
  }

  private obterHost(apiType: RestAPIType): string {
    switch (apiType) {
      case RestAPIType.Auth:
        return environment.url.Auth;
      case RestAPIType.Resources:
        return environment.url.Resources;
      case RestAPIType.Seguranca:
        return environment.url.Seguranca;
      case RestAPIType.GemedWebImage:
        return environment.url.GemedWebImage;
      case RestAPIType.ProntuarioExame:
        return environment.url.ProntuarioExameWeb;
      case RestAPIType.ChecagemMobile:
        return environment.url.Mobile;
      case RestAPIType.VideoChamada:
        return environment.url.VideoAtendimento;
      case RestAPIType.VideoChamadaToken:
        return environment.url.VideoAtendimentoToken;
      // eslint-disable-next-line no-throw-literal
      default:
        throw "Tipo de API desconhecido.";
    }
  }
}
