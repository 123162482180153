import {
  Component,


  ElementRef, Inject,



  OnDestroy, OnInit,

  Optional, ViewChild
} from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { IPDialogConfig } from "src/app/core/dialog/ipDialog.service";
import { LibComponent } from "../../../core/libraries/libComponent";
import { getCoresDialog } from "./ip-dialog-colors.enum";

// [ngClass]="{'full-screen': _resolver.dialog.config.FullScreen }"

@Component({
  selector: "ip-dialog-container",
  styleUrls: ["./ip-dialog.scss"],
  // eslint-disable-next-line
  host: {
    "(body:keydown)": "documentKeypress($event)",
    "(click)": "onClick()",
  },

  template: `
    <section>
      <div (click)="onContainerClick($event)" class="ip-dialog-frame">
        <div>
          <ng-content select="[header]"></ng-content>
        </div>

        <div>
          <ng-content select="[content]"></ng-content>
        </div>

        <div>
          <ng-content select="[actions]"></ng-content>
        </div>
      </div>
    </section>
  `,
})
export class IPDialogContainerComponent implements OnInit, OnDestroy {
  @ViewChild("tituloDialog") tituloDialog: ElementRef;
  public theme: any;
  constructor(
    public config: IPDialogConfig,
    private media: MediaObserver,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  private mediaQuery: Subscription;

  onContainerClick($event: any): void {
    $event.stopPropagation();
  }

  onClick(): any {
    /*if (this._resolver.dialog.config.Dismissible) {
      this._resolver.dialog.dismiss();
    }*/
  }

  documentKeypress(event: KeyboardEvent): any {
    /*if (this._ipDialogService.stackPosition(this._resolver.dialog) !== this._ipDialogService.stackLength() - 1) {
      return;
    }

    if (event.keyCode === 27 && this._resolver.dialog.config.CloseOnEsc) {
      this._resolver.dialog.dismiss();
      event.stopPropagation();
    }*/
  }

  ngOnInit(): void {
    this.theme = getCoresDialog(this.config.Cor);
    this.mediaQuery = this.media.asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0])
      )
      .subscribe((media) => {
        const titulo = LibGeral.estaPreenchido(this.tituloDialog) ? <HTMLElement>this.tituloDialog.nativeElement : null;
        if (titulo) {
          const toolbar = <HTMLElement>titulo.querySelector("mat-toolbar");
          if (media.mqAlias === "sm") {
            LibComponent.AddClass(titulo, "ip-dialog-title-small");
            if (toolbar) {
              toolbar.style.fontSize = "initial";
            }
          } else {
            LibComponent.RemoveClass(titulo, "ip-dialog-title-small");
            if (toolbar) {
              toolbar.style.fontSize = null;
            }
          }
        }
      });
  }
  ngOnDestroy(): void {
    if (this.mediaQuery) {
      this.mediaQuery.unsubscribe();
    }
  }
}
