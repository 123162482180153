<!--<div layout="column" layout-align="space-around center">-->
<div flex layout-fill layout="row" layout-align="center center" Style="height: 100%; width: 100%;">
    <mat-progress-spinner
        *ngIf='isCircular'
        [mode]="mode"
        [value]="valor"
        [color]="cor"
        [diameter]="raio">
    </mat-progress-spinner>

  <mat-progress-bar
      *ngIf='isLinear'
      [mode]="mode"
      [value]="valor"
      [color]="cor">
  </mat-progress-bar>
</div>
<!--</div>-->
