<div *ngIf="!hide">
  <div *ngIf="habilitarIcone" class="inputicon" id="snap">
    <ip-button-default
      (onClick)="showCam()"
      [color]="'primary'"
      [tipoDeBotao]="'fab'"
      [formatoRedondo]="true"
      [textoAuxiliar]="'farma.Modal.adicionarFoto'"
      [materialIconName]="'photo_camera'"
    ></ip-button-default>
  </div>
</div>
