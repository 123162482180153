import {
  Component,
  ElementRef,

  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "@gemed-core/auth/auth.service";
import { MonitoringService } from "@gemed-core/logging.service";
// @ngrx
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
// rxjs
import { Observable, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { LibGeral } from "src/app/core/libraries/libGeral";
// actions
import { LogInAction } from "../../core/store/actions/auth.actions";
// router
import { ChangeURLAction } from "../../core/store/actions/route.actions";
// reducers
import {
  getAuthenticationError,
  isAuthenticated,
  isAuthenticationLoading
} from "../../core/store/reducers/auth.reducer";
import { GemedState } from "../../core/store/state";
import { IPToastService } from "./../../core/toast/ipToast.service";

@Component({
  selector: "ip-login",
  templateUrl: "./log-in.component.html",
  styleUrls: ["./log-in.component.scss"],
})
export class LogInComponent implements OnDestroy, OnInit {
  @ViewChild("inputSenha") inputSenha: ElementRef;
  public validarSomenteAutenticacao: boolean;
  public isAuthenticated$: Observable<boolean>;
  public error$: Observable<any>;
  public loading: Observable<boolean>;
  public form: UntypedFormGroup;
  public clinicas: string[];
  public errorMessage = "";
  public nomeLogoPassword = "visibility_off";
  public hasErros = false;
  private subcriptionArray: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<GemedState>,
    private tradutorServico: TranslateService,
    private toastService: IPToastService,
    private authService: AuthService,
    public dialogRefLogin: MatDialogRef<LogInComponent>
  ) { }

  public ngOnInit() {
    this.isAuthenticated$ = this.store.select(isAuthenticated);

    this.form = this.formBuilder.group({
      usuario: ["", Validators.required],
      password: ["", Validators.required],
      clinica: ["", Validators.required],
    });

    if (this.verificarDisponibilidadeLocalStorage()) {
      const ultimoClienteLogado = localStorage.getItem(
        "@Gemed:UltimaClinicaInformada"
      );
      if (LibGeral.estaPreenchido(ultimoClienteLogado)) {
        this.form.controls["clinica"].setValue(ultimoClienteLogado);
      }
    }

    this.error$ = this.store.select(getAuthenticationError);
    this.subcriptionArray.push(
      this.error$.subscribe((erroEncontrado) => {
        if (erroEncontrado) {
          this.errorMessage = erroEncontrado.Menssagem;
          this.hasErros = true;
          this.toastService.erro(this.errorMessage, null, {
            tempoApresentacao: 8,
          });
        }
      })
    );

    this.loading = this.store.select(isAuthenticationLoading);

    this.store.select(isAuthenticated).pipe(
      take(1),
      filter((authenticated) => authenticated)
    );
  }
  public ngOnDestroy() {
    this.subcriptionArray.forEach((incricao) => incricao.unsubscribe());
  }
  public handleVoltarParaHome() {
    this.store.dispatch(new ChangeURLAction("/"));
  }

  public async handleSubmit() {
    this.hasErros = false;

    const usuario: string = this.form.get("usuario").value.trim();
    const password: string = this.form.get("password").value.trim();
    const clinica: string = this.form.get("clinica").value.trim();

    const payload = {
      user: {
        usuario: usuario,
        password: password,
        clinica: clinica,
      },
    };

    if (this.validarSomenteAutenticacao) {
      const dados = await this.authService.LogInAsync(usuario, password, clinica);
      if (LibGeral.estaPreenchido(dados) && LibGeral.estaPreenchido(dados.usuario)) {
        this.dialogRefLogin.close(dados.usuario.IdUsuario);
      }
    } else {
      this.handleAtualizaUltimaClinicaUsada(clinica);
      this.store.dispatch(new LogInAction(payload));
    }
  }

  public handleAtualizaUltimaClinicaUsada(nomeDaClinica: string) {
    if (this.verificarDisponibilidadeLocalStorage()) {
      window.localStorage.setItem(
        "@Gemed:UltimaClinicaInformada",
        nomeDaClinica
      );
    }
  }

  public recuperarUltimaClinicaInformada(): string {
    return window.localStorage.getItem("@Gemed:UltimaClinicaInformada");
  }

  public handleAlterarIdioma(linguagem: string): void {
    this.tradutorServico.use(linguagem);
  }

  public handleAlterarVisibilidadeDaSenha() {
    let type = "";
    const inputSenha = document.getElementById(
      "passwordInput"
    ) as HTMLInputElement;

    inputSenha.type =
      inputSenha.type === "password" ? (type = "texto") : (type = "password");

    this.nomeLogoPassword =
      type === "password" ? "visibility_off" : "visibility";
  }

  public verificarDisponibilidadeLocalStorage() {
    let storage: Storage;
    try {
      storage = window.localStorage;
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === "QuotaExceededError" ||
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        storage &&
        storage.length !== 0
      );
    }
  }
}
