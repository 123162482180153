import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { parametrosManagerReducer } from "./Store/Reducer/parametrosManager.reducer";
import { EffectsModule } from "@ngrx/effects";
import { ParametrosManagerEffects } from "./Store/Effects/parametrosManager.effects";
import { ParametrosManagerDirective } from "./parametrosManager.directive";
import { ParametrosManagerService } from "./Services/parametrosManager.service";
import { PEPService } from "@pep-service/pep.service";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature("ParametrosManager", parametrosManagerReducer),
        EffectsModule.forFeature([ParametrosManagerEffects])
    ],
    declarations: [ParametrosManagerDirective],
    exports: [ParametrosManagerDirective],
    providers:[
        ParametrosManagerService,
        PEPService
    ]
})
export class ParametrosManagerModule {

}
