import { createAction, props } from '@ngrx/store';
import { type } from '../../../../core/libraries';

export const ActionTypes = {
    CARREGA_MENU: type('[CARREGA_MENU] Carregando menu de acesso do usuário.'),
    CARREGA_MENU_SUCESSO: type('[CARREGA_MENU_SUCESSO] Carregado os dados do menu do usuário.'),
    CARREGA_ERRO: type('[CARREGA_ERRO] Dados não foram carregados.'),

    CRIAR_STATE_MENU: type('[CRIAR_STATE_MENU] Criar o state de menu de acesso do usuário.'),
    CRIAR_STATE_MENU_SUCESSO: type('[CRIAR_STATE_MENU_SUCESSO] Criado o state de menu de acesso do usuário.'),

    RESET_MENU: type('[RESET_MENU] Limpar o state de menu de acesso do usuário.'),
    RESET_MENU_SUCESSO: type('[RESET_MENU] Limpar o state de menu de acesso do usuário.'),
};

export const criarStateMenuAction = createAction(
    ActionTypes.CRIAR_STATE_MENU,
    props<{ payload: any }>()
)

export const criarStateMenuSucessoAction = createAction(
    ActionTypes.CRIAR_STATE_MENU_SUCESSO,
    props<{ payload: any }>()
)

export const carregarMenuAction = createAction(
    ActionTypes.CARREGA_MENU,
    props<{ payload: number }>()
)

export const carregarMenuSucessoAction = createAction(
    ActionTypes.CARREGA_MENU_SUCESSO,
    props<{ payload: any[] }>()
)

export const carregaFiltroErroAction = createAction(
    ActionTypes.CARREGA_ERRO,
    props<{ payload: any }>()
)

export const resetStateMenuAction = createAction(
  ActionTypes.RESET_MENU
)

export const resetStateMenuSucessoAction = createAction(
  ActionTypes.RESET_MENU_SUCESSO,
  props<{ payload: any }>()
)
