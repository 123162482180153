import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import environment from "src/environments/environment";
import { IPToastService } from './../../../core/toast/ipToast.service';

const TAMANHOMAXIMOARQUIVO = 2;
@Component({
  selector: 'ip-upload-file',
  templateUrl: './ip-upload-file.component.html',
  styleUrls: ['./ip-upload-file.component.scss']
})
export class IpUploadFileComponent {
  @Input() textoLabel: any;
  @Input() habilitarIcone = false;
  @Input() hide = false;
  @Output() onUploadFile: EventEmitter<any> = new EventEmitter();
  @ViewChild("formArquivos") formArquivos !: ElementRef<HTMLFormElement>;

  public hasErrorFile = "";
  public hasErrorType = "";
  private countFiles: number;
  public files: Array<any> = [];

  constructor(private toastService: IPToastService) { }


  // Armazena os arquivos no formdata
  uploadFile(event) {
    const self = this;

    this.hasErrorFile = "";
    this.hasErrorType = "";
    if (event.target.files.length > 0) {
      let idxFiles = 0;
      this.countFiles = event.target.files.length;

      Array.from(event.target.files).forEach(value => {
        const file = (<any>value);
        if ((file.size / 1024 / 1024) > TAMANHOMAXIMOARQUIVO) { // Mb
          this.toastService.erro(`Tamanho excede o limite de ${TAMANHOMAXIMOARQUIVO}Mb.`, null, { tempoApresentacao: 5 });
          return;
        }

        if (!this.checkType(file.type)) {
          this.toastService.erro('Tipo de arquivo não suportado.', null, { tempoApresentacao: 5 });
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const id: string = btoa(Date.now().toString());
          let resultPreview: any = reader.result;
          if (file.type === "application/pdf") {
            resultPreview = `${environment.domain}assets/images/farma/pdf.png`;
          }
          self.files.push({ id: id, nome: file.name, extension: file.name.slice(file.name.lastIndexOf('.') + 1), type: file.type, bytearray: reader.result.toString().split(",")[1], result: reader.result, preview: resultPreview });
          idxFiles += 1;

          if (self.countFiles === idxFiles) {
            this.onUploadFile.emit({ files: self.files });
            self.limparDados();
          }

        };
      });
    }
  }

  public openFileExplorer() {
    const element = document.getElementById('selecao-arquivo');
    element.click();
  }

  private limparDados() {
    this.countFiles = 0;
    this.files = [];
    this.formArquivos.nativeElement.reset();
  }

  private checkType(type) {
    const types = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];
    return types.includes(type);
  }
}
