import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { MonitoringService } from "@gemed-core/logging.service";
import { ErrorService } from "./errorService";
import { LoggingService } from "./loggingService";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {

  }

  handleError(error: Error | HttpErrorResponse) {

    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const monitorService = this.injector.get(MonitoringService);

    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }

    if (monitorService.enableApplicationInsights()) {
      monitorService.logException(error, 3);
    }

    logger.logError(message, stackTrace);
  }
}
