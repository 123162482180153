import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { SanatizeHtmlPipe } from "./sanatize-html.directive";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [ SanatizeHtmlPipe ],
  exports: [SanatizeHtmlPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SanatizeHtmlPipeModule {

}
