import {
   ComponentFactoryResolver, Directive, ElementRef, Host, Optional, Renderer2, Type, ViewContainerRef
} from '@angular/core';
import { IPCellDirective } from './ip-cell.directive';
import { IPCheckBoxComponent } from '../checkbox';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';

/**
 * Diretiva para edição de valores boleanos inline no componente ip-table.
 * Quando a celula é passada para o modo de edição, a diretiva altera
 * seu conteúdo para apresentar um checkbox.
 *
 * @export
 * @class IPCellInlineEditCheckBoxDirective
 * @extends {IPCellInlineEditDirective<string>}
 */
@Directive({selector: '[ip-cell-inline-edit-checkbox]'})
export class IPCellInlineEditCheckBoxDirective extends IPCellInlineEditDirective<string> {

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);
  }

  public getComponent(): Type<IPCheckBoxComponent> {
    return IPCheckBoxComponent;
  }

  public setComponentProps(editorComponent: IPCheckBoxComponent): void {
    editorComponent.model = this.cell.getCurrentValue();
  }
}
