import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { IPInputComponent, EnumAlignText } from "./ip-input";
@Component({
  selector: "ip-input-text",
  styleUrls: ["./ip-input-text.component.scss"],
  template: `
    <mat-form-field
      dividerColor="accent"
      [align]="align"
      [floatPlaceholder]="floatPlaceholder"
    >
      <input
        matInput
        [disabled]="disabled"
        [maxLength]="maxLength"
        [placeholder]="placeholder"
        [required]="required"
        [type]="type"
        [value]="valorDisplay"
        [max]="max"
        [min]="min"
        [(ngModel)]="model"
        (change)="onChange($event)"
        (keydown)="onKeyDown($event)"
        (focusout)="onFocusOut($event)"
        flex
      />
      <span matPrefix>
        <ng-content select="[prefix]"></ng-content>
      </span>
      <span matSuffix>
        <ng-content select="[sufix]"></ng-content>
      </span>
      <mat-hint align="end">
        <ng-content select="[hint]"></ng-content>
      </mat-hint>
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Pesquisar"
        [style.display]="mostrarIcone ? '' : 'none'"
        (click)="iniciarPesquisa($event)"
      >
        <mat-icon class="pesquisaStyle" [style.color]="corIcone">{{
          nameIcone
        }}</mat-icon>
      </button>
    </mat-form-field>
  `,
})
export class IPInputTextComponent
  implements OnInit, OnDestroy, IPInputComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("aria-label") ariaLabel: string;
  @Input() align: EnumAlignText = EnumAlignText.start;
  // @Input() dividerColor: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() disabled = false;
  @Input() floatingPlaceholder: any = true;
  @Input() maxLength = 356;
  @Input() max: number;
  @Input() min: number;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() type = "text";
  @Input() model: any;
  @Input() nameIcone: string = "search";
  @Input() corIcone: string = "white";
  @Input() mostrarIcone: boolean = false;
  @Input() keyDownCommand: any;
  @Output() modelChange: EventEmitter<string>;
  @Output() focusOut: EventEmitter<Event>;
  @Output() pesquisaEvent: EventEmitter<Event>;

  public get valorDisplay(): string {
    return this.model ? this.model : "";
  }
  public set valorDisplay(valor: string) {
    // não deve alterar valores
  }
  public get floatPlaceholder(): string {
    return JSON.parse(this.floatingPlaceholder) ? "always" : "never";
  }
  public set floatPlaceholder(valor: string) {
    this.floatingPlaceholder = valor;
  }
  private elementRef: ElementRef;
  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.modelChange = new EventEmitter<string>();
    this.focusOut = new EventEmitter<Event>();
    this.pesquisaEvent = new EventEmitter<Event>();
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.componentIntance = this;
  }
  ngOnDestroy(): void {
    this.elementRef.nativeElement.componentIntance = null;
  }
  getRawValue(): any {
    return this.elementRef.nativeElement.querySelector("input").value;
  }
  onChange($event: any): void {
    this.modelChange.next($event.target.value);
  }
  onKeyDown(event: Event): void {
    if (this.keyDownCommand) {
      this.keyDownCommand(event);
    }
  }
  onFocusOut(event: Event) {
    event.stopPropagation();
    this.focusOut.emit(event);
  }

  iniciarPesquisa(event: Event) {
    this.pesquisaEvent.emit(event);
  }
}
