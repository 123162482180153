import { ActionTypes } from '../actions/auth.actions';
import { AuthState } from '../state/auth.state';
import { createSelector } from "reselect";
import { GemedState } from '../state';
import { ILoginTemporario } from '@gemed-core/interfaces/gemed.interface';


export const getAuthState = (state: GemedState) => state.auth;
export const getAuthenticationError = createSelector(getAuthState, (state: AuthState) => state.error);
export const isAuthenticated = createSelector(getAuthState, (state: AuthState) => state.IsAuthenticated);
export const isAuthenticationLoading = createSelector(getAuthState, (state: AuthState) => state.isLoading);
export const getAuthenticatedUser = createSelector(getAuthState, (state: AuthState) => state.Usuario);


// TODO: Melhorar tratamento de erros.
export function AuthReducer(state: AuthState = new AuthState(), action: any): AuthState {
  switch (action.type) {
    case ActionTypes.LIMPAR_ERRO_LOG_IN:
      return {
        ...state,
        error: null
      };
    case ActionTypes.LOG_IN:
      return Object.assign({}, state, { isLoading: true });
    case ActionTypes.AUTHENTICATED_SUCCESS:
      return Object.assign({}, state, action.payload);
    case ActionTypes.LOG_OUT:
      return Object.assign({}, state = new AuthState());
    case ActionTypes.LOG_OUT_SUCCESS:
      return state = new AuthState();
    case ActionTypes.ATUALIZAR_TOKEN_REFRESH:
      return state = {
        ...state,
        Usuario: {
          ...state.Usuario,
          Id: state.Usuario.Id,
          Token: action.payload.Token,
          RefreshToken: action.payload.RefreshToken,
          TokenExpirado: action.payload.TokenExpirado
        }
      };

    case ActionTypes.FORCAR_REFRESHTOKEN:
      return state = {
        ...state,
        Usuario: {
          ...state.Usuario,
          Id: state.Usuario.Id,
          TokenExpirado: true
        }
      };
      case ActionTypes.FECHAR_MODAL_CLINICAS:
        return {
          ...state,
          isLoading: false
        }

      case ActionTypes.LOG_IN_ERROR:
      return Object.assign({}, state, {
        ...state,
        error: action.payload,
        isLoading: false
      });
    default:
      return state;
  }
}
