import { LibGeral } from "./libGeral";

export class LibString {
  /**
   * Verifica se o valor passado é um string nula/vazia.
   * Caso for passado uma propriedade que não seja string, é retornado FALSE.
   * @static
   * @param {string} value (String a ser verificada)
   * @returns {boolean}
   */
  public static isNullOrEmpty(value: string): boolean {
  
    return (
      value === undefined ||
      value === "" ||
      value.trim().length === 0 ||
      value === null 
    );
  }
}
