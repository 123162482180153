import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

declare type SideNavMode = 'over' | 'push' | 'side';
@Component({
  selector: 'ip-side-nav-layout',
  template: `
  <mat-sidenav-container flex layout="row" class="ip-side-nav-layout" (backdropClick)="backDropClick()">
    <mat-sidenav
                (keydown.escape)="escClick()"
                [align]="align"
                [style.width]="width"
                hasBackdrop="true"
                [(opened)]="opened"
                [mode]="mode"
                (close)="close($event)"
                (open)="open($event)"
                (mouseenter)="onMouseEnter($event)"
                (mouseleave)="onMouseLeave($event)"
                flex="nogrow">
          <ng-content select="section.ip-side-nav"></ng-content>
        <ng-content layout="row" flex layout-fill></ng-content>
    </mat-sidenav>
    <ng-content layout="row" flex layout-fill></ng-content>
  </mat-sidenav-container>`,
  styleUrls: ['./ip-side-nav-layout.component.scss']
})
export class IPSideNavLayoutComponent implements OnInit, OnChanges, OnDestroy {
  // @select(obterUrl) url$: Observable<string>;
  @Input() align: 'start' | 'end';
  // time in ms
  @Input() autoHideTime: number;
  @Input() isResponsive: boolean;
  @Input() mode: SideNavMode = 'over';
  @Input() width: number;
  @Input() onOpen: Function;
  @Input() onClose: Function;
  @Input() opened: boolean;

  @Output() backDropClicked: EventEmitter<any>;
  @Output() escClicked: EventEmitter<any>;
  @Output() openedChange: EventEmitter<boolean>;

  @ViewChild(MatSidenav) sideNav: MatSidenav;
  private externalMode: SideNavMode;
  private interval: any;
  private resposiveModeValue: SideNavMode;
  private subUrl: Subscription;
  constructor(elementRef: ElementRef) {
    this.align = 'start';
    this.autoHideTime = 40000; // 2500
    this.isResponsive = true;
    this.opened = false;
    this.openedChange = new EventEmitter<boolean>();
    this.backDropClicked = new EventEmitter<any>();
    this.escClicked = new EventEmitter<any>();
    this.resposiveModeValue = 'over';
    this.width = 250;
  }
  open(event: Event): void {
    if (this.onOpen) {
      this.onOpen(event);
    }
  }
  close(event: Event): void {
    this.endAutoClose();
    this.openedChange.emit(false);
    if (this.onClose) {
      this.onClose(event);
    }
  }
  backDropClick(): void {
    this.backDropClicked.emit();
  }

  escClick(): void {
    this.escClicked.emit();
  }

  onMouseEnter(event: Event): void {
    this.endAutoClose();
  }
  onMouseLeave(event: Event): void {
    this.startAutoClose();
  }
  eviarEventoFechamento(): void {
    this.opened = false;
     this.openedChange.emit(this.opened);
  }
  startAutoClose(): void {
    this.interval = setTimeout(() => {
      this.eviarEventoFechamento();
    }, this.autoHideTime);
  }
  endAutoClose(): void {
    clearTimeout(this.interval);
  }
  ngOnInit(): void {
    this.externalMode = this.mode;
    /*this.subUrl = this.url$.subscribe(x => {
      this.eviarEventoFechamento();
    });*/
  }
  ngOnChanges(changes: {[propertyName: string]: SimpleChange} | any): void {
    if (changes && 'opened' in changes) {
      const openedSimpleChanges = changes.opened;
      if (openedSimpleChanges.currentValue === true) {
        this.changeModeByResolution();
      }
    }
  }
  changeModeByResolution(): void {
    if (this.isResponsive) {
      const width = document.body.getBoundingClientRect().width;
      if (width <= 1024) {
        if (this.mode !== this.resposiveModeValue) {
          this.mode = this.resposiveModeValue;
        }
      } else {
        if (this.mode !== this.externalMode) {
          this.mode = this.externalMode;
        }
      }
    }
  }
  ngOnDestroy(): void {
    clearTimeout(this.interval);
    if (this.subUrl) {
      this.subUrl.unsubscribe();
    }
  }
}
