import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getSomeTextWhenThisIsBlank'})
export class ReverseSomeWhenBlank implements PipeTransform {
  transform(cellInfo: any): string {
    let newStr: string = "";
    
    if(!!cellInfo){
        newStr = cellInfo;
    }else{
        newStr = "-";
    }

    return newStr;
  }
}