import { LibGeral } from "@gemed-core/libraries/libGeral";
import { QuestionarioPerguntaStatus } from "@pep-models/questionario/questionarioPerguntaStatus.enum";
import { mapToArray } from "../../libraries/libCollections";


export const obterIdClinica = ({ auth }) => auth.idClinica;
export const obterPaciente = ({ assistencial }): IPaciente => assistencial.prontuario.paciente;
export const obterResumoPaciente = ({ assistencial }) => assistencial.prontuario.resumoPaciente;
export const obterFotoPaciente = ({ assistencial }) => assistencial.prontuario.foto;
export const obterIndicacao = ({ assistencial }) => assistencial.prontuario.indicacao;
export const obterAlergias = ({ assistencial }) => assistencial.prontuario.alergias;
export const obterProgramasApoioPaciente = ({ assistencial }) => assistencial.prontuario.programasApoioPaciente;
export const obterAgendas = ({ assistencial }) => assistencial.prontuario.agendas;
export const obterEvolucoes = ({ assistencial }) => assistencial.prontuario.evolucoesLista;
export const obterResumoEvolucoesRespostas = ({ assistencial }) => {
  return assistencial.prontuario.resumoEvolucoes;
};
export const obterPrescricoes = ({ assistencial }) => assistencial.prontuario.prescricoes;
export const obterQuestionarios = (state) => {
  return state.assistencial.prontuario.questionarios;
};
export const obterDiagnostico = ({ assistencial }) => assistencial.prontuario.diagnosticos;
export const obterComorbidades = ({ assistencial }) => assistencial.prontuario.comorbidades;
export const obterMedicamentosEmUso = ({ assistencial }) => assistencial.prontuario.medicamentosEmUso;
export const obterUltimosTratamentos = ({ assistencial }) => assistencial.prontuario.ultimosTratamentos;
export const obterMedidasAntrometricas = ({ assistencial }) => assistencial.prontuario.medidasAntropometricas;

export const obterDocumentos = ({ assistencial }) => assistencial.prontuario.documentos;
export const obterLoading = ({ assistencial }) => assistencial.prontuario.loading;
export const obterPrescricaoSelecionada = ({ assistencial }) => assistencial.prontuario.prescricaoSelecionada;

export const obterTeleMedicinaSelecionada = (store) => {
  const existeCentralMedico = LibGeral.estaPreenchido(store.centralmedico);
  const existeTeleMedicina = existeCentralMedico && LibGeral.estaPreenchido(store.centralmedico.teleConsultaPaciente);
  const existePacienteTeleMedicina = existeTeleMedicina && LibGeral.estaPreenchido(store.centralmedico.teleConsultaPaciente.pacienteSala);
  if (existePacienteTeleMedicina) {
    return store.centralmedico.teleConsultaPaciente.pacienteSala;
  }
}


export const obterRespostas = ({ assistencial }) => assistencial.prontuario.respostas;
export const obterRequest = ({ requests }) => requests;

export const obterNomeUsuario = ({ auth }) => auth.Usuario ? auth.Usuario.Nome : null;
export const obterUsuario = ({ auth }) => auth.Usuario;
export const obterContextoUsuario = ({ auth }) => auth.contexto;
export const obterErrosAPP = ({ app }) => app.erros;
export const obterMensagensAPP = ({ app }) => app.mensagens;
export const obterIdProfissional = ({ auth }) => auth.Usuario.IdProfissional;
export const obterCategoriaProfissional = ({ auth }) => auth.Usuario ? auth.Usuario.CategoriaProfissional : null;
export const obterDescricaoProfissional = ({ auth }) => auth.Usuario ? auth.Usuario.DescricaoProfissional : null;
export const obterAgendasSize = ({ agendas }) => agendas.size;

export const obterUrl = ({ route }) => {
  const navagationAccepts = route.navagations.filter(x => x.type === 'NAVAGATE_ACCEPT');
  const navagation = navagationAccepts[navagationAccepts.length - 1];
  return navagation ? navagation.url : null;
};
export const obterPerguntasEmEdicao = ({ assistencial }) => {
  return mapToArray(assistencial.prontuario.perguntas).filter(pergunta => pergunta.TipoEnum == QuestionarioPerguntaStatus.EmEdicao);
};

export const obterStatusLoadingPaciente = ({ assistencial }) => {
  return assistencial.prontuario.pacienteIsLoading;
};

export const verficaSeDeveAbrirModal = ({ assistencial }, idPaciente?) => {
  let possuiQuestionariosAbertos = false;

  if (!LibGeral.estaEmBranco(assistencial)) {
    const questionariosAbertos = assistencial.prontuario.questionarios;
    possuiQuestionariosAbertos = !!questionariosAbertos && Object.entries(questionariosAbertos).length > 0;
  }

  return possuiQuestionariosAbertos;
};
