import { Component, Input } from '@angular/core';

import { IPButtonComponent } from './ip-button.component';

@Component({
    selector: 'ip-button-flat',
    template: `
    <button mat-flat-button [color]="color" [disabled]="disabled" [disableRipple]="!efeitoRipple" class="ip-button">
      <ng-content></ng-content>
    </button>
    `,
    
})
export class IPButtonFlatComponent extends IPButtonComponent {
  @Input() efeitoRipple = true;
}
