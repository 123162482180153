import { Component } from '@angular/core';
import { HabilitarModalConfirmaAcao } from '@gemed-core/store/actions/app.actions';
import { Store } from '@ngrx/store';
import { AssistencialState } from 'src/app/assistencial/shared/store/state/assistencial.state';
import environment from "../../../../environments/environment";
import { LogOutAction } from '../../../core/store/actions/auth.actions';
import { GemedState } from '../../../core/store/state';

@Component({
    selector: 'ip-button-log-out',
    template: `
      <button mat-button (click)="onClick($event)">
        <div style="display:flex;">
          <img style="max-width: 48px; width: 100%;" src="${environment.domain}assets/images/sair.svg" />
          <ng-content></ng-content>
        </div>
      </button>`,
      styles: [
        `
        button {
          width: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          flex: 1;
          align-items: stretch;
          justify-content: space-between;
        }

        @media(max-width:768px){
          .mat-button{
            min-width:100%;
          }

          .mat-button > .mat-button-wrapper{
            width: 100%;
          }
        }
        `
      ]
})
export class IPButtonLogOutComponent {
  constructor(private store: Store<GemedState>, private assistencialStore: Store<AssistencialState>) {}

  onClick(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(  new HabilitarModalConfirmaAcao(new LogOutAction()));
  }
}
