import { Component, EventEmitter, Input, Output, Optional } from '@angular/core';
import { IPRadioGroupComponent } from './ip-radio-group.component';

@Component({
  selector: 'ip-radio-button',
  template: `
    <mat-radio-button [(checked)]="checked" [color]="'primary'" [disabled]="disabled" [name]="name" [value]="value" (change)="onValueChange($event)">
      <ng-content></ng-content>
    </mat-radio-button>
  `
})
export class IPRadioButtonComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() name: string;
  @Input() value: any;

  @Output() checkedChange: EventEmitter<boolean>;

  private radioGroup: IPRadioGroupComponent;

  constructor(@Optional() radioGroup: IPRadioGroupComponent) {
    /* O radioGroup é injetado via DI, para que quando o radio for
     * checado o valor do group seja alterado. */
    this.radioGroup = radioGroup;
    this.checkedChange = new EventEmitter<boolean>();
  }

  onValueChange($event: any): void {
    this.checkedChange.emit(!$event.source.checked);
    if (this.radioGroup) {
      this.radioGroup.change.emit($event.source.value);
    }
  }
}
