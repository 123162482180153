import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
// @angular/flex-layout
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IPRadioModule } from "../core-ui/components/radio";
import { IPButtonModule } from "./../core-ui/components/button/ip-button.module";
import { LogInComponent } from "./log-in/log-in.component";
// components
import { UserAccountComponent } from "./user-account/user-account.component";

const baseMaterial = [
  MatInputModule,
  MatCardModule,
  MatMenuModule,
  MatIconModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatButtonModule,
];
// components constant
const components = [UserAccountComponent, LogInComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    IPRadioModule,
    IPButtonModule,
    ...baseMaterial,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: components,
  exports: components,
})
export class UsersModule { }
