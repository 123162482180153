import { Component } from '@angular/core';
import environment from "../../environments/environment";
import { GemedState } from '../core/store/state';
import { Store } from '@ngrx/store';
import { ChangeURLAction } from '../core/store/actions/route.actions';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pagina-nao-encontrada',
  template: `
        <section (click)="onClick($event)">
          <img style="height: 100%;width: 82%;"
                src="${environment.domain}assets/img/404.svg"
                alt="404 página não encontrada." />
          <div>
            <header><h1>404</h1></header>
            <section>
              {{'paginaNaoEncontrada' | translate }}
            </section>
          </div>
        </section>
      `,
  styles: [`
        :host {
            background: white;
            text-align: center;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
        }
        section div {
          color: #32579a;
          font-size: 4.8em;
          font-weight: bold;
          font-family: 'Montserrat', sans-serif;
          margin-bottom: 60px;
        }
        section div h1 {
          padding: 0;
          margin: 52px;
        }
        section div section {
          font-size: .4em;
        }
    `]
})
export class PaginaNaoEncontradaComponent {
  constructor(private store: Store<GemedState>) {
  }

  onClick(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(new ChangeURLAction('/assistencial/prontuario/principal'));
  }
}
