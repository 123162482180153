/* Componente utiliza classes da bliblioteca MUICSS
 * Referência: https://www.muicss.com/docs/v1/css-js/tabs */

import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  QueryList,
  ViewChildren,
  ContentChildren,
  AfterContentChecked,
  OnDestroy
} from '@angular/core';
import { MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { IPTab } from './ip-tab.component';

import './ip-tab.component.less';

@Component({
  selector: 'ip-tabs',
  template: `
    <md-tab-group
      [selectedIndex]="selectedIndex"
      (selectedIndexChange)="selectTab($event)">
      <ng-content></ng-content>
    </md-tab-group>`,
  styles: [`
    :host .md-tab-body-content {
      display: flex;
      flex: 1;
      height: 100%;
    }
    :host md-tab-group {
      display: flex;
      flex: 1;
      height: 100%;
    }
    :host .md-tab-body-wrapper {
      height: 100%;
    }`
  ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IPTabs implements OnInit, AfterContentChecked, OnDestroy {

  @ContentChildren(IPTab) tabs$: QueryList<IPTab>;
  @ViewChildren(MatTabGroup) mdTabGroup$: QueryList<MatTabGroup>;
  @ViewChildren(MatTabHeader) mdTabHeader: QueryList<MatTabHeader>;

  @Input() selectedIndex: number;
  @Output() selectedChange: EventEmitter<number>;

  tabs: Array<IPTab>;
  private _tabCount = 0;
  private subSelect: any[];

  constructor() {
    this.selectedChange = new EventEmitter<number>();
  }
  ngOnInit(): void {
    this.subSelect = [];
  }
  ngAfterContentChecked(): void {
    if (this.mdTabHeader && (this.subSelect.length !== this.mdTabHeader.length)) {
      this.limparSubs();
      this.mdTabHeader.forEach(mdHeader => {
        this.subSelect.push(mdHeader.selectFocusedIndex.subscribe(this.selectTab));
      });
    }

    if (!this.mdTabGroup$) {
      return null;
    }

    const mdTabGroup: MatTabGroup = this.mdTabGroup$.reduce((_, x) => x, null);
    if (mdTabGroup) {

      if (mdTabGroup._tabs.length === this.tabs$.length) {
        return null;
      }

        const lista = <any>this.tabs$.map(tab => tab.mdTab.reduce((_, mdTab) => mdTab, null));
        lista.toArray = _ => lista;
        mdTabGroup._tabs = lista;
        mdTabGroup.ngAfterContentChecked();
    }
  }
  ngOnDestroy(): void {
    this.limparSubs();
  }

  addTab(tab: IPTab): void {
    if (this.tabs.length === 0) {
      tab.active = true;
    }

    tab.tabId = this.createTabId();
    this.tabs.push(tab);
  }

  selectTab(indice: number): void {
    this.selectedChange.emit(indice);
  }

  limparSubs(): void {
    this.subSelect.forEach(x => x.unsubscribe());
  }

  // private mapTabs(tab: IPTab): IPTab {
  //   return tab;
  // }

  private createTabId(): string {
    this._tabCount++;
    return `ip-tab-${this._tabCount}`;
  }
}
