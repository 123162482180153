import { ProfissionalService } from './../../assistencial/shared/services/profissional.service';
import { HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ILoginTemporario } from "@gemed-core/interfaces/gemed.interface";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { Clinica } from "@gemed-core/models/clinica.model";
import { HttpStatus } from "@gemed-core/models/httpStatus.enum";
import { Usuario } from "@gemed-core/models/usuario.model";
import { AtualizarTokenRefresh, ForcarRefreshToken, LogInErroAction, LogInSucessoAction, LogOutAction } from "@gemed-core/store/actions/auth.actions";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { ListaMultiClinicasComponent } from "src/app/core-ui/components/lista-multi-clinicas/lista-multi-clinicas.component";
import { UsuarioTipo } from "src/app/users/shared/model/UsuarioTipo.enum";

import { RestApiService } from "../http/restApi.service";
import { HttpResponse, RestAPIType } from "../models";
import {
  getAuthenticatedUser,
  isAuthenticated
} from "../store/reducers/auth.reducer";
import { GemedState } from "../store/state";
import { IUsuarioAutenticadoDTO } from 'ip_types_ts/common-typings';

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  static TempoMaximoAutenticadoEmSegundos = 4600;

  public lock: any;
  public timer: number;
  private dialogClinicasRef: MatDialogRef<ListaMultiClinicasComponent>;

  private reset: Function;
  private timeout: any;
  private tradutorSub: Subscription;

  constructor(
    private store: Store<GemedState>,
    private apiService: RestApiService,
    private profissionalService: ProfissionalService,
    // private authAction: AuthActions,
    public tradutor: TranslateService,
    public dialog: MatDialog,
  ) {
    this.reset = this.resetTimerSessao.bind(this);
    this.timer = AuthService.TempoMaximoAutenticadoEmSegundos;
    this.tradutor = tradutor;
  }
  iniciarLockNovoLogin(): void { }

  obterPlaceholdersLock(callbacks: Function[]): void { }

  public habilitarModalClinicas(loginTemporario: ILoginTemporario) {
    if (LibGeral.estaPreenchido(this.dialogClinicasRef)) {
      this.dialogClinicasRef.close();
    }

    this.dialogClinicasRef = this.dialog.open(ListaMultiClinicasComponent, {
      disableClose: true,
      data: { clinicas: loginTemporario.clinicas },
      height: "50vh"
    });

    this.dialogClinicasRef
      .beforeClosed()
      .pipe(take(1))
      .subscribe(async (clinica: Clinica) => {
        if (LibGeral.estaPreenchido(clinica)) {

          try {
            const { Usuario } = loginTemporario.usuario;

            const novosJwtToken = await this.alterarClinicaLogada({
              IdEmpresa: clinica.IdEmpresa,
              IdUsuario: loginTemporario.usuario.IdUsuario,
              Login: Usuario,
              Sistema: LibGeral.obterSistema(loginTemporario.identificador),
              Cliente: loginTemporario.identificador,
              Token: loginTemporario.token,
              RefreshToken: loginTemporario.refreshToken
            });

            if (novosJwtToken.Status == HttpStatus.OK) {
              const token = novosJwtToken.Headers.get("authorization").split(" ")[1];
              const refreshToken = novosJwtToken.Headers.get("authrefreshtoken");

              this.store.dispatch(new LogInSucessoAction(
                {
                  idClinica: clinica.IdClinica,
                  usuario: loginTemporario.usuario,
                  token: token,
                  refreshToken: refreshToken,
                  razaoClinica: clinica.Razao
                }
              ));
              
              setTimeout(async ()=>{
                const result = await this.profissionalService.getProfissionalUsuario(loginTemporario.usuario.IdUsuario).toPromise();
                const{usuario}= loginTemporario;
                const usuarioComProfissionalAtualizado  = {...usuario,IdProfissional:result.Data.IdProfissional} as IUsuarioAutenticadoDTO  ;
                this.store.dispatch(new LogInSucessoAction(
                  {
                    idClinica: clinica.IdClinica,
                    usuario: usuarioComProfissionalAtualizado,
                    token: token,
                    refreshToken: refreshToken,
                    razaoClinica: clinica.Razao
                  }
                ));
              },1000);
            }
          } catch (error) {
            this.store.dispatch(new LogInErroAction(error))
          }
        }
      })

  }



  public LogInAsync(
    usuario: string,
    senha: string,
    clinica: string
  ): Promise<any> {

    return new Promise((resolve, reject) => {
      this.apiService
        .Post({
          sistema: LibGeral.obterSistema(clinica),
          cliente: clinica,
          usuario,
          senha,
        }, {
          api: RestAPIType.Auth,
          controller: "Usuario",
          action: "AutenticarWeb",
        })
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          (error) => reject(error)
        );
    });
  }

  public LogIn(
    usuario: string,
    senha: string,
    clinica: string
  ): Observable<HttpResponse<any>> {

    return this.apiService.Post<any, any>(
      {
        sistema: LibGeral.obterSistema(clinica),
        cliente: clinica,
        usuario,
        senha,
      },
      {
        api: RestAPIType.Auth,
        controller: "Usuario",
        action: "AutenticarWeb",
      }
    ).pipe(
      map(async retorno => {
        retorno.Data = await LibGeral.ParseAndResolve(JSON.stringify(retorno.Data));
        return retorno
      }),
      switchMap(retorno => retorno)
    );
  }

  public async alterarClinicaLogada(auth: {
    IdEmpresa: Number,
    Cliente: string,
    IdUsuario: Number,
    Login: string,
    Token: string,
    RefreshToken: string,
    Sistema: string
  }) {
    let headers = new HttpHeaders();
    headers = headers.set("Authorization", `Bearer ${auth.Token}`);
    headers = headers.set("AuthRefreshToken", auth.RefreshToken);

    return await this.apiService.Post(
      auth,
      {
        api: RestAPIType.Seguranca,
        controller: "Login",
        action: "AlterarClinicaLogada",
        headers: headers
      }
    ).toPromise()
  }

  public LogOut() {
    this.store.dispatch(new LogOutAction());
  }

  public refresh(): Observable<HttpResponse<any>> {
    this.store.dispatch(new ForcarRefreshToken({}));

    return this.apiService.Post<any, any>(
      {},
      {
        api: RestAPIType.Seguranca,
        controller: "Login",
        action: "RefreshToken",
      }
    )
      .pipe(
        tap((response) => {
          const possuiHeaders = LibGeral.estaPreenchido(response.Headers.get("authorization")) && LibGeral.estaPreenchido(response.Headers.get("authrefreshtoken"));

          if (possuiHeaders) {
            const token = response.Headers.get("authorization").split(" ")[1];
            const refreshToken = response.Headers.get("authrefreshtoken");
            this.store.dispatch(new AtualizarTokenRefresh({ Token: token, RefreshToken: refreshToken, TokenExpirado: false }));
          }
        }),
        take(1),

      );
  }

  async checkAutenticated(): Promise<any> {
    return new Promise((resolve) => {
      const observable = this.store.select(isAuthenticated);
      observable.pipe(take(1)).subscribe((value: boolean) => {
        resolve(value);
      });
    });
  }

  async hasUsuariosConvenio(): Promise<any> {
    return new Promise((resolve) => {
      const observable = this.store.select(getAuthenticatedUser);
      observable.subscribe((usuario: Usuario) => {
        if (LibGeral.estaPreenchido(usuario)) {
          resolve(usuario.IdConvenio > 0);
        } else {
          resolve(null);
        }
      });
    });
  }

  async hasUsuariosRepresentanteConvenio(): Promise<any> {
    return new Promise((resolve) => {
      const observable = this.store.select(getAuthenticatedUser);
      observable.subscribe((usuario: Usuario) => {
        if (LibGeral.estaPreenchido(usuario)) {
          const isRepresentante = UsuarioTipo[usuario.Tipo] === "C";
          resolve(isRepresentante);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getUsuarioTipo(): Promise<any> {
    return new Promise((resolve) => {
      const observable = this.store.select(getAuthenticatedUser);
      observable.subscribe((usuario: Usuario) => {
        if (LibGeral.estaPreenchido(usuario)) {
          resolve(UsuarioTipo[usuario.Tipo]);
        } else {
          resolve(null);
        }
      });
    });
  }

  login(): void {
    if (this.lock) {
      this.removerEventosResetToken();
      this.lock.show();
      const botaoFechamentoModal = document.querySelector(
        ".auth0-lock-close-button"
      );
      if (botaoFechamentoModal) {
        this.adicionarEventoClickParaFechamentoModal(botaoFechamentoModal);
      }
    }
  }
  adicionarEventoClickParaFechamentoModal(botao: Element): void {
    botao.addEventListener("click", () => this.tratarFechamentoModal(), false);
  }
  tratarFechamentoModal(): void {
    this.removerBlurFundo();
    this.logout();
  }

  /**
   * Remove token from localStorage
   * @returns void
   */
  logout(): Promise<any> {
    return new Promise((resolve) => {
      // Registrar o logout na API
      resolve(true);
    });
  }

  startTimerSessao(): void {
    if (this.timer === 0) {
      clearTimeout(this.timeout);
      // muda para modo modal
      this.lock = this.obterModal();
      this.login();
      this.aplicarBlurFundo();
      // this.logout();
    } else {
      this.timeout = setTimeout((_) => {
        this.timer--;
        this.startTimerSessao();
      }, 1000);
    }
  }
  resetTimerSessao(): void {
    this.timer = AuthService.TempoMaximoAutenticadoEmSegundos;
  }
  obterEventosParaToken(): Array<string> {
    return [
      "mousemove",
      "mousedown",
      "keypress",
      "DOMMouseScroll",
      "mousewheel",
      "touchmove",
      "MSPointerMove",
    ];
  }
  adicionarEventosAtualizacaoToken(): void {
    const eventos = this.obterEventosParaToken();
    eventos.forEach((evento) => {
      document.addEventListener(evento, <any>this.reset, false);
    });
  }
  removerEventosResetToken(): void {
    const eventos = this.obterEventosParaToken();
    eventos.forEach((evento) => {
      document.removeEventListener(evento, <any>this.reset);
    });
  }
  setupTimerSessao(): void {
    this.adicionarEventosAtualizacaoToken();
    this.startTimerSessao();
  }

  // todo revisar para criar solução que ira fazer que dados fiquem escondidos
  removerBlurFundo(): void {
    const app = <HTMLTableSectionElement>document.body.querySelector("section");
    app.style.filter = "";
  }

  aplicarBlurFundo(): void {
    const app = <HTMLTableSectionElement>document.body.querySelector("section");
    const blur = "blur(8px)";
    app.style.filter = blur;
  }
  obterModal(): any {
    const opcoes = this.obterOpcoesLock();
    opcoes.auth.redirect = false;
    const modal = this.obterLock(opcoes);
    modal.on("authenticated", (authResult) => {
      const token = authResult.idToken;
      this.removerBlurFundo();
    });
    return modal;
  }
  obterLock(opcoes: any = this.obterOpcoesLock()): any {
    return null;
  }
  obterOpcoesLock(): any {
    return null;
  }
  limparTradutorSub(): void {
    if (this.tradutorSub) {
      this.tradutorSub.unsubscribe();
    }
  }
  ngOnDestroy(): void {
    this.removerEventosResetToken();
    this.limparTradutorSub();
  }
}
