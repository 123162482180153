import { DataPEP } from './dataPep.model';
import { LibGeral } from '../../../../core/libraries/libGeral';
import { PEPBase } from './pepBase.model';
import { PEPProgramaApoio } from './pepProgramaApoio.model';

const paraDataPEP = (data): DataPEP => {
  return data
    ? new DataPEP(new Date(data))
    : null;
};

export class PEPProgramasApoioPaciente extends PEPBase {
  public set ProgramaNome(valor: string) { /* */ }
  public get ProgramaNome(): string { return this.Programa ? this.Programa.Nome : null; }
  public Programa: PEPProgramaApoio;
  public DataInicio: DataPEP;
  public DataFim: DataPEP;
  public Motivo: string;

  static Criar(obj: any): PEPProgramasApoioPaciente {
    const programaDeApoio = Object.assign(new PEPProgramasApoioPaciente(), obj);
    programaDeApoio.DataInicio = paraDataPEP(obj.DataInicio);
    programaDeApoio.DataFim = paraDataPEP(obj.DataFim);
    return programaDeApoio;
  }
  constructor() {
    super();
    this.DataInicio = new DataPEP(new Date());
  }


  IsFinalizado(): boolean {
    return this.DataFim != null && LibGeral.estaPreenchido(this.DataFim.Data);
  }
  Terminar(): void {
    this.DataFim = new DataPEP(new Date());
  }
  toString(): string {
    // todo
    return null;
  }
}
