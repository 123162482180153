import {
  IArquivoUpload,
  IAtualizarFotoPacienteDTO,
} from "@gemed-core/interfaces/gemed.interface";
import { createAction, props } from "@ngrx/store";
import { type } from "../../../../core/libraries";
import { PagingInfo } from "../../../../core/models";
import { Documento } from "../../../../gestao-documentos/shared/models";

export const ActionTypes = {
  SELECIONAR_PACIENTE: type("[prontuario] Paciente selecionado."),
  PACIENTE_SELECIONADO: type("[prontuario] Paciente selecionado com sucesso."),
  DESELECIONAR_PACIENTE: type("[prontuario] Paciente deselecionado."),
  CARREGAR_PACIENTE_FOTO: type("[prontuario] Carregado foto paciente."),
  CARREGAR_PACIENTE_FOTO_SUCESSO: type(
    "[prontuario] Carregado foto paciente com sucesso."
  ),
  CARREGAR_PACIENTE_FOTO_ERRO: type(
    "[prontuario] Carregado foto paciente com erro."
  ),
  CARREGAR_ALERGIAS: type("[prontuario] Alergias paciente sendo carregadas."),
  CARREGAR_ALERGIAS_SUCESSO: type(
    "[prontuario] Alergias paciente carregadas com sucesso."
  ),
  CARREGAR_WIDGET: type("[prontuario] Carregado widget."),
  CARREGAR_TAB_PRINCIPAL: type("[prontuario] Carregar tab principal."),
  CARREGAR_TAB_PRINCIPAL_SUCESSO: type(
    "[prontuario] Carregar tab principal sucesso."
  ),
  LIMPAR_TAB_PRINCIPAL: type("[prontuario] Limpar tab principal."),
  CARREGAR_RESUMO_EVOLUCOES_RESPOSTA: type(
    "[prontuario] Carregado resumo respostas."
  ),
  CARREGAR_RESUMO_EVOLUCOES_RESPOSTA_SUCESSO: type(
    "[prontuario] Carregado resumo respostas com sucesso."
  ),
  CARREGAR_EVOLUCOES_RESPOSTA_LISTA: type(
    "[prontuario] Carregado evoluções lista."
  ),
  CARREGAR_EVOLUCOES_RESPOSTA_LISTA_SUCESSO: type(
    "[prontuario] Carregado evoluções lista sucesso."
  ),
  CARREGAR_PRESCRICOES: type("[prontuario] Carregar prescrições."),
  CARREGAR_PRESCRICOES_SUCESSO: type(
    "[prontuario] Carregar prescrições sucesso."
  ),
  LIMPAR_PRESCRICOES: type("[prontuario] Limpar prescrições."),
  CARREGAR_AGENDAS: type("[prontuario] Carregar agendas."),
  CARREGAR_AGENDAS_SUCESSO: type("[prontuario] Carregar agendas sucesso."),
  LIMPAR_AGENDAS: type("[prontuario] Limpar agendas."),
  CARREGAR_DOCUMENTOS: type("[prontuario] Carregar documentos."),
  CARREGAR_DOCUMENTOS_SUCESSO: type(
    "[prontuario] Carregar documentos sucesso."
  ),
  CARREGAR_CONTEUDO_DOCUMENTO: type(
    "[prontuario] Carregar conteudo documento."
  ),
  CARREGAR_CONTEUDO_DOCUMENTO_SUCESSO: type(
    "[prontuario] Carregar conteudo documento sucesso."
  ),
  CARREGAR_CONTEUDO_DOCUMENTO_SEM_SUCESSO: type(
    "[prontuario] Carregar conteudo documento sem sucesso."
  ),
  REQUISITAR_CRIAR_EVOLUCAO: type(
    "[prontuario] Requisitar criação de evolução."
  ),
  CRIAR_EVOLUCAO_QUESTIONARIO: type(
    "[prontuario] Criar evolução questionario."
  ),
  ATUALIZAR_EVOLUCAO_QUESTIONARIO: type(
    "[prontuario] Atualizar evolução questionario."
  ),
  REMOVER_EVOLUCAO_QUESTIONARIO: type(
    "[prontuario] Remover evolução questionario."
  ),
  REMOVER_TODOS_QUESTIONARIOS_EVOLUCAO: type(
    "[prontuario] Remover todos evolução questionario."
  ),
  REMOVER_QUESTIONARIOS_EVOLUCAO_PACIENTE: type(
    "[prontuario] Remover todos evolução paciente questionario."
  ),
  ADICIONAR_QUESTIONARIO_PERGUNTA: type(
    "[prontuario] Adicionar questionario pergunta."
  ),
  REMOVER_QUESTIONARIO_PERGUNTA: type(
    "[prontuario] Remover questionario pergunta."
  ),
  CRIAR_EVOLUCAO_RESPOSTA: type("[prontuario] Criar evolucao resposta."),
  EXCLUIR_EVOLUCAO_RESPOSTA: type("[prontuario] Excluir evolução resposta."),
  ATUALIZAR_EVOLUCAO_RESPOSTA: type(
    "[prontuario] Atualizar evolução resposta."
  ),
  ADICIONAR_ERRO_QUESTIONARIO: type(
    "[prontuario] Adicionar erro questionario."
  ),
  REMOVER_ERRO_QUESTIONARIO: type("[prontuario] Remover erro questionario."),
  INICIADA_EDICAO_PERGUNTA: type("[prontuario] Iniciada edição pergunta."),
  FINALIZADA_EDICAO_PERGUNTA: type("[prontuario] Finalizado edição pergunta."),
  INICIAR_CARREGAMENTO_PERGUNTA: type(
    "[prontuario] Iniciar carregamento pergunta."
  ),
  FINALIZAR_CARREGAMENTO_PERGUNTA: type(
    "[prontuario] Finalizar carregamento pergunta."
  ),
  CARREGAR_RESUMO_PACIENTE: type("[prontuario] Carregando resumo paciente."),
  CARREGAR_ULTIMA_EVOLUCAO_PACIENTE: type(
    "[prontuario] Carregando ultima evolução paciente."
  ),
  CARREGAR_ULTIMA_EVOLUCAO_PACIENTE_SUCESSO: type(
    "[prontuario] Carregado ultima evolução paciente com sucesso."
  ),
  CARREGAR_RESUMO_PACIENTE_SUCCESS: type(
    "[prontuario] Carregamento com sucesso do resumo paciente"
  ),
  CARREGAR_INFORMACOES_RESUMO_PACIENTE: type(
    "[prontuario] Carregamento informações resumo paciente"
  ),
  CARREGAR_INFORMACOES_RESUMO_PACIENTE_SUCCESS: type(
    "[prontuario] Carregamento com sucesso informações resumo paciente"
  ),
  SALVAR_RESUMO_PACIENTE: type("[prontuario] Salvar resumo paciente"),
  SALVAR_RESUMO_PACIENTE_SUCCESS: type(
    "[prontuario] Salvo resumo paciente Success"
  ),
  LOADING_RESUMO_PACIENTE: type("[prontuario] Loading resumo paciente"),
  PACIENTE_NAO_ENCONTRADO: type("[prontuario] Paciente não encontrado"),
  PACIENTE_IS_LOADING: type("[prontuario] Paciente começou loading"),
  PACIENTE_LOADING_FINISH: type("[prontuario] Paciente Loading finalizado"),
  CARREGAR_PROGRAMAS_APOIO_PACIENTE: type(
    "[prontuario] Carrega programas apoio paciente"
  ),
  CARREGAR_PROGRAMAS_PACIENTE_SUCESSO: type(
    "[prontuario] Programas de apoio do paciente carregados"
  ),
  DOCUMENTO_IS_LOADING: type("[prontuario] Iniciado loading do documento..."),
  DOCUMENTO_FINISH_LOADING: type(
    "[prontuario] Documento loading finalizado ..."
  ),
  INSERIR_PRESCRICOES_QUESTIONARIO_EDICAO: type(
    "[prontuario] inseri prescrições no questionário em edição"
  ),
  ATUALIZAR_RESPOSTAS_QUESTIONARIO: type(
    "[prontuario] Atualizar respostas questionario."
  ),
  SALVAR_STATE_LOCALSTORAGE: type("[prontuario] State salvo no localStorage."),
  LOAD_STATE_LOCALSTORAGE: type("[prontuario] State carregado do localStorage"),
  FECHAR_MENU_LATERAL: type("[prontuario] Fechar menu Lateral"),
  LIMPAR_PRESCRICAO_SELECIONADA: type(
    "[prontuario] Limpar prescrição selecionada"
  ),
  SELECIONAR_PRESCRICAO: type("[prontuario] Selecionar prescrição"),
  ATUALIZAR_BLOQUEIO_PACIENTE: type(
    "[prontuario] Atualizar bloqueio de dados do paciente"
  ),
  ATUALIZAR_PACIENTE_FOTO: type("[prontuario] Atualizar foto do paciente"),
};
const mensagemBase = "Ocorreu um erro ao tentar carregar";

export const selecionarPacienteAction = createAction(
  ActionTypes.SELECIONAR_PACIENTE,
  props<{ payload: Number }>()
);

export const deselecionarPacienteAction = createAction(
  ActionTypes.DESELECIONAR_PACIENTE
);

export const atualizarFotoPacienteAction = createAction(
  ActionTypes.ATUALIZAR_PACIENTE_FOTO,
  props<{ payload: IAtualizarFotoPacienteDTO }>()
);

export const carregarFotoPacienteAction = createAction(
  ActionTypes.CARREGAR_PACIENTE_FOTO,
  props<{ payload: Number }>()
);

export const carregarAlergiasPacienteAction = createAction(
  ActionTypes.CARREGAR_ALERGIAS,
  props<{ payload: Number }>()
);

export const carregarAlergiasPacienteSucessoAction = createAction(
  ActionTypes.CARREGAR_ALERGIAS_SUCESSO,
  props<{ payload: any }>()
);

export const carregarProgramasApoioPacienteAction = createAction(
  ActionTypes.CARREGAR_PROGRAMAS_APOIO_PACIENTE,
  props<{ payload: any }>()
);

export const carregarProgramasApoioPacienteSucessoAction = createAction(
  ActionTypes.CARREGAR_PROGRAMAS_PACIENTE_SUCESSO,
  props<{ payload: any }>()
);

export const carregarResumoEvolucoesRespostasAction = createAction(
  ActionTypes.CARREGAR_RESUMO_EVOLUCOES_RESPOSTA,
  props<{ payload: any }>()
);

export const carregarResumoEvolucoesRespostasSucessoAction = createAction(
  ActionTypes.CARREGAR_RESUMO_EVOLUCOES_RESPOSTA_SUCESSO,
  props<{ payload: any }>()
);

export const carregarResumoEvolucoesListaAction = createAction(
  ActionTypes.CARREGAR_EVOLUCOES_RESPOSTA_LISTA,
  props<{ payload: any }>()
);

export const carregarResumoEvolucoesListaSucessoAction = createAction(
  ActionTypes.CARREGAR_EVOLUCOES_RESPOSTA_LISTA_SUCESSO,
  props<{ payload: any }>()
);

export const carregarPrescricoesAction = createAction(
  ActionTypes.CARREGAR_PRESCRICOES,
  props<{
    payload: { idPaciente: number; status: any; pagingInfo: PagingInfo };
  }>()
);

export const carregarPrescricoesSucessoAction = createAction(
  ActionTypes.CARREGAR_PRESCRICOES_SUCESSO,
  props<{ payload: any }>()
);

export const carregarAgendasAction = createAction(
  ActionTypes.CARREGAR_AGENDAS,
  props<{ payload: any }>()
);

export const carregarAgendasSucessoAction = createAction(
  ActionTypes.CARREGAR_AGENDAS_SUCESSO,
  props<{ payload: any }>()
);

export const carregarDocumentosAction = createAction(
  ActionTypes.CARREGAR_DOCUMENTOS,
  props<{ payload: any }>()
);

export const carregarDocumentosSucessoAction = createAction(
  ActionTypes.CARREGAR_DOCUMENTOS_SUCESSO,
  props<{ payload: Documento[] }>()
);

export const documentoLoadingStart = createAction(
  ActionTypes.DOCUMENTO_IS_LOADING
);

export const documentoLoadingFinish = createAction(
  ActionTypes.DOCUMENTO_FINISH_LOADING
);

export const carregarConteudoDocumentoAction = createAction(
  ActionTypes.CARREGAR_CONTEUDO_DOCUMENTO,
  props<{ payload: Number }>()
);

export const carregarConteudoDocumentoSucessoAction = createAction(
  ActionTypes.CARREGAR_CONTEUDO_DOCUMENTO_SUCESSO,
  props<{ payload: any }>()
);

export const carregarConteudoDocumentoSemSucessoAction = createAction(
  ActionTypes.CARREGAR_CONTEUDO_DOCUMENTO_SEM_SUCESSO
);
export const requisitarCriarEvolucaoAction = createAction(
  ActionTypes.REQUISITAR_CRIAR_EVOLUCAO
);

export const criarQuestionarioEvolucaoAction = createAction(
  ActionTypes.CRIAR_EVOLUCAO_QUESTIONARIO,
  props<{ payload: any }>()
);

export const atualizarQuestionarioEvolucaoAction = createAction(
  ActionTypes.ATUALIZAR_EVOLUCAO_QUESTIONARIO,
  props<{ payload: any }>()
);

export const atualizarRepostasQuestionario = createAction(
  ActionTypes.ATUALIZAR_RESPOSTAS_QUESTIONARIO,
  props<{ payload: any }>()
);

export const removerQuestionarioEvolucaoAction = createAction(
  ActionTypes.REMOVER_EVOLUCAO_QUESTIONARIO,
  props<{ payload: any }>()
);

export const removerTodosQuestionarioEvolucaoAction = createAction(
  ActionTypes.REMOVER_TODOS_QUESTIONARIOS_EVOLUCAO
);

export const removerQuestionarioEvolucaoPacienteAction = createAction(
  ActionTypes.REMOVER_QUESTIONARIOS_EVOLUCAO_PACIENTE,
  props<{ idPaciente: number }>()
);

export const adicionarErroQuestionarioAction = createAction(
  ActionTypes.ADICIONAR_ERRO_QUESTIONARIO,
  props<{ payload: any }>()
);

export const removerErroQuestionarioAction = createAction(
  ActionTypes.REMOVER_ERRO_QUESTIONARIO,
  props<{ payload: any }>()
);

export const comecarEdicaoPerguntaAction = createAction(
  ActionTypes.INICIADA_EDICAO_PERGUNTA,
  props<{ payload: any }>()
);

export const finalizarEdicaoPerguntaAction = createAction(
  ActionTypes.FINALIZADA_EDICAO_PERGUNTA,
  props<{ payload: any }>()
);

export const iniciarCarregamentoPerguntaAction = createAction(
  ActionTypes.INICIAR_CARREGAMENTO_PERGUNTA,
  props<{ payload: Number }>()
);

export const finalizarCarregamentoPerguntaAction = createAction(
  ActionTypes.FINALIZAR_CARREGAMENTO_PERGUNTA,
  props<{ payload: Number }>()
);

export const criarQuestionarioRespostaAction = createAction(
  ActionTypes.CRIAR_EVOLUCAO_RESPOSTA,
  props<{ payload: any }>()
);

export const atualizarQuestionarioRespostaAction = createAction(
  ActionTypes.ATUALIZAR_EVOLUCAO_RESPOSTA,
  props<{ payload: any }>()
);

export const excluirQuestionarioRespostaAction = createAction(
  ActionTypes.EXCLUIR_EVOLUCAO_RESPOSTA,
  props<{ payload: Number }>()
);

export const limparAgendasAction = createAction(ActionTypes.LIMPAR_AGENDAS);

export const limparPrescricaoAction = createAction(
  ActionTypes.LIMPAR_PRESCRICOES
);

export const limparTabPrincipalAction = createAction(
  ActionTypes.LIMPAR_TAB_PRINCIPAL
);

export const carregarTabPrincipalAction = createAction(
  ActionTypes.CARREGAR_TAB_PRINCIPAL,
  props<{ payload: Number }>()
);

export const carregarTabPrincipalSucessoAction = createAction(
  ActionTypes.CARREGAR_TAB_PRINCIPAL_SUCESSO,
  props<{ payload: Map<string, []> }>()
);

export const pacienteSelecionadoAction = createAction(
  ActionTypes.PACIENTE_SELECIONADO,
  props<{ payload: any }>()
);

export const carregarFotoPacienteSucessoAction = createAction(
  ActionTypes.CARREGAR_PACIENTE_FOTO_SUCESSO,
  props<{ payload: any }>()
);

export const carregarFotoPacienteErroAction = createAction(
  ActionTypes.CARREGAR_PACIENTE_FOTO_ERRO
);

export const carregarResumoPacienteAction = createAction(
  ActionTypes.CARREGAR_RESUMO_PACIENTE,
  props<{ payload: Number }>()
);

export const carregarResumoPacienteSuccessAction = createAction(
  ActionTypes.CARREGAR_RESUMO_PACIENTE_SUCCESS,
  props<{ payload: any }>()
);

export const carregarUltimaEvolucaoPacienteAction = createAction(
  ActionTypes.CARREGAR_ULTIMA_EVOLUCAO_PACIENTE,
  props<{ payload: number }>()
);

export const carregarUltimaEvolucaoPacienteSuccessAction = createAction(
  ActionTypes.CARREGAR_ULTIMA_EVOLUCAO_PACIENTE_SUCESSO,
  props<{ payload: any }>()
);

export const carregarInformacoesResumoPacienteAction = createAction(
  ActionTypes.CARREGAR_INFORMACOES_RESUMO_PACIENTE,
  props<{ payload: any }>()
);

export const carregarInformacoesResumoPacienteSuccessAction = createAction(
  ActionTypes.CARREGAR_INFORMACOES_RESUMO_PACIENTE_SUCCESS,
  props<{ payload: any }>()
);

export const inserirPrescricoesQuestionarioEdicao = createAction(
  ActionTypes.INSERIR_PRESCRICOES_QUESTIONARIO_EDICAO,
  props<{ payload: any }>()
);

export const salvarResumoPacienteAction = createAction(
  ActionTypes.SALVAR_RESUMO_PACIENTE,
  props<{ payload: any }>()
);

export const salvarResumoPacienteSuccessAction = createAction(
  ActionTypes.SALVAR_RESUMO_PACIENTE_SUCCESS,
  props<{ payload: any }>()
);

export const loadingResumoPacienteAction = createAction(
  ActionTypes.LOADING_RESUMO_PACIENTE
);

export const pacienteIsLoadingAction = createAction(
  ActionTypes.PACIENTE_IS_LOADING
);

export const pacienteLoadingFinish = createAction(
  ActionTypes.PACIENTE_LOADING_FINISH
);

export const fecharMenuLateral = createAction(ActionTypes.FECHAR_MENU_LATERAL);

export const limparPrescricaoSelecionada = createAction(
  ActionTypes.LIMPAR_PRESCRICAO_SELECIONADA
);

export const saveStateToLocalStorage = createAction(
  ActionTypes.SALVAR_STATE_LOCALSTORAGE
);

export const loadStateFromLocalStorage = createAction(
  ActionTypes.LOAD_STATE_LOCALSTORAGE
);

export const selecionarPrescricao = createAction(
  ActionTypes.SELECIONAR_PRESCRICAO,
  props<{ payload: HTMLElement }>()
);

export const atualizarBloqueioPacienteAction = createAction(
  ActionTypes.ATUALIZAR_BLOQUEIO_PACIENTE,
  props<{ payload: any }>()
);
