import { ActionTypes } from "../actions/route.actions";
import { RouteState } from "../state/route.state";

export function RouteReducer(
  state: RouteState = new RouteState(),
  action: any
): RouteState {
  switch (action.type) {
    case ActionTypes.NAVAGATE_ACCEPT:
      return Object.assign({}, state, {
        ultimaRotaAcessada: action.payload,
      });
    default:
      return state;
  }
}
