export enum IpDialogColor {
  gemedAzul = 'azul',
  gemedVermelho = 'vermelho',
  gemedAmarelo = 'amarelo'
}

export const getCoresDialog = (selectedColor: IpDialogColor) => {
  switch (selectedColor) {
      case IpDialogColor.gemedVermelho:
          return { backgroundColor: '#d64541', fontColor: '#FFFFFF' };
      case IpDialogColor.gemedAmarelo:
          return { backgroundColor: '#fad859', fontColor: '#3f51b5' };
      case IpDialogColor.gemedAzul:
      default:
          return { backgroundColor: '#345ca5', fontColor: '#FFFFFF'};
  }
}
