/**
 * Classe de configuração para paginação de registros em chamadas HTTP.
 *
 * @export
 * @class PagingInfo
 */
export class PagingInfo implements IPaginacao {
  private static _HeaderPrefix = 'x-paging-';
  static PageNumberHeaderName = `${PagingInfo._HeaderPrefix}pageNo`;
  static PageSizeHeaderName = `${PagingInfo._HeaderPrefix}pagesize`;
  static PageCountHeaderName = `${PagingInfo._HeaderPrefix}pagecount`;
  static TotalRecordCountHeaderName = `${PagingInfo._HeaderPrefix}totalrecordcount`;

  /**
   * Representa o número de registros apresentados.
   *
   * @type {number}
   */
  PageSize: number;

  /**
   * Número da página
   *
   * @type {number}
   */
  PageNumber: number;

  /**
   * Número total de reigstros no sistema.
   *
   * @type {number}
   */
  TotalRecordCount: number;

  /**
   * Número total de páginas.
   *
   * @type {number}
   */
  PageCount: number;

  /**
   * Campo pelo qual os registros devem ser ordenados.
   *
   * @type {string}
   */
  Order: string;

  Reset(): void {
    this.PageNumber = 1;
  }

  constructor(pageSize: number, pageNumber?: number, totalRecordCount?: number) {

    this.PageSize = pageSize;
    this.PageNumber = pageNumber || 1;

    if (totalRecordCount) {
      this.TotalRecordCount = totalRecordCount;
      this.PageCount = this.PageSize > 0 ? Math.ceil(this.TotalRecordCount / this.PageSize) : 0;
    }
  }
}
