import { Component, Inject, ElementRef } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { IPToastOptions } from "src/app/core/toast/ipToast.service";
import environment from "src/environments/environment";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ip-toast',
  template: `
    <div class="toastPanel" fxFlex="1 1 auto" fxLayoutAlign="center center">
      <div class="toastTitulo">
        <mat-icon *ngIf='tipo === 0' class="icons-alergias">
          <img style="width: 25px" src="{{urlBase}}assets/icons/iconeChecked.svg" />
        </mat-icon>
        <mat-icon *ngIf='tipo === 1' class="icons-alergias">
          <img style="width: 25px" src="{{urlBase}}assets/icons/iconeInformation.svg" />
        </mat-icon>
        <mat-icon *ngIf='tipo === 2' class="icons-alergias">
          <img style="width: 25px" src="{{urlBase}}assets/icons/iconeError.svg" />
        </mat-icon>
        <mat-icon *ngIf='tipo === 3' class="icons-alergias">
          <img style="width: 25px" src="{{urlBase}}assets/icons/iconeAtencao.svg" />
        </mat-icon>
      </div>
      <div class="toastContent" style="padding-left:16px;">
        <span> {{mensagem}} </span>
      </div>
    </div>`,
    styleUrls: ['./ip-toast-default.component.scss'],
})


export class IpToastComponent {
  public urlBase = environment.domain;
  public titulo: string;
  public mensagem: string;
  public tipo: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IPToastOptions, matSnackBar: MatSnackBarRef<any>, elementRef: ElementRef) {
    this.tipo = data.tipo;
    this.titulo = data.titulo;
    this.mensagem = data.mensagem;
    elementRef.nativeElement.addEventListener('click', () => {
      matSnackBar.dismiss();
    });
  }
}
