import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewContainerRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { MatTab } from '@angular/material/tabs';
@Component({
  selector: 'ip-tab',
  template: `
    <mat-tab [id]="tabId" label="{{titulo}}">
      <ng-content></ng-content>
    </mat-tab>`
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IPTab {
  @ViewChildren(MatTab) mdTab: QueryList<MatTab>;
  /**
   * Título que é apresentado na tab.
   *
   * @type {string}
   */
  @Input() titulo: string;

  /**
   * Desabilita tab, impossibilitando o usuário selecionar a mesma.
   *
   * @type {boolean}
   */
  @Input() disabled = false;

  /* Identicador único da TAB, que vincula o elemento <li> (tab) e a <div> (conteúdo da tab).
   * Através deste identificador o componente seta a div do conteúdo para visível quando a
   * tab é selecionada. */
  tabId: string;

  private _active = false;
  get active(): boolean {
    return this._active;
  }
  @Input() set active(value: boolean) {
    value = value || false;

    if (this._active !== value) {
      this._active = value;

      if (this._active) {
        this.onActiveted.emit(this);
      } else {
        this.onDeactivated.emit(this);
      }
    }
  }

  /**
   * Evento disparado quando a tab é ativada (selecionada).
   *
   * @type {EventEmitter<IPTab>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onActiveted: EventEmitter<IPTab>;

  /**
   * Evento disparada quando a tab é desativada (deselecionada).
   *
   * @type {EventEmitter<IPTab>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDeactivated: EventEmitter<IPTab>;

  private elementRef: ElementRef;
  private elementTab: HTMLElement;

  constructor(elementRef: ElementRef, viewContainerRef: ViewContainerRef) {
    this.onActiveted = new EventEmitter<IPTab>();
    this.onDeactivated = new EventEmitter<IPTab>();
    this.elementRef = elementRef;
  }

  tratarTamanhoTitulo(titulo: string): string {
    if (!titulo || titulo.length <= 20) {
      return titulo;
    }

    return titulo.substr(0, 20) + (titulo.length > 20 ? '...' : '');
  }

  setStatus(status: boolean): void {
    // http://tableless.com.br/entendendo-os-reflows-2/
    // mostro uma melhora de ~200 ms para realizar o reflow e update da arvore
    if (this.elementTab === undefined) {
      this.elementTab = <HTMLElement>(<HTMLElement>this.elementRef.nativeElement).querySelector('div');
    }

    if (status) {
      this.elementTab.style.transform = 'scale3d(1,1,1)';
      this.elementTab.style.position = 'relative';
    } else {
      this.elementTab.style.transform = 'scale3d(0, 0, 0) translateY(-100%)';
      this.elementTab.style.position = 'absolute';
    }

    this.active = status;
  }
}
