import { IUsuario, IMenuDetalhe, IUsuarioSenhaHistorico, IProcessoObjeto } from "@gemed-core/interfaces/gemed.interface";
import { Clinica } from "./clinica.model";
import { UsuarioTipo } from "src/app/users/shared/model/UsuarioTipo.enum";
import { ICliente, IUsuarioAutenticado } from "ip_types_ts/common-typings";

export class Usuario implements IUsuario {
  Id: number;
  IdUsuario: number;
  //NomeUsuario: string;
  IdIntegracao: string;
  Nome: string;
  Senha: string;
  DataSenha?: Date;
  Admin: string;
  Status: string;
  Hash: string;
  Clinica: Clinica;
  Direitos: IProcessoObjeto[] = [];
  Menu: IMenuDetalhe[];
  SenhaHistoricos: IUsuarioSenhaHistorico[];
  Tipo: UsuarioTipo;

  Login: string;
  Email: string;
  IdGrupo: number;
  Grupo: string;
  Cidade: string;
  UF: string;
  CategoriaProfissional: string;
  IdProfissional: number;
  Clientes: ICliente[];

  // IUsuarioAutenticadoDTO - COmmon typings
  DescricaoProfissional: string;
  IdConvenio: number;
  NomeRazao: string;
  NomeConvenio: string;
  Usuario: string;

  // IUsuarioAutenticado - Common typings
  Token: string;
  RefreshToken: string;
  TokenExpirado: boolean;



  constructor() {

  }

  public static MapIUsuarioAutenticadoParaUsuario(usuarioAutenticado: IUsuarioAutenticado): Usuario {
    const dadosUsuario = usuarioAutenticado.usuario;
    return Object.assign(new Usuario(), {
      Id: dadosUsuario.IdUsuario,
      IdUsuario: dadosUsuario.IdUsuario,
      Nome: dadosUsuario.Nome,
      DataSenha: dadosUsuario.DataSenha,
      Admin: dadosUsuario.Admin,
      Status: dadosUsuario.Status,
      Direitos: dadosUsuario.Direitos,
      Tipo: dadosUsuario.Tipo,
      NomeConvenio: dadosUsuario.NomeConvenio,
      IdGrupo: dadosUsuario.IdGrupo,
      Grupo: dadosUsuario.Grupo,
      Cidade: dadosUsuario.Cidade,
      UF: dadosUsuario.UF,
      CategoriaProfissional: dadosUsuario.CategoriaProfissional,
      IdProfissional: dadosUsuario.IdProfissional,
      DescricaoProfissional: dadosUsuario.DescricaoProfissional,
      IdConvenio: dadosUsuario.IdConvenio,
      Usuario: dadosUsuario.Usuario,
      Token: usuarioAutenticado.token,
      RefreshToken: usuarioAutenticado.refreshToken
    });
  }

}
