<button
  [aria-label]="textoParaPessoasCegas"
  [ngClass]="['buttonQuestionario', color]"
  [disabled]="disabled"
  [disableRipple]="!efeitoRipple"
  class="btn"
  (click)="clickEvent($event)"
  [type]="htmlTypeButton"
  #buttonDefault
>
  <mat-icon *ngIf="materialIconName">{{ materialIconName }}</mat-icon>
  <span class="auxiliar" *ngIf="textoAuxiliar">{{
    textoAuxiliar | translate
  }}</span>
  <ng-content></ng-content>
</button>
