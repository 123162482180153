import { Component, OnInit, ElementRef, ViewChild, Inject, Renderer2, AfterViewInit } from '@angular/core';
import { IPDialogService } from '@gemed-core/dialog/ipDialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ip-preview-file',
  templateUrl: './ip-preview-file.component.html',
  styleUrls: ['./ip-preview-file.component.scss']
})
export class IpPreviewFileComponent implements AfterViewInit {

  public imageBase64 : any;
  public id : number;
  public type : string;
  private dialogService: IPDialogService;
  @ViewChild('container') container : ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogService: IPDialogService,
    public dialogRef: MatDialogRef <IpPreviewFileComponent>, 
    private _renderer: Renderer2
  ) {
    this.dialogService = dialogService;
    this.imageBase64 = data.imageBase64;
    this.id = data.id;
    this.type = data.type;
  }

  ngAfterViewInit(): void {
    this.mostrarPreview();
  }


  // Ação que fecha o popup
  fechar(){
    this.dialogRef.close();
  }

  /**
   * @todo ajustar o grid do componente html e remover tamanhos fixos, para que esse componente seja responsivo.
   * 
  */
  mostrarPreview(): void{
    var obj = document.createElement('object');
    obj.style.width = '100%';
    obj.style.height = '842pt';
    obj.type = this.type;//'application/pdf';
    obj.data = this.imageBase64;
    this._renderer.appendChild(this.container.nativeElement, obj);
  }

  abrirEmUmaNovaJanela(){
    let w = window.open("");
    let obj = w.document.createElement('object');
    obj.style.width = '100%';
    obj.style.height = '842pt';
    obj.type = this.type;//'application/pdf';
    obj.data = this.imageBase64;
    w.document.body.appendChild(obj);
  }


}
