export const DIREITO_ALTERAR = "obAlterar";
export const DIREITO_SOMENTE_RASCUNHO = "cpPrescreveSomentePrePrescricao";
export const DIREITO_ALTERAR_MEDICO = "cpAlterarMedicoPrescricao";
export const DIREITO_INCLUIR = "obIncluir";
export const PROCESSO = "Prescrição";


export const CLINICAS_CARDIO = [
    "CISAME",
    "EcoFetal",
    "Ingastro",
    "Interprocess",
    "Cecor",
    "OcularCenter",
    "EcoMoinhos",
    "JaqueCostaCoelho",
    "OrtopediaZonaSul",
    "ICI",
    "CACV",
    "Apresentacao"

]


export const CHAVE_PRIMEIRO_ACESSO = "@Gemed:ProfissionalPrimeiroAcesso:";
export const CHAVE_PROFISSIONAL_PREFERENCIAS_FILTRO_AGENDA = "@Gemed:ProfissionalPreferenciasFiltroAgenda:";