import { Component, OnInit } from '@angular/core';
import { Usuario } from '@gemed-core/models/usuario.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChangeURLAction } from '../../core/store/actions/route.actions';
import { getAuthenticatedUser } from '../../core/store/reducers/auth.reducer';
import { GemedState } from '../../core/store/state';



/**
 * The user's account.
 * @class MyAccountComponent
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  // the authenticated user
  public user: Observable<Usuario>;

  /**
   * @constructor
   */
  constructor(private store: Store<GemedState>) { }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @method ngOnInit
   */
  public ngOnInit() {
    // get authenticated user
    this.user = <Observable<Usuario>>this.store.select(getAuthenticatedUser);
  }

  /**
   * Go to the home page.
   * @method home
   */
  public home() {
    this.store.dispatch(new ChangeURLAction('/'));
  }

  /**
   * Sign out.
   * @method home
   */
  public signOut() {
    this.store.dispatch(new ChangeURLAction('/users/log-out'));
  }

}
