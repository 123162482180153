import { Component, Host, Inject } from '@angular/core';
import { IPRowDirective } from './ip-row.directive';

@Component({
  selector: 'ip-cell-edit-buttons',
  template: `
      <ip-button-icon
        *ngIf="!isRowEditing"
        [disabled]="!allowEdit"
        ip-button ip-icon
        class="mat-icon-button mat-primary"
        (click)="start($event);"
        aria-label="edit">
        <i style="cursor: pointer;" class="material-icons">edit</i>
      </ip-button-icon>
      <ip-button-icon
        *ngIf="!isRowEditing"
        [disabled]="!allowRemove"
        ip-button ip-icon
        (click)="delete($event)"
        class="mat-warn"
        aria-label="delete">
          <i style="cursor: pointer;" class="material-icons">delete</i>
      </ip-button-icon>
      <ip-button-icon
        *ngIf="isRowEditing"
        ip-button ip-icon
        class="mat-icon-button mat-primary" (click)="confirm($event);" aria-label="edit">
        <i style="cursor: pointer;" matTooltip="{{tooltip}}" class="material-icons">done</i>
      </ip-button-icon>
      <ip-button-icon *ngIf="isRowEditing" ip-button ip-icon (click)="cancel($event)" class="mat-warn" aria-label="delete">
          <i style="cursor: pointer; color:#d94d42;" class="material-icons">cancel</i>
      </ip-button-icon>
  `
})
export class CellEditButtonsComponent {
  private row: IPRowDirective;
  public get tooltip(): string { return this.row.tooltip; }
  public get allowEdit(): boolean { return this.row && this.row.allowEdit; }
  public get allowRemove(): boolean { return this.row && this.row.allowRemove; }
  public get isRowEditing(): boolean { return this.row && this.row.editing; }

  constructor(@Inject(IPRowDirective) row: IPRowDirective) {
    this.row = row;
  }
  start(event: Event): void {
    event.stopPropagation();
    this.row.beginEdit();
  }
  delete(event: Event): void {
    event.stopPropagation();
    this.row.delete();
  }
  confirm(event: Event): void {
    event.stopPropagation();
    this.row.endEdit(true);
  }
  cancel(event: Event): void {
    event.stopPropagation();
    this.row.endEdit(false);
  }
}
