import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPTooltipComponent } from './ip-tooltip.component';
import { MaterialModule } from '../../../material/material.module';


@NgModule({
  imports: [MaterialModule],
  declarations: [IPTooltipComponent],
  exports: [IPTooltipComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPTooltipModule {
}
