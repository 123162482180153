import { AppState } from './app.state';
import { AuthState } from './auth.state';
import { RouteState } from './route.state';
import { IPRequest } from './request.state';
import { MenuBase } from '@pep-assistencial-store/state/menu.state';
// import { ProntuarioBase } from './prontuario.state';

export class GemedState {

  public app: AppState;
  public auth: AuthState;
  public router: RouteState;
  public menu: MenuBase;
  public requests: Map<string, IPRequest>;

  constructor() {
    this.app = new AppState();
    this.auth = new AuthState();
    this.router = new RouteState();
    this.requests = new Map();
    this.menu = new MenuBase();
  }
}
