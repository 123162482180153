import { ActionTypes, Actions } from '../actions/app.actions';
import { AppState, AppStateFactory } from '../state/app.state';

export function AppReducer(state: AppState = new AppState(), action): AppState {
  switch (action.type) {
    case ActionTypes.APRESENTAR_MENSAGEM:
      return Object.assign({}, state, {
        mensagens: action.payload
      });
    case ActionTypes.REGISTRAR_ERRO:
      return Object.assign({}, state, {
        erros: action.payload
      });
    case ActionTypes.ON_LINE:
      return {
        ...state,
        Online: true
      };
    case ActionTypes.OFF_LINE:
      return {
        ...state,
        Online: false
      };
    
    case ActionTypes.MODAL_CONFIRMA_ACAO:
      return {
        ...state,
        mostrarModal: action.payload
      }  
    default: return state;
  }
}
