<section class="modal-aviso">
  <div mat-dialog-title class="cabecalho-aviso-confirmacao">
    <div>
      <span class="fs-title">{{ titulo | translate }}</span>
    </div>
    <div
      style="
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
      "
    >
      <i class="material-icons" (click)="onFechar()">close</i>
    </div>
  </div>

  <div class="body-aviso">
    <p>
      {{ texto }}
    </p>

    <div class="btn-grid">
      <ip-button-default
      *ngIf="exibirBotaoCancelar"
        [color]="'secondary'"
        class="cmg-btn"
        (onClick)="onFechar()"
        >{{ textoBotaoCancelar | translate }}</ip-button-default
      >
      <ip-button-default
        *ngIf="exibirBotaoConfirmar"
        [color]="'primary'"
        class="cmg-btn"
        (onClick)="onGravar()"
        >{{ textoBotaoConfirmar | translate }}</ip-button-default
      >
    </div>
  </div>
</section>
