import { CdkDrag } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'ip-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],


})
export class ModalComponent implements OnDestroy, AfterViewInit {

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFechar: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(CdkDrag) cdkDrag: CdkDrag;
  @ViewChild('conteudo', { read: ViewContainerRef }) viewContainerRef;
  isVisible: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private componentFactoryResolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.data.tipoComponente) {
      this.changeDetectorRef.detach();

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.tipoComponente);

      this.viewContainerRef.clear();

      const componentRef = this.viewContainerRef.createComponent(componentFactory);

      if (this.data.props !== null) {
        this.data.props.map(({ prop, value }) => {
          componentRef.instance[prop] = value;
        })
      }
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.reattach();
      this.changeDetectorRef.detectChanges();
    }

  }

  onDragDropped() {
    //work around corrigido na versão 8 do angular material.
    this.cdkDrag._dragRef['_previewRect'] = undefined;
    this.cdkDrag._dragRef['_boundaryRect'] = undefined;
  }

  Fechar() {
    this.onFechar.emit(true);
  }

  ngOnDestroy() {
    this.onFechar.unsubscribe();
  }
}
