<div class="sidebar-header header-cover">
  <i class="material-icons icon-person"> person </i>
  <div class="sidebar-brand sidebar-user">
    <label class="usuario-logado">{{ NomeUsuario$ | async }} </label>
    <label class="usuario-tipo">{{ categoriaProfissional$ | async }}</label>
  </div>
</div>
<ip-menu-lateral-list
  layout="column"
  style="padding: 0;"
  [source]="systemMenuOptions"
  [display]="getDisplay"
  [childrens]="getChildrens"
  (itemClick)="go($event)"
  [hidden]="!isHiddenMenuUser"
>
</ip-menu-lateral-list>

<a
  class="wrapper-logo"
  href="https://www.interprocess.com.br/gemed-onco/"
  title="Interprocess - Site"
  target="_blank"
>
  <img
    class="logo"
    src="assets/logo-Interprocess-gemed-sf-branco.png"
    alt="Interprocess - Logo"
  />
</a>
