import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { LibGeral } from '../../../core/libraries/libGeral';

@Component({
  selector: 'ip-cep-input-text',
  template: `
    <ip-input-text  flex
                    layout-fill
                    [(model)]="maskValue"
                    [placeholder]="placeholder"
                    [maxLength]="9"
                    (input)="onInput($event)"
                    (change)="onChange($event)">
    </ip-input-text>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IPCEPInputTextComponent {
  @Input() placeholder: string;
  @Input() model: string;
  @Output() change: EventEmitter<any>;
  @Output() modelChange: EventEmitter<string>;

  public maskValue: string;
  constructor() {
    this.change = new EventEmitter();
    this.modelChange = new EventEmitter<string>();
    this.maskValue = '';
  }
  onChange(event: Event): void {
    event.stopPropagation();
    this.change.emit({ model: this.model, maskValue: this.maskValue });
  }
  onInput(event: Event): void {
    this.applyMask();
    this.modelChange.emit(this.getRaw());
  }
  applyMask(): void {
    const rawValue = this.getRaw();
    if (rawValue.toString().length >= 5) {
      if (LibGeral.estaPreenchido(rawValue)) {
        this.maskValue = `${rawValue.substr(0, 5)}-${rawValue.substr(5)}`;
      }
    }
  }
  getRaw(): string {
    let value = this.maskValue;
    if (LibGeral.estaPreenchido(value)) {
      value = value.toString().replace(/-/gi, '');
    }
    return value;
  }
}
