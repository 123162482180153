import { TranslateService } from "@ngx-translate/core";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  INovoProfissional,
  IProfissional,
  IProfissionalEspecialidade,
  IProfissionalVinculadoAssistenteDTO,
} from "@gemed-core/interfaces/gemed.interface";
import { Profissional } from "@gemed-core/models/profissional.model";
import { Observable } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { AbstractService } from "../../../core/abstract.service";
import { RestApiService } from "../../../core/http/restApi.service";
import { LibGeral } from "../../../core/libraries/libGeral";
import { HttpResponse, RestAPIType } from "../../../core/models";
import { PagingInfo } from "../../../core/models/pagingInfo.model";

@Injectable()
export class ProfissionalService extends AbstractService {
  private servicesRoutes = {
    Inserir: "Inserir",
    RecuperarListaStatusClinicaCategoria:
      "RecuperarListaStatusClinicaCategoria",
    RecuperarListaProfissionaisProntuario:
      "RecuperarListaProfissionaisProntuario",
    RecuperarProfissionalPorUsuario: "RecuperarProfissionalPorUsuario",
    RecuperarProfissionalCategoria: "RecuperarProfissionalCategoria",
    RecuperarProfissionalCategoriaComProfissional:
      "RecuperarProfissionalCategoriaComProfissional",
    RecuperarListaProfissionaisPorCategoria:
      "RecuperarListaProfissionaisPorCategoria",
    RecuperarProfissionalCategoriaSemRestricao:
      "RecuperarProfissionalCategoriaSemRestricao",
    RecuperarEspecialidades: "RecuperarEspecialidades",
    RecuperarProfissional: "RecuperarProfissional",
    ObterProfissionaisVinculadosAoAssistenteLogado: "ObterProfissionaisVinculadosAoAssistenteLogado",
    ObterListaProfissionaisPorCategoriaComCertificadoValidoOuSemCertificado: "ObterListaProfissionaisPorCategoriaComCertificadoValidoOuSemCertificado",
  };

  private controles = {
    Profissional: "Profissional",
  };

  constructor(
    restApiService: RestApiService,
    private tradutor: TranslateService
  ) {
    super(restApiService);
  }

  /**
   * @method get  Recupera iterável de profissionais.
   * @param {number} idClinica identificador da clínica.
   * @param {string} status status do profissional.
   * @param {IPaginacao} paginacaoObj paginação para chamada.
   * @return retorna o Observable contendo a resposta da chamada.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  get(
    idClinica: number,
    categoria: string,
    status: string,
    paginacaoObj: IPaginacao
  ): Observable<Array<Profissional>> {
    let params = new HttpParams();

    if (!LibGeral.estaPreenchido(idClinica)) {
      throw new Error(this.tradutor.instant("avisoFaltaDeParametroIdClinica"));
    }

    if (!LibGeral.estaPreenchido(categoria)) {
      throw new Error(this.tradutor.instant("avisoFaltaDeParametroCategoria"));
    }

    if (!LibGeral.estaPreenchido(status)) {
      throw new Error(this.tradutor.instant("avisoFaltaDeParametroStatus"));
    }

    params = params.append("idClinica", String(idClinica));
    params = params.append("categoria", categoria);
    params = params.append("status", status);

    if (LibGeral.estaEmBranco(paginacaoObj)) {
      throw new Error(this.tradutor.instant("avisoFaltaDeParametroPaginacao"));
    }

    const pagingInfo = new PagingInfo(
      +paginacaoObj.PageSize,
      +paginacaoObj.PageNumber
    );
    return this.restApiService
      .Get<Array<Profissional>>(
        {
          api: RestAPIType.Resources,
          controller: this.controles.Profissional,
          action: this.servicesRoutes.RecuperarListaStatusClinicaCategoria,
          pagingInfo: pagingInfo,
        },
        params
      )
      .pipe(
        take(1),
        switchMap(
          async (response) =>
            await LibGeral.ParseAndResolve(JSON.stringify(response.Data))
        )
      );
  }
  /**
   * @method getProfissionalUsuario  Recupera  profissional por identificador do usuário informado .
   * @param {number} idUsuario identificador do usuário.
   * @return retorna o Observable contendo a resposta da chamada.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  getProfissionalUsuario(
    idUsuario: number
  ): Observable<HttpResponse<IProfissional>> {
    return this.restApiService
      .GetById<IProfissional>(idUsuario, {
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.RecuperarProfissionalPorUsuario,
      })
      .pipe(take(1));
  }
  /**
   * @method getListProfissionaisProntuario  Retorna profissionais médicos.
   * @return retorna o Observable contendo a resposta da chamada.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  getListProfissionaisProntuario(): Observable<HttpResponse<any>> {
    return this.restApiService
      .Get({
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.RecuperarListaProfissionaisProntuario,
      })
      .pipe(take(1));
  }
  /**
   * @method getProfissionaisComFiltroPermissoes recupera categorias do profissional
   * @param {number} idProfissional identificador do profissional.
   * @return retorna o Observable contendo a resposta da chamada.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  getProfissionaisComFiltroPermissoes(
    idProfissional: number
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append("idProfissional", String(idProfissional));

    return this.restApiService.Get(
      {
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.RecuperarProfissionalCategoria,
      },
      params
    );
  }

  recuperarProfissionalCategoriaComProfissional(): Observable<
    HttpResponse<IProfissionalCategoria>
  > {
    return this.restApiService
      .Get<IProfissionalCategoria>({
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes
          .RecuperarProfissionalCategoriaComProfissional,
      })
      .pipe(take(1));
  }

  obterListaProfissionalsPorCategoria(identificadorCategoria: string) {
    let params = new HttpParams();
    params = params.set("identificadorCategoria", identificadorCategoria);
    return this.restApiService
      .Get<Profissional[]>(
        {
          api: RestAPIType.Resources,
          controller: this.controles.Profissional,
          action: this.servicesRoutes.RecuperarListaProfissionaisPorCategoria,
        },
        params
      )
      .pipe(take(1));
  }

  /**
   * @method ObterEspecialidadesAtivas  Recupera as especialidades cadastradas e ativas.
   * @return retorna o Promise com array de especidalidade cadastradas e ativas.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  public async obterEspecialidadesAtivas(): Promise<
    HttpResponse<IProfissionalEspecialidade[]>
  > {
    return await this.restApiService
      .Get<IProfissionalEspecialidade[]>({
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.RecuperarEspecialidades,
      })
      .pipe(take(1))
      .toPromise();
  }

  /**
   * @method ObterProfissionaisCategorias  Recupera as categorias  profissionais cadastradas.
   * @return retorna o Promise de array de categorias profissionais cadastradas.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  public async obterProfissionaisCategorias(): Promise<
    HttpResponse<IProfissionalCategoria[]>
  > {
    return await this.restApiService
      .Get<IProfissionalCategoria[]>({
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.RecuperarProfissionalCategoriaSemRestricao,
      })
      .pipe(take(1))
      .toPromise();
  }
  /**
   * @method cadastrarProfissional  Cadastra novo profissional.
   * @return retorna o Promise de boleano, onde true indica novo registro criado com sucesso.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  public async cadastrarProfissional(
    novoProfissional: INovoProfissional
  ): Promise<HttpResponse<IProfissional>> {
    return await this.restApiService
      .Post<INovoProfissional, IProfissional>(novoProfissional, {
        api: RestAPIType.Resources,
        controller: this.controles.Profissional,
        action: this.servicesRoutes.Inserir,
      })
      .pipe(take(1))
      .toPromise();
  }
  /**
   * @method recuperarProfissionalPorNomeStatus  Recupera registro de profissional pelo nome e Status
   * @param {string} nome Nome do profissional
   * @param {Status} status Status do profissional
   * @return retorna o Promise de boleano, onde true indica novo registro criado com sucesso.
   * ##### esta chamada utiliza take(1), logo quem se inscreve ao seu return não necessita realizar o unsubscribe.
   */
  public async recuperarProfissionalPorNomeStatus(
    nome: string,
    status: Status = "A"
  ): Promise<HttpResponse<IProfissional>> {
    const parametros = new HttpParams();

    parametros.append("nomeProfissional", nome);
    parametros.append("status", status);

    return await this.restApiService
      .Get<IProfissional>(
        {
          api: RestAPIType.Resources,
          controller: this.controles.Profissional,
          action: this.servicesRoutes.RecuperarProfissional,
        },
        parametros
      )
      .pipe(take(1))
      .toPromise();
  }

  ObterProfissionaisVinculadosAoAssistenteLogado(): Promise<IProfissionalVinculadoAssistenteDTO[]> {
    return new Promise((resolve, reject) => {
      this.restApiService
        .Get(
          {
            api: RestAPIType.Resources,
            controller: this.controles.Profissional,
            action: this.servicesRoutes.ObterProfissionaisVinculadosAoAssistenteLogado,
          }
        )
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          (error) => reject(error)
        );
    });
  }

  ObterListaProfissionaisPorCategoriaComCertificadoValidoOuSemCertificado(identificadorCategoria: string): Promise<Profissional[]> {
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
    params = params.set("identificadorCategoria", identificadorCategoria);


      this.restApiService
        .Get(
          {
            api: RestAPIType.Resources,
            controller: this.controles.Profissional,
            action: this.servicesRoutes.ObterListaProfissionaisPorCategoriaComCertificadoValidoOuSemCertificado,
          },
          params
        )
        .pipe(take(1))
        .subscribe(
          async (result) =>
            resolve(
              await LibGeral.ParseAndResolve(JSON.stringify((<any>result).Data))
            ),
          (error) => reject(error)
        );
    });
  }

}
