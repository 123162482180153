import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { IPButtonComponent, IPButtonFlatComponent, IPButtonIconComponent, IPButtonMiniFabComponent, IPButtonFabComponent, IPButtonRaisedDirective, IPButtonLogOutComponent, IPButtonDefaultComponent } from './index';

import { MaterialModule } from '../../../material/material.module';
import { TranslateModule } from '@ngx-translate/core';

const buttonsIP = [
  IPButtonComponent,
  IPButtonFlatComponent,
  IPButtonIconComponent,
  IPButtonMiniFabComponent,
  IPButtonFabComponent,
  IPButtonRaisedDirective,
  IPButtonLogOutComponent,
  IPButtonDefaultComponent
];
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild()
  ],
  declarations: buttonsIP,
  exports: buttonsIP,
  schemas: [NO_ERRORS_SCHEMA]
})

export class IPButtonModule {
}
