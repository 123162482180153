import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/assistencial/shared/module/sharedModule";
import { IPCoreModule } from "src/app/core-ui/core.module";
import { IPButtonModule } from "../button/ip-button.module";
import { ListaMultiClinicasComponent } from "./lista-multi-clinicas.component";



@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule.forChild(),
    IPButtonModule,
    MatIconModule,
    MatRadioModule,
    SharedModule,
    IPCoreModule
  ],
  declarations: [
    ListaMultiClinicasComponent
  ],
  exports: [
    ListaMultiClinicasComponent
  ]

})
export class ListaMultiClinicasModule {

}
