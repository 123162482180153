
import {ReverseSomeWhenBlank} from './reverse-some-when-blank.pipe';
import { NgModule } from '@angular/core';
import { FormatDateHours } from './format-date-hours.pipe';
import { ValidaFormato } from './valida-formato.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    ReverseSomeWhenBlank,
    FormatDateHours,
    ValidaFormato,
    EnumToArrayPipe,
    SafePipe
  ],
  exports: [
    ReverseSomeWhenBlank,
    FormatDateHours,
    ValidaFormato,
    EnumToArrayPipe,
    SafePipe
  ]
})
export class PipesModule {}