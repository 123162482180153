import { Reflection } from "../../../core/libraries/reflection";
import { LibGeral } from "../../../core/libraries/libGeral";
import {
  IConvenio,
  ICategoriaConvenio,
  IConvenioPlano,
  IPortador,
  ITabela,
  ILotes,
  ICategoriaPlano,
  IPacienteConvenio,
  IProfissionalParticularidades,
  IInsumosDePara,
  ITISSGrupo,
  IClinica,
  IConvenioGrupoInsumo,
  IConvenioInsumoDesconto,
  IConvenioClinica,
} from "@gemed-core/interfaces/gemed.interface";
import {
  TipoConvenio,
  ConvenioTISSVersao,
  ConvenioTipoCodPrestador,
  SimNao,
  CaraterAtendimento,
  TISSProfissionalComoContratanteEnum,
  RetemLei10925,
  ImpressaoPrescricaoPrincipioAtivoNomeComercial,
  TipoUnidadeMedida,
  Arredondamento,
  TipoRepasseHonorario,
  Status,
} from "@gemed-core/enums/gemed.enum";
declare const Proxy: any;

export class Convenio implements IConvenio {
  Autorizacoes: any;
  ProfissionalParticularidades: IProfissionalParticularidades[];
  InsumosDePara: IInsumosDePara[];
  TissGrupos: ITISSGrupo[];
  Clinica: IClinica;
  TISSQtdMinDigitos: number;
  TISSUtilizaSenhaNumGuia: SimNao;
  CaraterAtendimento: CaraterAtendimento;
  RepasseHonorario: string;
  TISSSolucaoComoMed: SimNao;
  TISSIgnoraReducaoAcrescimo: SimNao;
  TISSProfissionalComoContratante: TISSProfissionalComoContratanteEnum;
  TISSIgnoraTipoInsumo: string;
  TISSGeraEquipeSADT: SimNao;
  TISSIgnoraInsumoZerado: SimNao;
  TISSValorUnitarioBruto: SimNao;
  FaturaDuasCasasDecimais: SimNao;
  TISSConsultaNrOperadoraSADT: SimNao;
  TISSArredondaVlLiquidoUnitario: SimNao;
  RetemImpostoPadraoNF: SimNao;
  ISSQN: number;
  IdVersao: number;
  VersaoBras: string;
  VersaoSimpro: string;
  RetemLei10925: RetemLei10925;
  GruposInsumo: IConvenioGrupoInsumo[];
  InsumosDesconto: IConvenioInsumoDesconto[];
  IgnoraTipoInsumo: boolean;
  TISSUsaAutorizacaoNumeroGeral: SimNao;
  TISSOutrasDespesasDataPrimeiraPrescricao: SimNao;
  TISSProfissionalSolicitante: SimNao;
  Credenciamento: IConvenioClinica;
  ImpressaoPrescricaoPrincipioAtivoNomeComercial: ImpressaoPrescricaoPrincipioAtivoNomeComercial;
  TISSTipoUnidadeMedidaOutrasDespesas: TipoUnidadeMedida;
  TISSTipoArredondamento: Arredondamento;
  TISSQuantidadeMaximaPorItem: number;
  PrescricaoAtendimentoExterno: SimNao;
  ObrigarDistribuicaoPorInsumos: SimNao;
  TipoRepasseHonorario: TipoRepasseHonorario;
  NaoDuplicarNumeroGuiaPorPaciente: SimNao;
  TISSEquipeSADTUsaCodigoPrestador: SimNao;
  Descricao: string;
  IdGrupoCusto: number;
  Fantasia: string;
  IE: string;
  DiasValidade: number;
  Categoria: ICategoriaConvenio;
  Tipo: string;
  TipoEnum: TipoConvenio;
  CGC: string;
  EmiteNFiscal: string;
  EmiteNFEntrega: string;
  FormatoMatricula: string;
  FormatoGuia: string;
  RegistroANSConvenio: string;
  TISSVersao: ConvenioTISSVersao;
  TISSTipoCodPrestador: string;
  TipoCodPrestador: ConvenioTipoCodPrestador;
  TISSTipoNumeroGuia: string;
  TipoConvenio: TipoConvenio;
  ParticipaAgendaWeb: string;
  IM: string;
  RegistrosClinica: string;
  RegistroConsultaClinica: string;
  TISSGuiaConsultaSADT: SimNao;
  FaturaMedSolUM: SimNao;
  PlanoConta: any;
  Planos: IConvenioPlano[];
  IdPlanoContas: number;
  DescricaoPlanoConta: string;
  Portador: IPortador;
  Tabela: ITabela;
  TabelaConsulta: ITabela;
  TabelaInsumo: ITabela;
  TabelaMedicamento: ITabela;
  TabelaTaxa: ITabela;
  TabelaHIndividual: ITabela;
  Lotes: ILotes[];
  public IdConvenio: number;
  public Id: number;
  public Razao: string;
  constructor() {
    this.Id = 0;
    this.IdConvenio = null;
    this.Razao = null;
  }
}

export class ConvenioPlano implements IConvenioPlano {
  Id: number;
  Convenio: IConvenio;
  ExigeMatricula: string;
  ExigeGuia: string;
  ExigeSenha: string;
  ExigeAutorizacao: string;
  ValidadeInicio: Date;
  ValidadeFim: Date;
  Status: Status;
  CodIntegracao: string;
  Padrao: SimNao;
  IdTipoAtendimento: number;
  Categoria: ICategoriaPlano;
  Limite: number;
  Matricula: string;
  Titular: string;
  public IdPlano: number;
  public Plano: string;
}
export class PacienteConvenio implements IPacienteConvenio {
  PlanoDescricao: string;
  public Paciente: any;
  public Convenio: Convenio;
  public Matricula: string;
  public Titular: string;
  public ValidadeCarteira: Date;
  public Plano: string;
  public Padrao: any; // SimNao
  public PadraoBool: boolean;
  public ConvenioPlano: ConvenioPlano;
  constructor() {
    this.PadraoBool = false;
  }
}

/**
 * Factory para gerar instancia de PacienteConvenio com alteração em seu coportamento.
 * @param  {PacienteConvenio} - opcional, caso não seja informado criará novo PacienteConvenio
 * @returns PacienteConvenio
 */
export function PacienteConvenioProxy(
  pacienteConvenio: PacienteConvenio
): PacienteConvenio {
  return new Proxy(pacienteConvenio, {
    get: function getter(target: PacienteConvenio, property: string): any {
      if (property === "Convenio" && LibGeral.estaEmBranco(target[property])) {
        target[property] = new Convenio();
      }
      if (
        property === "ConvenioPlano" &&
        LibGeral.estaEmBranco(target[property])
      ) {
        target[property] = new ConvenioPlano();
      }
      if (property === "PadraoBool") {
        return Reflection.getValue(target, "Padrao") === "Sim" ? true : false;
      }
      return target[property];
    },
    set: function setter(
      target: PacienteConvenio,
      property: string,
      value: any
    ): boolean {
      if (property === "PadraoBool") {
        let enumValue = "Nao";
        if (value) {
          enumValue = "Sim";
        }
        Reflection.setValue(target, "Padrao", enumValue);
      }
      target[property] = value;
      return true;
    },
  });
}
