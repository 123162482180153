import { Component } from "@angular/core";

@Component({
  selector: 'ip-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashBoardComponent {

}
