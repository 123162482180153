import { CIDCategoria } from './cidCategoria.model';
import { TipoCID } from '@gemed-core/enums/gemed.enum';
import { ICategoria } from '@gemed-core/interfaces/gemed.interface';
import { LibGeral } from '@gemed-core/libraries/libGeral';
 

export class CID {
  public Id = 0;
  public Codigo: string;
  public Doenca: string;
  public Tipo: TipoCID;
  public Categoria: ICategoria;
  public Pesquisa: string;
  public Errors: any;
  public Usado: boolean;

  public get Resumo(): string {
    return (LibGeral.estaPreenchido(this.Codigo) && LibGeral.estaPreenchido(this.Doenca)) ? `${this.Codigo} - ${this.Doenca}` : "";
  }

  public set Resumo(value) {
    setTimeout(() =>
      this.Resumo = value
    );
  }

  toString(): string {
    return `${this.Codigo} - ${this.Doenca}`;
  }
}
