import {
  PrescricaoConciliado,
  PrescricaoSituacaoNF,
  SimNao,
} from "@gemed-core/enums/gemed.enum";
import { Clinica } from "@gemed-core/models/clinica.model";
import { DTOTelaPrescricao } from "@gemed-core/models/dtoTelaPrescricao.model";
import { Prescricao } from "@gemed-core/models/prescricao.model";
import { PrescricaoProtocolo } from "@gemed-core/models/prescricaoProtocolo.model";
import { PrescricaoSugerida } from "@gemed-core/models/prescricaoSugerida.model";
import { ProtocoloGrupo } from "@gemed-core/models/protocoloGrupo.model";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { MenuUsuarioStoreService } from "@pep-assistencial-store/store-service/menuUsuarioStoreService.service";
import { PrescricaoSituacao } from "src/app/assistencial/central-medico/shared/models/PrescricaoSituacao.enum";
import { LibPEPPaciente } from "src/app/assistencial/prontuario/shared/libraries/libPEPPaciente";
import { CID, Protocolo } from "src/app/assistencial/shared/models";

import { LibString } from "./libString";
import { Reflection } from "./reflection";
import { Usuario } from "@gemed-core/models/usuario.model";
import { take } from "rxjs/operators";
import { PrescricaoMovimentoInsumoDTO } from "@gemed-core/models/prescricaoMovimentoInsumoDTO.model";
import { InsumosAtendidosDesejados } from "@gemed-core/models/prescricaoSeparacaoEstoqueDTO";
import { CLINICAS_CARDIO } from "@gemed-core/models/constants";
import { AuthState } from "@gemed-core/store/state";
import { IPToastService } from "@gemed-core/toast/ipToast.service";
import { IPError } from "@gemed-core/models";

declare const _: any;

export class LibGeral {

  public static imprimirHTML(html: string) {
    const template = document.createElement('template');
    const htmlTrim = html.trim();
    template.innerHTML = htmlTrim;

    this.inserirNodoParaImpressao(template.content as any);

  }

  public static imprimirZPLEtiqueta(etiqueta: string) {
    const printWindow = window.open();
    printWindow.document.open('text/plain')
    printWindow.document.write(etiqueta);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  public static imprimirEtiqueta(etiqueta: string) {
    const printElement = document.createElement("div");
    const text = document.createTextNode(etiqueta);
    printElement.appendChild(text);

    this.inserirNodoParaImpressao(printElement);
  }

  static inserirNodoParaImpressao(elementoParaImprimir: Element) {
    let nodoDeImpressao = document.getElementById("printSection");

    if (!nodoDeImpressao) {
      nodoDeImpressao = document.createElement("div");
      nodoDeImpressao.id = "printSection";
      document.getElementsByTagName("app-root")[0].appendChild(nodoDeImpressao);
    }

    nodoDeImpressao.innerHTML = "";

    nodoDeImpressao.appendChild(elementoParaImprimir);
    window.print();
    document.getElementById("printSection").remove();
  }

  public static imprimirTemplateExpedicao(template: Element) {
    this.inserirNodoParaImpressao(template);
  }

  public static converterStringParaBoolean(
    stringParaConverter: string
  ): boolean {
    return Boolean(JSON.parse(stringParaConverter));
  }

  public static async getIdClinica(store: Store<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      store
        .select(({ auth }) => auth.idClinica)
        .pipe(take(1))
        .subscribe((idClinica) => {
          if (!!idClinica) {
            resolve(idClinica);
          }
        });
    });
  }

  public static MapInsumosSelecionadosToListaInsumosDesejado(
    insumo: PrescricaoMovimentoInsumoDTO
  ): InsumosAtendidosDesejados {
    return Object.assign(new InsumosAtendidosDesejados(), {
      ...insumo,
      UnidadeEstoque: insumo.UnidadeEstoque.Descricao,
      IdLote: insumo.IdLote,
      IdMovimento: insumo.IdMovimento,
      Atendido: "S",
      Conciliado: true,
      QuantidadeReservada: insumo.Quantidade,
      QuantidadeUtilizada: insumo.Quantidade,
      PrincipioNome: insumo.MedicamentoDescricao,
      DoseReferencia: insumo.Dose,
    });
  }

  /**
   * Subdivide o array em subarrays referentes a propriedade selecionada (similar ao group by do sql)
   * @static
   * @param arr array de objetos para ser agrupado por um objeto específico
   * @param propToGroup chave da propriedade para agrupar o array
   * @param {boolean} propAsPrefix True => chave de cada subarray será propToGroup+valor do propToGroup | False => chave de cada subarray será o valor do propToGroup
   * @returns Retorna um Map de Arrays com os subarrays agrupados pela propriedade propToGroup
   *
   */
  public static agruparArrayPorPropriedade(
    arr: any[],
    propToGroup: any,
    propAsPrefix: boolean = false
  ): Map<string | number | boolean, any[]> {
    return arr.reduce((acc, currentObj) => {
      let propValue = currentObj[propToGroup];
      if (propToGroup.includes(".")) {
        const propSplitted = propToGroup.split(".");
        const nestedPropValue = propSplitted.reduce(
          (acc, current) =>
            acc && acc[current] !== "undefined" ? acc[current] : undefined,
          currentObj
        );
        if (LibGeral.estaPreenchido(nestedPropValue)) {
          propValue = nestedPropValue;
        }
      }
      const prefix = propAsPrefix ? propToGroup + propValue : propValue;
      acc.set(prefix, acc.has(prefix) ? acc.get(prefix) : []);
      acc.get(prefix).push(currentObj);
      return acc;
    }, new Map());
  }

  /**
   * Implementação de um flatMap
   * @static
   * @param arrToFlat Array que será planificado pela função
   * @returns Array planificado (1 nível a menos)
   *
   */
  public static flatMap(arrToFlat: any[]) {
    return arrToFlat.reduce((destArray, array) => destArray.concat(array), []);
  }

  public static deepCopy(model: any) {
    let retorno = model;

    if (typeof retorno === "object") {
      const MapPrototype = new Map<string, any>();

      //Mapea o objeto com o prototype original
      JSON.stringify(model, function (key, value) {
        if (this.__proto__.constructor.name !== "Object") {
          if (!this["ctor"]) {
            this["ctor"] = this.__proto__.constructor.name;
          }
          MapPrototype.set(
            this.__proto__.constructor.name,
            Object.getPrototypeOf(this)
          );
        }
        return value;
      });

      //Deep Copy
      const _json = JSON.stringify(model);

      //Parse retornando o prototype original baseado no construtor(ctor).
      retorno = JSON.parse(_json, function (key, value) {
        if (!!this["ctor"]) {
          if (MapPrototype.has(this["ctor"])) {
            Object.setPrototypeOf(this, MapPrototype.get(this["ctor"]));
            delete this["ctor"];
          }
        }
        return value;
      });
    }
    return retorno;
  }

  public static async getIdProfissionalLogado(store: Store<any>): Promise<number> {
    return new Promise((resolve, reject) => {
      store
        .select(({ auth }) => auth.Usuario)
        .pipe(take(1))
        .subscribe((usuario: Usuario) => {
          if (
            LibGeral.estaPreenchido(usuario) &&
            LibGeral.estaPreenchido(usuario.IdProfissional)
          ) {
            resolve(usuario.IdProfissional);
          } else {
            resolve(null);
          }
        });
    });
  }

  public static async getIdUsuario(store: Store<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      store
        .select(({ auth }) => auth.Usuario)
        .pipe(take(1))
        .subscribe((usuario: Usuario) => {
          if (
            LibGeral.estaPreenchido(usuario) &&
            LibGeral.estaPreenchido(usuario.IdUsuario)
          ) {
            resolve(usuario.IdUsuario);
          } else {
            resolve(null);
          }
        });
    });
  }

  public static async getNomeUsuario(store: Store<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      store
        .select(({ auth }) => auth.Usuario)
        .subscribe((usuario: Usuario) => {
          if (LibGeral.estaPreenchido(usuario) && LibGeral.estaPreenchido(usuario.Nome)) {
            resolve(usuario.Nome);
          } else {
            resolve(null);
          }
        });
    });
  }

  public static async getTituloMenu(
    rota: string,
    menuUsuario: MenuUsuarioStoreService
  ): Promise<string> {
    return menuUsuario.dispatchCreateActionBuscarNomeMenu(rota);
  }

  /**
   * @deprecated
   * Utilizar o método instant do Translate service
   * this.tradutor.instant("cancelar")
   */
  public static async getTraducao(
    texto: string,
    tradutor: TranslateService
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      tradutor.get(texto).subscribe((value) => {
        resolve(value);
      });
    });
  }

  public static estaPreenchido(valor: any): boolean {
    if (Array.isArray(valor)) {
      return valor.length > 0;
    }
    if (valor instanceof Date) {
      return !isNaN(valor.getDate());
    }
    if (typeof valor === "string") {
      return !LibString.isNullOrEmpty(valor as string);
    }
    return !LibGeral.estaEmBranco(valor);
  }

  public static todosOsValoresEstaoValidos(valores: any[]) {
    return valores.every(valor => LibGeral.estaPreenchido(valor));
  }

  public static estaEmBranco(valor: any): boolean {
    if (valor instanceof Date) {
      return isNaN(valor.getDate());
    }

    return valor === undefined || valor === null || valor === "";
  }

  public static isBoolean(value: any): boolean {
    return typeof value === "boolean";
  }

  public static isNumber(value: any): boolean {
    return typeof value === "number";
  }

  public static isString(value: any): boolean {
    return typeof value === "string";
  }

  public static isFunction(value: any): boolean {
    return typeof value === "function";
  }

  public static isType(value: any): boolean {
    return this.isFunction(value);
  }

  public static isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public static isDate(value: any): boolean {
    return value instanceof Date && !isNaN(value.valueOf());
  }

  public static isObject(value: any): boolean {
    return (
      value !== null &&
      (typeof value === "function" || typeof value === "object")
    );
  }

  public static getValue<T>(object: T, propertyName: string): T {
    return Reflection.getValue<any>(object, propertyName);
  }
  public static setValue<T>(object: T, propertyName: string, value: any): T {
    return Reflection.setValue<any>(object, propertyName, value);
  }

  public static curry(f: any): any {
    const params = Array.prototype.slice.call(arguments, 1);
    return function g(): any {
      params.push(Array.prototype.slice.call(arguments, 0));
      return f.apply(this, params) || g;
    };
  }

  public static capitalizeString = (stringToConvert: string) => {
    let finalString: string;
    typeof stringToConvert !== "string"
      ? (finalString = "")
      : (finalString =
        stringToConvert.charAt(0).toUpperCase() + stringToConvert.slice(1));
    return finalString;
  };

  public static VerificaSeATelaEhMobile(): boolean {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
    //return window.matchMedia("(max-width: 599px) and (orientation: portrait), (max-width: 767px) and (orientation: landscape)").matches;
  }

  public static async ParseAndResolve(json): Promise<any> {
    const idMap = {};
    const refMap = [];

    json = JSON.parse(json, function (key, value) {
      if (key === "$id") {
        idMap[value] = this;
        return void 0;
      }

      if (key === "$type") {
        idMap[value] = this;
        return void 0;
      }


      if (value && value["$ref"]) {
        //Adiciona a referência para ser tratada depois, evitando stackoverflow.
        refMap.push({ obj: this, key, ref: value["$ref"] });
      }

      return value;
    });

    return new Promise((resolve) =>
      //espera esvaziar a call stack e faz as atribuições.
      setTimeout(() => {
        refMap.forEach(
          async (value) => (value.obj[value.key] = idMap[value.ref])
        );
        resolve(json);
      })
    ).then((result) => result);
  }

  public static ocultarColunaMobile(innerWidth): Boolean {
    return innerWidth < 768;
  }

  public static unificarDataHora(data: Date, tempo: Date): Date {
    if (!!data && !!tempo) {
      const dataChange = new Date(data);
      const timeChange = new Date(tempo);
      dataChange.setHours(timeChange.getHours());
      dataChange.setMinutes(timeChange.getMinutes());
      dataChange.setSeconds(timeChange.getSeconds());
      return dataChange;
    }

    return null;
  }

  public static recuperarHora(data: Date): string {
    if (LibGeral.estaPreenchido(data)) {
      return data
        .toLocaleTimeString()
        .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
    }

    return "";
  }

  public static formatarDataIso(data: Date) {
    if (this.isDate(data)) {
      return data.toISOString().split("T")[0];
    }

    return null;
  }

  public static removeTimeZonePart(timestampStr) {
    return new Date(
      new Date(timestampStr).getTime() -
      new Date(timestampStr).getTimezoneOffset() * 60 * 1000
    );
  }


  public static handleCalcularCorComContraste(backgroundColor: string): string {
    if (LibGeral.estaPreenchido(backgroundColor) && backgroundColor.length >= 7) {
      const corHexadecimalPadronizada = backgroundColor?.charAt(0) === '#'
        ? backgroundColor.substr(1, 6)
        : backgroundColor;

      const vermelho = parseInt(corHexadecimalPadronizada.substr(0, 2), 16);
      const verde = parseInt(corHexadecimalPadronizada.substr(2, 2), 16);
      const azul = parseInt(corHexadecimalPadronizada.substr(4, 2), 16);

      const contraste = (vermelho + verde + azul) / (255 * 3);

      return contraste >= 0.5 ? 'black' : 'white';
    }

    return 'black';
  }

  public static base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };


  public static formatoFile(extensao: string): string {
    extensao = extensao.toLocaleLowerCase().replace(".", "");
    switch (extensao) {
      case "pdf":
        return "application/pdf";
      case "png":
        return "image/png";
      default:
        return "image/jpg";
    }
  }

  public static romanize(num: string | number) {
    if (!+num) {
      return false;
    }
    let digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) {
      roman = (key[+digits.pop() + i * 10] || "") + roman;
    }
    return Array(+digits.join("") + 1).join("M") + roman;
  }

  public static deromanize(str: string) {
    let result = str.toUpperCase(),
      validator = /^M*(?:D?C{0,3}|C[MD])(?:L?X{0,3}|X[CL])(?:V?I{0,3}|I[XV])$/,
      token = /[MDLV]|C[MD]?|X[CL]?|I[XV]?/g,
      key = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      num = 0,
      m: (string | number)[];
    if (!(result && validator.test(result))) {
      return false;
    }
    while ((m = token.exec(result))) {
      num += key[m[0]];
    }
    return num;
  }

  public static calcularDose(dose: number, fator1: number, ajuste: number): number {
    if (!LibGeral.estaPreenchido(dose)) {
      return 0;
    }
    const doseComFator = dose * fator1;
    return !!ajuste
      ? doseComFator + (doseComFator * ajuste) / 100
      : doseComFator;
  }

  /**
   * Somente valida com "," pois "-" era utilizado somente no vb6
   */
  public static validaFormatoDias(value): boolean {
    const _regexExp = /((\b\d{1,3}(\,)\b)+(?=\d{1,3}$)\d{1,3}|^\d{1,3}$)/g.exec(
      value
    );

    return LibGeral.estaPreenchido(_regexExp) && _regexExp.index == 0;
  }

  public static validaFormatoSemanas(value): boolean {
    const _regexExp = /((\b[1-9]{1}(\,)\b)+(?=[1-9]{1}$)[1-9]{1}|^[0-9]{1}$)/g.exec(
      value
    );

    return LibGeral.estaPreenchido(_regexExp) && _regexExp.index == 0;
  }

  public static validaFormatoMensal(Quantidade): boolean {
    return LibGeral.estaPreenchido(Quantidade) && Quantidade > 0;
  }

  public static MapProtocoloGrupoToPrescricaoSugerida(
    _protocoloGrupo: ProtocoloGrupo
  ): PrescricaoSugerida {
    return Object.assign(new PrescricaoSugerida(), { ..._protocoloGrupo });
  }

  public static MapPrescricaoToPrescricaoSugerida(
    _prescricao: Prescricao
  ): PrescricaoSugerida {
    return Object.assign(new PrescricaoSugerida(), { ..._prescricao });
  }

  public static MapDTOTelaPrescricaoToPrescricao(
    _prescricao: Prescricao,
    prescricaoDto: DTOTelaPrescricao,
    _protocolos: Protocolo[],
    _cid: CID,
    prescricaoSituacao: PrescricaoSituacao,
    prescricaoSugerida: PrescricaoSugerida
  ): Prescricao {
    _prescricao.Protocolos = _protocolos.map((_protocolo) =>
      Object.assign(new PrescricaoProtocolo(), {
        Protocolo: _protocolo,
        Cid: _cid,
      })
    );

    _prescricao.Detalhes = prescricaoSugerida.Detalhes;

    _prescricao.Peso = prescricaoDto.Medidas.Peso
      ? prescricaoDto.Medidas.Peso
      : 0;
    _prescricao.Curva = prescricaoDto.Medidas.Curva
      ? prescricaoDto.Medidas.Curva
      : 0;

    _prescricao.SuperficieCorporal = prescricaoDto.Medidas.Superficie
      ? Number(
        LibPEPPaciente.calculaSuperficieCorporalMosteller(
          prescricaoDto.Medidas.Peso,
          prescricaoDto.Medidas.Altura
        ).toFixed(2)
      )
      : 0;

    _prescricao.ProfissionalMedicoAssinou = prescricaoDto.ProfissionalAssinante;
    _prescricao.Altura = prescricaoDto.Medidas.Altura
      ? prescricaoDto.Medidas.Altura
      : 0;
    _prescricao.Conciliado = PrescricaoConciliado.Nao;
    _prescricao.Clinica = Object.assign(new Clinica(), {
      IdClinica: prescricaoDto.IdClinica,
    });
    _prescricao.ImprimiuEvolucao = SimNao.Nao;
    _prescricao.ProfissionalMed = prescricaoDto.Medico;
    _prescricao.NrVersao = 1;
    _prescricao.Usuario = prescricaoDto.Usuario;
    _prescricao.ProfissionalAssis = prescricaoDto.MedicoAssistente;

    _prescricao.Situacao = prescricaoSituacao;
    _prescricao.SituacaoValorEnum = prescricaoSituacao;

    _prescricao.SituacaoNf = PrescricaoSituacaoNF.SemNota;
    _prescricao.Detalhes = _prescricao.Detalhes ? _prescricao.Detalhes : [];
    _prescricao.Paciente = prescricaoDto.Paciente;

    return _prescricao;
  }

  /**
   * @method deviceVibration  vibra dispositivo se API estiver disponível no aparelho usado.
   * @return {void}
   */
  public static deviceVibration(pattern: number[] | number): void {
    if ("vibrate" in navigator) {
      // vibration API suportada
      window.navigator.vibrate(pattern);
    }
  }

  public static async detectarWebCam(): Promise<any> {
    const cameraAvailability = await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function () {
        return true;
      })
      .catch(function (err) {
        return err;
      });
    return cameraAvailability;
  }

  public static obterSistema(clinica: string) {
    const clinicaCardio = CLINICAS_CARDIO.includes(clinica);
    return clinicaCardio ? "GemedCardio" : "GemedOnco";
  }

  public static async isCardio(store: Store<any>) {
    const tituloClinica = await this.getSistemaClinica(store);

    if (!this.estaPreenchido(tituloClinica)) {
      return false;
    }

    return tituloClinica == "GemedCardio";
  }


  public static async getSistemaClinica(store: Store<any>): Promise<string> {
    return new Promise((resolve, reject) => {
      store
        .select(({ auth }) => auth)
        .pipe(take(1))
        .subscribe((auth: AuthState) => {
          if (this.estaPreenchido(auth)) {
            resolve(auth.sistema);
          } else {
            reject("");
          }
        });
    });
  }

  public toCamelCase(key, value) {
    if (value && typeof value === "object") {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }


  public static verificarSeOValorEhNumeroValido(numero: any): boolean {
    return (typeof numero === "number" && !isNaN(numero) && numero > -1);
  }

  public static showErrorException(toast: IPToastService, error: unknown) {
    if (error instanceof IPError) {
      toast.erro(error.Menssagem);
    }
  }

  public static isIphone(): boolean {
    const ua = navigator.userAgent.toLowerCase()
    return ua.search("iphone") > -1
  }

}
