// Exporta o valor equivalente ao banco de dados
export enum TipoParticipante {
    NaoInformado = "",
    Paciente = "P",
    ProfissionaldaSaude = "S"
  }

// Exporta o valor conforme recebo da API
export const TipoParticipanteValueApi = new Map<string, string>([
  [TipoParticipante.NaoInformado, 'NaoInformado'],
  [TipoParticipante.Paciente, 'Paciente'],
  [TipoParticipante.ProfissionaldaSaude, 'ProfissionaldaSaude']
]);

// Exportar o texto do Enum tipo participante
export const TipoParticipanteDescricao = new Map<string, string>([
  [TipoParticipante.NaoInformado, 'Não Informado'],
  [TipoParticipante.Paciente, 'Paciente'],
  [TipoParticipante.ProfissionaldaSaude, 'Profissional da saúde']
]);