import { LibGeral } from "@gemed-core/libraries/libGeral";



export class LibPEPPaciente {
  /**
   * Calcular DCE usando a equação de Cockcroft-Gault
   * @param  {number} peso KG
   * @param  {number} idade Anos
   * @param  {number} creatPlasm Centímetros
   * @param  {string} sexo Masculino é o padrão
   * @returns number
   */
  static calculaDCE(peso: number, idade: number, creatPlasm: number, sexo: string): number {
    if (Math.abs(peso) <= 0 || idade === 0 || Math.abs(creatPlasm) <= 0) {
      return 0;
    }
    let resultado = ((140 - idade) * peso) / (creatPlasm * 72);
    if (sexo === 'Feminino') {
      resultado = resultado * 0.85;
    }
    return resultado;
  }
  /**
   * Calcular dose por AUC usando a fórmula de Calvert
   * @param  {number} curva valor de curva da medida ou diretamente o valor do auc
   * @param  {number} dce dce calculado usando a funcao calculaDCE
   * @returns number
   */
  static calcularDosePorAuc(curva: number, dce: number) {
    if (!curva) { return 0; }
    return curva * (dce + 25);
  }
  /**
   * Calcular a Superfício Corporal usando a fórmula de Dubois Dubois
   * @param  {number} peso - KG
   * @param  {number} altura - Centímetros
   * @returns number
   */
  static calculaSuperficieCorporalDubois(peso: number, altura: number): number {
    if (Math.abs(peso) < 0 || altura === 0) {
      return 0;
    }
    return 0.007184 * (Math.pow(altura, 0.725) * (Math.pow(peso, 0.425)));
  }
  /**
   * Calcular a Superfício Corporal usando a fórmula de Mosteller
   * @param  {number} peso - KG
   * @param  {number} altura - Centímetros
   * @returns number
   */
  static calculaSuperficieCorporalMosteller(peso: number, altura: number): number {
    if (!peso || !altura) {
      return 0;
    }
    return (Math.pow((peso * altura / 3600), 0.5));
  }
  /**
   * Calcular IMC sem arredondamento. Fórmula Peso/(Altura*Altura)
   * @param  {number} peso KG
   * @param  {number} altura Anos
   * @returns number
   */
  static caculaIMC(peso: number, altura: number): number {
    if (Math.abs(peso) < 0 || altura === 0) {
      return 0;
    }
    return peso / Math.pow(altura, 2);
  }
  /**
   * Avalia se paciente é considerado idoso.
   * @param  {number} idade
   * @returns boolean
   */
  static isPacienteIdoso(idade: number): boolean {
    return idade >= 60;
  }
  /**
   * Classificação do IMC (Índice de Massa Corpórea) para paciente idoso.
   * @param  {number} imc
   * Referência a ser utilizada para definir classificação (ver parâmetro "RefClassificacaoIMCIdosos")
   * @param  {string} referenciaClassificacaoIMC
   * @returns string
   */
  static obterClassificacaoIMCPacienteIdoso(imc: number, referenciaClassificacaoIMC: string): string {
    if (LibGeral.estaPreenchido(referenciaClassificacaoIMC)) {
      switch (referenciaClassificacaoIMC) {
        case 'OMS95': return LibPEPPaciente.obterClassificacaoIMCPorOMS95(imc);
        case 'OMS98': return LibPEPPaciente.obterClassificacaoIMCPorOMS98(imc);
        case 'Lipschitz94': return LibPEPPaciente.obterClassificacaoIMCPorLipschitz94(imc);
        default: throw new Error(`Referência para classificação IMC de idosos não implementada (${referenciaClassificacaoIMC}).`);
      }
    }
  }
  /**
   * Classificação do IMC (Índice de Massa Corpórea)
   * @param  {number} imc
   * @param  {string} referenciaClassificacaoIMC - Referência a ser utilizada para definir classificação (ver parâmetro "RefClassificacao")
   * @returns string
   */
  static obterClassificacaoIMC(imc: number, referenciaClassificacaoIMC: string): string {
    if (LibGeral.estaPreenchido(referenciaClassificacaoIMC)) {
      switch (referenciaClassificacaoIMC) {
        case 'OMS95': return LibPEPPaciente.obterClassificacaoIMCPorOMS95(imc);
        case 'OMS98': return LibPEPPaciente.obterClassificacaoIMCPorOMS98(imc);
        default: throw new Error(`Referência para classificação IMC não implementada (${referenciaClassificacaoIMC}).`);
      }
    }
  }
  private static obterClassificacaoIMCPorOMS95(imc: number): string {
    if (imc < 16) {
      return 'Magreza grave';
    } else if (imc < 17) {
      return 'Magreza moderada';
    } else if (imc < 18.5) {
      return 'Magreza leve';
    } else if (imc < 25) {
      return 'Saudável';
    } else if (imc < 30) {
      return 'Obeso';
    } else if (imc < 35) {
      return 'Obesidade grau I';
    } else if (imc < 40) {
      return 'Obesidade grau II (severa)';
    } else if (imc >= 40) {
      return 'Obesidade grau III (mórbida)';
    }
    return 'Não informado';
  }
  private static obterClassificacaoIMCPorOMS98(imc: number): string {
    if (imc < 18.5) {
      return 'Baixo peso';
    } else if (imc < 25) {
      return 'Eutrofia';
    } else if (imc < 30) {
      return 'Sobrepeso';
    } else if (imc < 35) {
      return 'Obesidade grau I';
    } else if (imc < 40) {
      return 'Obesidade grau II';
    } else if (imc >= 40) {
      return 'Obesidade grau III';
    }
    return null;
  }
  private static obterClassificacaoIMCPorLipschitz94(imc: number): string {
    if (imc < 22) {
      return 'Baixo peso';
    } else if (imc <= 27) {
      return 'Eutrofia';
    } else {
      return 'Sobrepeso';
    }
  }
}
