import { HttpHeaders } from './httpHeaders.model';
import { LibGeral } from '../libraries/libGeral';
/**
 * Classe que representa o retorno de uma chamada HTTP.
 *
 * @export
 * @class HttpResponse
 * @template T (Tipo da resposta)
 */
export class HttpResponse<T> {
  Status: number;
  StatusText: string;
  Url: string;
  Headers: HttpHeaders;
  Data: T;

  get hasData(): boolean { return LibGeral.estaPreenchido(this.Data); }
}
