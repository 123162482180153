<swiper *ngIf="listaItems?.length > 0" 
  (indexChange)="onSlideChange($event)"
  (swiper)="onSwiper($event)"  
  [slidesPerView]="'auto'" 
  [spaceBetween]="30"  
  [navigation]="true" 
  [centeredSlides]="true" 
  [pagination]="{ clickable: true }">
  <ng-template swiperSlide *ngFor="let data of listaItems">
    <div class="slide-img-container">
      <div>
        <img 
          class="slide-img-container-item" 
          [src]="(data.extension === '.pdf' ? urlPdfImage : data.preview)"
          (dblclick)="abrirPreviewArquivoSelecionado(data)" 
        >
        <a *ngIf="mostrarBtnClose" class="boxclose" id="boxclose" (click)="remover(data.id)">x</a>
      </div>
    </div>
   
  </ng-template>
</swiper>