import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface MenuBaseState extends EntityState<MenuBase> {
  isLoading: boolean;
  selectedMenuBaseId: any;
  error: any;
}

export const MenuBaseAdapter: EntityAdapter<MenuBase> = createEntityAdapter<MenuBase>({
  selectId: (menuBase: MenuBase) => menuBase.id,
  sortComparer: false,
});


export const MenuBaseInitialState: MenuBaseState = MenuBaseAdapter.getInitialState({
  isLoading: true,
  selectedMenuBaseId: null,
  error: null
});

export const selectedMenuBaseId = (state: MenuBaseState) => state.selectedMenuBaseId;
export const selectIsLoading = (state: MenuBaseState) => state.isLoading;
export const selectError = (state: MenuBaseState) => state.error;


export class MenuBase {
  public id = 0;
  public menus: any[] = [];
}
