import { PEPDiagnostico } from './pepDiagnostico.model';

export class PEPDiagnosticoOncologico extends PEPDiagnostico {
  public Estadiamento: string;
  public T: string;
  public N: string;
  public M: string;
  public Observacao: string;

  constructor() {
    super();
    this.Tipo = 'O';
  }
}
