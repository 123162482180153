<div #container *ngIf="(LoadingType === 'SkeletonList')" class="container list">
  <!-- item -->
  <div  #item  *ngFor="let placeholder of [].constructor(_counter); let i = index ">
    <!-- data -->
    <div class="title-header list-margin"></div>
    <!-- body do container -->
    <div class="list-container">
      <!-- profisional -->
      <div class="list-description list-margin"></div>
  
    </div>
  </div>
</div>

<div #container *ngIf="(LoadingType === 'SkeletonBox')" class="container">
  <!-- item -->
  <div #item class="tab-content" *ngFor="let placeholder of [].constructor(_counter); let i = index ">
    <!-- header -->
    <div class="box-header list-margin"></div>
    <!-- title -->
    <div class="title-header list-margin"></div>
    <!-- body do container -->
    <div class="box-container">
      <!-- profisional -->
      <div class="box-description list-margin"></div>

    </div>
  </div>
</div>