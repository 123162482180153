import { IClinica, IClinicaHorario, IContaFluxo, IIpParametroChave, ILote, INotaFiscal, INotaFiscalConfiguracao, IPortador } from "@gemed-core/interfaces/gemed.interface";
import { Empresa } from "./empresa.model";

export class Clinica implements IClinica {
    Id: number;
    IdClinica: number;
    IdEmpresa:number;
    IdIntegracao: number;
    UltimaAutorizacao: number;
    UltimaNFiscal: number;
    UltimoRecibo: number;
    UltimoTISS?: number;
    ChequeCaucao: number;
    CodIPE: string;
    CNES: string;
    PercPIS: number;
    PercCofins: number;
    PercCSLL: number;
    Fantasia: string;
    EmiteNFiscal: string;
    AliquotaIss: number;
    Registro: string;
    RegistroConsulta: string;
    Razao: string;
    PercBaseISSQN: number;
    ConvenioNomeContratado: string;
    ConvenioCNPJContratado: string;
    Portador: IPortador;
    Empresa: Empresa;
    NotaFiscalConfiguracao: INotaFiscalConfiguracao;
    ContaFluxo: IContaFluxo[];
    Lote: ILote[];
    NotaFiscal: INotaFiscal[];
    Parametros: IIpParametroChave[];
    Horarios: IClinicaHorario[];
  
}