import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MonitoringService } from '@gemed-core/logging.service';
import { Clinica } from '@gemed-core/models/clinica.model';
import { HttpResponse } from '@gemed-core/models/httpResponse.model';
import { Usuario } from '@gemed-core/models/usuario.model';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { resetStateMenuAction } from '@pep-assistencial-store/actions/menu.action';
import { deselecionarPacienteAction } from '@pep-assistencial-store/actions/prontuario.actions';
import { IUsuarioAutenticado, IUsuarioAutenticadoDTO } from 'ip_types_ts/common-typings';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { HttpStatus } from '../../models/httpStatus.enum';
import { AbrirModalClinicasAction, ActionTypes, AutenticadoSucessoAction, LimparErroLogInAction, LogInErroAction, LogInSucessoAction, LogOutErroAction, LogOutSuccessAction } from '../actions/auth.actions';
import { toPayload } from '../libraries';
import { AuthState, GemedState } from '../state';

@Injectable({
  providedIn: 'root'
})


export class AuthEffects {

  private existemMultiplasClinicas(clinicas: Clinica[]): boolean {
    return !!clinicas && clinicas.length > 1;
  }

  @Effect({ dispatch: false })
  public LimparErroLogIn: Observable<Action> = this.actions
    .pipe(ofType(ActionTypes.LOG_IN_ERROR))
    .pipe(tap(() => {
      setTimeout(() => {
        this.store.dispatch(new LimparErroLogInAction());
      }, 3 * 1000);
    }));

  @Effect()
  public LogIn: Observable<Action> = this.actions
    .pipe(ofType(ActionTypes.LOG_IN))
    .pipe(debounceTime(500))
    .pipe(map(toPayload))
    .pipe(map((payload: any) => {
      const userTemp = payload.user;
      return this.authService.LogIn(userTemp.usuario, userTemp.password, userTemp.clinica)
        .pipe(
          map((result: HttpResponse<{
            usuario: IUsuarioAutenticadoDTO, idClinica: number, clinicas: Clinica[]
          }>) => {
            const { clinicas, usuario } = result.Data;
            const token = result.Headers.get("authorization").split(" ")[1];
            const refreshToken = result.Headers.get("authrefreshtoken");

            if (this.existemMultiplasClinicas(clinicas)) {
              return new AbrirModalClinicasAction({
                clinicas,
                token,
                refreshToken,
                usuario,
                identificador: userTemp.clinica
              });
            }

            const { Razao } = clinicas[0];

            return new LogInSucessoAction({
              ...result.Data,
              token: token,
              refreshToken: refreshToken,
              manterLogado: payload.manterLogado,
              razaoClinica: Razao
            });
          }), catchError((err) => {
            return of(new LogInErroAction(this.handleError(err)));
          }));
    }))
    .pipe(switchMap(x => x));

  @Effect()
  public AbrirModalClinicas: Observable<never> = this.actions.pipe(
    ofType(ActionTypes.ABRIR_MODAL_CLINICAS),
    map((action: AbrirModalClinicasAction) => action.payload),
    switchMap(objeto => {
      this.authService.habilitarModalClinicas(objeto);

      return NEVER;
    })
  )


  @Effect()
  public LogInSucess: Observable<Action> = this.actions
    .pipe(ofType(ActionTypes.LOG_IN_SUCCESS))
    .pipe(debounceTime(500))
    .pipe(map(toPayload))
    .pipe(map((payload: IUsuarioAutenticado) => {

      // TODO: Rever criar map para dados serializados.
      const usuarioLogado = Usuario.MapIUsuarioAutenticadoParaUsuario(payload);
      const authState = new AuthState();
      //Atribui propriedades ao state que será enviado ao reducer
      authState.Usuario = usuarioLogado;
      authState.IsAuthenticated = true;
      authState.isLoading = false;
      authState.manterLogado = payload.manterLogado;
      authState.idClinica = payload.idClinica;
      authState.sistema = payload.sistema;
      authState.error = null;
      authState.ehPrimeiroAcesso = false;
      authState.razaoClinica = payload.razaoClinica;

      this.monitor.appInsights.setAuthenticatedUserContext(usuarioLogado.IdUsuario.toString(), payload.idClinica.toString());

      return new AutenticadoSucessoAction(authState);
    }));


  @Effect()
  public LogOut: Observable<Action> = this.actions
    .pipe(ofType(ActionTypes.LOG_OUT))
    .pipe(switchMap(() => this.authService.logout()))
    .pipe(map((isLogout: boolean) => {
      if (isLogout) {
        this.store.dispatch(deselecionarPacienteAction());
        this.store.dispatch(resetStateMenuAction());
        this.monitor.appInsights.clearAuthenticatedUserContext();
        return of(new LogOutSuccessAction());
      }
      return of(new LogOutErroAction());
    }))
    .pipe(switchMap(x => x));



  @Effect({ dispatch: false })
  public LogOutSucess: Observable<any> = this.actions
    .pipe(ofType(ActionTypes.LOG_OUT_SUCCESS))
    .pipe(map(() => {
      return this.router.navigate(['/users/log-in']);
    }))




  private handleError<T>(operation, result?: T) {
    if (!operation) {
      operation = {
        StackTrace: {
          status: HttpStatus.UNKNOW_ERROR
        }
      };
    }

    const { status } = operation.StackTrace;
    if (
      (status === HttpStatus.INTERNAL_SERVER_ERROR) ||
      (status === HttpStatus.UNKNOW_ERROR) ||
      (status === HttpStatus.NOT_FOUND)) {
      operation.Menssagem = "mensagemAvisoSuporte";
    }
    return operation;
  }

  constructor(private actions: Actions,
    private authService: AuthService,
    private store: Store<GemedState>,
    private router: Router,
    private monitor: MonitoringService) { }
}
