import { LibGeral } from "@gemed-core/libraries/libGeral";
import { createReducer, on } from "@ngrx/store";
import { buscarParametroEmpresaSucessoAction, buscarParametroSucessoAction } from "src/app/parametrosManager/Store/Actions/parametrosManager.actions";
import { ParametrosManagerState } from "../State/ParametrosManagerState";

// https://github.com/ngrx/platform/issues/910
export const initialState = new ParametrosManagerState();


const _parametrosManagerReducer = createReducer(
    initialState,
    on(buscarParametroSucessoAction, (state, { payload }) => {

        if (LibGeral.estaPreenchido(payload.Parametro)) {
            return {
                ...state,
                Parametros: {
                    ...state.Parametros,
                    [payload.Parametro.IdParametro]: payload
                }
            };
        }
        return state;
    }),
    on(buscarParametroEmpresaSucessoAction, (state, { payload }) => {
        if (LibGeral.estaPreenchido(payload.Parametro)) {
            return {
                ...state,
                Parametros: {
                    ...state.Parametros,
                    [payload.Parametro.IdParametro]: payload
                }
            };
        }
        return state;
    })
)

export function parametrosManagerReducer(state, action) {
    return _parametrosManagerReducer(state, action);
}

