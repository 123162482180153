export enum FiltroAgendaSituacao {
  EmAtendimento = "emAtendimento",
  Recepcionados = "recepcionados",
  Canceladas = "canceladas",
}

export enum IpModeloTipo {
  NaoInformado = "",
  NotaFiscal = "N",
  EtiquetaInsumo = "I",
  EtiquetaInsumoReduzida = "R",
  EtiquetaFarmaciaInsumo = "F",
  EtiquetaFarmaciaGrupo = "G",
  EtiquetaFarmaciaOrdemServico = "O",
  EtiquetaFarmaciaInvolucroDaBolsa = "E",
  EtiquetaFarmaciaEstoqueDiarioBandeja = "D",
  EtiquetaFarmaciaInvolucroDaPrescricao = "P",
  EtiquetaFarmaciaInvolucroDaPrescricaoExtra = "X",
  EtiquetaClinicaPulseiraPaciente = "Y",
  EtiquetaFarmaciaEstoqueDiarioSoro = "S",
  EtiquetaFarmaciaEstoqueDiarioFrascoAvulso = "U",
  EtiquetaFarmaciaEstoqueDiarioComprimidos = "C",
  EtiquetaFarmaciaComprimidoTotal = "T",
  EtiquetaFarmaciaComprimidoFracionado = "M",


}

export enum TiposPrescricaoVO {
  NAO_UTILIZA_PRESCRICAO_VO = "N",
  UTILIZA_PRESCRICAO_VO_UNIMED = "U",
  UTILIZA_PRESCRICAO_VO_VIVER = "S",
}

export enum TipoProtocoloTipo {
  NaoInformado = "",
  Quimioterapia = "Q",
  Radioterapia = "R",
  Braquiterapia = "B",
  OutrosProcedimentos = "O",
}

export enum TipoProtocoloTipoDescricao {
  "Não Informado" = TipoProtocoloTipo.NaoInformado,
  "Quimioterapia" = TipoProtocoloTipo.Quimioterapia,
  "Radioterapia" = TipoProtocoloTipo.Radioterapia,
  "Braquiterapia" = TipoProtocoloTipo.Braquiterapia,
  "Outros Procedimentos" = TipoProtocoloTipo.OutrosProcedimentos,
}

export enum EsquemaFracionamento {
  NaoInformado = "",
  Diario = "D",
  DiasAlternados = "A",
  DuasVezesAoDia = "2",
  Outro = "O",
}



export const EsquemaFracionamentoApi = new Map<string, string>(
  [
    [EsquemaFracionamento.NaoInformado, "NaoInformado"],
    [EsquemaFracionamento.Diario, "Diario"],
    [EsquemaFracionamento.DiasAlternados, "DiasAlternados"],
    [EsquemaFracionamento.DuasVezesAoDia, "DuasVezesAoDia"],
    [EsquemaFracionamento.Outro, "Outro"],
  ]
);


export enum EsquemaFracionamentoDescricao {
  "Não Informado" = EsquemaFracionamento.NaoInformado,
  "Diário" = EsquemaFracionamento.Diario,
  "Dias Alternados" = EsquemaFracionamento.DiasAlternados,
  "2x ao Dia" = EsquemaFracionamento.DuasVezesAoDia,
  "Outro" = EsquemaFracionamento.Outro,
}

export enum FinalidadeTratamento {
  NaoInformado = "",
  Curativa = "1",
  Neoadjuvante = "2",
  Adjuvante = "3",
  Paliativa = "4",
}

export enum FinalidadeTratamentoDescricao {
  "Não Informado" = FinalidadeTratamento.NaoInformado,
  "1 - Curativa" = FinalidadeTratamento.Curativa,
  "2 - Neoadjuvante" = FinalidadeTratamento.Neoadjuvante,
  "3 - Adjuvante" = FinalidadeTratamento.Adjuvante,
  "4 - Paliativa / De Controle" = FinalidadeTratamento.Paliativa,
}

export enum Ambiente {
  Homologacao = "H",
  Producao = "P",
  Testes = "T",
  NaoInformado = "",
}

export enum IncentivadorCultural {
  Sim = "1",
  Nao = "2",
  OptanteSimplesNacionalPrimeirasTrêsCompetencias = "3",
  ServicoPrestadoProgramaMinhaCasaMinhaVida = "4",
  NaoInformado = "",
}

export enum LoteTipo {
  NaoInformado = "N",
  Quimioterapica = "Q",
  Consulta = "C",
  HonorarioIndividual = "H",
  Exame = "E",
  Glosa = "G",
  Avulso = "A",
}

export enum DiaSemana {
  NaoInformado = "",
  Domingo = "1",
  SegundaFeira = "2",
  TercaFeira = "3",
  QuartaFeira = "4",
  QuintaFeira = "5",
  SextaFeira = "6",
  Sabado = "7",
}

export enum TipoNf {
  NFe = "E",
  NFSe = "S",
  NaoInformado = "",
}

export enum TipoItemCobrado {
  NaoInformado = "",
  Servico = "S",
  Produto = "P",
  Ambos = "A",
}

export enum LocalPrestacaoServico {
  NoMunicipioSemRentencao = "1",
  NoMunicipioComRetencao = "2",
  ForaMunicipioSemRetencao = "3",
  ForaMunicipioComRetencao = "4",
  ForaMunicípioComPagamentoNoLocal = "5",
  OutroMunicipio = "6",
  NaoInformado = "",
}

export enum DocumentoTipo {
  PRPDocumento = "F",
  PEPDocumento = "P",
  PEPModeloDocumento = "M",
  Exame = "E",
  Administrativo = "A",
  NaoInformado = "",
}

export enum OptanteSimplesNacional {
  Sim = "1",
  Não = "2",
  SIMEI = "3",
  SimplesFederalAliquota1 = "7",
  SimplesFederalAliquota05 = "8",
  SimplesMunicipalNfPaulistana = "9",
  NaoInformado = "",
}

export enum RegistroEspecialTributacao {
  MicroempresaMunicipal = "1",
  Estimativa = "2",
  SociedadeProfissionais = "3",
  Cooperativa = "4",
  MicroempresarioIndividual = "5",
  MicroempresarioEmpresaPequenoPorte = "6",
  OptanteSimplesNacional = "7",
  TributacaoNormal = "8",
  Autonomo = "9",
  Variavel = "10",
  LucroReal = "11",
  LucroPresumido = "12",
  SociedadeProfissionaisPessoaJurídica = "13",
  Nao = "14",
  NotasTotalizadoras = "15",
  InscritoPRODEVAL = "16",
  NaoInformado = "",
}

export enum NexodataAmbienteEnum {
  QA = "QA",
  PROD = "PROD",
  HOMOLOG = "HOMOLOG",
}

export enum MaterialEnfermagemTipo {
  NaoInformado = "",
  EquipoEspecial = "E",
  EquipoSimples = "F",
  Agulha = "A",
  Seringa = "S",
  Luva = "L",
  Outros = "O",
}

export enum MaterialEnfermagemTipoUso {
  NaoInformado = "",
  Padrao = "D",
  Prescricao = "P",
}

export enum PlanoContaDCA {
  /**
   * Crédito
   */
  Credito = "C",
  /**
   * Débito
   */
  Debito = "D",
  /**
   * Ambos
   */
  Ambos = "A",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum SobraSituacao {
  /**
   * Conciliado
   */
  Conciliado = "C",
  /**
   * Vencido
   */
  Vencido = "V",
  /**
   * Descartado
   */
  Descartado = "I",
  /**
   * Ativo
   */
  Ativo = "A",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoLog {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Inclusão
   */
  Inclusao = "I",
  /**
   * Alteração
   */
  Alteracao = "A",
  /**
   * Exclusão
   */
  Exclusao = "E",
  /**
   * Todos
   */
  Todos = "T",
  /**
   * Leitura
   */
  Leitura = "L",
}

export enum AcaoPendenteStatusResolucao {
  Pendente = "",
  Resolvida = "R",
  Descartada = "D",
  Vencida = "V"
}


export enum AcaoPendenteImportancia {
  Baixa = 1,
  Media = 2,
  Alta = 3,
  Critica = 4,
}

export enum LoteSituacao {
  /*
    A - Aberto (pendente para faturar)
    F - Faturado(Fechado)
    G - Gerado Disquete (IPE)
    E - Entregue
    I  - Inativo
        */
  Aberto = "A",
  Faturado = "F",
  Gerado = "G",
  Entregue = "E",
  Inativo = "I",
  NaoInformado = "",
}

export enum PrescricaoPedidoSituacao {
  NaoInformado = "",
  Aberta = "A",
  Revisada = "R",
  Digitada = "T",
  Recusada = "X",
  Cancelada = "C",
}

export enum PrescricaoPedidoPrioridade {
  NaoInformado = "",
  Normal = "N",
  Urgente = "U",
}

export enum TipoProcesso {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Visual Basic
   */
  VisualBasic = "V",
  /**
   * .Net Windows Forms
   */
  WindowsForms = "F",
  /**
   * .Net Web
   */
  Web = "W",
  /**
   * Gemed Web
   */
  Internet = "I",
}

export enum TipoContaPacienteEnum {
  /**
   * Crédito
   */
  Credito = "C",
  /**
   * Débito
   */
  Debito = "D",
}

export enum PacienteSituacao {
  /**
   * Ativo
   */
  Ativo = "A",
  /**
   * Passivo
   */
  Passivo = "P",
  /**
   * Óbito
   */
  Obito = "O",
  /**
   * Não Informada
   */
  NaoInformada = "",
}

export enum MotivoEncerramento {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Alta Curado
   */
  AltaCurado = "11",
  /**
   * Alta Melhorado
   */
  AltaMelhorado = "12",
  /**
   * Alta a Pedido
   */
  AltaAPedido = "14",
  /**
   * Alta com previsão de retorno para acompanhamento do paciente
   */
  AltaComPrevisao = "15",
  /**
   * Alta por Evasão
   */
  AltaPorEvasao = "16",
  /**
   * Alta por outros motivos
   */
  AltaPorOutrosMotivos = "18",
  /**
   * Alta de Paciente Agudo em Psiquiatria
   */
  AltaPacienteAgudoPsiquiatria = "19",
  /**
   * Permanência, por características próprias da doença
   */
  PermanenciaCaracteristicasPropriasDoenca = "21",
  /**
   * Permanência, por intercorrência
   */
  PermanenciaIntercorrencia = "22",
  /**
   * Permanência, por impossibilidade sócio-familiar
   */
  PermanenciaImpossibilidadeSocioFamiliar = "23",
  /**
   * Permanência, por Processo de doação de órgãos, tecidos e células - doador vivo
   */
  PermanenciaDoadorVivo = "24",
  /**
   * Permanência, por Processo de doação de órgãos, tecidos e células - doador morto
   */
  PermanenciaDoadorMorto = "25",
  /**
   * Permanência, por mudança de Procedimento
   */
  PermanenciaMudancaProcedimento = "26",
  /**
   * Permanência, por reoperação
   */
  PermanenciaReoperacao = "27",
  /**
   * Permanência, outros motivos
   */
  PermanenciaOutrosMotivos = "28",
  /**
   * Transferido para outro estabelecimento
   */
  TransferidoOutroEstabelecimento = "31",
  /**
   * Transferência para Internação Domiciliar
   */
  TransferidoInternacaoDomiciliar = "32",
  /**
   * Óbito com declaração de óbito fornecida pelo médico assistente
   */
  ObitoMedicoAssistente = "41",
  /**
   * Óbito com declaração de Óbito fornecida pelo Instituto Médico Legal - IML
   */
  ObitoIML = "42",
  /**
   * Óbito com declaração de Óbito fornecida pelo Serviço de Verificação de Óbito - SVO.
   */
  ObitoSVO = "43",
  /**
   * Encerramento Administrativo
   */
  EncerramentoAdminstrativo = "51",
  /**
   * Alta da mãe/puérpera e do recém-nascido
   */
  AltaMaeERecemNascido = "61",
  /**
   * Alta da mãe/puérpera e permanência do recém-nascido
   */
  AltaMaePermanenciaRecemNascido = "62",
  /**
   * Alta da mãe/puérpera e óbito do recém-nascido
   */
  AltaMaeObitoRecemNascido = "63",
  /**
   * Alta da mãe/puérpera com óbito fetal
   */
  AltaMaeObitoFetal = "64",
  /**
   * Óbito da gestante e do concepto
   */
  ObitoGestanteConcepto = "65",
  /**
   * Óbito da mãe/puérpera e alta do recém-nascido
   */
  ObitoMaeAltaRecemNascido = "66",
  /**
   * Óbito da mãe/puérpera e permanência do recém-nascido
   */
  ObitoMaePermanenciaRecemNascido = "67",
}

export enum AutorizacaoTipoAtendimento {
  /**
   * Remoção
   */
  Remocao = "01",
  /**
   * Pequena Cirurgia
   */
  PequenaCirurgia = "02",
  /**
   * Terapias
   */
  Terapias = "03",
  /**
   * Consulta
   */
  Consulta = "04",
  /**
   * Exame Ambulatorial
   */
  Exame = "05",
  /**
   * Atendimento Domiciliar
   */
  AtendimentoDomiciliar = "06",
  /**
   * Internação
   */
  Internacao = "07",
  /**
   * Quimioterapia
   */
  Quimioterapia = "08",
  /**
   * Radioterapia
   */
  Radioterapia = "09",
  /**
   * TRS-Terapia Renal Substitutiva
   */
  TerapiaRenalSubstitutiva = "10",
  /**
   * Não informado
   */
  NaoInformado = "",
}

export enum AutorizacaoTipo {
  /**
   * Convênio
   */
  Convenio = "C",
  /**
   * Particular
   */
  Particular = "P",
  /**
   * Cheque Caução
   */
  ChequeCaucao = "Q",
  /**
   * Consulta Convênio
   */
  ConsultaConvenio = "O",
  /**
   * Consulta Particular
   */
  ConsultaParticular = "R",
  /**
   * Honorário Individual
   */
  HonorarioIndividual = "I",
  /**
   * Temporária
   */
  Temporaria = "T",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoContaFluxo {
  Caixa = "C",
  Banco = "B",
  Aplicacao = "A",
  Profissionais = "F",
  PadraoProfissionais = "P",
  NaoInformado = "",
}

export enum TipoGlosaRestante {
  /**
   * Glosa Definitiva
   */
  Definitiva = "D",
  /**
   * Manter Glos
   */
  Manter = "M",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoRecurso {
  /**
   * Não Cabe Recurso
   */
  NaoCabeRecurso = "I",
  /**
   * Completo
   */
  Completo = "C",
  /**
   * Parcial
   */
  Parcial = "P",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoGlosa {
  /**
   * Glosa
   */
  Glosa = "G",
  /**
   * Honorário
   */
  Honorario = "H",
  /**
   * Avaliação
   */
  Avaliacao = "A",
  /**
   * Desconto
   */
  Desconto = "D",
  /**
   * Outros
   */
  Outros = "O",
  /**
   * Não Acatado
   */
  NaoAcatado = "N",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum SituacaoGlosa {
  /**
   * Aberto
   */
  Aberto = "A",
  /**
   * Recorrido
   */
  Recorrido = "R",
  /**
   * Fechado
   */
  Fechado = "F",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TecnicaUtilizada {
  /**
   * Videolaparoscopia
   */
  Videolaparoscopia = "V",
  /**
   * Convencional
   */
  Convenional = "C",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum ViaAcesso {
  /**
   * Única
   */
  Unica = "U",
  /**
   * Mesma Via
   */
  MesmaVia = "M",
  /**
   * Diferentes Vias
   */
  DiferentesVias = "D",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum AutorizacaoTipoConsulta {
  /**
   * Primeira
   */
  Primeira = "1",
  /**
   * Seguimento
   */
  Seguimento = "2",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum AutorizacaoConferido {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Sim
   */
  Sim = "S",
  /**
   * Não
   */
  Nao = "N",

  /**
   * Parcial pela Secretária
   */
  ParcialSecretaria = "P",
  /// <summary>
  /// R=Parcial pela Gerencia
  /// </summary>
  /**
   * Parcial pela Gerencia
   */
  ParcialGerencia = "R",
}

export enum AutorizacaoSituacao {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Solicitação
   */

  Solicitacao = "S",
  /**
   * Negada
   */
  Negada = "N",
  /**
   * Pendente
   */
  Pendente = "P",
  /**
   * Baixa Automática - Sem Faturar
   */
  BaixaAutomatica = "B",
  /**
   * Guia de Consulta
   */
  GuiaConsulta = "C",
  /**
   * Faturada
   */
  Faturada = "F",
}

export enum PortadorOrigem {
  /**
   * Portador
   */
  Portador = "O",
  /**
   *  Profissional
   */
  Profissional = "P",
  /**
   * Clinica
   */
  Clinica = "L",
  /**
   * Recurso
   */
  Recurso = "R",
  /**
   * Convenio
   */
  Convenio = "C",
  /**
   * Paciente
   */
  Paciente = "A",
  /**
   * Fornecedor
   */
  Fornecedor = "F",
  /**
   * Instituição
   */
  Instituicao = "I",
  /**
   * Não Tem Tipo Definido
   */
  NaoTemTipoDefinido = "N",
}

export enum TipoMedicamento {
  /**
   * Quimioterápico Patente
   */
  QuimioPatente = "Q",
  /**
   * Quimioterápico Não Patente
   */
  QuimioNaoPatente = "R",
  /**
   *Não Quimioterápico Patente
   */
  NaoQuimioPatente = "N",
  /**
   * Não Quimioterápico Não Patente
   */
  NaoQuimioNaoPatente = "O",
}

export enum FarmaciaOrdemServicoSituacao {
  /**
   * Aberta
   */
  Aberta = "A",
  /**
   * Aguardando Separacao
   */
  AguardandoSeparacao = "X",
  /**
   * Pedido Separado
   */
  PedidoSeparado = "I",
  /**
   * Insumo Separados Por Paciente
   */
  InsumosSeparadosPorPaciente = "S",
  /**
   * Produzida
   */
  Produzida = "P",
  /**
   * Liberada / Qualidade verificada
   */
  LiberadaQualidadeVerificada = "L",
  /**
   * Faturada
   */
  Faturada = "F",
  /**
   * Expedida
   */
  Expedida = "E",
  /**
   * Entregue ao Cliente
   */
  EntregueAoCliente = "C",
  Cancelado = "O"
}

export enum FarmaciaOrdemServicoSituacaoDescricao {
  Aberta = FarmaciaOrdemServicoSituacao.Aberta,
  "Aguardando separação" = FarmaciaOrdemServicoSituacao.AguardandoSeparacao,
  "Pedido separado" = FarmaciaOrdemServicoSituacao.PedidoSeparado,
  "Insumo Separados Por Paciente" = FarmaciaOrdemServicoSituacao.InsumosSeparadosPorPaciente,
  "Produzida" = FarmaciaOrdemServicoSituacao.Produzida,
  "Liberada / Qualidade verificada" = FarmaciaOrdemServicoSituacao.LiberadaQualidadeVerificada,
  "Faturada" = FarmaciaOrdemServicoSituacao.Faturada,
  "Expedida" = FarmaciaOrdemServicoSituacao.Expedida,
  "Entregue Ao Cliente" = FarmaciaOrdemServicoSituacao.EntregueAoCliente,
  "Cancelado" = FarmaciaOrdemServicoSituacao.Cancelado,
}

export enum FarmaciaOrdemServicoSituacaoApi {
  "Aberta" = FarmaciaOrdemServicoSituacao.Aberta,
  "Aguardando separação" = FarmaciaOrdemServicoSituacao.AguardandoSeparacao,
  "PedidoSeparado" = FarmaciaOrdemServicoSituacao.PedidoSeparado,
  "InsumosSeparadosPorPaciente" = FarmaciaOrdemServicoSituacao.InsumosSeparadosPorPaciente,
  "Produzida" = FarmaciaOrdemServicoSituacao.Produzida,
  "LiberadaQualidadeVerificada" = FarmaciaOrdemServicoSituacao.LiberadaQualidadeVerificada,
  "Faturada" = FarmaciaOrdemServicoSituacao.Faturada,
  "Expedida" = FarmaciaOrdemServicoSituacao.Expedida,
  "EntregueAoCliente" = FarmaciaOrdemServicoSituacao.EntregueAoCliente,
  "Cancelado" = FarmaciaOrdemServicoSituacao.Cancelado,
}

export enum TipoCID {
  /**
   * CID 10
   */
  CID10 = "C",
  /**
   * CID Morfológico
   */
  Morfologico = "M",
  /**
   * CID para Heparenização de catéter
   */
  HeparenizacaoCateter = "H",
  /**
   *  CID para atendimento expresso
   */
  AtendimentoExpresso = " A",
  /**
   * Outros
   */
  Outros = "O",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoMovClassificacao {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Nota de Compra
   */
  NotaCompra = "N",
  /**
   * Ajuste de Estoque
   */
  Ajuste = "A",
  /**
   * Baixa
   */
  Baixa = "B",
  /**
   * Inventário
   */
  Inventario = "I",
  /**
   * Empréstimo
   */
  Emprestimo = "E",
  /**
   * Transferência
   */
  Transferencia = "T",
  /**
   * Descarte
   */
  Descarte = "D",
  /**
   * Devolução
   */
  Devolução = "V",
}

export enum Tipo {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Baixa
   */
  Baixa = "B",
  /**
   * Ajuste
   */
  Ajuste = "A",
  /**
   * Devolução Estoque
   */
  DevolucaoEstoque = "E",
  /**
   * Descarte
   */
  Descarte = "D",
  /**
   * Transferência
   */
  Transferencia = "T",
  /**
   * Emprestar
   */
  Emprestar = "0",
  /**
   * Devolver
   */
  Devolver = "1",
  /**
   * Receber Emprestimo
   */
  ReceberEmprestado = "2",
  /**
   * Receber Devolução
   */
  ReceberDevolucao = "3",
  /**
   * "Compra"
   */
  Compra = "C",
}

export enum Movimento {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Entrada
   */
  Entrada = "E",
  /**
   * Saída
   */

  Saida = "S",
}

export enum TipoValorEnum {
  /**
   * Valor
   */
  Valor = "V",
  /**
   * Percentual
   */
  Percentual = "P",
  NaoInformado = "",
}

export enum TipoCustoEnum {
  /**
   * Imposto
   */
  Imposto = "I",
  /**
   * Frete
   */
  Frete = "F",
  /**
   * Embalagem
   */
  Embalagem = "E",
  /**
   * Operacional
   */
  Operacional = "O",

  NaoInformado = "",
}

export enum TipoFracionamento {
  /**
   * Quantidade
   */
  Quantidade = "Q",
  /**
   * Unidade de Medida
   */
  UnidadeMedida = "M",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum TipoRepasseHonorario {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Clínica
   */
  Clinica = "C",
  /**
   * Médico
   */
  Medico = "M",
  /**
   * Terceiro
   */
  Terceiro = "T",
}

export enum TipoUnidadeMedida {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Descrição
   */
  Descricao = "D",
  /**
   * Valor
   */
  Valor = "V",
}

export enum Arredondamento {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Para Cima
   */
  ParaCima = "C",
  /**
   * Para Baixo
   */
  ParaBaixo = "B",
}

export enum ImpressaoPrescricaoPrincipioAtivoNomeComercial {
  /**
   * Não Informado
   */
  NaoInformado = "",
  /**
   * Princípio Ativo
   */
  PrincipioAtivo = "P",
  /**
   * Nome Comercial
   */
  NomeComercial = "N",
}

export enum TipoNumeroGuiaInternacao {
  /**
   * Manual
   */
  Manual = "M",
  /**
   * Automático
   */
  Automatico = "A",
}

export enum PercentualOrigem {
  /**
   * Exceção
   */
  Excecao = "E",

  /**
   * Grupo
   */

  Grupo = "G",
  /**
   * Origem
   */

  Origem = "O",

  /**
   * Personalizado
   */

  Personalizado = "P",
  /**
   * Taxa
   */

  Taxa = "T",
  /**
   * Recalculo
   */

  Recalculo = "R",
}

export enum TipoGrupoInsumo {
  /**
   *"Comum"
   */
  Comum = "C",
  /**
   * "Restrito Hospitalar"
   */
  RestritoHospitalar = "R",
  /**
   * "Personalizado"*
   */
  Personalizado = "P",

  /**
   *"Nâo Restrito Hospitalar"
   */
  NaoRestritoHospitalar = "N",
}

/**
 * Indica se deve respeitar a lei de retenções de impostos 10925/04, que diz que os impostos COFINS,
CSLL e PIS devem ser retidos na fonte quando o valor bruto acumulado das notas fiscais dentro de um
mês emitidas para um mesmo CNPJ ultrapassarem R$ 5.000,00.
S: deve respeitar a lei de retenções
N: não deve respeitar, ignora somente controla o IRRF
R: sempre retem, mesmo que o valor seja inferior ao 5.000,00 da lei.Caso banco central que sempre deve reter.
 */

export enum RetemLei10925 {
  /**
   * Sim.
   */
  Sim = "S",
  /**
   * Não.
   */
  Nao = "N",
  /**
   * Retem.
   */
  Retem = "R",
}

export enum TISSProfissionalComoContratanteEnum {
  /**
   * Não Informado.
   */
  NaoInformado = "",
  /**
   * Sim.
   */
  Sim = "S",
  /**
   * Não.
   */
  Nao = "N",
  /**
   * Apenas Contatado Solicitante.
   */
  ApenasSolicitante = "O",
}

export enum CaraterAtendimento {
  Eletivo = "1",
  UrgenteEmergencial = "2",
  NaoInformado = "",
}

export enum TISSGrupoAcao {
  Incluir = "I",
  Excluir = "E",
  NaoInformado = "",
}

export enum DoseVolume {
  Dose = "D",
  Volume = "V",
}

export enum PrincipioAtivoTipo {
  Quimioterapico = "Q",
  MedicamentoSuporte = "M",
  Imunobiologico = "I",
  Psicotropico = "P",
  NaoInformado = "",
}

export enum PrincipioAtivoTipoReacao {
  NaoInformado = "",
  Vesicante = "V",
  Irritante = "I",
}

export enum GenericoMarca {
  Generico = "G",
  Similar = "S",
  Referencia = "R",
  NaoInformado = "",
}

export enum InsumoOrigem {
  NaoInformado = "",
  MaterialConsumo = "C",
  MaterialEnfermagem = "E",
  Medicamento = "M",
  Solucao = "S",
  TaxaSala = "T",
  HonorarioQuimio = "H",
  HonorarioConsulta = "N",
  HonorariosIndividuais = "I",
  AtivoImobilizado = "A",
}

export enum PlanoContasOrigem {
  Fornecedor = "F",
  ContaFluxo = "X",
  Convenio = "C",
  SemVinculo = "",
  Estoque = "E",
  Todos = "T",
}

export enum PrescricaoConciliado {
  NaoInformado = "",
  Sim = "S",
  Nao = "N",
  BaixaAutomatica = "T",
  Gerada = "G",
  Orcada = "O",
  ConfirmaPrescricao = "P",
  Impressa = "I",
  Admitida = "A",
  ConfirmadaQuimio = "Q",
  Faturada = "F",
  Cancelada = "C",
}

export enum TipoAssinatura {
  Farmacia = "F",
  Revisao = "R",
  Assinatura = "A",
}

export enum OrcamentoSituacao {
  OrcamentoNormal = "A",
  AguardandoDefinicao = "P",
  Solicitacao = "S",
  NaoInformado = "",
}

export enum MedidaTempo {
  /**
   * Dia
   */
  Dia = "d",
  /**
   * Hora
   */
  Hora = "h",
  /**
   * Minuto
   */
  Minuto = "m",
  /**
   * Segundo
   */
  Segundo = "s",
  /**
   * Bolus
   */
  Bolus = "b",
  /**
   * Não Informado
   */
  NaoInformado = "",
}

export enum Status {
  /**
   * "Não Informado"
   */
  NaoInformado = "",
  /**
   * Ativo
   */
  Ativo = "A",
  /**
   * Inativo
   */
  Inativo = "I",
}

export enum AutorizacaoSolicitacaoStatus {
  Pendente = "P",
  AguardandoSolicitacao = "G",
  Solicitado = "S",
  Negado = "N",
  Autorizado = "A",
}

export enum SituacaoFaturamentoProtocolo {
  Pendente = "P",
  EmTransito = "E",
  Recebido = "R",
  Devolvido = "D",
  NaoInfomado = "",
}

export enum TipoFaturamentoProtocolo {
  Envio = "E",
  Devolucao = "D",
  NaoInformado = "",
}

export enum SituacaoProtocoloDetalhe {
  Adicionado = "A",
  Removido = "R",
  NaoInformado = "",
}

export enum PrescricaoSituacaoNF {
  NotaFiscalImpressa = "I",
  NaoImpresso = "N",
  SemNota = "S",
  NaoInformado = "",
}

export enum TipoCiclo {
  Ciclo = "C",
  Semanal = "S",
  Mensal = "M",
  Aplicacao = "A",
  NaoInformado = "",
  Radioterapia = "R",
}

export enum SolicitacaoSituacao {
  NaoInformado = "",
  Pendente = "P",
  EmAtendimento = "E",
  Atendido = "A",
  Cancelado = "C",
}

export enum TipoDose {
  NaoInformado = "NULL",
  Manutencao = "M",
  Ataque = "A",
}

export enum SimNao {
  NaoInformado = "I",
  Nao = "N",
  Sim = "S",
}

export enum FluxoTipo {
  Receita = "R",
  Despesa = "D",
  ReceitaNaoRecebivel = "U",
  NaoInformado = "",
}

export enum SituacaoDistribuicao {
  CreditoNaoDistribuido = "C",
  CreditoDistribuido = "D",
  CreditoParticular = "P",
  Lote = "L",
  NaoInformado = "",
}

export enum SituacaoNotaFiscal {
  Aberta = "A",
  Impressa = "I",
  Cancelada = "C",
  ParcialmenteDistribuida = "P",
  TotalmenteDistribuida = "T",
  Inutilizada = "Z",
  Rejeitada = "R",
  Pendente = "E",
  NaoInfomado = "N",
}

export enum TipoConvenio {
  Particular = "T",
  Pesquisa = "P",
  Ipasgo = "G",
  Ipe = "I",
  Unimed = "U",
  DemaisConvenios = "C",
  PlanAssiste = "A",
  Bloqueio = "B",
  SUS = "S",
  Estrangeiro = "E",
}

export enum SobraTipo {
  Sobra = "S",
  Overfill = "O",
}

export enum ConvenioTISSVersao {
  V2_01_02 = "2.01.02",
  V2_01_03 = "2.01.03",
  V2_02_01 = "2.02.01",
  V2_02_02 = "2.02.02",
  V2_02_03 = "2.02.03",
  V3_00_00 = "3.00.00",
  V3_00_01 = "3.00.01",
  V3_01_00 = "3.01.00",
  V3_02_00 = "3.02.00",
  V3_02_01 = "3.02.01",
  V3_02_02 = "3.02.02",
  V3_03_01 = "3.03.01",
  V3_03_02 = "3.03.02",
  V3_03_03 = "3.03.03",
  V3_04_00 = "3.04.00",
  NaoInformado = "",
}

export enum ConvenioTipoCodPrestador {
  CodigoPrestadorOperadora = "P",
  CNPJConvenio = "C",
}

export enum AgendaAtendimento {
  Padrao = "P",
  Customizado = "C",
  PrimeiroAtendimento = "A",
}

export enum AgendaAutorizacaoTipoConsulta {
  Primeira = "1",
  Seguimento = "2",
  NaoInformado = "",
}

export enum AgendaTipoConsulta {
  Normal = "N",
  Encaixe = "E",
  Internet = "I",
  NaoInformado = "",
}

export enum TipoUnidadeEstoque {
  Principal = "P",
  UnidadeEstoque = "U",
  NaoInformado = "",
}

export enum AgendaStProducao {
  NaoLiberado = "N",
  Liberado = "L",
  Produzido = "P",
  NaoInformado = "",
}

export enum UnidadeMedidaEnum {
  amp = "amp",
  cp = "cp",
  g = "g",
  centigray = "cGy",
  gray = "Gy",
  mcg = "mcg",
  mg = "mg",
  ml = "ml",
  MUI = "MUI",
  UI = "UI",
  Dia = "d",
  Hora = "h",
  Minuto = "m",
  Bolus = "b",
  Unidade = "Un",
  NaoInformado = "",
}

export enum UnidadeMedidaEnumDescricao {
  amp = "amp",
  cp = "cp",
  g = "g",
  cGy = "centigray",
  Gy = "gray",
  mcg = "mcg",
  mg = "mg",
  ml = "ml",
  MUI = "MUI",
  UI = "UI",
  d = "Dia",
  h = "Hora",
  m = "Minuto",
  b = "Bolus",
  Un = "Unidade",
}

export enum PrescricaoPedidoItemControleQualidadeSituacao {
  Confirmado = "C",
  Devolvido = "D",
}


export const InsumoOrigemValueApi = new Map<string, string>(
  [
    [InsumoOrigem.NaoInformado, "NaoInformado"],
    [InsumoOrigem.MaterialConsumo, "MaterialConsumo"],
    [InsumoOrigem.MaterialEnfermagem, "MaterialEnfermagem"],
    [InsumoOrigem.Medicamento, "Medicamento"],
    [InsumoOrigem.Solucao, "Solucao"],
    [InsumoOrigem.TaxaSala, "TaxaSala"],
    [InsumoOrigem.HonorarioQuimio, "HonorarioQuimio"],
    [InsumoOrigem.HonorarioConsulta, "HonorarioConsulta"],
    [InsumoOrigem.HonorariosIndividuais, "HonorariosIndividuais"],
    [InsumoOrigem.AtivoImobilizado, "AtivoImobilizado"],
  ]
);

export enum AssinaturaDigitalModo {
  NaoInformado,
  Assincrono,
  Sincrono
}

export enum AssinaturaDigitalPadrao {
  NaoInformado = "",
  CAdES = "CAdES",
  XadES = "XadES",
  PadEs = "PadEs"
}

export enum AssinaturaDigitalPolitica {
  NaoInformado = "",
  AD_RB = "AD-RB",
  AD_RT = "AD-RT",
  AD_RC = "AD-RC"
}

export enum AlgoritmoHash {
  NaoInformado,
  SHA1,
  SHA256,
  SHA512
}

export enum FormatoDadoAssinar {
  NaoInformado,
  Base64,
  Hexadecimal,
  ByteArray
}
export enum DocumentoFormato {
  NaoInformado,
  DOC,
  PDF,
  Imagem,
  XML,
  DOCX
}
