declare const _: any;
import { Record } from "immutable";

const estaPreenchido = (valor) =>
  !(valor === undefined || valor === null || valor === "");

export class Reflection {
  private static isValid<T>(object: T, propertyName: string): void {
    if (!estaPreenchido(propertyName)) {
      throw new Error("propertyName is not valid");
    }
    if (!estaPreenchido(object)) {
      throw new Error("object is not valid");
    }
  }
  public static getValue<T>(object: any, propertyName: string): T {
    Reflection.isValid<T>(object, propertyName);
    let value: any = null;
    if (object instanceof Record) {
      value = (<any>object).get(propertyName);
    } else {
      value = object[propertyName];
    }
    /*
    let lastPropertyValue = null;
    _(propertyName.split('.'))
    .forEach((path: string) => {
      if (lastPropertyValue === undefined) {
        throw new Error(`property ${path} can't be set`);
      }
      lastPropertyValue = object[path];
    });
    */
    return value;
  }
  public static setValue<T extends any>(
    object: T,
    propertyName: string,
    value: any
  ): T {
    Reflection.isValid<T>(object, propertyName);
    const pathParts = propertyName.split(".");
    if (pathParts.length === 1) {
      Reflection.setPropertyValue(object, propertyName, value);
    } else {
      // todo: implemetar para immutable;
      let lastPropertyValue = null;
      _(pathParts).forEach((path: string, index: number) => {
        if (lastPropertyValue === undefined) {
          throw new Error(`property ${path} can't be set`);
        }
        if (index === pathParts.length - 1) {
          lastPropertyValue[path] = value;
        } else {
          lastPropertyValue = object[path];
        }
      });
    }
    return object;
  }
  public static createRecord(object: any): any {
    const recordClass = Record(object);
    return new recordClass();
  }

  /**
   * transform enum class to array
   * @param  {any} type
   * @returns Array<object>
   */
  public static enumToArray<T>(type: any): Array<T | any> {
    const object = new type();
    return Object.keys(object).map((propName: string) => {
      return {
        key: object[propName],
        display: "display" in object ? object.display : propName,
      };
    });
  }
  private static setPropertyValue(
    object: any,
    propertyName: string,
    value: any
  ): any {
    if (object instanceof Record) {
      return (<any>object).set(propertyName, value);
    }
    object[propertyName] = value;
    return object;
  }
}
