import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ip-slider',
  styleUrls: ['./ip-slider.component.scss'],
  template: `
     <mat-slider
       class="ip-slider"
       [max]="max"
       [min]="min"
       [value]="model"
       [tick-interval]="step"
       [disabled]="naoInformado"
       [color]="'primary'"
       (change)="onChange($event)"
     ></mat-slider>`
})
export class IPSliderComponent implements AfterContentInit {
  @Input() discrete: boolean;
  @Input() naoInformado: boolean;
  @Input() max: number;
  @Input() min: number;
  @Input() model: number;
  @Input() step: number;
  @Output() private modelChange: EventEmitter<number>;

  private elementRef: ElementRef;
  private element: HTMLElement;
  private id: number;
  private ticks: HTMLOptionElement[];

  constructor(elementRef: ElementRef) {
    this.discrete = false;
    this.elementRef = elementRef;
    this.element = (<HTMLElement>this.elementRef.nativeElement);
    this.id = Date.now();
    this.min = 0;
    this.max = 100;
    this.modelChange = new EventEmitter<number>();
    this.step = 1;
    this.ticks = [];
  }


  ngAfterContentInit(): void {
    if (this.discrete === false) {
      this.step = 0;
    }

  }

  onChange = (evento): void => {
    if (!!evento.value) {
      this.model = evento.value;
      this.modelChange.emit(this.model);
    }
  }
}
