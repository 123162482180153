import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPAlertDialogComponent } from './ip-alert-dialog.component';
import { IPConfirmDialogComponent } from './ip-confirm-dialog.component';
import { IPDialogContainerComponent } from './ip-dialog-container.component';
import { MaterialModule } from '../../../material/material.module';
import { MatDialogRef } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout/flex';
import { IPButtonModule } from '../button/ip-button.module';
import { ConfirmSimpleDialogComponent } from './confirm-simple-dialog/confirm-simple-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

const dialogs = [
  IPDialogContainerComponent,
  IPAlertDialogComponent,
  IPConfirmDialogComponent,
  ConfirmSimpleDialogComponent,
  IPDialogContainerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    IPButtonModule,
    FlexModule,
    TranslateModule.forChild(),
  ],
  declarations: dialogs,
  exports: dialogs,
  providers: [{ provide: MatDialogRef, useValue: MatDialogRef }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class IPDialogModule {}
