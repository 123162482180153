import { NgModule } from "@angular/core";
import { LoadingWithOverlayDirective } from "src/app/core-ui/directives/loadingWithOverlay/loading-with-overlay.directive";
import { LoadingWithOverlayComponent } from "src/app/core-ui/components/loading-with-overlay/loading-with-overlay.component";
import { SkeletonLoadingComponent } from "src/app/core-ui/components/skeleton-loading/skeleton-loading.component";
import { IPProgressModule } from "src/app/core-ui/components/progress";

import { CommonModule } from "@angular/common";
import { ModalComponent } from "src/app/core-ui/components/modal/modal.component";
import { MatCardModule } from "@angular/material/card";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DxDataGridModule } from "devextreme-angular";


@NgModule({
    imports: [
        CommonModule,
        IPProgressModule,
        MatCardModule,
        DragDropModule,
        DxDataGridModule
    ],
    declarations: [
        LoadingWithOverlayDirective,
        LoadingWithOverlayComponent,
        SkeletonLoadingComponent,
        ModalComponent,
    ],
    exports: [
        LoadingWithOverlayDirective,
        LoadingWithOverlayComponent,
        SkeletonLoadingComponent,
        ModalComponent,
    ]
})
  
  export class SharedModule {}