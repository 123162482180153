import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LibGeral } from '@gemed-core/libraries/libGeral';

@Component({
  selector: 'gemed-select',
  templateUrl: './gemed-select.component.html',
  styleUrls: ['./gemed-select.component.scss']
})
export class GemedSelectComponent implements OnInit {

  @ViewChild('inputFiltro', { read: ElementRef }) inputFiltro !: ElementRef<HTMLInputElement>;
  @ViewChild('viewContainerRef', { read: ElementRef }) containerDiv !: ElementRef<HTMLDivElement>;
  @Input() options: IGemedOption[] = [];
  @Input() placeholder: string;
  @Input() label: string;
  @Input() deveMostrarIcone = false;

  @Input() valorSelecionado: string;
  @Output() valorSelecionadoChange: EventEmitter<string> = new EventEmitter<string>();

  listaOpcoes: Array<IGemedOption>;
  mostrarListagem = false;

  constructor() { }
  ngOnInit(): void {
    this.listaOpcoes = [...this.options];
    this.alterarValorInputFiltroComTextoOpcao();
  }

  handleAlterarVisibilidadeLista() {
    this.handleFiltrarItens();
    this.mostrarListagem = !this.mostrarListagem;
  }


  handleFiltrarItens(valorFiltro?: string) {
    if (LibGeral.estaPreenchido(valorFiltro)) {
      this.listaOpcoes = this.options.filter(option => option.text.toLowerCase().includes(valorFiltro.toLowerCase()));
    } else {
      this.listaOpcoes = [...this.options];
    }

  }

  handleSelecionarOpcao(option: IGemedOption) {
    this.alterarValorOptionSelecionado(option);
    this.handleAlterarVisibilidadeLista();
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(evento: MouseEvent) {

    if (this.mostrarListagem && !this.containerDiv.nativeElement.contains(evento.target as Node)) {
      this.handleAlterarVisibilidadeLista();
      this.alterarValorInputFiltroComTextoOpcao();
    }

  }

  private alterarValorInputFiltroComTextoOpcao() {
    const textoValorSelecionado = this.options.find(option => option.value === this.valorSelecionado);
    this.inputFiltro.nativeElement.value = LibGeral.estaPreenchido(textoValorSelecionado) ? textoValorSelecionado.text : "";
  }

  private alterarValorOptionSelecionado(option: IGemedOption) {
    this.valorSelecionado = option.value;
    this.valorSelecionadoChange.emit(option.value);
    this.alterarValorInputFiltroComTextoOpcao();
  }

}
