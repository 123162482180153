import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPSliderComponent } from './ip-slider.component';
import { IPSliderDescritivoComponent } from './ip-slider-descritivo.component';
import { MaterialModule } from '../../../material/material.module';
import { FlexModule } from '@angular/flex-layout/flex';

@NgModule({
  imports: [CommonModule, MaterialModule, FlexModule],
  declarations: [IPSliderComponent, IPSliderDescritivoComponent],
  exports: [IPSliderComponent, IPSliderDescritivoComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPSliderModule {
}
