import { Directive, ElementRef, Input, Renderer2, OnChanges, Output, EventEmitter } from '@angular/core';
import { IPHighlightService } from './highlight.service';
import { LibGeral } from 'src/app/core/libraries/libGeral';

/**
 *
 *
 * @export
 * @class HighlightSubDirective
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[highlightSub]' })
export class HighlightSubDirective {

  /**
   * Termo a ser destacado.
   * @type {string}
   */

  @Input() style: Map<string, string>;

  @Output() elementChange: EventEmitter<HTMLElement> = new EventEmitter();


  private idSubscriber: string;
  private elementOriginalStyle: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private highlightService: IPHighlightService) {

    this.highlightService.currentElement$.subscribe( element => this.highlighElement(element) );
  }

  highlighElement(publisherElement) {
    this.idSubscriber = this.elementRef.nativeElement.id;

    if (this.idSubscriber === publisherElement.id) {
      this.elementOriginalStyle = this.elementRef.nativeElement.style;

      this.style.forEach( (propriedadeValor, propriedadeStyle) => {
        this.renderer.setStyle(this.elementRef.nativeElement, propriedadeStyle, propriedadeValor);
      });
      this.elementChange.next(this.elementRef.nativeElement);
    } else {
      this.elementRef.nativeElement.style = this.elementOriginalStyle;
    }
  }
}
