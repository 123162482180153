import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPNotificationComponent } from './ip-notification.component';
import { MaterialModule } from '../../../material/material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IPCollapseModule } from '../collapse';

const components = [
    IPNotificationComponent
  ];


@NgModule({
  imports: [MaterialModule, CommonModule, IPCollapseModule, TranslateModule.forChild()],
  declarations: components,
  exports: components,
  schemas: [NO_ERRORS_SCHEMA]
})

export class IPNotificationModule {
}
