import { createReducer, on } from '@ngrx/store';
import { MenuBase } from '@pep-assistencial-store/state/menu.state';
import { ActionTypes, carregarMenuSucessoAction, criarStateMenuSucessoAction, resetStateMenuAction } from '../actions/menu.action';

const menuInitialState = new MenuBase();

export const menuReducer = createReducer(
  menuInitialState,
  on(carregarMenuSucessoAction, (state, { payload }) => {
    return {
      ...state,
      menus: payload
    }
  }),
  on(criarStateMenuSucessoAction, (state, { payload }) => {
    return new MenuBase()
  }),
  on(resetStateMenuAction, (state) => {
    return new MenuBase()
  }),
)

