import * as moment from "moment";

import { LibGeral } from "./libGeral";
import { LibString } from "./libString";
import { DateRange } from "../models";
import * as libMoment from "moment";

export class LibDate {
  public static lang = "pt-BR";

  /**
   * Retorna data atual com a hora.
   * @readonly
   * @static
   * @type {Date}
   */
  public static get Now(): Date {
    return new Date();
  }

  /**
   * Retorna data atual sem a hora.
   * @readonly
   * @static
   * @type {Date}
   */
  public static get Today(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  /**
   * Retorna TRUE caso o valor passado seja do tipo 'Date'.
   *
   * @static
   * @param {*} value
   * @returns {boolean}
   */
  public static IsDate(value: any): boolean {
    return value instanceof Date && !isNaN(new Date(<any>value).getDate());
  }

  /**
   * Converte valor passado para o tpo 'Date', conforme o formato passado.
   *
   * @static
   * @param {string} value
   * @param {string} [format='DD/MM/YYYY']
   * @returns {Date}
   */
  public static ToDate(value: string, format: string = "DD/MM/YYYY"): Date {
    let date: Date = null;

    if (LibGeral.estaPreenchido(value)) {
      if (LibString.isNullOrEmpty(format)) {
        date = moment(value).toDate();
      } else {
        date = moment(value, format).toDate();
      }
    }
    return date;
  }
  /**
   * Retorna string representando a data no formato passado.
   *
   * @static
   * @param {(Date | any)} data
   * @param {DataFormato} [formato]
   * @returns {string}
   */
  public static FormatarData(
    data: Date | any,
    formato?: DataFormato | string
  ): string {
    const momentData =
      data instanceof Date ? moment(data) : moment(new Date(<string>data));

    if (typeof formato === "string") {
      return momentData.locale(this.lang).format(formato);
    }
    switch (formato || DataFormato.Completa) {
      case DataFormato.Completa:
        return momentData.locale(this.lang).format("L");
      case DataFormato.Mes:
        return momentData.locale(this.lang).format("MMMM");
      // case DataFormato.MesAno: return momentData.format('MMMM [de] YYYY');
      case DataFormato.MesAno:
        return momentData.locale(this.lang).format("MM/YYYY");
      case DataFormato.Ano:
        return momentData.locale(this.lang).format("YYYY");
      case DataFormato.DataHora:
        return `${momentData.locale(this.lang).format("L")} ${momentData
          .locale(this.lang)
          .format("LT")}`;
      case DataFormato.Hora:
        return momentData.locale(this.lang).format("LT");
      case DataFormato.Rest:
        return `${momentData
          .locale(this.lang)
          .format("YYYY-MM-DD")} ${momentData.locale(this.lang).format("LT")}`;
      case DataFormato.DataHoraGMT:
        return momentData.utc().format("YYYY-MM-DD HH:mm:ss");

      // eslint-disable-next-line no-throw-literal
      default:
        throw "Formato não suportado.";
    }
  }

  /**
   * Retorna TRUE caso as duas datas sejam referentes ao mesmo dia, sem considerar a hora/minuto/segundo.
   *
   * @static
   * @param {Date} date1
   * @param {Date} date2
   * @returns {boolean}
   */
  public static IsSameDay(date1: Date, date2: Date): boolean {
    const date1Copy = new Date(date1.getTime());
    date1Copy.setHours(0, 0, 0, 0);

    const date2Copy = new Date(date2.getTime());
    date2Copy.setHours(0, 0, 0, 0);

    return date1Copy.toDateString() === date2Copy.toDateString();
  }

  /**
   * Retorna TRUE caso a data esteja dentro do intervalo de tempo.
   *
   * @static
   * @param {DateRange} interval
   * @param {Date} date
   * @returns {boolean}
   */
  public static Intersects(interval: DateRange, date: Date): boolean {
    return interval.startDate <= date && interval.endDate >= date;
  }

  /**
   * Retorna TRUE caso o segundo período esteja dentro do primeiro.
   *
   * @static
   * @param {DateRange} interval
   * @param {DateRange} intervalToCompare
   * @returns {boolean}
   */
  public static IsInside(
    interval: DateRange,
    intervalToCompare: DateRange
  ): boolean {
    return (
      LibDate.Intersects(interval, intervalToCompare.startDate) &&
      LibDate.Intersects(interval, intervalToCompare.endDate)
    );
  }

  /**
   * Retorna TRUE caso o segundo período seja menor que o primeiro.
   *
   * @static
   * @param {Date} date1
   * @param {Date} date2
   * @returns {boolean}
   */
  public static ValidPeriod(date1: Date, date2: Date): boolean {
    if (!!date1 && !!date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);

      // Check if the first is greater than second
      if (d1 > d2) {
        return false;
      }
    }

    return true;
  }

  /**
   * Método para a configuração dos formatos de datas.
   * Necessário para o moment - chamar na inicialização da aplicação.
   */
  public static configurarMomentJs(): void {
    // Configura formatos de dados para o momento.js
    moment.locale("pt-br", {
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY LT",
        LLLL: "dddd D MMMM YYYY LT",
      },
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    });

    // Configura LocaleProvider para md-datepicker
    // $mdDateLocaleProvider.formatDate = function (date: any): string {
    //   if (date) {
    //     return moment(date).format('L');
    //   }

    //   return null;
    // };

    // Configura parser do LocaleProvider para o md-datepicker, para casos em que o usuário alterar a hora
    // 'na mão', ou seja, com os números e não pelo picker. Este tratamento é necessário para que o formato
    // fique correto:
    // http://stackoverflow.com/questions/33475874/md-datepicker-input-format
    // http://stackoverflow.com/questions/32566416/change-format-of-md-datepicker-in-angular-material
    // $mdDateLocaleProvider.parseDate = function (dateString: any): any {
    //   var m = moment(dateString, 'L', true);

    //   if (m.isValid()) {
    //     return m.toDate();
    //   } else {
    //     return new Date(NaN);
    //   }
    // };
  }

  public static ObterTextoPorFormato(Data, Formato): string {
    if (!Data || !Formato) {
      return null;
    }
    switch (Formato) {
      case "D":
        return LibDate.FormatarData(Data, DataFormato.Completa);
      case "M":
        return LibDate.FormatarData(Data, DataFormato.MesAno);
      case "A":
        return LibDate.FormatarData(Data, DataFormato.Ano);
      default:
        throw new Error("Formato para DataPEP não suportado.");
    }
  }

  public static toDotNetFormat(Data, Texto, Desconhecida): Object {
    return {
      Data,
      Texto,
      Desconhecida: Desconhecida !== "D",
    };
  }

  public static addMinutes(data: Date, minutos: number): Date {
    data.setMinutes(data.getMinutes() + minutos);
    return data;
  }

  public static getFirstLastDatesOfMonth(mes: number, ano?: number) {
    ano = LibGeral.estaPreenchido(ano) ? ano : new Date().getFullYear();

    return {
      primeiroDiaMes: new Date(ano, mes, 1),
      ultimoDiaMes: new Date(ano, mes + 1, 0),
    };
  }

  public static convertTimeToMinute(value: number, idUnindade: string) {
    switch (idUnindade) {
      case "d":
        return this.convertDayHourMinToMinute(value, 0, 0);
      case "h":
        return this.convertDayHourMinToMinute(0, value, 0);
      case "m":
      case "b":
        return this.convertDayHourMinToMinute(0, 0, value);
      default:
        return 0;
    }
  }

  public static getMonthValueFromString(monthName: string) {
    monthName = monthName.toLowerCase();
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

    return moment.months().indexOf(monthName);
  }

  public static convertMinuteToDayHourMin(tempoTotalEmMinutos: number) {
    const dias = Math.trunc(tempoTotalEmMinutos / (24 * 60));
    const horas = Math.trunc((tempoTotalEmMinutos % (24 * 60)) / 60);
    const minutos = Math.trunc((tempoTotalEmMinutos % (24 * 60)) % 60);
    return { dias: dias, horas: horas, minutos: minutos };
  }

  public static convertDayHourMinToMinute(
    dias: number,
    horas: number,
    minutos: number
  ) {
    let totalEmMinutos = dias * (24 * 60);
    totalEmMinutos += horas * 60;
    totalEmMinutos += minutos;
    return totalEmMinutos;
  }

  public static formatTextDayHourMin(tempoTotalEmMinutos: number) {
    const tempo = this.convertMinuteToDayHourMin(tempoTotalEmMinutos);
    let texto = "";

    if (tempo.dias > 0) {
      texto = ` ${tempo.dias} ${tempo.dias === 1 ? "dia" : "dias"}`;
    }
    if (tempo.horas > 0) {
      texto += ` ${tempo.horas} ${tempo.horas === 1 ? "hora" : "horas"}`;
    }
    if (tempo.minutos >= 0) {
      texto += ` ${tempo.minutos} min`;
    }
    return texto;
  }

  public static paraFormatoLocal(data: Date, locale: string) {
    const newDate = new Date(data);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    } as any;
    return newDate.toLocaleDateString(locale, options);
  }

  public static getDateFormat(data: any): string {
    switch (data.Formato) {
      case "D":
        return "day";
      case "M":
        return "month";
      case "A":
        return "year";
    }
  }

  public static getEndOfDateByFormat(dateObj) {
    return libMoment(dateObj.Data).endOf(this.getDateFormat(dateObj) as any);
  }

  public static getEndOfDate(dateObj) {
    return dateObj.Data ? this.getEndOfDateByFormat(dateObj) : dateObj.Data;
  }

  public static ConcateDateTime(date: Date, time: string) {
    var timeSplit = time.split(':');
    date.setHours(Number(timeSplit[0]));
    date.setMinutes(Number(timeSplit[1]));
    date.setSeconds(0);

    var utcDateTime = [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()].join('-') + ' ' + [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()].join(':') + 'Z'; // eg "2020-5-24 11:31:59Z"
    return new Date(utcDateTime);
  }
}

export enum DataFormato {
  Completa,
  Mes,
  MesAno,
  Ano,
  DataHora,
  Hora,
  Rest,
  DataHoraGMT,
}
