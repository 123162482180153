import { PEPBase } from './pepBase.model';
import { DataPEP } from './dataPep.model';
import { LibGeral } from '../../../../core/libraries/libGeral';
import { SimNao } from '@gemed-core/enums/gemed.enum';

export class PEPMedicamentoEmUso extends PEPBase {
  public IdPEP: number;
  public Medicamento: string;
  public NaoFazUso: SimNao;
  public Posologia: string;
  IdTemp: number;
  Novo: boolean;

  constructor() {
    super();
    this.Medicamento = "";
    this.NaoFazUso = SimNao.Nao;
    this.Novo = false;
  }

  IsFinalizado(): boolean {
    return this.DataFim != null && LibGeral.estaPreenchido(this.DataFim.Data);
  }

  Terminar(): void {
    this.DataFim = new DataPEP(new Date());
  }
  toString(): string {
    const texto: string[] = [];
    texto.push(this.NaoFazUso === SimNao.Sim ? 'Não faz uso de medicamento' : this.Medicamento);
    if (this.IsFinalizado()) {
      texto.push(' (Término)');
    }
    if (LibGeral.estaPreenchido(this.Complemento) === false) {
      texto.push(` (Complemento: ${this.Complemento})`);
    }
    return texto.join('');
  }
}
