// Exporta o valor equivalente ao banco de dados
export enum UsuarioTipo {
    Usuario = "U",
    ProfissionalSaude = "S",
    RepresentanteConvenio = "C"
  }

// Exporta o valor conforme recebo da API
export const UsuarioTipoValueApi = new Map<string, string>([
  [UsuarioTipo.Usuario, 'Usuario'],
  [UsuarioTipo.ProfissionalSaude, 'ProfissionalSaude'],
  [UsuarioTipo.RepresentanteConvenio, 'RepresentanteConvenio']
]);

// Exportar o texto do Enum Situacao
export const UsuarioTipoDescricao = new Map<string, string>([
    [UsuarioTipo.Usuario, 'Usuário'],
    [UsuarioTipo.ProfissionalSaude, 'Profissional Saúde'],
    [UsuarioTipo.RepresentanteConvenio, 'Representante Convênio']  
]);