import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ip-tooltip',
  template: `
    <div [matTooltip]="texto">
      <ng-content></ng-content>
    </div>
  `
})
export class IPTooltipComponent implements OnInit {
  @HostBinding('class') classes: string;

  @Input() texto: string;
  @Input() position: string;
  @Input() size: string;

  private elementRef: ElementRef;
  private renderer: Renderer2;

  constructor(renderer: Renderer2, elRef: ElementRef) {
    this.elementRef = elRef;
    this.renderer = renderer;
  }

  ngOnInit(): void {
    // Define as classes do elemento de acordo com os atributos passados.
    this.definirLayout();
  }

  private definirLayout(): void {
    this.classes = 'mdl-tooltip';

    switch (this.position) {
      case 'left':
        this.classes += ' mdl-tooltip--left';
        break;
      case 'top':
      this.classes += ' mdl-tooltip--top';
        break;
      case 'right':
      this.classes += ' mdl-tooltip--right';
        break;
      case 'bottom':
      this.classes += ' mdl-tooltip--bottom';
        break;
    }

    if (this.size === 'large') {
      this.classes += ' mdl-tooltip--large';
    }
  }
}
