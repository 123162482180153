import { PEPBase } from './pepBase.model';
import { SimNao } from '@gemed-core/enums/gemed.enum';

export class PEPAlergia extends PEPBase {
  public Id: number;
  public Nome: string;
  public Nega: SimNao;
  public Justificativa: string;
  public Nova: boolean;
  public Comentario: string;

  constructor() {
    super();
    this.Nome = "";
    this.Nega = SimNao.Nao;
    this.Justificativa = "";
  }
}
