<div class="container-login" *ngIf="(estaAutenticado$ | async) !==true">
  <ip-login class="container-item login-component-item"></ip-login>
  <div class="gradiend-background container-item mensagem-item">
    <h1>{{'login.boasVindas' | translate}}</h1>
    <div class="container-direitos">
      <div class="container-direitos-item container-direitos-item-bold">{{'login.mensagemSobre' | translate}}</div>
      <div class="container-direitos-item">{{'login.mensagemDireitos' | translate}}</div>
      <div class="container-direitos-item">v: {{version}}</div>

    </div>
  </div>
</div>
