import { Injectable } from "@angular/core";
import { LibGeral } from "@gemed-core/libraries/libGeral";
import { Store } from "@ngrx/store";
import { map, take } from "rxjs/operators";
import { buscarParametroAction } from "../Store/Actions/parametrosManager.actions";
import { PEPService } from "./../../assistencial/prontuario/shared/services/pep.service";

@Injectable({
  providedIn: "root",
})
export class ParametrosManagerService {
  constructor(protected store: Store<any>, private pepService: PEPService) { }

  async getParametrosAsync(parametro: string) {
    this.store.dispatch(buscarParametroAction({ payload: parametro }));

    return await new Promise((resolve, reject) => {
      this.store
        .select((state) => state["ParametrosManager"].Parametros)
        .pipe(take(1))
        .subscribe((parametrosManager) => {
          let valor = null;
          if (LibGeral.estaPreenchido(parametrosManager[parametro])) {
            valor = parametrosManager[parametro].Valor;
          }

          resolve(valor);
        });
    });
  }

  async getParametroPromise(parametro: string) {
    return await new Promise((resolve, reject) => {
      this.pepService
        .getParametro(parametro)
        .pipe(take(1))
        .subscribe((parametroResponse) => {
          resolve(parametroResponse.Data.Valor);
        });
    });
  }
  getParametroObservable(parametro: string) {
    this.store.dispatch(buscarParametroAction({ payload: parametro }));
    return this.store
      .select((state) => state["ParametrosManager"].Parametros)
      .pipe(
        map((parametrosManager) => {
          if (parametrosManager[parametro]) {
            return parametrosManager[parametro].Valor;
          }

          return null;
        })
      );
  }
}
