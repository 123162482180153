import {
  AcaoPendenteImportancia,
  AcaoPendenteStatusResolucao,
  AgendaAtendimento,
  AgendaAutorizacaoTipoConsulta,
  AgendaStProducao,
  AgendaTipoConsulta,
  Ambiente,
  Arredondamento,
  AutorizacaoConferido,
  AutorizacaoSituacao,
  AutorizacaoSolicitacaoStatus,
  AutorizacaoTipo,
  AutorizacaoTipoAtendimento,
  AutorizacaoTipoConsulta,
  CaraterAtendimento,
  ConvenioTipoCodPrestador,
  ConvenioTISSVersao,
  DiaSemana,
  DoseVolume,
  EsquemaFracionamento,
  FinalidadeTratamento,
  FluxoTipo,
  GenericoMarca,
  ImpressaoPrescricaoPrincipioAtivoNomeComercial,
  IncentivadorCultural,
  InsumoOrigem,
  IpModeloTipo,
  LocalPrestacaoServico,
  LoteSituacao,
  LoteTipo,
  MaterialEnfermagemTipo,
  MaterialEnfermagemTipoUso,
  MedidaTempo,
  MotivoEncerramento,
  Movimento,
  NexodataAmbienteEnum,
  OptanteSimplesNacional,
  OrcamentoSituacao,
  PercentualOrigem,
  PortadorOrigem,
  PrescricaoConciliado,
  PrescricaoSituacaoNF,
  RegistroEspecialTributacao,
  RetemLei10925,
  SimNao,
  SituacaoDistribuicao,
  SituacaoFaturamentoProtocolo,
  SituacaoGlosa,
  SituacaoNotaFiscal,
  SituacaoProtocoloDetalhe,
  SolicitacaoSituacao,
  Status,
  TecnicaUtilizada,
  Tipo,
  TipoAssinatura,
  TipoCiclo,
  TipoContaFluxo,
  TipoContaPacienteEnum,
  TipoConvenio,
  TipoCustoEnum,
  TipoDose,
  TipoFaturamentoProtocolo,
  TipoFracionamento,
  TipoGlosa,
  TipoGlosaRestante,
  TipoGrupoInsumo,
  TipoItemCobrado,
  TipoMovClassificacao,
  TipoNf,
  TipoNumeroGuiaInternacao,
  TipoProcesso,
  TipoProtocoloTipo,
  TipoRecurso,
  TipoRepasseHonorario,
  TipoUnidadeEstoque,
  TipoUnidadeMedida,
  TipoValorEnum,
  TISSGrupoAcao,
  TISSProfissionalComoContratanteEnum,
  ViaAcesso,
} from "@gemed-core/enums/gemed.enum";
import { Clinica } from "@gemed-core/models/clinica.model";
import { Empresa } from "@gemed-core/models/empresa.model";
import { FormaPrescrever } from "@gemed-core/models/formaPrescreve.enum";
import { Fornecedor } from "@gemed-core/models/fornecedor.model";
import { GrupoCusto } from "@gemed-core/models/grupoCusto.model";
import { IPrescricaoEstoqueDiarioDTO } from "@gemed-core/models/IEstoqueDiarioPrescricoesInsumosDTO";
import { InsumoLote } from "@gemed-core/models/insumoLote.model";
import { InsumoMovimento } from "@gemed-core/models/insumoMovimento.model";
import { InsumoSaldoLote } from "@gemed-core/models/insumoSaldoLote.model";
import { Medicamento } from "@gemed-core/models/medicamento.model";
import { PlanoContas } from "@gemed-core/models/planoContas.model";
import { Portador } from "@gemed-core/models/portador.model";
import { Prescricao } from "@gemed-core/models/prescricao.model";
import { PrescricaoDetalhe } from "@gemed-core/models/prescricaoDetalhe";
import { PrescricaoDetalheFormaCalculo } from "@gemed-core/models/prescricaoDetalheFormaCalcuclo.enum";
import { PrescricaoProtocolo } from "@gemed-core/models/prescricaoProtocolo.model";
import { PrescricaoSeparacaoEstoqueDTO } from "@gemed-core/models/prescricaoSeparacaoEstoqueDTO";
import { Profissional } from "@gemed-core/models/profissional.model";
import { ProtocoloGrupo } from "@gemed-core/models/protocoloGrupo.model";
import { ReferenciaPath } from "@gemed-core/models/referenciaPath.model";
import { Usuario } from "@gemed-core/models/usuario.model";
import { Via } from "@gemed-core/models/via.model";
import dxDataGrid, { dxDataGridColumn } from "devextreme/ui/data_grid";
import { AgendaSituacao } from "src/app/assistencial/central-medico/shared/models/AgendaSituacao.enum";
import { PrescricaoSituacao } from "src/app/assistencial/central-medico/shared/models/PrescricaoSituacao.enum";
import { TipoAgenda } from "src/app/assistencial/central-medico/shared/models/TipoAgenda.enum";
import { CID, Protocolo } from "src/app/assistencial/shared/models";
import {
  Convenio,
  ConvenioPlano,
} from "src/app/assistencial/shared/models/paciente-convenio.model";
import { Paciente } from "src/app/assistencial/shared/models/paciente.model";
import { ProtocoloOficialEnum } from "src/app/assistencial/shared/models/protocoloOficial.enum";
import { UsuarioTipo } from "src/app/users/shared/model/UsuarioTipo.enum";
import { IInsumoSobraOrdemServico } from "./IInsumoSobraOrdemServico.interface";
import { dxEvent } from "devextreme/events";
import { IUsuarioAutenticadoDTO } from "ip_types_ts/common-typings";
import { QuestionarioPergunta, QuestionarioTipoResposta } from "@pep-models/questionario";
import { DataPEP } from "@pep-models/dataPep.model";
import { PEPRadioterapiaDosagemSitio } from "src/app/assistencial/shared/models/PEPRadioterapiaDosagemSitio";
import { InsumoSobraDTO } from "@gemed-core/models/insumoSobraDTO";
import { Apresentacao } from "@gemed-core/models/apresentacao.model";

export interface IArquivoDTO {
  Bytes: number[],
  Nome: string,
  Extensao: string,
  ReferenciaPath: ReferenciaPath,
  SubstituirCasoExistir: boolean,
  IgnoraPathNaoEncontrado: boolean
}

export interface IDiagnosticoRadioterapiaRepostaComponente {
  IdCidSitioTratamento: number,
  CodigoCidSitioTratamento: string,
  DoencaCidSitioTratamento: string,
  IdProtocoloTipo: number,
  IdProtocolo: number,
  ProtocoloSigla: string,
  Finalidade: FinalidadeTratamento,
  Areas: string,
  ECOG: string,
  DosagensPorSitio: string,
  TipoPreparo: string,
  PlanoTratamento: string,
  ProtocoloRadioterapias?: IProtocoloRadioterapia[]
}

export interface IDiagnosticoRadioterapia {
  Paciente?: Paciente;
  CIDSitioTratamento: CID;
  ProtocoloTipo: IProtocoloTipo;
  Protocolo: Protocolo;
  FinalidadeTratamento: FinalidadeTratamento;
  ECOG: string;
  AreasIrradiadas: IProtocoloArea[];
  DosagensPorSitio: PEPRadioterapiaDosagemSitio[];
  TipoPreparo: string;
  PlanoTratamento: string;
}

export interface IProtocoloTipo {
  Id: number;
  Descricao: number;
  Tipo: TipoProtocoloTipo;
  ExigePrescricao: SimNao;
  Status: Status
}


export interface IProtocoloRadioterapia {
  Id: number;
  Protocolo: Protocolo;
  ProtocoloArea: IProtocoloArea;
  Dose?: number;
  DoseUnidadeMedida: string;
  DoseTotal?: number;
  DoseTotalCalculada?: number;
  DoseTotalUnidadeMedida: string;
  NumeroCampos?: number;
  NumeroTotalDias?: number;
  NumeroCampoPrevistos?: number;
  NumeroInsercoes?: number;
  Status: Status;
  Prescricoes: Prescricao[];
  QuantidadeFracoes?: number;
  EsquemaFracionamento: EsquemaFracionamento;
}

export interface IProtocoloArea {
  Id: number;
  Descricao: string;
  Status: Status;
}



export interface ITipoBaseProntuario {
  Id: number;
  Paciente: Paciente;
  Complemento: string;
  DataInicio: DataPEP;
  DataFim: DataPEP;
  QuestionarioResposta: IProntuarioQuestionarioResposta
}

export interface IProntuarioQuestionarioResposta {
  Id: number;
  ProntuarioQuestionario: IProntuarioQuestionario;
  Pergunta: QuestionarioPergunta;
  PerguntaItemLista: IQuestionarioPerguntaItemLista;
  RespostaInteger?: number;
  RespostaDecimal?: number;
  RespostaDatetime?: Date;
  RespostaString: string;
  TipoResposta: QuestionarioTipoResposta;
  TipoProntuario: ITipoBaseProntuario;
}

export interface IProntuarioQuestionario {
  Id: number;
  Questionario: IQuestionario;
  Profissional: Profissional;
  Paciente: Paciente;
  Data: Date;
  DataEvento: Date;
  DataAssinatura?: Date;
  Respostas: IProntuarioQuestionarioResposta[];
}

export interface IDevExtremeOnRowDblClick<T, R> {
  columns: dxDataGridColumn[];
  component: dxDataGrid;
  data: T;
  element: HTMLElement;
  event: dxEvent;
  groupIndex: number;
  isExpanded: boolean;
  isNewRow: boolean;
  isSelected: boolean;
  key: R;
  rowElement: HTMLElement;
  rowIndex: Number;
  rowType: string;
  values: [];
}

export interface IDevExtremeSelecionChange<T, R> {
  component: dxDataGrid;
  currentDeselectedRowKeys: R[];
  currentSelectedRowKeys: R[];
  element: HTMLElement;
  selectedRowKeys: R[];
  selectedRowsData: T[];
}

/**
 * ##### Equivalente ao struct IP.Entities.Gemed.PEP.DTO.PrescricaoFiltro.
 */
export interface IPrescricaoAjusteDoseFiltro {
  IdPaciente: number;
  IdProfissional: number;
  RecuperarNaoAssinadas: boolean;
  IdsProtocolos: number[];
  IdPrescricao: number;
  IdPrincipioAtivo: number;
  Ciclo: number;
  GruposPrescricoesDetalhe: number[];
  TipoCiclo: TipoCiclo;
  DataInicio?: Date;
  DataFim?: Date;
  SituacoesAgenda: AgendaSituacao[];
  SituacoesPrescricao: PrescricaoSituacao[];
  AjustarPrescricoesFuturas: boolean;
  BuscarNaoRevisadas: boolean;
  NumeroDiasNaoRevisadas: number;
}

export interface IDetalhesPrescricaoAjusteDose {
  IdSeqDetalhe: number;
  Grupo: number;
  Via: string;
  IdPrincipioAtivo: number;
  PrincipioAtivo: string;
  IdMedicamento?: number;
  Medicamento: string;
  Dose: number;
  MedidaDose: string;
  Ajuste: number;
  Selecionado: boolean;
}

export interface IPrescricaoAjusteDoseDTO {
  Id: number;
  IdProtocolo: number;
  IdProtocoloGrupo: number;
  IdPrescricao: number;
  IdPrincipioAtivo: number;
  Grupo: number;
  GrupoRomano: string;
  Via: string;
  AjusteDose: number;
  PrescricaoIdentificacao: string;
  Arredondamento: number;
  Data: Date;
  Dose: number;
  Detalhes: IDetalhesPrescricaoAjusteDose[];
}

export interface IEvolucoesFiltroDTO {
  IdPaciente: number;
  IdAgenda?: number;
  IdQuestResposta?: number;
  Categorias?: string[];
  Questionarios?: number[];
  ModoImpressao: boolean;
  Ordenacao: "Decrescente" | "Crescente";
}

export interface IFiltroIpParametro {
  IpParamateros: string[];
  IdEmpresa: number;
}

export interface IItemsMenuContexto {
  text: string;
  eventoClick: Function;
}

export interface IPrescricaoMedicamentoMaterialDetalhe {
  Grupos: IPrescricaoGrupo[];
}

export interface ISistemaInfo {
  Sistema: string;
  SistemaDescricao: string;
  SistemaNomeAmigavel: string;
  SistemaTitulo: string;
  CodigoSessao: number;
}

export interface ILoginTemporario {
  clinicas: Clinica[];
  token: string;
  refreshToken: string;
  usuario: IUsuarioAutenticadoDTO;
  deveMostrarModal?: boolean;
  identificador: string;
}

export interface IPrescricaoGrupo {
  Id: number;
  IdPrescricaoGrupo: number;
  Prescricao: Prescricao;
  Ordem: number;
  Diluicao: string;
  Via: Via;
  TempoAplicacao: number;
  ListaMateriais: IPrescricaoMaterialEnfermagem[];
  ListaMedicamentos: IPrescricaoPrincipioAtivo[];
}

export interface IPrescricaoItem {
  Id: number;
  IdPrescricaoItem: number;
  Prescricao: Prescricao;
  Grupo: IPrescricaoGrupo;
  Usuario: Usuario;
  Diluicao: string;
  Ordem: number;
  VlMedio: number;
}

export interface IPrescricaoPrincipioAtivo extends IPrescricaoItem {
  PrincipioAtivo: PrincipioAtivo;
  Medicamento: Medicamento;
  MedicamentoPrescricao: Medicamento;
  Dose: number;
  ProfissionalAplicacao: Profissional;
  DoseOrigem: number;
  MedidaDose: string;
  FormaCalculo: PrescricaoDetalheFormaCalculo;
  MedidaTempo: MedidaTempo;
  Volume: number;
  MedidaVolume: string;
  QSP: SimNao;
  AjusteDose: number;
  FormaCalculoOrigem: PrescricaoDetalheFormaCalculo;
  ConfirmacaoQuimioJustificada: SimNao;
  MedicacaoFornececidaPeloPaciente: SimNao;
  HoraInicio: Date;
  HoraFim: Date;
}

export interface IPrescricaoMaterialEnfermagem extends IPrescricaoItem {
  MaterialEnfermagem: IMaterialEnfermagem;
  Quantidade: number;
}
export interface IMaterialEnfermagem {
  Id: number;
  Descricao: string;
  Tipo: MaterialEnfermagemTipo;
  TipoUso: MaterialEnfermagemTipoUso;
  Status: Status;
}

export interface IDevolverFarmaciaOrdemServico {
  IdOrdemServico: number;
  Justificativa: string;
}

export interface IDesfazerVinculoPrescricaoOrdemServicoDTO {
  IdUsuario: number;
  IdClinica: number;
  IdFarmaciaOrdemServico: number;
  Justificativa: string;
  ResponsavelBloqueioLiberacao: string;
  Prescricao: IPrescricaoEstoqueDiarioDTO;
}

export interface IPrescricaoProgramacaoDTO {
  Id: number;
  IdAgenda: number;
  IdUsuario: number;
  Identificacao: string;
  Situacao: PrescricaoSituacao;
  Ciclo: number;
  Sessao: number;
  IntervaloCiclo: number;
  TipoCiclo: TipoCiclo;
  Data: Date;
  Observacao: string;
  PrescricaoSugeridaDescricao: string;
}

export interface ITempoAplicacaoDetalheDTO {
  dias: number;
  horas: number;
  minutos: number;
  totalEmMinutos: number;
  tempoValido: boolean;
}

export interface ITipoAtendimento {
  Id: string;
  Descricao: string;
  Status: Status;
}

export interface IPatologia {
  Id: number;
  IdPatologia: number;
  Nome: string;
  Status: string;
  Clinica: IClinica;
}

export interface IPacienteConta {
  IdPacienteConta: number;
  Data: Date;
  Paciente: Paciente;
  Tipo: TipoContaPacienteEnum;
  Valor: number;
  Autorizacao: IAutorizacao;
  NotaFiscal: INotaFiscal;
  Usuario: IUsuario;
  Fluxos: IFluxo[];
}

export interface IAlergia {
  Nome: string;
  Nega: SimNao;
  TerminoAlergia: boolean;
  TipoResposta: string;
  VersaoSerializacao: number;
}

export interface IAutorizacao {
  Id: number;
  IdAutorizacao: number;
  Numero: string;
  Senha: string;
  NumerSpSadt: string;
  NumeroGuiaOperadora: string;
  Matricula: string;
  Titular: string;
  ValidadeCarteira?: Date;
  Plano: string;
  DataSolicitado?: Date;
  Data?: Date;
  DataRecebeu?: Date;
  DataVencto?: Date;
  DataPrimeiraPrescricao?: Date;
  Observacao: string;
  IndicacaoClinica: string;
  TipoSaida: string;
  VlLiquido: number;
  VlBruto: number;
  ClasseNota: string;
  Situacao: AutorizacaoSituacao;
  Status: Status;
  Tipo: string;
  Conferido: AutorizacaoConferido;
  Quitado: SimNao;
  Negociacao: string;
  NrNota: number;
  Autorizado: number;
  Utilizado: number;
  ComentarioSolicitacao: string;
  Paciente: Paciente;
  Convenio: Convenio;
  TipoConsulta: AutorizacaoTipoConsulta;
  HonorarioIndividual: IHonorarioIndividual;
  //TODO CLASSE LOTE
  Lote: any;
  PlanoObj: ConvenioPlano;
  AutorizacaoFluxo: IAutorizacaoFluxo[];
  AutorizacaoGlosas: IAutorizacaoGlosa[];
  Agendas: IAgenda[];
  //TODO CLASSE FATURA
  Faturas: any[];
  AutorizacoesRecGlosas: IAutorizacaoRecGlosa[];
  Prescricoes: Prescricao[];
  DataSolicitacaoNegada?: Date;
  CentroCusto: ICentroCusto;
  VersaoBras: number;

  CaraterAtendimento: CaraterAtendimento;
  AutorizacaoTipo: AutorizacaoTipo;
  TipoAtendimento: AutorizacaoTipoAtendimento;
  MotivoEncerramento: MotivoEncerramento;

  PossuiLote: boolean;
  VlLiquidoFatura: number;
  VlBrutoFatura: number;
  VlRecebeuFatura: number;

  VlFaturaGlosaAvaliacao: number;
  VlFaturaRecuperacao: number;

  Sessoes: string;
  NomeTabelaLog: string;
}

export interface ICentroCusto {
  IdCentroCusto: number;
  Clinica: IClinica;
  Descricao: string;
  Status: Status;
}

export interface IAcaoPendenteParametro {
  Id: number;
  IdAcaoPendente: number;
  Nome: string;
  Valor: string;
  AcaoPendente: IAcaoPendente;

}

export interface IAcaoPendenteConsultaDTO {
  IdAcaoPendente?: number;
  Titulo?: string;
  Descricao?: string;
  DescricaoComParametros?: string;
  Observacao?: string;
  DataHoraCriacao?: Date;
  StatusResolucao?: AcaoPendenteStatusResolucao;
  StatusResolucaoDescricao?: string;
  IsPendente?: boolean;
  IsConcluida?: string;
  DataHoraResolucao?: Date;
  IdUsuarioResolucao?: number;
  NomeUsuarioResolucao?: string;
  IdTipo?: number;
  Tipo?: string;
  DataHoraVencimento?: Date;
  Urgencia?: AcaoPendenteUrgencia;
  UrgenciaDescricao?: string;
  IdUsuarioCriacao?: number;
  NomeUsuarioCriacao?: string;
  UsuariosResponsaveis?: IUsuario[];
  AcaoPendenteParametros?: Array<IAcaoPendenteParametro>
  Paciente?: string;
  IdPaciente?: number;
}


export interface IAcaoPendenteParametroDTO {
  Id: number;
  Valor: string;
  Nome: string;
}

export const AcaoPendenteStatusResolucaoDescricao = new Map<string, string>([
  [AcaoPendenteStatusResolucao.Pendente, 'Pendente'],
  [AcaoPendenteStatusResolucao.Resolvida, 'Resolvida'],
  [AcaoPendenteStatusResolucao.Descartada, 'Descartada'],
  [AcaoPendenteStatusResolucao.Vencida, 'Vencida']
]);

export const AcaoPendenteStatusResolucaoValueApi = new Map<string, string>([
  [AcaoPendenteStatusResolucao.Pendente, 'Pendente'],
  [AcaoPendenteStatusResolucao.Resolvida, 'Resolvida'],
  [AcaoPendenteStatusResolucao.Descartada, 'Descartada'],
  [AcaoPendenteStatusResolucao.Vencida, 'Vencida']
]);

export enum AcaoPendenteUrgencia {
  NaoInformado = "",
  Baixa = "1",
  Media = "2",
  Alta = "3",
  Crítica = "4"
}

export const AcaoPendenteUrgenciaDescricao = new Map<string, string>([
  [AcaoPendenteUrgencia.NaoInformado, 'Não Informado'],
  [AcaoPendenteUrgencia.Baixa, 'Baixa'],
  [AcaoPendenteUrgencia.Media, 'Media'],
  [AcaoPendenteUrgencia.Alta, 'Alta'],
  [AcaoPendenteUrgencia.Crítica, 'Crítica']
]);

export enum AcaoPendenteTipoInterno {
  NaoInformado = "",
  Paciente = "P",
  Geral = "G",
  DoseAutorizadaExcedida = "DOSE_AUTORIZADA_EXCEDIDA",
  PEPDocumento = "PEPDOCUMENTO"
}

export const AcaoPendenteTipoInternoDescricao = new Map<string, string>([
  [AcaoPendenteTipoInterno.NaoInformado, 'Não Informado'],
  [AcaoPendenteTipoInterno.Paciente, 'Paciente'],
  [AcaoPendenteTipoInterno.Geral, 'Geral'],
  [AcaoPendenteTipoInterno.DoseAutorizadaExcedida, 'Dose Autorizada Excedida'],
  [AcaoPendenteTipoInterno.PEPDocumento, 'Validade PEP Documento']
]);
export interface IAcaoPendenteTipo {
  Id: number;
  Descricao: string;
  ProcessoResolucao: IProcesso;
  TipoInterno: AcaoPendenteTipoInterno;
  Urgencia: AcaoPendenteUrgencia,
  Interno: SimNao,
  Status: Status;
}

export interface IAcaoPendente {
  Id: number;
  IdProcessoHook: number;
  Titulo: string;
  Descricao: string;
  DescricaoComParametros: string;
  Observacao: string;
  Paciente: string;
  Urgencia: AcaoPendenteUrgencia;
  Tipo: IAcaoPendenteTipo;
  UsuarioCriacao: IUsuario;
  DataHoraVencimento: Date;
  Responsaveis: Array<IUsuario>;
  Parametros: Array<IAcaoPendenteParametro>;

  AcaoPendenteUsuarios: IAcaoPendenteUsuario[];
  DataHoraCriacao: Date;
  IdUsuarioResolucao?: number;
  DataHoraResolucao: Date;
  StatusResolucao: AcaoPendenteStatusResolucao;

  ProcessoHook: IProcessoHook;
  UsuarioResolucao: IUsuario;
}

export interface IAcaoPendenteResolucaoDTO {
  Id: number;
  Observacao?: string;
  Justificativa?: string;
  Status: string;
}

export interface IAcaoPendenteFiltroDTO {
  SomenteNaoResolvidos: boolean;
  DataInicio: string;
  DataFim: string;
  RetornarApenasDoUsuarioLogado: boolean;
  MontarDescricaoComParametros: boolean;
}


export interface ICentralDoMedicoDTO {
  IdProfissional: number;
  NomeProfissional: string;
  QuantidadePrescricaoNaoAssinada: number;
  PrimeiraDataPrescricaoNaoAssinada: Date;
  QuantidadePrescricaoNaoRevisada: number;
  QuantidadeTarefasPendentes: number;
}

export interface IArquivoDTO {
  Conteudo: string;
  Bytes: number[];
  Nome: string;
  Extensao: string;
  Path: ReferenciaPath;
  SubstituirCasoExistir: boolean;
  IgnoraPathNaoEncontrado: boolean;
  NomeCompleto: string;
}

export interface IProcessoHook {
  Importancia: AcaoPendenteImportancia;
  IdProcesso: number;
  Processo: IProcesso;
}

export interface IAcaoPendenteUsuario {
  Id: number;
  IdAcaoPendente: number;
  Usuario: IUsuario;
}

export interface IPacienteObito {
  Paciente: IPaciente;
  Data: Date;
  Causa: any;
  Local: string;
  Comentario: string;
  Usuario: IUsuario;
  CausaDescricao: string;
  TipoResposta: string;
  VersaoSerializacao: number;
}

export interface IAutorizacaoFluxo {
  Valor: number;
  Autorizacao: IAutorizacao;
  Fluxo: IFluxo;
  AutorizacaoGlosa: IAutorizacaoGlosa[];
  AutorizacaoFluxoInsumo: IAutorizacaoFluxoInsumo[];
}

export interface IAutorizacaoFluxoInsumo {
  Fluxo: IFluxo;
  Autorizacao: IAutorizacao;
  Insumo: IInsumo;
  Valor: number;
}

export interface IAutorizacaoGlosa {
  Id: number;
  IdAutorizacaoGlosa: number;
  Data: Date;
  Valor: number;
  ValorRecuperado: number;
  Justificativa: string;
  Situacao: SituacaoGlosa;
  AutorizacaoFluxo: IAutorizacaoFluxo;
  GlosaCategoria: IGlosaCategoria;
  Autorizacao: IAutorizacao;
  AutorizacaoRecGlosas: IAutorizacaoRecGlosa[];
  AutorizacaoGlosaInsumos: IAutorizacaoGlosaInsumo[];
  NomeTabelaLog: string;
}

export interface IAutorizacaoGlosaInsumo {
  Fluxo: IFluxo;
  AutorizacaoGlosa: IAutorizacaoGlosa;
  Insumo: IInsumo;
  Valor: number;
  Observacao: string;
  GlosaCategoria: IGlosaCategoria;
}

export interface IAutorizacaoRecGlosa {
  Id: number;
  IdAutoGlosa: number;
  NrGuiaGlosa: string;
  NrGuiaOperadora: string;
  Lote: any;
  Autorizacao: IAutorizacao;
  TipoRecurso: TipoRecurso;
  ListaAutorizacaoRecGlosaCompleta: IAutorizacaoRecGlosaCompleta[];
  ListaAutorizacaoRecGlosaParcial: IAutorizacaoRecGlosaParcial[];
  GlosasRestantes: IGlosaRestante[];
  TipoRecursoDescricao: string;
  TotalRecorrido: number;
  GlosaDefinitiva: number;
  GlosaMantidaEmAvaliacao: number;
  TotalTratado: number;
  Valor: number;
  TipoRecursoValor: string;
  TISSCodGlosa: ITISSCodGlosa;
}

export interface IGlosaRestante {
  Tipo: TipoGlosaRestante;
  Categoria: IGlosaCategoria;
  Valor: number;
  Observacao: string;
  Identificador?: number;
  FaturaGlosa: IFaturaGlosa;
  TipoDescricao: string;
}

export interface IFaturaGlosa {
  Id: number;
  IdFaturaGlosa: number;
  Autorizacao: IAutorizacao;
  //todo implementar classe fatura;
  Fatura: any;
  Data: Date;
  Valor: number;
  Justificativa: string;
  Categoria: IGlosaCategoria;
  ValorRecuperado: number;
  Situacao: SituacaoGlosa;
  NotaFiscal: INotaFiscal;
  //todo lote
  Lote: any;
  NrRecurso?: number;
  FaturaGlosaOrigem: IFaturaGlosaOrigem;
  Identificador?: number;
  Fluxo: IFluxo;
}

export interface IFaturaGlosaOrigem {
  FaturaGlosa: IFaturaGlosa;
  Categoria: IGlosaCategoria;
  Valor: number;
  Justificativa: string;
  Identificador?: number;
}

export interface IAutorizacaoRecGlosaParcial {
  IdAutoGlosaParcial: number;
  AutorizacaoRecGlosa: IAutorizacaoRecGlosa;
  DataRealizacao: Date;
  DataFinalPeriodo?: Date;
  Tabela: string;
  Codigo: string;
  Descricao: string;
  Origem: InsumoOrigem;
  TISSCodGlosa: ITISSCodGlosa;
  Valor: number;
  Justificativa: string;
}

export interface IAutorizacaoRecGlosaCompleta {
  Id: number;
  IdAutoGlosaCompleta: number;
  AutorizacaoRecGlosa: IAutorizacaoRecGlosa;
  TISSCodGlosa: ITISSCodGlosa;
  Valor: number;
  Justificativa: string;
}

export interface ITISSCodGlosa {
  Id: number;
  IdTISSCodGlosa: number;
  CodGlosa: string;
  Descricao: string;
  DescricaoCompleta: string;
}

export interface IGlosaCategoria {
  Id: number;
  IdGlosaCategoria: number;
  Descricao: string;
  Status: Status;
  Tipo: TipoGlosa;
  AutorizacaoGlosa: IAutorizacaoGlosa[];
  DescricaoStatus: string;
  DescricaoTipo: string;
}

export interface IHonorarioIndividual {
  Id: number;
  IdHIndividual: number;
  Autorizacao: IAutorizacao;
  Contratado: IContratado;
  Clinica: IClinica;
  Profissional: Profissional;
  Insumos: IHonorarioIndividualInsumo[];
}

export interface IHonorarioIndividualInsumo {
  IdSeqHIndividual: number;
  HonorarioIndividual: IHonorarioIndividual;
  Convenio: Convenio;
  Taxa: IInsumo;
  Clinica: IClinica;
  Data: Date;
  HoraIni: string;
  HoraFim: string;
  Qtde: number;
  ViaAcesso: ViaAcesso;
  TecnicaUtilizada: TecnicaUtilizada;
  ReducaoAcrescimo: number;
  Valor: number;
  ValorTotal: number;
  ValorUnitario: number;
}

export interface IContratado {
  Id: number;
  IdContratado: number;
  Nome: string;
  RegistroOperadora: string;
  RegistroCNES: string;
}

export interface ICategoria {
  Id: string;
  IdCategoria: string;
  Descricao: string;
  IdCapitulo: string;
}

export interface IDTOConsultaCID {
  ListaPaciente: Array<CID>;
  ListaClinica: Array<CID>;
  ListaCompleta: Array<CID>;
}

export interface IFiltroConsultaMedicamentoDTO {
  Origem: InsumoOrigem;
  Status: Status;
  NomeMedicamento: string;
  ApenasItensComSaldo: boolean;
  UnidadeEstoqueId?: number;
  ConvenioId?: number;
}

export interface IMedicamento {
  $id?: number;
  Id: number;
  IdMedicamento: number;
  Nome: string;
  CodBrasindice: string;
  Origem: InsumoOrigem;
  IdPaciente: number;
  DataInicio: Date;
  Status: Status;
  GenericoMarca: GenericoMarca;
  Armazenamento: string;
  PrincipioAtivo: IPrincipioAtivo;
  Insumos: IInsumo[];
  Estabilidades: IMedicamentoEstabilidade[];
  Apresentacoes: IApresentacao[];
  ConciliacaoAutomaticas: IConciliacaoAutomatica[];
  RestritoHospitalar: SimNao;
  OrigemValor: string;
  GenericoMarcaValor: string;
}

export interface IApresentacao extends IInsumo {
  Medicamento: IMedicamento;
  Dosagem: number;
  DosagemDescricao: string;
  MedidaDose: string;
  Vias: Via[];
  EscolhidaFormula: SimNao;
  ReconEstabilidade: number;
  ReconMedidaTempo: MedidaTempo;
  ReconConservacao: string;

  ArredondarFracionamentoVOContagem: SimNao;
  PermiteFracionarVO: SimNao;
  DescricaoCompleta: string;
  DescricaoInsumo: string;

  Volume: number;
  VolumeUnidade: string;
  TipoEquipo: MaterialEnfermagemTipo
}

export interface IMedicamentoEstabilidade {
  Medicamento: IMedicamento;
  IdSeqEstabilidade: number;
  Estabilidade: string;
  Diluicao: string;
  MedicamentoNome: string;
}

export interface ICicloConfig {
  //esta interface não possui propriedades no .net
}

export interface IGerarProgramacaoRetorno {
  Value: any[];
  IsSuccess: boolean;
  Error: string;
  TipoMensagem: string;
  IsFailure: boolean;
}

export interface IProtocoloUsadoPorPacienteDTO {
  IdProtocolo: number;
  IdProtocoloGrupo: number;
  Protocolo: string;
  ProtocoloGrupo: string;
  ProtocoloOficial: ProtocoloOficialEnum;
  TipoCiclo: TipoCiclo;
  Sessoes: string;
  Intervalo: number;
  IdCid: number;
  CodCid: string;
  Doenca: string;
  Data: Date;
  CID: string;
}

export interface IPrescricaoProgramacaoSimpleDataDTO {
  GrupoProtocolo: string;
  Descricao: string;
  Data: Date;
  Dia: number;
  DiaSemana: string;
  TipoCiclo: TipoCiclo;
  Ciclo: number;
  Agendada: boolean;
  Observacao: string;
  ProgramacaoPrescricoes: Prescricao[];
}

export interface IIpParametroChave {
  Parametro: IpParametro;
  Chave: IPortador;
  Valor: string;
}

export interface IGeracaoPrescricaoDTO {
  ListaPrescricoesGeradas: Prescricao[];
  InicioCiclo: number;
  FimCiclo: number;
  Dias: string;
  Intervalo: number;
  Semanas: string;
}

export interface IConfiguracaoProtocoloGrupo {
  IdentificadorConjunto: string;
  DescricaoConjunto: string;
  DescricaoGrupo: string;
  Quantidade: number;
  PrescricaoProtocolo: PrescricaoProtocolo;
  ProtocoloGrupo: ProtocoloGrupo;
  GeracaoPrescricaoDTO: IGeracaoPrescricaoDTO;
  Prescricoes: Prescricao[];
  _Protocolo: Protocolo;
}

export interface IInsumo {
  Id: number;
  IdInsumo: number;
  Descricao: string;
  InsumoOrigem: InsumoOrigem;
  CodBarras: string;
  CodBrasindice: string;
  CodBrasM: string;
  CodIntegracao: string;
  UsuarioLog: IUsuario;
  DataLog: Date;
  TipoLog: string;
  Status: Status;
  UnidadeMedida: string;
  DescricaoParticularidade: string;
  CodigoParticularidade: string;
  CodGrupoParticularidade: string;
  QtdPorVolume: number;
  IsentoPisCofins: SimNao;
  Fornecedores: Fornecedor[];
  TipoFracionamento: TipoFracionamento;
  CodTISS: string;
  CodTUSS: string;
  CustoAdicional: ICustoAdicional;
  Medicamento: IMedicamento;
  Movimentos: InsumoMovimento[];
  Inventarios: IInsumoInventario[];
  InsumoSaldoLote: InsumoSaldoLote[];
  InsumoSaldos: IInsumoSaldo[];
  Lotes: InsumoLote[];

  Versao: IInsumoVersao;
  //todo
  TabelaPreco: any;
  ComprasInsumo: INotaCompraInsumo[];

  Laboratorio: IFornecedor;
  Codigo: string;
  DescricaoInsumo: string;
  InsumoOrigemDesc: string;
  InsumoOrigemValor: string;
  TabelasInsumo: ITabelaInsumo[];

  UltimoInventario: IInsumoInventario;
}

export interface IPacienteComplento {
  IdPaciente: number;
  _IdInstituicao?: number;
  IdInstituicao: number;
  _IdCidadeNatal: number;
  IdCidadeNatal: number;
  Cor?: IIpIndicadorItem;
  Escolaridade?: IIpIndicadorItem;
  NomePai: string;
  EscolaridadeResponsavel?: IIpIndicadorItem;
  Site: string;
  Instituicao: IPortador;
  CidadeNatal: ICidade;
}

export interface IPacienteFoto {
  Foto: number[];
  NomeArquivo: string;
  Path: ReferenciaPath;
}

export interface IEstadoCivil {
  Id: number;
  IdECivil: number;
  Descricao: string;
  Status: Status;
  Paciente: Paciente[];
}

export interface IIpIndicadorItem {
  Dominio: string;
  Descricao: string;
}

export interface ITabelaInsumo {
  Tabela: ITabela;
  Insumo: IInsumo;
  Valor: number;
  Codigo: string;
}

export interface INotaCompraInsumo {
  IdNCompraInsumo: number;
  UnidadeEstoque: IUnidadeEstoque;
  NotaCompra: any;
  Insumo: IInsumo;
  Fornecedor: IFornecedor;
  Quantidade: number;
  Valor: number;
  Desconto: number;
  VlMedioAnt: number;
  SaldoAnt: number;
  AtuEstoque: SimNao;
  Lotes: INotaCompraInsumoLote[];
}

export interface INotaCompraInsumoLote {
  NotaCompraInsumo: INotaCompraInsumo;
  Lote: IInsumoLote;
  Quantidade: number;
}

export interface IInsumoVersao {
  IdVersao: number;
  Insumo: IInsumo;
  Valor: number;
  Id: string;
  NomeTabelaLog: string;
}

export interface IInsumoSaldo {
  Clinica: IClinica;
  Insumo: IInsumo;
  Saldo: number;
  SaldoMin: number;
  SaldoEmprestimo: number;
  VlMedio: number;
  DataUltCompra?: Date;
  VlUltCompra: number;
  DataUltMovimento?: Date;
  UnidadeEstoque: IUnidadeEstoque;
}

export interface IInsumoInventario {
  DataInicio: Date;
  Saldo: number;
  VlMedio: number;
  VlCompra: number;
  VlVenda: number;
  VlFinalEstoque: number;
  Clinica: IClinica;
  UnidadeEstoque: IUnidadeEstoque;
  Insumo: IInsumo;
}

export interface IInsumoMovimento {
  Id: number;
  IdInsumo: number;
  IdMovimento: number;
  Data: Date;
  Hora: string;
  Quantidade: number;
  DosagemDisponivel: number;
  IdEmprestimo?: number;
  IdTransferencia?: number;
  SaldoAnt: number;
  VlVenda: number;
  VlMovimento: number;
  VlMedio: number;
  Complemento: string;
  InsumoOrigem: InsumoOrigem;
  Movimento: Movimento;
  Tipo: Tipo;
  Conciliado: SimNao;
  MovClassificacao: TipoMovClassificacao;
  Insumo: IInsumo;
  Clinica: IClinica;
  UnidadeEstoque: IUnidadeEstoque;
  Apresentacao: IApresentacao;
  InsumoLote: IInsumoLote;
  Usuario: IUsuario;
  InsumoMovimentoClassificacao: IInsumoMovimentoClassificacao;
  IdMovClassificacao?: number;
  Dosagem: number;
  DataHora: Date;
  MovimentoDescricao: string;
  TipoDescricao: string;
}

export interface IInsumoMovimentoClassificacao {
  Id: number;
  IdMovClassificacao: number;
  Descricao: string;
  Movimento: Movimento;
  Tipo: TipoMovClassificacao;
  Status: Status;
  MovimentoDescricao: string;
  TipoDescricao: string;
  StatusDescricao: string;
}

export interface IInsumoLote {
  Id: number;
  IdLote: number;
  Insumo: IInsumo;
  Lote: string;
  DataRegistro: Date;
  DataValidade: Date;
  Apresentacao: IApresentacao;
  InsumoSaldoLote: IInsumoSaldoLote[];
  DataBloqueio?: Date;
  _saldo: number;
  Saldo: number;
}

export interface IInsumoSaldoLote {
  Insumo: IInsumo;
  Clinica: IClinica;
  Lote: IInsumoLote;
  Saldo: number;
  UnidadeEstoque: IUnidadeEstoque;
}

export interface ICustoAdicional {
  Id: number;
  Descricao: string;
  Valor: number;
  TipoCusto: TipoCustoEnum;
  TipoValor: TipoValorEnum;
  TipoCustoDescricao: string;
  TipoValorDescricao: string;
  CustoAdicionalEstados: ICustoAdicionalEstado[];
  Insumos: IInsumo[];
}

export interface ICustoAdicionalEstado {
  Id: number;
  CustoAdicional: ICustoAdicional;
  Uf: IUF;
  Valor: number;
  TipoValor: TipoValorEnum;
  TipoValorDescricao: string;
}

export interface IPrincipioAtivo {
  Id: number;
  IdPrincipio: number;
  Descricao: string;
  Status: string;
  Tipo: string;
  TipoReacao: string;
  DoseMaxima: number;
  DoseMaximaUnidade: string;
  DoseFormatada: string;
  Gotejo: string;
  TempoInfusao: string;
  Equipo: string;
  Concentracao: string;
  Precaucoes: string;
  Observacao: string;
  TipoDescricao: string;
  TipoReacaoDescricao: string;
  PossuiOrientacao: boolean;
  MostrarPrecaucaoNaPrescricao: string;
  PrincipiosAtivosIncompativeis: IPrincipioAtivo[];
  ConciliacaoAutomaticas: IConciliacaoAutomatica[];
  Medicamentos: IMedicamento[];
}

export interface IConciliacaoAutomatica {
  Id: number;
  IdSequencia: number;
  PrincipioAtivo: IPrincipioAtivo;
  Medicamento: IMedicamento;
  VolumeIni: number;
  VolumeFim: number;
  DoseVolume: DoseVolume;
}

export interface IEvolucaoAtendimentoDTO {
  IdQuestResposta: number;
  IdProfissional: number;
  NomeProfissional: string;
  Data: Date;
  teste: IMedicamento;
}

export interface ISolicitacaoAtendimento {
  Id: number;
  Profissional: IProfissional;
  Prescricao: IPrescricao;
  Agenda: IAgenda;
  Data: Date;
  Observacao: string;
  Categoria: IProfissionalCategoria;
  Situacao: SolicitacaoSituacao;
  Evolucao: IEvolucaoAtendimentoDTO;
}

export interface IFaturamentoProtocoloMovimento {
  Id: number;
  Data: Date;
  Situacao: SituacaoFaturamentoProtocolo;
  Observacao: string;
  Usuario: IUsuario;
  FaturamentoProtocolo: IFaturamentoProtocolo;
}
export interface IFaturamentoProtocolo {
  Id: number;
  Tipo: TipoFaturamentoProtocolo;
  Observacao: string;
  Situacao: SituacaoFaturamentoProtocolo;
  Movimentos: IFaturamentoProtocoloMovimento[];
  Detalhes: IFaturamentoProtocoloDetalhe[];
  DetalhesPorPaciente: IFaturamentoProtocoloDetalhe[];
}

export interface IFaturamentoProtocoloDetalhe {
  Id: number;
  FaturamentoProtocolo: IFaturamentoProtocolo;
  Prescricao: IPrescricao;
  ValorLiquidoAutorizacaoFaturas: number;
  Status: Status;
  QtGuias: string;
  QtdGuiasEmLoteVsAutorizado: string;
  Observacao: string;
  Movimentos: IFaturamentoProtocoloDetalheMovimento[];
}

export interface IFaturamentoProtocoloDetalheMovimento {
  Id: number;
  Data: Date;
  DataString: string;
  Situacao: SituacaoProtocoloDetalhe;
  Observacao: string;
  Usuario: IUsuario;
  FaturamentoProtocolo: IFaturamentoProtocolo;
}

export interface IConfirmacaoQuimioDTO {
  AgendaData: Date;
  AgendaHoraInicio: string;
  AgendaConvenioId: number;
  AgendaConvenioFantasia: string;
  AgendaConvenioRazao: string;
  AgendaSituacao: AgendaSituacao;
  AgendaId: number;
  AgendaDataHoraChegada?: Date;
  AgendaDataHoraInicioAtendimento?: Date;
  AgendaDataHoraFimAtendimento?: Date;
  AgendaDataHoraInicioInfusao?: Date;
  PacienteId: number;
  PacienteNome: string;
  PacienteNomeSocial: string;
  PrescricaoId: number;
  PrescricaoData?: Date;
  PrescricaoNrVersao: number;
  PrescricaoViaId: string;
  PrescricaoIdentificacao: string;
  PrescricaoSituacao: PrescricaoSituacao;
  PrescricaoEvolucao: string;
  PrescricaoAutorizacaoId: number;
  PrescricaoDataAssinaturaEnfermagem: Date;
  PrescricaoProfissionalEnfermagemId?: number;
  PrescricaoProfissionalEnfermagemNome: string;
  PrescricaoProfissionalMedicoId?: number;
  PrescricaoProfissionalMedicoNome: string;
}
export interface INovoProfissional {
  Nome: string;
  IdEspecialidade: string;
  RegistroConselho: string;
  Conselho: string;
  UfConselho: string;
  Categoria: string;
}
export interface IProfissional {
  Id: number;
  IdProfissional: number;
  Nome: string;
  Conselho: string;
  RegistroConselho: string;
  UfConselho: string;
  IdEspecialidade?: number;
  Especialidade: string;
  CBOS: string;
  CBOS_V2_02_03: string;
  CBOS_V3: string;
  Categoria: IProfissionalCategoria;
  Tratamento: string;
  Registro: string;
  CPF: string;
  PercentualRepasse: number;
  Portador: IPortador;
  Usuario: IUsuario;
  Clinica: IClinica;
  UnidadeEstoque: IUnidadeEstoque;
  Abreviado: string;
  CodIntegracao: string;
  InformacaoAdicionalAssinatura: string;
  NomeTratamento: string;
  PrimeiroNome: string;
  PrimeiroNomeTratamento: string;
  NomeEConselho: string;
  RegistroCompleto: string;
  IdTextBoxPesquisa: number;
  DescricaoTextBoxPesquisa: string;
  ToolTipTextBoxPesquisa: string;
}

export interface IPrescricao {
  Id: number;
  IdPrescricao: number;
  Data?: Date;
  DataDescricao: string;
  Identificacao: string;
  Peso: number;
  Altura: number;
  SuperficieCorporal: number;
  TempoAplicacao: number;
  SolicitacaoAtendimentos: ISolicitacaoAtendimento[];
  TipoDose: TipoDose;
  IdUsuarioAutorizou?: number;
  DataAutorizou?: Date;
  TAxilar: number;
  Situacao: PrescricaoSituacao;
  SituacaoValorEnum: string;
  NrVersao: number;
  Conciliado: PrescricaoConciliado;
  TipoCiclo: TipoCiclo;
  Ciclo: number;
  CicloExtenso: string;
  Sessao: number;
  Periodicidade: string;
  SituacaoNf: PrescricaoSituacaoNF;
  ImprimiuEvolucao: SimNao;
  ObservacaoSugerida: string;
  FaturamentoProtocolos: any[];
  PossuiAjuste: SimNao;
  QtdVia: number;
  QtdVia2: number;
  TemConciliacao: boolean;
  Evolucao: string;
  Conduta: string;
  versaoBras: number;
  FC: number;
  FR: number;
  PA1: number;
  PA2: number;
  Creatinina: number;
  Curva: number;
  HoraInicio: string;
  HoraFim: string;
  SituacaoDetalhe: string;
  MedicoAssinante: string;
  FarmaceuticoAssinante: string;
  CabecalhoLivre: string;
  DataAssinaturaMedico?: Date;
  DataAssinaturaFarmacia?: Date;
  DataAssinaturaEnfermagem?: Date;
  ProfissionalMedicoAssinou: IProfissional;
  ProfissionalFarmaciaAssinou: IProfissional;
  ProfissionalEnfermagemAssinou: IProfissional;
  ProfissionalMed: IProfissional;
  ProfissionalAssis: IProfissional;
  ProfissionalAplicacao: IProfissional;
  Via: Via;
  Via2: Via;
  Paciente: Paciente;
  Clinica: IClinica;
  Agenda: IAgenda;
  Autorizacao: any;
  AutorizacaoSolicitacao: IAutorizacaoSolicitacao;
  Orcamento: IOrcamento;
  Usuario: IUsuario;
  Fatura: any;
  Detalhes: PrescricaoDetalhe[];
  Protocolos: PrescricaoProtocolo[];
  MovimentoInsumo: IPrescricaoMovimentoInsumo[];
  PrescricaoSolicitaAgenda: IPrescricaoSolicitaAgenda;
  PrescricaoAssinaturas: IPrescricaoAssinatura[];
  IdentificacaoCompleta: string;
  UsuarioSolicitou: IUsuario;
  DataSolicitou: Date;
  FormaPrescrever: FormaPrescrever;
  FormaPrescreverValor: string;
  FaturamentoProtocoloDevolvida: boolean;
  IdentificacaoComProtocoloGrupo(): string;
  TemRastreabilidade: boolean;
  ProtocoloPrePrescricao: SimNao;
  Revisada: boolean;
}

export interface IPrescricaoAssinatura {
  Id: number;
  Prescricao: IPrescricao;
  Usuario: IUsuario;
  Data: Date;
  TipoAssinatura: TipoAssinatura;
  TipoAssinaturaDescricao: string;
  TipoAssinaturaValor: string;
}

export interface IPrescricaoMovimentoInsumo {
  Prescricao: IPrescricao;
  Movimento: any;
  Usuario: IUsuario;
  DataRegistro: Date;
}

export interface IPrescricaoSolicitaAgenda {
  Prescricao: IPrescricao;
  Data: Date;
  Hora: string;
  DataInicio?: Date;
  Comentario: string;
  Usuario: IUsuario;
}

export interface IOrcamento {
  Id: number;
  IdOrcamento: number;
  Prescricao: IPrescricao;
  Situacao: OrcamentoSituacao;
  Insumos: IOrcamentoInsumo[];
}

export interface IOrcamentoInsumo {
  IdSequencia: number;
  VlVenda: number;
  AcreDesc: number;
  Percentual: number;
}

export interface IAutorizacaoSolicitacao {
  Id: number;
  Paciente: Paciente;
  Convenio: IConvenio;
  Autorizacao: any;
  UsuarioSolicitacao: IUsuario;
  DataSolicitacao: Date;
  Numero: string;
  QuantidadeSessoes: number;
  Configuracoes: string;
  Observacoes: string;
  Status: AutorizacaoSolicitacaoStatus;
  NOME_TABELA_LOG: string;
}

export interface IPacienteConvenio {
  Paciente: IPaciente;
  Convenio: Convenio;
  Matricula: string;
  Titular: string;
  ValidadeCarteira?: Date;
  Plano: string;
  Padrao: SimNao;
  ConvenioPlano: ConvenioPlano;
  PlanoDescricao: string;
}

export interface IDescricaoPadrao {
  Id: number;
  IdDescPadrao: number;
  Descricao: string;
  IndicaProcesso: string;
}

export interface IFormaPagamento {
  Id: string;
  IdFormaPgto: string;
  Descricao: string;
  TipoPagar: string;
  TipoReceber: string;
  Orderm: number;
  Status: string;
  Portador: IPortador;
}

export interface IImposto {
  VlBruto: number;
  VlBase: number;
  VlIRRF: number;
  VlCSLL: number;
  VlBaseCOFINS: number;
  VlCOFINS: number;
  VlPIS: number;
  VlISSQN: number;
  VlINSS: number;
  PercIRRF: number;
  VlOutros: number;
  VlDesconto: number;
  VlLiquidoOriginal: number;
  RetemISSQN: string;
  ClinicaPercISS: number;
  ClinicaVlISS: number;
  PercISSQN: number;
  IgnorarRetencao: boolean;
}

export interface IBanco {
  Id: string;
  IdBanco: string;
  Descricao: string;
  Tipo: string;
  Status: string;
  ContaFluxo: IContaFluxo[];
  Fluxo: IFluxo[];
}

export interface IFluxoCredito {
  IdFluxo: number;
  SituacaoDistribuido: SituacaoDistribuicao;
  DataCredito: Date;
  Imposto: IImposto;
}

export interface ICategoriaPlano {
  Id: number;
  IdCategoria: number;
  IdPlano: number;
  Categoria: string;
  Percentual: number;
  PercentualParticular: number;
  DefineManual: string;
  Status: string;
  PercentualC?: number;
  UtilizaMesmaGuia: string;
  Fantasia: string;
}

export interface IConvenioPlano {
  Id: number;
  IdPlano: number;
  Convenio: IConvenio;
  Plano: string;
  ExigeMatricula: string;
  ExigeGuia: string;
  ExigeSenha: string;
  ExigeAutorizacao: string;
  ValidadeInicio: Date;
  ValidadeFim: Date;
  Status: Status;
  CodIntegracao: string;
  Padrao: SimNao;
  IdTipoAtendimento: number;
  Categoria: ICategoriaPlano;
  Limite: number;
  Matricula: string;
  Titular: string;
}

export interface ITabela {
  Id: number;
  IdTabela: number;
  CodTISS: string;
  NomeTabela: string;
  Descricao: string;
  TabelaPreco: string;
  TabelaTISS: string;
  Status: string;
  Convenio: IConvenio[];
  ConvenioConsulta: IConvenio[];
}

export interface ILotes { }

export interface IConvenio {
  Id: number;
  IdConvenio: number;
  IdGrupoCusto: number;
  Razao: string;
  Fantasia: string;
  IE: string;
  DiasValidade: number;
  Categoria: ICategoriaConvenio;
  Tipo: string;
  TipoEnum: TipoConvenio;
  CGC: string;
  EmiteNFiscal: string;
  EmiteNFEntrega: string;
  FormatoMatricula: string;
  FormatoGuia: string;
  RegistroANSConvenio: string;
  TISSVersao: ConvenioTISSVersao;
  TISSTipoCodPrestador: string;
  TipoCodPrestador: ConvenioTipoCodPrestador;
  TISSTipoNumeroGuia: string;
  TipoConvenio: TipoConvenio;
  ParticipaAgendaWeb: string;
  IM: string;
  RegistrosClinica: string;
  RegistroConsultaClinica: string;
  TISSGuiaConsultaSADT: SimNao;
  FaturaMedSolUM: SimNao;
  PlanoConta: PlanoContas;
  Planos: ConvenioPlano[];
  IdPlanoContas: number;
  DescricaoPlanoConta: string;
  Portador: IPortador;
  Tabela: ITabela;
  TabelaConsulta: ITabela;
  TabelaInsumo: ITabela;
  TabelaMedicamento: ITabela;
  TabelaTaxa: ITabela;
  TabelaHIndividual: ITabela;
  Lotes: ILotes[];
  Autorizacoes: any;
  ProfissionalParticularidades: IProfissionalParticularidades[];
  InsumosDePara: IInsumosDePara[];
  TissGrupos: ITISSGrupo[];
  Clinica: IClinica;
  TISSQtdMinDigitos: number;
  TISSUtilizaSenhaNumGuia: SimNao;
  CaraterAtendimento: CaraterAtendimento;
  RepasseHonorario: string;
  TISSSolucaoComoMed: SimNao;
  TISSIgnoraReducaoAcrescimo: SimNao;
  TISSProfissionalComoContratante: TISSProfissionalComoContratanteEnum;
  TISSIgnoraTipoInsumo: string;
  TISSGeraEquipeSADT: SimNao;
  TISSIgnoraInsumoZerado: SimNao;
  TISSValorUnitarioBruto: SimNao;
  FaturaDuasCasasDecimais: SimNao;
  TISSConsultaNrOperadoraSADT: SimNao;
  TISSArredondaVlLiquidoUnitario: SimNao;
  RetemImpostoPadraoNF: SimNao;
  ISSQN: number;
  IdVersao: number;
  VersaoBras: string;
  VersaoSimpro: string;
  RetemLei10925: RetemLei10925;
  GruposInsumo: IConvenioGrupoInsumo[];
  InsumosDesconto: IConvenioInsumoDesconto[];
  IgnoraTipoInsumo: boolean;
  TISSUsaAutorizacaoNumeroGeral: SimNao;
  TISSOutrasDespesasDataPrimeiraPrescricao: SimNao;
  TISSProfissionalSolicitante: SimNao;
  Credenciamento: IConvenioClinica;
  ImpressaoPrescricaoPrincipioAtivoNomeComercial: ImpressaoPrescricaoPrincipioAtivoNomeComercial;
  TISSTipoUnidadeMedidaOutrasDespesas: TipoUnidadeMedida;
  TISSTipoArredondamento: Arredondamento;
  TISSQuantidadeMaximaPorItem: number;
  PrescricaoAtendimentoExterno: SimNao;
  ObrigarDistribuicaoPorInsumos: SimNao;
  TipoRepasseHonorario: TipoRepasseHonorario;
  NaoDuplicarNumeroGuiaPorPaciente: SimNao;
  TISSEquipeSADTUsaCodigoPrestador: SimNao;
  Descricao: string;
}

export interface IConvenioClinica {
  PrazoPgto: number;
  Registro: string;
  RegistroConsulta: string;
  RepasseHonorario: string;
  IRRF: string;
  RetemIRRFMenor: string;
  RetemISSQN: string;
  ISSQN: number;
  RetemLei10925: string;
  Contato: string;
  Orientacao: string;
  NrCopiaLaudo: number;
  SeqNota: number;
  SeqLote: number;
  SeqLoteConsulta: number;
  SeqXMLTISS: number;
  SeqNrGuia: number;
  DescTaxa: number;
  DescMaterial: number;
  DescMedicam: number;
  DescSolucao: number;
  Descgeral: number;
  TempoReconsulta: number;
  Convenio: IConvenio;
  Clinica: IClinica;
  ClinicaFaturar: IClinica;
  ContaFluxo: IContaFluxo;
  InsumoOrigemRestricao: string;
  TipoNumeroGuiaInternacao: TipoNumeroGuiaInternacao;
  SeqNroGuiaInternacao: number;
  IsNumeracaoGuiaInternacaoManual: boolean;
  IsNumeracaoGuiaInternacaoAutomatica: boolean;
}

export interface IConvenioInsumoDesconto {
  Clinica: IClinica;
  Convenio: IConvenio;
  Insumo: IInsumo;
  Desconto: number;
  Origem: PercentualOrigem;
}

export interface IConvenioGrupoInsumo {
  Convenio: IConvenio;
  GrupoInsumo: IGrupoInsumo;
  Percentual: number;
}

export interface IGrupoInsumo {
  Id: number;
  IdGrupoInsumo: number;
  Descricao: string;
  Origem: InsumoOrigem;
  Tipo: TipoGrupoInsumo;
  Status: Status;
  Insumos: IInsumo[];
  GruposInsumo: IConvenioGrupoInsumo[];
}

export interface ITISSGrupo {
  IdGrupo: number;
  Tabela: string;
  Descricao: string;
  Codigo: string;
  InsumoOrigens: InsumoOrigem[];
  Convenio: IConvenio;
  TissGrupoInsumos: ITISSGrupoInsumo[];
}

export interface ITISSGrupoInsumo {
  Grupo: ITISSGrupo;
  Insumo: IInsumo;
  Acao: TISSGrupoAcao;
}

export interface IInsumosDePara {
  Convenio: IConvenio;
  InsumoDe: IInsumo;
  InsumoPara: IInsumo;
}

export interface IProfissionalParticularidades {
  Convenio: IConvenio;
  Profissional: Profissional;
  ProfissionalSubstituto: Profissional;
  Matricula: string;
}

export interface ICategoriaConvenio {
  IdCategoria: number;
  Descricao: string;
  Status: Status;
  UnificaConvContasReceber: SimNao;
  StatusDescricao: string;
  UnificaConvContasReceberDescricao: string;
  Convenios: IConvenio[];
}

export interface INotaFiscal {
  Id: number;
  NomeTabelaLog: string;
  IdNFiscal: number;
  DataRegistro?: Date;
  DataEmissao?: Date;
  Numero: number;
  VlBruto: number;
  Cidade: string;
  UF: string;
  Nome: string;
  CnpjCpf: string;
  IE: string;
  StDistribuido: SituacaoDistribuicao;
  Situacao: SituacaoNotaFiscal;
  UtilizaNFSE: string;
  Email: string;
  CidadeIBGE: string;
  UFIBGE: string;
  IM: string;

  VlMedicamentos: number;
  VlMateriais: number;
  VlSolucoes: number;
  VlTaxas: number;
  VlHonorarios: number;
  VlDesconto: number;
  VlLiquidoOriginal: number;
  VlConsulta: number;
  NumeroSubstituto?: number;
  Endereco: IEndereco;
  Imposto: IImposto;
  Clinica: IClinica;
  Portador: IConvenio;
}

export interface IFluxo {
  NomeTabelaLog: string;
  Id: number;
  IdFluxo: number;
  Tipo: FluxoTipo;
  Agencia: string;
  Conta: string;
  Cheque: string;
  Valor: number;
  ValorBruto: number;
  VlAcreDesc: number;
  Complemento: string;
  Conferido: SimNao;
  Status: Status;
  Documento: string;

  DataLancamento: Date;
  DataVencimento: Date;
  DataCompensado?: Date;
  DataLog?: Date;
  Paciente: IPaciente;
  Portador: IPortador;
  Clinica: IClinica;
  ContaFluxo: IContaFluxo;
  DescricaoPadrao: IDescricaoPadrao;
  FormaPagamento: IFormaPagamento;

  FluxoCredito: IFluxoCredito;
  Banco: IBanco;
  GrupoCusto: GrupoCusto;
  NotaFiscal: INotaFiscal;
}

export interface IFornecedor {
  IdChFornecedor: number;
  Razao: string;
  IdentificadorFornecedor: IIpIndicador;
  CGC: string;
  Complemento: string;
  Fantasia: string;
  UF: string;
  CodBrasindice: string;
  Contato: string;
  DadosPgto: string;
  Pesquisa: string;
  Alvara: string;
  DataAlvara?: Date;
  DataAlvaraSanitario?: Date;
  AlvaraSanitario: string;
  Observacao: string;
  IdPlanoContas: number;
  GrupoCusto: GrupoCusto;
  PlanoConta: PlanoContas;
}

export interface IContaFluxo {
  IdCFluxo: number;
  Descricao: string;
  Agencia: string;
  Conta: string;
  IndicaTipo: TipoContaFluxo;
  IndicaTipoDescricao: string;
  DataMinUltAlteracao?: Date;
  Status: Status;
  IdPlanoContas: number;
  PlanoConta: PlanoContas;
}

export interface IUF {
  Uf: string;
  Id: number;
  Descricao: string;
  IBGEUF: string;
}

export interface ICidade {
  Id: number;
  IdCidade: number;
  Descricao: string;
  UF: IUF;
  CodigoIBGE: string;
  IdTextBoxPesquisa: number;
  DescricaoTextBoxPesquisa: string;
  ToolTipTextBoxPesquisa: string;
  Portador: IPortador[];
}

export interface IEndereco {
  Descricao: string;
  Complemento: string;
  Bairro: string;
  Cep: string;
  Numero: string;
}

export interface IPortador {
  Id: number;
  IdPortador: number;
  Origem: PortadorOrigem;
  RazaoNome: string;
  FantasiaApelido: string;
  TipoPessoa: string;
  CNPJ_CPF: string;
  CnpjCpf: string;
  Fone: string;
  Fone2: string;
  Fax: string;
  //Está como EMail na classe
  EMail: string;
  Entrada: string;
  Saida: string;
  DataRegistro: Date;
  DataAtualizado: Date;
  Status: Status;
  StatusValor: string;
  CodIntegracao: string;
  Endereco: IEndereco;
  Cidade: ICidade;
  ContaFluxo: IContaFluxo;
  GrupoFluxo: IGrupoFluxo;
  NotaFiscal: INotaFiscal[];
  Fluxo: IFluxo[];
  PortadorOrigem: PortadorOrigem;
  Chave: number;
  IE: string;
  IM: string;
  GrupoCusto: GrupoCusto;
  EnderecoNFSe: string;
  EnderecoCompleto: string;
  DescricaoOrigem: string;
  StatusDescricao: string;
  IdTextBoxPesquisa: number;
  DescricaoTextBoxPesquisa: string;
  ToolTipTextBoxPesquisa: string;
  CNPJ_CPFFormatado: string;
}

export interface IGrupoFluxo {
  Id: number;
  IdGrupoFluxo: number;
  Descricao: string;
  Despesa: string;
  Receita: string;
  Tipo: string;
  Status: string;
  Portador: Portador[];
}

export interface IClasseObjeto {
  Id: number;
  IdClasse: number;
  Descricao: string;
  Tipo: string;
}

export interface IObjeto {
  Id: number;
  IdObjeto: number;
  Classe: IClasseObjeto;
  Nome: string;
  Descricao: string;
  Tipo: string;
}

export interface IMenu {
  Id: number;
  IdMenu: number;
  Nome: string;
  Tipo: string;
}

export interface IProcesso {
  Id: number;
  IdProcesso: number;
  Componente: string;
  Nome: string;
  Parametros: string;
  Icone: string;
  Prefixo: string;
  Tipo: TipoProcesso;
  Rota: string;
  Objetos: IObjeto[];
  Incluir: boolean;
  Alterar: boolean;
  Excluir: boolean;
  PaginaInicial: SimNao.Sim | SimNao.Nao;
}

export interface ISubMenu {
  Descricao: string;
  IconPath?: string;
  Id: number;
  IdPai: number;
  Ordem: number;
  Rota?: string;
  PaginaInicial: SimNao.Sim | SimNao.Nao;
}

export interface IMenuDetalhe {
  Usuario: IUsuario;
  IdPasta?: number;
  IdSubPasta?: number;
  Pasta: string;
  Processo: IProcesso;
  Menu: IMenu;
  Descricao: string;
  Ordem: number;
  Favorito: SimNao;
}

export interface IUsuarioSenhaHistorico {
  IUsuario: IUsuario;
  Senha: string;
  Hash: string;
  DataSenha: Date;
}

export interface IProcessoObjeto {
  Processo: IProcesso;
  Objeto: IObjeto;
  Acao: string;
}

export interface IUsuario {
  Id: number;
  IdUsuario: number;
  //NomeUsuario: string;
  IdIntegracao: string;
  Nome: string;
  Senha: string;
  DataSenha?: Date;
  Admin: string;
  Status: string;
  Hash: string;
  Clinica: Clinica;
  Direitos: IProcessoObjeto[];
  Menu: IMenuDetalhe[];
  SenhaHistoricos: IUsuarioSenhaHistorico[];
  Tipo: UsuarioTipo;
  CategoriaProfissional: string;
  Login: string;
  Email: string;
  IdGrupo: number;
  Grupo: string;
  Cidade: string;
  UF: string;
  IdProfissional: number;
}

export interface IClinica {
  Id: number;
  IdClinica: number;
  IdIntegracao: number;
  UltimaAutorizacao: number;
  UltimaNFiscal: number;
  UltimoRecibo: number;
  UltimoTISS?: number;
  ChequeCaucao: number;
  CodIPE: string;
  CNES: string;
  PercPIS: number;
  PercCofins: number;
  PercCSLL: number;
  EmiteNFiscal: string;
  AliquotaIss: number;
  Registro: string;
  RegistroConsulta: string;
  Razao: string;
  PercBaseISSQN: number;
  ConvenioNomeContratado: string;
  ConvenioCNPJContratado: string;
  Portador: IPortador;
  Empresa: Empresa;
  NotaFiscalConfiguracao: INotaFiscalConfiguracao;
  ContaFluxo: IContaFluxo[];
  Lote: ILote[];
  NotaFiscal: INotaFiscal[];
  Parametros: IIpParametroChave[];
  Horarios: IClinicaHorario[];
}

export interface IClinicaHorario {
  Clinica: IClinica;
  DiaSemana: DiaSemana;
  HoraIni: string;
  HoraFim: string;
  //TimeSpan
  Inicio: number;
  Fim: number;
}

export interface ILote {
  Id: number;
  IdLote: number;
  Protocolo: string;
  Numero: number;
  DataGerou: Date;
  DataEntrega?: Date;
  DataPrevista?: Date;
  VlDescEntrega?: number;
  VlBruto: number;
  VlLiquido: number;
  Tipo: string;
  LoteTipo: LoteTipo;
  Situacao: string;
  LoteSituacao: LoteSituacao;
  Quitado: string;
  TISSGeraSADT: SimNao;
  Autorizacoes: IAutorizacao[];
  Clinica: IClinica;
  Convenio: Convenio;
  NotaFiscal: INotaFiscal;
  Usuario: Usuario;
  AutorizacaoRecGlosas: IAutorizacaoRecGlosa[];
  NrFaturaIni?: number;
  NrFaturaFim?: number;
}

export interface INotaFiscalConfiguracao {
  Chave: string;
  Ambiente: Ambiente;
  Tipo: string;
  ItemListaServico: string;
  NaturezaOperacao: string;
  CodigoTributacaoMunicipio: string;
  LocalPrestacaoServico: LocalPrestacaoServico;
  RegistroEspecialTributacao: RegistroEspecialTributacao;
  OptanteSimplesNacional: OptanteSimplesNacional;
  IncentivadorCultural: IncentivadorCultural;
  TipoNf: TipoNf;
  TipoItemCobrado: TipoItemCobrado;
  Clinica: IClinica;
}

export interface IRecurso {
  Id: number;
  IdChRecurso: number;
  Descricao: string;
  Ordem?: number;
  TipoAgenda: string;
  TempoAgenda?: number;
  Clinica: IClinica;
  //todo2
}

export interface IAgenda {
  NomeTabelaLog: string;
  Id: number;
  IdAgenda: number;
  Data?: Date;
  HoraInicio: string;
  HoraFim: string;
  Tempo: number;
  Matricula: string;
  Atendimento: AgendaAtendimento;
  DtHrChegada?: Date;
  DtHrFimAtend?: Date;
  DtHrIniAtend?: Date;
  DtHrInicioInfusao?: Date;
  DataFim?: Date;

  AutorizacaoTipoConsulta: AgendaAutorizacaoTipoConsulta;
  TipoConsulta: AgendaTipoConsulta;
  Complemento: string;
  Confirmado: string;
  NrGuia: string;
  PacienteConvenio: IPacienteConvenio;
  Convenio: IConvenio;
  Recurso: IRecurso;
  Profissional: IProfissional;
  Cid: CID;
  Fatura: any;
  Paciente: IPaciente;
  Prescricao: any;
  Usuario: IUsuario;
  Clinica: IClinica;
  ClinicaFaturar: IClinica;
  UnidadeAtendimento: IUnidadeAtendimento;
  AgendaOcorrencia: any[];
  TipoAgenda: TipoAgenda;
  AgendaExame: any;
  Autorizacao: any;
  Plano: ConvenioPlano;
  StProducao: AgendaStProducao | string;
  TipoAtendimento: ITipoAtendimento;
  AgendaClassificacao: any;
  AgendaSubClassificacao: any;
  PrimeiraConsulta: SimNao;

  corAberta: any;
  corConfimada: any;
  corPrimeiro: any;
  corProduzida: any;
  corLiberadaProd: any;
  corAguardando: any;

  SituacaoLaudoDescricao: string;

  Situacao: AgendaSituacao;
  SituacaoDescricao: string;
  TempoAtendimento?: Date;
  TempoEspera?: Date;
  DataInicioAgenda?: Date;
  DataFimAgenda?: Date;
  NrFilaEspera?: number;
  //todo1
}

export interface ITipoAtendimento {
  Id: string;
  Descricao: string;
  Status: Status;
}

export interface IUnidadeAtendimento {
  Id: number;
  IdUAtendimento: number;
  Clinica: IClinica;
  Descricao: string;
  StConsulta: SimNao;
  StQuimio: SimNao;
  Status: Status;
  UnidadeEstoques: IUnidadeEstoque[];
  ClinicaUnidade: string;
}

export interface IUnidadeEstoque {
  Id: number;
  IdUEstoque: number;
  Descricao: string;
  Clinica: IClinica;
  Status: Status;
  Tipo: TipoUnidadeEstoque;
}

export interface IPacientePulseiraQrCode {
  codigoPaciente: string;
}
export interface IEtiquetaInsumoQrCode {
  codigoPaciente: string;
  codigoMedicacao: string;
}

// Generated by https://quicktype.io

export interface InsumoEstoqueDiarioDTO {
  QuantidadeReservada: number;
  IdSobra: number;
  IdPrincipioAtivo: number;
  IdInsumo: number;
  PrincipioAtivo: PrincipioAtivo;
  IdLote: number;
  IdMovimento: number;
  IdUEstoque: number;
  QuantidadeUtilizada: number;
  QuantidadeMovimento: number;
  Descricao: string;
  Dose: number;
  MedidaDose: string;
  Lote: string;
  SaldoTotalItem: number;
  SaldoLote: number;
  DataValidade: string;
  Origem: string;
  UnidadeEstoque: string;
  QuantidadeAtendida: number;
  Atendido: string;
}

export interface PrincipioAtivo {
  Id: number;
  IdPrincipio: number;
  Descricao: string;
  Status: string;
  Tipo: string;
  TipoReacao: string;
  DoseMaxima: number;
  DoseFormatada: string;
  TipoDescricao: string;
  TipoReacaoDescricao: string;
  AdicionadoSemProtocolo: boolean;
}
export interface PrescricaoPedidoItemAlterarSituacaoDTO {
  Id: number;
  Situacao: string;
  DataTratamento?: Date;
  DataProducao?: Date;
}

export interface IResult<T = any> {
  Value: T;
  IsSuccess: boolean;
  Error: string;
  TipoMensagem: string;
  IsFailure: boolean;
  StatusCode: string;
}

export interface IResult2<T = any> {
  Valor: T;
  IsSuccess: boolean;
  Error: string;
  TipoMensagem: string;
  IsFailure: boolean;
  StatusCode: string;
}

export interface IFiltroCliente {
  Status: string;
  PesquisaLivre: string;
}
export interface IPrescricaoPedidoItemFiltroDTO {
  IdCliente?: number;
  IdUsuarioLiberacao?: number;
  IdFarmaciaOrdemServico?: number;
  IdPrescricao?: number;
  IdPrescricaoPedido?: number;
  IdPrescricaoPedidoItem?: number;
  DataHoraLiberacaoInicial?: Date;
  DataHoraLiberacaoFinal?: Date;
  DataHoraProducaoInicial?: Date;
  DataHoraProducaoFinal?: Date;
  DataHoraTratamentoInicial?: Date | string;
  DataHoraTratamentoFinal?: Date | string;
  Situacao?: string[];
  Prioridade?: string[];
  IdsClientes?: number[];
  CarregarPedido?: boolean;
  IdsPrescricoes?: number[];
  DesconsiderarPrescricaoComOrdemServico?: boolean;
  SomenteFaturadas?: boolean;
  PrescricaoSituacao?: string;
  ExisteResponsavelLiberacao?: boolean;
  SituacaoLiberacao?: string;
}

export interface IWrapperArquivoUpload {
  files: IArquivoUpload[];
}

export interface IAtualizarFotoPacienteDTO {
  idPaciente: number;
  fotoByteArray: string;
}

export interface IArquivoUpload {
  bytearray: string;
  extension: string;
  id: string;
  nome: string;
  preview: string;
  result: string;
  type: string;
  novoArquivo?: boolean;
}

export interface IPrescricaoPedidoItemSimplificadoDTO {
  IdPrescricaoPedidoItem: number;
  IdPrescricao: number;
  DataHoraProducao: Date;
  PacienteNome: string;
  Prescricao: string;
}

export interface IFiltro {
  filtro: {
    NFEmitida: boolean;
    Faturada: boolean;
    Cliente: number[];
    DataFim: Date;
    DataInicio: Date;
    Situacoes: string[];
    Turnos: number[];
    SituacoesPrescricao: string[];
    SituacoesRemessa: string[];
    SituacaoLiberacao: string;
    Prioridades: string[];
  };
}

export interface IPeriodo {
  PeriodoInicial: Date;
  PeriodoFinal: Date;
}
export interface IPeriodoLista {
  Periodo: IPeriodo;
  Descricao: string;
}

export interface IChaveValor {
  valor: string | number;
  descricao: string;
}

export interface IBaseUrlsApis {
  Auth: string;
  Resources: string;
  Seguranca: string;
  GemedWebImage: string;
  Graphql: string;
  ProntuarioExameWeb: string;
  Mobile: string;
  VideoAtendimento: string;
  VideoAtendimentoToken: string;
}

export interface IEnvironmentProps {
  production: boolean;
  idiomasSuportados: string[];
  url: IBaseUrlsApis;
  TipoAmbienteNexoData: NexodataAmbienteEnum;
  domain: string;
  readOnlyMode: boolean;
  hmr: boolean;
  applicationInsightsKey?: string;
  version: string;
}
export interface IRetornoCriacaoDeOverfill {
  lote: string;
  dataAbertura: Date;
  quantidadeDevolvida: number;
  validadeRecalculada: Date;
}

export interface ICriarOverfill {
  lote: string;
  unidadeDeMedida: string;
  nome: string;
  quantidadeLimite: number;
  idInsumo: number;
}

export interface ISobra {
  lote: string;
  dataAbertura: Date;
  quantidadeDevolvida: number;
  validadeRecalculada: Date;
}

export interface IRetornoCriacaoDeSobra {
  sobraCriada: ISobra;
}

export interface ICriarSobra {
  lote: string;
  unidadeDeMedida: string;
  nome: string;
  quantidadeLimite: number;
  idInsumo: number;
}

export interface IResultInsumoPorLote {
  DataRegistro: Date;
  DataValidade: Date;
  Id: number;
  IdLote: number;
}

export interface IEtiquetaFarmaWebDTO {
  ClienteNome: string;
  IdCliente: string;
  PacienteNome: string;
  IdPaciente: string;
  IdPrescricao: string;
  DataProducao: string;
  IdFarmaciaOrdemServico: number;
  IdProfissionalManipulacao?: number;
  OrdemPrescricao?: {};
  Turno?: string;
  TiposEtiquetas: IpModeloTipo[] | string[];
}

export interface IProfissionalEspecialidade {
  Id: number;
  Descricao: string;
}

export interface IEstado {
  Uf: string;
  Descricao: string;
  IBGEUF: string;
}

export interface IInsumosSelecionados {
  PrescricaoSelecionada: PrescricaoSeparacaoEstoqueDTO;
  ListaDeInsumosSelecionados: IInsumoSobraOrdemServico;
}

export interface PrescricaoPedidoItemDetalheDTO {
  IdPrescricaoPedidoItem: number;
  IdPrescricao: number;
  ListaIdsSeqDetalhes: number[];
  IdPrescricaoItem: number;
}

export interface IQuestionarioPerguntaItemLista {
  Id: number;
  Pergunta: QuestionarioPergunta;
  Descricao: string;
  Valor: string;
  Ordem: number;
  Status: Status;
}


export interface InsumoCodBarrasFiltro {
  Codigo: string;
  IdUEstoque?: number;
  DeveValidarLote?: boolean;
  DeveBuscarSemUnidadeEstoque?: boolean;
}


export interface IEnumItem {
  Descricao: string;
  Valor: string;
}

export interface IPrescricaoDetalheRadioterapia {
  Id: number;
  Prescricao: Prescricao;
  ProtocoloRadioterapia: IProtocoloRadioterapia;
  Dose: number;
  DoseUnidadeMedida: string;
  DoseTotal: number;
  DoseTotalUnidadeMedida: string;
  NumeroCampos: number;
  NumeroTotalDias: number;
  NumeroCamposPrevistos: number;
  NumeroInsercoes: number;
  QuantidadeFracoes: number;
  SitioTratamento: string;
  EsquemaFracionamento: string;
  TecnicaRadioterapia: string;
  Energia: number;
  EnergiaUnidadeMedida: string;
  Boost: string;


}

export interface IPrescricaoRadioterapiaAPartirNovoProtocolo {
  IdPrescricao: number;
  IdPaciente: number;
  IdClinica: number;
  IdUsuario: number;
  IdProtocolo: number;
  IdCid: number;
  IdArea: number;
}

export interface IPrescricaoRadioterapiaDetalheSimplificado {
  IdPrescricao: number;
  IdProtocolo: number;
  IdCid: number;
  IdArea: number;
  IdMedico: number;
  IdAssistente: number;
  DataAgendada: Date | string;
  ProtocoloSelecionadoDescricao: string;
  CIDSelecionadoDescricao: string;
  AreasSelecionadasDescricao: string;
  SitioTratamento: string;
  SitioEnergia: number;
  SitioEnergiaUnidadeMedida: string;
  SitioTecnica: string;
  Boost: boolean;
  BoostSelecionado: string;
  DoseTotalUnidadeMedida: string;
  DoseTotal: number;
  DoseTotalQuantidadeFracoes: number;
  DoseFracaoUnidadeMedida: string;
  DoseFracao: number;
  DosePorFracaoNaoSeAplica: boolean;
  NumeroCamposNaoSeAplica: boolean;
  NumeroCamposPrevistos: number;
  NumeroCamposAreaDia: number;
  NumeroCamposFracoes: number;
  QuantidadeInsercoes: number;
  EsquemaFracionamento: string;
  DiagnosticoRadioterapiaDTO: IDiagnosticoRadioterapiaDTO;
  HabilitarBotaoGravarAssinar: boolean;
}

export interface IPrescricaoRadioterapiaDetalheDTO {
  IdPrescricao: number;
  IdUsuario: number;
  IdClinica: number;
  IdPaciente: number;
  IdProtocolo: number;
  IdCID: number;
  IdArea: number;
  SitioTratamento: string;
  SitioEnergia: number;
  SitioEnergiaUnidadeMedida: string;
  SitioTecnica: string;
  Boost: boolean;
  BoostSelecionado: string;
  DoseTotalUnidadeMedida: string;
  DoseTotal: number;
  DoseTotalQuantidadeFracoes: number;
  DoseFracaoUnidadeMedida: string;
  DoseFracao: number;
  DosePorFracaoNaoSeAplica: boolean;
  NumeroCamposNaoSeAplica: boolean;
  NumeroCamposPrevistos: number;
  NumeroCamposAreaDia: number;
  NumeroCamposFracoes: number;
  QuantidadeInsercoes: number;
  EsquemaFracionamento: string;
  IdMedico: number;
  IdAssistente: number;
  AssinarPrescricao: boolean;
  DataAgendada: string;
}


export interface IDiagnosticoRadioterapiaDTO {
  CidPrimario: string;
  CidSitioTratamento: string;
  Estadiamento: string;
}

export interface IPacienteFiltro {
  Registro: number;
  Nome: string;
  Matricula: string;
  CPF: string;
  Situacao: string[];
  Status: string;
  Profissional: IProfissional;
  ConsultarSistemaGestao: boolean;
  DataNascimento?: Date;
  NomeMae: string;
  Email: string;
  Telefone: string;
  IdConvenio: number;
  PeriodoInicio?: Date;
  PeriodoFim?: Date;
  IdMedicamentos: number[];
  IdProtocolos: number;
}


export interface IPrescricaoDetalheViaOral {
  Id: number;
  IdApresentacao: number;
  IdItem: string;
  PrescricaoDetalhe: PrescricaoDetalhe;
  Insumo: IInsumo;
  DosagemApresentacao: number;
  DosagemTotalAdministrada: number;
  UnidadeMedidaApresentacao: string;
  QuantidadeDiasMedicacao: number;
  TotalComprimidosEntregues: number;
  QuantidadeComprimidosPorVez: number;
  QuantidadeVezesPorDia: number;
  DoseDoDia: number;
  DosePorVez: number;
  Orientacao: string;
  Fracionamento: number;
  PermiteFracionar: SimNao;
  ArredondarFracionamento: SimNao;
}

export interface IProfissionalVinculadoAssistenteDTO {
  IdProfissional: number;
  Nome: string;
}


export interface ILogAdaptatividade {
  DataHora: string;
  Acao: number;
  Classe: string;
  Usuario: Usuario;
  Complemento: string;
  Empresa: Empresa;
  ComputadorNome: string;
}

export interface ILogDto {
  NomeFantasiaCliente: string;
  NomeSocialPaciente: string;
  IdPrescricao: number;
  IdentificacaoPrescricao: string;
  DataTratamento: string;
}

export interface ILogDetalheDto {
  NomeUsuario: string;
  Data: string;
  Detalhe: string;
}

export interface IFiltroLogFarma {
  IdPrescricao: number;
  IdCliente: number;
  IdPaciente: number;
  DataTratamentoInicial: string;
  DataTratamentoFinal: string;
}

export interface IInsumoSobraOverfillDTO {
  Insumo: InsumoSobraDTO;
  DataAberturaFrasco?: string;
  IdPrescricao: number;
}

export interface IMedicamentoSobra {
  Id: number;
  IdSobra: number;
  IdMedicamento: number;
  Apresentacao: Apresentacao;
  DataVence: Date;
}
