import { Component } from '@angular/core';
import './ip-list.component.less';
@Component({
  selector: 'ip-list',
  template: `
    <mat-list>
      <ng-content></ng-content>
    </mat-list>`
})
export class IPListComponent {
}
