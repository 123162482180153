<div class="container">
  <main class="content">
    <router-outlet *ngIf="(isAuthenticated$ | async) === false"></router-outlet>
    <section flex layout="row" layout-fill *ngIf="isAuthenticated$ | async">
      <ip-side-nav-layout [(opened)]="sideMenuOpen" (backDropClicked)="fecharMenuLateral()"
        (escClicked)="fecharMenuLateral()" flex layout="row">
        <section style="height: 100%" class="sidebar ip-side-nav" flex layout="column">
          <ip-menu-lateral [sairSistema]="logout" [systemMenuOptions]="opcoesMenu" (opcaoSelecionada)="openMenu()">
          </ip-menu-lateral>
        </section>
        <section flex layout="column" layout-fill class="ip-content" style="height: 100%">
          <header style="flex: 0 0 auto;">
            <mat-toolbar class="ip-toolbar-primary">
              <mat-toolbar-row class="app-header mat-toolbar-row">
                <ip-button-default data-test="gemed-button-menu-sandwich" *ngIf="isAuthenticated$ | async"
                  [hidden]="loadingRouteConfig" [color]="'transparent'" [tipoDeBotao]="'fab'"
                  [materialIconName]="'reorder'" (onClick)="openMenu()"
                  [textoParaPessoasCegas]="'Ir para página inicial'">
                </ip-button-default>
                <span flex class="toolbar__label fs-title tc-white-1 ml xs" *ngIf="isAuthenticated$ | async">{{ title
                  }}</span>
                <ip-button-default *ngIf="isAuthenticated$ | async" [color]="'transparent'" [tipoDeBotao]="'fab'"
                  [materialIconName]="'exit_to_app'" (onClick)="logout($event)"
                  matTooltip="{{ 'sairSistema' | translate}}" [textoParaPessoasCegas]="'Sair da aplicação'">
                </ip-button-default>
              </mat-toolbar-row>
            </mat-toolbar>
          </header>
          <section flex layout="column">
            <ip-progress *ngIf="loadingRouteConfig" style="width: 100vw"></ip-progress>
            <router-outlet></router-outlet>
          </section>
        </section>
      </ip-side-nav-layout>
    </section>
  </main>
</div>
