import { PEPBase } from './pepBase.model';
import { CID } from '../../../shared/models';

export class PEPDiagnostico extends PEPBase {
  public CID: CID;
  public Descricao: string;
  public Tipo: string;
  public DescricaoSimplificadaCID: string;
  public Editando: boolean;

  constructor() {
    super();
    this.Tipo = 'C';
    this.CID = new CID();
    this.Editando = false;
  }
}
