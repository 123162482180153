import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "ip-confirm-simple-dialog",
  templateUrl: "./confirm-simple-dialog.component.html",
  styleUrls: ["./confirm-simple-dialog.component.scss"],
})
export class ConfirmSimpleDialogComponent {
  @Input() exibirBotaoConfirmar = true;
  @Input() exibirBotaoCancelar = true;
  @Input() textoBotaoCancelar = "nao";
  @Input() textoBotaoConfirmar = "sim";
  public titulo: string;
  public texto: string;
  public dialogRefAviso: MatDialogRef<ConfirmSimpleDialogComponent>;

  constructor(dialogRefAviso: MatDialogRef<ConfirmSimpleDialogComponent>) {
    this.dialogRefAviso = dialogRefAviso;
  }

  onFechar() {
    this.dialogRefAviso.close(false);
  }

  onGravar() {
    this.dialogRefAviso.close(true);
  }
}
