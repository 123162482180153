import { DataPEP } from './dataPep.model';
import { LibGeral } from '../../../../core/libraries/libGeral';
import { LibDate } from '../../../../core/libraries/libDate';

export abstract class PEPBase {
  public Id: number;
  public Paciente: IPaciente;
  public Complemento: string;
  public DataInicio: DataPEP;
  public DataFim: DataPEP;
  public IdPaciente: number;
  
  constructor() {
    this.Id = 0;
    this.Complemento = "";
    this.DataInicio = new DataPEP(null);
    this.DataFim = new DataPEP(null);
  }

  hasDataInicio(): boolean { return LibGeral.estaPreenchido(this.DataInicio.Data) }
  hasDataFim(): boolean { return LibGeral.estaPreenchido(this.DataFim.Data) }

  private setDataPEP(value: any, self: any, propName: string): void {
    if (LibGeral.isDate(value)) {
      self[propName] = new DataPEP(value);
    } else if (value instanceof DataPEP) {
      self[propName] = value;
    } else {
      self[propName] = new DataPEP(LibDate.ToDate(value));
    }
  }
}
