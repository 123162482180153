declare const _: any;
import { Component, ComponentFactoryResolver, Directive, ElementRef, Host, Input, OnChanges, OnInit, Optional, Renderer2, SimpleChanges, Type, ViewContainerRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { LibGeral } from '@gemed-core/libraries/libGeral';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';
import { IPCellDirective } from './ip-cell.directive';


/*
 <ip-select [(model)]="model" [widthContainer]="widthContainer" [selecter]="selecter" (modelChange)="modelChangeEmit()">
    <ip-select-item *ngFor="let item of source" [value]="getKeyValue(item)" [display]="item[display]">
    </ip-select-item>
  </ip-select>
*/
@Component({
  selector: 'ip-cell-inline-select',
  template: `
      <mat-select
        [disabled]="disabled"
        [value]="obterValor()"
        (selectionChange)="modelChangeEmit($event)">
        <mat-option *ngFor="let item of source" [value]="modoSimples ? item : item[key]">
          {{(modoSimples ? item : item[display])}}
        </mat-option>
      </mat-select>
  `
})
export class IPCellInlineSelectComponent implements OnInit {
  @Input() display: string;
  @Input() key: string = "";
  @Input() model: any;
  @Input() disabled: boolean = false;
  @Input() modoSimples: boolean = false;
  @Input() source: Array<any>;
  @Input() widthContainer: number;
  @Input() modelChange: Function;
  @ViewChild(MatSelect) matSelect: MatSelect;
  public row: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {

  }

  public obterValor() {
    if (!LibGeral.estaPreenchido(this.model) ||
      (!this.modoSimples && !LibGeral.estaPreenchido(this.key))
    ) {
      return ""
    }

    return this.modoSimples ? this.model : this.model[this.key]
  }

  public detectaMudancas() {

    this.matSelect.writeValue(this.modoSimples ? this.model : this.model[this.key]);
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Ira retornar item caso key não tenha sido informada.
   * @param  {} item
   * @returns any
   */
  getKeyValue(item: string): any {
    return this.key ? item[this.key] : item;
  }


  ngOnInit() {

    if (!this.model) {
      this.model = {};
    }
  }



  modelChangeEmit(event: MatSelectChange): void {
    if (this.modelChange) {
      const newValue = this.modoSimples ? event.value : this.source.find(x => x[this.key] == event.value);
      this.modelChange(newValue);
    }
  }
}

@Directive({
  selector: '[ip-cell-inline-edit-select]'
})
export class IPCellInlineEditSelectDirective extends IPCellInlineEditDirective<any> implements OnChanges {
  @Input() allowEdit: boolean;
  @Input() key: string;
  @Input() display: string;
  @Input() modoSimples: boolean = false;
  @Input() source: any[];
  @Input() disabled: boolean = false;
  @Input() widthSelectContainer: number;

  private cellInlineSelectComponent: IPCellInlineSelectComponent;

  constructor(
    element: ElementRef,
    renderer: Renderer2,
    viewContainerRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    @Optional() @Host() cell: IPCellDirective) {
    super(element, renderer, viewContainerRef, componentResolver, cell);

  }

  /**
   * @returns Type
   */
  public getComponent(): Type<IPCellInlineSelectComponent> {
    return IPCellInlineSelectComponent;
  }
  /**
   * Seta propriedades para componente "IPCellInlineSelectComponent"
   * @param  {IPCellInlineSelectComponent} cellInlineSelectComponent
   * @returns void
   */

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!!this.cellInlineSelectComponent && !this.cellInlineSelectComponent.source) {
      this.cellInlineSelectComponent.source = simpleChanges.source.currentValue;
    }

  }

  public detectaMudancas() {
    this.cellInlineSelectComponent.detectaMudancas();
  }

  public setComponentProps(cellInlineSelectComponent: IPCellInlineSelectComponent): void {
    cellInlineSelectComponent.model = this.cell.getCurrentValue();
    cellInlineSelectComponent.key = this.key;
    cellInlineSelectComponent.display = this.display;
    cellInlineSelectComponent.modoSimples = this.modoSimples;
    cellInlineSelectComponent.disabled = this.disabled;
    cellInlineSelectComponent.source = this.source;
    cellInlineSelectComponent.widthContainer = this.widthSelectContainer;
    cellInlineSelectComponent.modelChange = (valor: any) => {
      const prop = <any>this.cell.getModelName();
      const model = <any>this.cell.row.model;
      model[prop] = valor;
      this.cell.row.modelChange.emit(this.cell.row);
    };
    this.cellInlineSelectComponent = cellInlineSelectComponent;
  }
  getValue(): any {

    // const model = <any>this.cell.row.model;
    const model = this.copiarModel(this.cell.row.model);
    return model[<any>this.cell.getModelName()];
  }

  private copiarModel(model): any {
    return (typeof model == "object") ? JSON.parse(JSON.stringify(model)) : model;
  }
}
