import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LibGeral } from '@gemed-core/libraries/libGeral';

@Component({
  selector: 'ip-aviso-simples',
  templateUrl: './aviso-simples.component.html',
  styleUrls: ['./aviso-simples.component.scss']
})
export class AvisoSimplesComponent implements OnInit {

  @Input() MensagemGenerica: string = "aviso404";

  constructor() { 
    
  }

  ngOnInit() {
    //https://medium.com/ableneo/how-to-pass-data-between-routed-components-in-angular-2306308d8255
    if(LibGeral.estaPreenchido(history.state.mensagem)){
      this.MensagemGenerica = history.state.mensagem;

    }
  }

}
