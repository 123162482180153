import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IPCheckBoxComponent } from './ip-checkbox.component';
import { MaterialModule } from '../../../material/material.module';

@NgModule({
  imports: [MaterialModule],
  declarations: [IPCheckBoxComponent],
  exports: [IPCheckBoxComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class IPCheckBoxModule {
}
