import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvisoSimplesComponent } from "./aviso-simples.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule
    ],
    declarations: [
        AvisoSimplesComponent
    ],
    exports: [
        AvisoSimplesComponent
    ]


})
export class AvisoSimplesModule {

}