import { Component, EventEmitter, Input, Output, OnInit, OnChanges, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, SimpleChanges } from '@angular/core';
import { LibGeral } from '@gemed-core/libraries/libGeral';



@Component({
  styleUrls: ['./ip-input-number.component.scss'],
  template: `

  <main>
    <label *ngIf="title?.length > 0">{{title}}</label>
    <mat-form-field [floatingPlaceholder]="floatingPlaceholder" [color]="ValorValido ? 'primary' : 'warn'">
    <input
      matInput
      flex
      layout-fill
      type="text"
      align="end"
      [placeholder]="placeholder"
      [(disabled)]="disabled"
      [value]="displayValue"
      (input)="applyMask($event)"
      #inputMask/>
      <mat-hint *ngIf="deveMostrarHint" align="end">{{geraHint()}}</mat-hint>
  </mat-form-field>
  </main>
  `,

  selector: 'ip-input-number'
})
export class IPInputNumberComponent implements OnInit, OnChanges {

  @ViewChild("inputMask") input: ElementRef;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() floatingPlaceholder = true;
  @Input() numInt: number;
  @Input() numFract: number;
  @Input() numSep: string;
  @Input() numThousand: boolean;
  @Input() numThousandSep: string;
  @Input() placeholder: string;
  @Input() deveMostrarHint = true;

  @Input() model: number;
  @Output() modelChange: EventEmitter<number>;
  public ValorValido: boolean;
  EhNumeroFracionario = false;


  public displayValue: string;
  constructor() {
    this.displayValue = null;
    this.numInt = 6;
    this.numFract = 2;
    this.model = 0;
    this.numSep = null;
    this.modelChange = new EventEmitter<number>();

  }
  ngOnInit(): void {
    this.EhNumeroFracionario = this.numFract !== 0;
  }

  geraHint(): string {
    return new Array(this.numInt).fill("0", 0, this.numInt).join("") +
      (this.EhNumeroFracionario
        ? this.numSep + new Array(this.numFract).fill("0", 0, this.numFract).join("")
        : ""
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('model') && LibGeral.estaPreenchido(changes.model.currentValue) && changes.model.currentValue >= 0) {
      const modelWithSeparator = String(this.model).replace(".", this.numSep);
      if (this.EhNumeroFracionario) {
        this.displayValue = String(this.model).includes(".") ? modelWithSeparator : this.formatInsertedValue();
      } else {
        this.displayValue = modelWithSeparator;
      }
    }
  }


  applyMask(event: any): void {
    event.preventDefault();
    const caretPosition = event.currentTarget.selectionStart;
    this.input.nativeElement.value = this.input.nativeElement.value.replace(/[^\d]/g, '');
    if (this.numInt === 0 && this.numFract === 0) {
      return;
    }
    const maxEntries = this.numFract + this.numInt;
    const inputValue = this.input.nativeElement.value;
    const isInserting = event.inputType === "insertText";
    const withoutLastDigit = inputValue.slice(0, -1);
    const overrideNextDigit = caretPosition > this.numInt ? inputValue.slice(0, caretPosition - 1) + inputValue.slice(caretPosition) : inputValue.slice(0, caretPosition) + inputValue.slice(caretPosition + 1);
    const isIntValueFull = this.numInt > 0 && this.numFract === 0 && (this.input.nativeElement.value.length > this.numInt);
    if (isIntValueFull) {
      if (caretPosition >= inputValue.length) {
        this.input.nativeElement.value = withoutLastDigit;
      } else {
        this.input.nativeElement.value = overrideNextDigit;
      }
      this.input.nativeElement.setSelectionRange(caretPosition, caretPosition);
      this.displayValue = this.input.nativeElement.value;
      this.dispararMudanca();
      return;
    }
    const isLengthExceed = inputValue.length > maxEntries;
    let arrayFormatted: string[];
    const isCaretOneBeforeSeparator = caretPosition - 1 === this.numInt;
    let arrayNoComma = this.getArrayNoComma(inputValue);
    if (isLengthExceed) {
      if (caretPosition >= inputValue.length) {
        arrayNoComma = this.getArrayNoComma(withoutLastDigit);
      } else if (isCaretOneBeforeSeparator) {
        const strNearDecimal = inputValue.slice(0, caretPosition) + inputValue.slice(caretPosition + 1);
        arrayNoComma = this.getArrayNoComma(strNearDecimal);
      } else {
        arrayNoComma = this.getArrayNoComma(overrideNextDigit);
      }
    }

    arrayFormatted = this.formatInput(arrayNoComma);
    const newValueFormatted = arrayFormatted.join("");
    this.displayValue = newValueFormatted;
    this.input.nativeElement.value = newValueFormatted;
    if (isInserting && inputValue.length > 1) {
      this.setCursorPosition(isCaretOneBeforeSeparator, caretPosition);
    } else if (!isInserting && inputValue.length > 1) {
      if (caretPosition === this.numInt) {
        this.input.nativeElement.setSelectionRange(caretPosition + 1, caretPosition + 1);
      } else {
        this.input.nativeElement.setSelectionRange(caretPosition, caretPosition);
      }
    }
    this.dispararMudanca();
  }


  dispararMudanca(event?) {
    const newValue = event ? event.target.value : this.displayValue;
    this.modelChange.emit(parseFloat(newValue.replace(",", ".")));
  }

  private formatInput(arrayNoComma: Array<string>) {
    if (arrayNoComma.length - 1 > this.numFract) {
      arrayNoComma.splice((arrayNoComma.length - this.numFract), 0, this.numSep);
    } else {
      while (arrayNoComma.length <= this.numFract) {
        arrayNoComma.unshift("0");
      }
      arrayNoComma.splice((arrayNoComma.length - this.numFract), 0, this.numSep);
    }
    const arrayFormatted = arrayNoComma;
    return this.formatLeftZeros(arrayFormatted);
  }
  private getArrayNoComma(str: string) {
    return this.removeComma(str).split("");
  }
  private removeComma(value: string) {
    return value.replace(new RegExp(this.numSep, "g"), "");
  }
  private formatLeftZeros(arrayFormatted: Array<string>) {
    const intPart = arrayFormatted.slice(0, this.getDecimalSeparatorIndex(arrayFormatted));
    const filledWithZeros = intPart.every(digit => digit === "0");
    if (intPart.length > 0 && filledWithZeros) {
      this.removeMultipleZeros(arrayFormatted);
    } else {
      const deleteZero = intPart.length > 1 && (intPart.indexOf("0") < intPart.indexOf(intPart.find(digit => digit !== "0")));
      if (deleteZero) {
        const newIntPart = intPart.join("").replace(/0/g, '');
        arrayFormatted.splice(0, this.getDecimalSeparatorIndex(arrayFormatted), newIntPart);
      }
    }
    return arrayFormatted;
  }
  private setCursorPosition(caretOneBeforeDecimal: boolean, caretPosition: number) {
    if (!caretOneBeforeDecimal) {
      this.input.nativeElement.setSelectionRange(caretPosition, caretPosition);
    } else {
      this.input.nativeElement.setSelectionRange(caretPosition + 1, caretPosition + 1);
    }
  }
  private getDecimalSeparatorIndex(arr: Array<string>) {
    return arr.indexOf(this.numSep);
  }
  private removeMultipleZeros(arr: Array<string>) {
    return arr.splice(0, this.getDecimalSeparatorIndex(arr), "0");
  }
  private formatInsertedValue() {
    let fractAux = "";
    for (let i = 0; i < this.numFract; i++) {
      fractAux += "0";
    }
    return String(this.model) + this.numSep + fractAux;
  }
}
