import { Component, Input } from '@angular/core';

import { IPButtonComponent } from './ip-button.component';

@Component({
    selector: 'ip-button-icon',
    template: `
      <button mat-icon-button [disabled]="disabled">
        <ng-content></ng-content>
      </button>`
})
export class IPButtonIconComponent extends IPButtonComponent { 
  @Input() disabled = false;
}
