import {
  IPrescricao,
  ISolicitacaoAtendimento,
  IClinica,
  IAgenda,
  IAutorizacaoSolicitacao,
  IOrcamento,
  IPrescricaoMovimentoInsumo,
  IPrescricaoSolicitaAgenda,
  IPrescricaoAssinatura,
  IProfissional,
  IUsuario,
  IPrescricaoItem,
  IPrescricaoMaterialEnfermagem,
  IPrescricaoGrupo,
} from "@gemed-core/interfaces/gemed.interface";
import {
  PrescricaoConciliado,
  TipoCiclo,
  PrescricaoSituacaoNF,
  SimNao,
} from "@gemed-core/enums/gemed.enum";
import { PrescricaoSituacao } from "src/app/assistencial/central-medico/shared/models/PrescricaoSituacao.enum";
import { Via } from "./via.model";
import { Paciente } from "src/app/assistencial/shared/models";
import { PrescricaoDetalhe } from "./prescricaoDetalhe";
import { PrescricaoProtocolo } from "./prescricaoProtocolo.model";
import { FormaPrescrever } from "./formaPrescreve.enum";
import { Profissional } from "./profissional.model";
import { TipoDose } from "./tipoDose.enum";
import { LibGeral } from "@gemed-core/libraries/libGeral";


interface IAgendasRadioterapia {
  IdPrescricao: number;
  PrescricaoIdentificacao: string;
  ProtocoloSigla: string;
  Ciclo: number;
  AreaDescricao: string;
  IdArea: number;
  Data: Date;
  PrescricaoSituacao: string;
}

export interface ListaCancelamentoPrescricoes {
  ListaCancelar: Prescricao[];
  ListaExcluir: Prescricao[];
  ListaNaoExcluir: Prescricao[];
}

export class Prescricao implements IPrescricao {
  // public get Identificacao(): string {
  //   return this._Identificacao;
  // }
  // public set Identificacao(value: string) {
  //   this._Identificacao = value;
  // }

  public IdentificacaoComProtocoloGrupo(): string {
    let retorno = "";

    if (LibGeral.estaPreenchido(this.Protocolos) && this.Protocolos.length > 0) {
      retorno = this.Protocolos.reduce((acc, _protocoloGrupo) => {
        if (acc) {
          acc += ", ";
        }
        return (acc +=
          _protocoloGrupo.Protocolo.Sigla +
          " " +
          _protocoloGrupo.Protocolo.Descricao);
      }, "");
    }

    return retorno;
  }

  // set IdentificacaoComProtocoloGrupo(value) {
  //   setTimeout(() => (this.IdentificacaoComProtocoloGrupo = value));
  // }

  public DevePreencherDataPrescricao(): boolean {
    return (
      (LibGeral.estaPreenchido(this.Agenda) && this.Agenda.Id > 0) ||
      this.Situacao === PrescricaoSituacao.AtendimentoExterno
    );
  }
  Id: number;
  IdPrescricao: number;
  Data?: any;
  DataDescricao: string;
  Peso: number;
  Altura: number;
  AgendasRadioterapia?: IAgendasRadioterapia[];
  SuperficieCorporal: number;
  TempoAplicacao: number;
  SolicitacaoAtendimentos: ISolicitacaoAtendimento[];
  TipoDose: TipoDose;
  IdUsuarioAutorizou?: number;
  DataAutorizou?: Date;
  TAxilar: number;
  Situacao: PrescricaoSituacao;
  SituacaoValorEnum: string;
  NrVersao: number;
  Conciliado: PrescricaoConciliado;
  TipoCiclo: TipoCiclo;
  Ciclo: number;
  CicloExtenso: string;
  Sessao: number;
  Periodicidade: string;
  SituacaoNf: PrescricaoSituacaoNF;
  ImprimiuEvolucao: SimNao;
  ObservacaoSugerida: string;
  FaturamentoProtocolos: any[];
  PossuiAjuste: SimNao;
  QtdVia: number;
  QtdVia2: number;
  TemConciliacao: boolean;
  Evolucao: string;
  Conduta: string;
  versaoBras: number;
  FC: number;
  FR: number;
  PA1: number;
  PA2: number;
  Creatinina: number;
  Curva: number;
  HoraInicio: string;
  HoraFim: string;
  SituacaoDetalhe: string;
  MedicoAssinante: string;
  EnfermeiroAssinante: string;
  FarmaceuticoAssinante: string;
  CabecalhoLivre: string;
  DataAssinaturaMedico?: Date;
  DataAssinaturaFarmacia?: Date;
  DataAssinaturaEnfermagem?: Date;
  ProfissionalMedicoAssinou: Profissional;
  ProfissionalFarmaciaAssinou: Profissional;
  ProfissionalEnfermagemAssinou: Profissional;
  ProfissionalMed: Profissional;
  ProfissionalAssis: Profissional;
  ProfissionalAplicacao: Profissional;
  Via: Via;
  Via2: Via;
  Paciente: Paciente;
  Clinica: IClinica;
  Agenda: IAgenda;
  Autorizacao: any;
  AutorizacaoSolicitacao: IAutorizacaoSolicitacao;
  Orcamento: IOrcamento;
  Usuario: IUsuario;
  Fatura: any;
  Detalhes: PrescricaoDetalhe[] = [];
  Materiais: IPrescricaoMaterialEnfermagem[] = [];
  Grupos: IPrescricaoGrupo[];
  Protocolos: PrescricaoProtocolo[] = [];
  MovimentoInsumo: IPrescricaoMovimentoInsumo[];
  PrescricaoSolicitaAgenda: IPrescricaoSolicitaAgenda;
  PrescricaoAssinaturas: IPrescricaoAssinatura[];
  IdentificacaoCompleta: string;
  UsuarioSolicitou: IUsuario;
  DataSolicitou: Date;
  FormaPrescrever: FormaPrescrever;
  FormaPrescreverValor: string;
  FaturamentoProtocoloDevolvida: boolean;

  TemRastreabilidade: boolean;
  ProtocoloPrePrescricao: SimNao;
  Revisada: boolean;
  VersaoEditor: string;
  Identificacao: string;
  IdentificacaoPrescricaoCopia: string;
}
