import { createAction, props } from '@ngrx/store';
import { MedicoCentralDoMedico } from 'src/app/assistencial/central-medico/shared/models/medicoCentralDoMedico.model';
import { PacienteTeleConsulta } from 'src/app/assistencial/central-medico/shared/models/pacienteTeleConsulta.model';
import { type } from '../../../../core/libraries';


export const ActionTypes = {
  CARREGAR_AVISO_CENTRALMEDICO: type('[central_medico] Consultando aviso sobre o profissional.'),
  CARREGAR_AVISO_CENTRALMEDICO_SUCESSO: type('[central_medico] Dados de aviso sobre o profissional carregados do sucesso.'),
  PACIENTE_PRONTUARIO_CENTRALMEDICO: type('[central_medico] Apresentar o prontuário do paciente selecionado.'),
  TELECONSULTA_PACIENTE: type('[central_medico] Apresentar a teleconsulta no prontuário do paciente selecionado.'),
  REMOVER_TELECONSULTA_PACIENTE_SELECIONADO: type('[central_medico] Remover paciente da teleconsulta.'),
  ATUALIZAR_FILTRO_DATA: type('[central_medico] Atualizar o filtro de data.'),
  ATUALIZAR_SITUACOES: type('[central_medico] Atualizar as situações.'),

}

export const atualizarFiltroData = createAction(
  ActionTypes.ATUALIZAR_FILTRO_DATA,
  props<{payload: {
    DataInicio: Date,
    DataFim: Date
  }
}>()
)

export const carregaAvisoCentralMedicoAction = createAction(
  ActionTypes.CARREGAR_AVISO_CENTRALMEDICO,
  props<{payload: MedicoCentralDoMedico}>()
)

export const carregaAvisoCentralMedicoSucessoAction = createAction(
  ActionTypes.CARREGAR_AVISO_CENTRALMEDICO_SUCESSO
)

export const pacienteProntuarioCentralMedicoAction = createAction(
  ActionTypes.PACIENTE_PRONTUARIO_CENTRALMEDICO,
  props<{payload?: number}>()
)

export const teleConsultaoCentralMedicoAction = createAction(
  ActionTypes.TELECONSULTA_PACIENTE,
  props<{payload?: PacienteTeleConsulta}>()
)

export const removerTeleMedicinaSelecionadoAction = createAction(
  ActionTypes.REMOVER_TELECONSULTA_PACIENTE_SELECIONADO
)

export const atualizarSituacoes = createAction(
  ActionTypes.ATUALIZAR_SITUACOES,
  props<{payload: string[]}>()
)

