import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Renderer2,
  RendererFactory2
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import environment from "@environment";
import { AuthService } from "@gemed-core/auth/auth.service";
import { IPDialogService } from "@gemed-core/dialog/ipDialog.service";
import { GenericInterceptor } from "@gemed-core/interceptors/genericInterceptor";
import { RefreshTokenInterceptor } from "@gemed-core/interceptors/RefreshTokenInterceptor";
import { IPRoteamentoService } from "@gemed-core/ip-services/roteamento.service";
import { MonitoringService } from "@gemed-core/logging.service";
import { IPToastService } from "@gemed-core/toast/ipToast.service";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { State, Store, StoreModule } from "@ngrx/store";
// Gemed
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LogService } from "@pep-assistencial-service/log.service";
import { ProfissionalService } from "@pep-assistencial-service/profissional.service";
import { MenuEffects } from "@pep-assistencial-store/effects/menu.effect";
import { MenuUsuarioStoreService } from "@pep-assistencial-store/store-service/menuUsuarioStoreService.service";
import { fromEvent, merge } from "rxjs";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { IPButtonModule } from "./core-ui/components/button/ip-button.module";
import { IPChartModule } from "./core-ui/components/chart";
import { ListaMultiClinicasComponent } from "./core-ui/components/lista-multi-clinicas/lista-multi-clinicas.component";
import { ListaMultiClinicasModule } from "./core-ui/components/lista-multi-clinicas/lista-multi-clinicas.module";
import { IPMenuModule } from "./core-ui/components/menu";
import { IPNotificationModule } from "./core-ui/components/notification";
import { IPProgressModule } from "./core-ui/components/progress";
import { IPSideNavLayoutModule } from "./core-ui/components/side-nav";
import { IPCoreModule } from "./core-ui/core.module";
import { PaginaNaoEncontradaComponent } from "./core-ui/pagina-nao-encontrada";
import { IPExceptionHandler } from "./core/exception/ipExceptionHandle.service";
import { IPHttpBaseInterceptor } from "./core/interceptors/IPHttpBaseInterceptor";
import { OfflineAction, OnlineAction } from "./core/store/actions/app.actions";
import { AppEffects } from "./core/store/effects/app.effect";
import { AuthEffects } from "./core/store/effects/auth.effect";
import { RouteEffects } from "./core/store/effects/route.effect";
import { metaReducers } from "./core/store/enhancers";
import { RootReducer } from "./core/store/reducers";
import { GemedState } from "./core/store/state";
import { DashBoardComponent } from "./dashboard/dashboard.component";
import { DashBoardModule } from "./dashboard/dashboard.module";
import { GlobalErrorHandler } from "./error/globalErrorHandler";
import { ServerErrorInterceptor } from "./error/serverErrorInterceptor";
import { ParametrosManagerModule } from "./parametrosManager/parametrosManager.module";
import { LogInComponent } from "./users/log-in/log-in.component";
import { LoginPageComponent } from "./users/login-page/login-page.component";
import { UsersModule } from "./users/users.module";
registerLocaleData(localePt);

const OFF_LINE = "offline";

const materialModules = [
  MatDialogModule,
  MatSnackBarModule,
  MatToolbarModule,
  IPSideNavLayoutModule,
];

// Renderer,
export const HttpLoaderFactory = (http: HttpClient): any => {
  return new TranslateHttpLoader(http, `assets/i18n/`, ".json");
};

export const getRenderer = (rendererFactory: RendererFactory2): Renderer2 => {
  return rendererFactory.createRenderer(null, null);
};

export const TranslateProvider = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};
const importsModules = [
  BrowserModule,
  BrowserAnimationsModule,
  CommonModule,
  MatIconModule,
  DashBoardModule,
  ParametrosManagerModule,
  MatTooltipModule,
  IPProgressModule,
  IPMenuModule,
  IPButtonModule,
  IPNotificationModule,
  IPChartModule,
  IPCoreModule,
  FlexLayoutModule,
  UsersModule,
  ListaMultiClinicasModule,
  ...materialModules,
  HttpClientModule,
  AppRoutingModule,

  TranslateModule.forRoot(TranslateProvider),
  StoreModule.forRoot(RootReducer, {
    metaReducers,
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: "router",
  }),
  EffectsModule.forRoot([AppEffects, AuthEffects, RouteEffects, MenuEffects]),
  ServiceWorkerModule.register(`./ngsw-worker.js`, { enabled: environment.production }),
  // StoreDevtoolsModule.instrument({
  //   maxAge: 25, // Retains last 25 states
  //   logOnly: environment.production, // Restrict extension to log-only mode
  // }),
];
@NgModule({
  declarations: [
    AppComponent,
    PaginaNaoEncontradaComponent,
    LoginPageComponent,
  ],
  imports: importsModules,
  providers: [
    MonitoringService,
    IPRoteamentoService,
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: IPExceptionHandler },
    { provide: Renderer2, useFactory: getRenderer, deps: [RendererFactory2] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GenericInterceptor,
      deps: [State, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IPHttpBaseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      deps: [
        AuthService,
        IPToastService,
        GenericInterceptor,
        HttpClient,
        MatDialog,
        State,
        Store,
      ],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "pt" },
    IPDialogService,
    LogService,
    TranslateService,
    ProfissionalService,
    MenuUsuarioStoreService,
  ],
  exports: [PaginaNaoEncontradaComponent, LoginPageComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private store: Store<GemedState>
  ) {
    this.init();
    merge(fromEvent(window, "online"), fromEvent(window, "offline")).subscribe(
      (x) => {
        if (event.type.toLowerCase() === OFF_LINE) {
          this.store.dispatch(new OfflineAction());
        } else {
          this.store.dispatch(new OnlineAction());
        }
      }
    );
  }

  init(): void {

    this.translate.addLangs(environment.idiomasSuportados);
    this.translate.setDefaultLang(environment.idiomasSuportados[0]);
    this.translate.use(environment.idiomasSuportados[0]);
  }
}
