// Referência: https://getmdl.io/components/index.html#tables-section
import { Directive, Input, ElementRef, Host, Renderer2, OnInit, Optional, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { IPRowDirective } from './ip-row.directive';
import { IPCellInlineEditDirective } from './ip-cell-inline-edit.directive';
import { LibGeral } from '../../../core/libraries/libGeral';

@Directive({
  selector: '[ip-cell]',
  // eslint-disable-next-line
  host: {
    '[class.mdl-data-table__cell--non-numeric]': '!_number',
    '[class.wrap-content]': '_wrap'
  }
})
export class IPCellDirective implements OnDestroy {
  /**
   * Define se o conteúdo da cell será numérico, para definir o alinhamento do mesmo.
   * Para conteúdo numérico o conteúdo é alinhado a direita.
   *
   * @type {boolean}
   */
  @Input() set number(value: boolean) { this._number = !!value; }

  /**
   * Nome da propriedade vinculada a celula.
   * Somente necessário informar caso a celula permita edição.
   *
   * @type {string}
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('model') modelName: string;
  @Input() modelDisplay: string = "Texto";

  /**
   * Define se a celula pode aumentar sua altura caso o conteúdo não caiba em uma linha.
   * Default: TRUE.
   *
   * @type {boolean}
   */
  @Input() set wrap(value: boolean) { this._wrap = !!value; }

  // TODO - IMPLEMENTAR controle de mudança !!!!!!!!!!!!!
  public get edited(): boolean { return true; }

  /**
   * Editor da celula para o modo de edição.
   *
   * @type {IPCellInlineEditDirective<any>}
   */
  public editor: IPCellInlineEditDirective<any>;
  public isEditing = false;
  public row: IPRowDirective;
  private _number = false;
  private _wrap = true;
  private element: ElementRef;
  private renderer: Renderer2;
  @Input() obterValoresComplexos: Function;

  constructor(ngElement: ElementRef, renderer: Renderer2, @Optional() @Host() row: IPRowDirective) {
    this.element = ngElement;
    this.renderer = renderer;
    this.row = row;
  }

  restaurarValor(): void {
    if (this.modelName && this.row) {
      const valor = this.obterValoresComplexos
        ? this.obterValoresComplexos(this, this.modelDisplay)
        : this.getCurrentValue();
      if (valor) {
        if (typeof (valor) === 'string') {

          this.element.nativeElement.innerText = valor;
        } else if (this.modelDisplay in valor) {
          this.element.nativeElement.innerText = valor[this.modelDisplay];
        }
      }

    }
  }
  // eslint-disable-next-line
  ngAfterViewInit(@Host() row: IPRowDirective): void {
    if (this.row) {
      this.row.addCell(this);
    }
  }
  ngOnDestroy(): void {
    this.editor = null;
  }

  setarEditor(editor: IPCellInlineEditDirective<any>): void {
    if (!this.obterValoresComplexos && editor.obterValoresComplexos) {
      this.obterValoresComplexos = editor.obterValoresComplexos;
    }
    this.editor = editor;
  }
  hasEditor(): boolean {
    return LibGeral.estaPreenchido(this.editor);
  }

  getModelName(): string {
    return this.modelName;
  }
  getCurrentValue(): any {
    return this.row.getCurrentValue(this);
  }
  getEditorValue(): any {
    return this.editor.getValue();
  }

  showEditor(): void {
    if (this.editor.allowEdit) {
      this.editor.show();
    }
  }
  hideEditor(): void {
    if (this.editor.allowEdit) {
      this.editor.hide();
      this.restaurarValor();
    }
  }

}
