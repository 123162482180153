<label class="gemed-select-label">{{ label | translate}}</label>
<div #viewContainerRef>
  <div class="gemed-select-container" [class.gemed-select-container-borda]="mostrarListagem"
    (click)="handleAlterarVisibilidadeLista()">
    <input class="gemed-select-campopesquisa" placeholder="{{placeholder | translate}}"
      (keyup)="(handleFiltrarItens(inputFiltro.value))" [class.gemed-select-campopesquisa-selecionado]="mostrarListagem"
      #inputFiltro>
    <div class="gemed-select-icone" [class.gemed-select-icone-selecionado]="mostrarListagem">
      <img src="/assets/images/farma/arrow-down.svg">
    </div>
  </div>
  <div *ngIf="this.mostrarListagem" class="gemed-select-container-items">
    <div *ngFor="let option of listaOpcoes;" class="gemed-select-container-item" (click)="handleSelecionarOpcao(option)">
      <div>
        <div *ngIf="deveMostrarIcone" [style.backgroundColor]="option.color" class="gemed-select-container-item-icone" ></div>
        <div>{{option.text}}</div>
      </div>
    </div>
  </div>
</div>